import axios from 'axios';
// import config from '../config';
import * as Config from '../config';

const myLocation = {
  GET_MY_LOCATION_LIST: 'GET_MY_LOCATION_LIST',
  SET_MY_LOCATION_LIST: 'SET_MY_LOCATION_LIST',
  POST_MY_LOCATION_LIST: 'POST_MY_LOCATION_LIST',
  DELETE_MY_LOCATION_LIST: 'DELETE_MY_LOCATION_LIST',
  GET_MY_LOCATION_HISTORY: 'GET_MY_LOCATION_HISTORY',
  POST_FAVORITE: 'POST_FAVORITE',
  DELETE_FAVORITE: 'DELETE_FAVORITE',
  GET_MY_LOCATION_DONG: 'GET_MY_LOCATION_DONG',
  GET_MY_LOCATION_DONG_BCODE: 'GET_MY_LOCATION_DONG_BCODE',
  POST_MY_LOCATION_LANDMARK: 'POST_MY_LOCATION_LANDMARK',
  GET_MY_LOCATION_CHECK: 'GET_MY_LOCATION_CHECK',
  GET_LOCATION_VO: 'GET_LOCATION_VO',
};
// 우리동네 리스트
export function getMyLocationList() {
  let promise;
  promise = axios.get(
    `${Config.default.API_BASE_URL}/mylocation/list`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 우리동네 추가
export function postMyLocationList(param) {
  let promise;
  promise = axios.post(
    `${Config.default.API_BASE_URL}/mylocation/list?locationType=${
      param.locationType
    }&locationCode=${param.locationCode}`,
    JSON.stringify(param),
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 우리동네 삭제
export function deleteMyLocationList(myLocationSeq) {
  let promise;
  promise = axios.delete(
    `${Config.default.API_BASE_URL}/mylocation/list/${myLocationSeq}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 우리동네 즐겨찾기 추가
export function postFavorite(myLocationSeq) {
  let promise;
  const token_request_info = {
    myLocationSeq,
  };
  promise = axios.post(
    `${Config.default.API_BASE_URL}/mylocation/list/favorite/${myLocationSeq}`,
    token_request_info,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 우리동네 즐겨찾기 삭제
export function deleteFavorite(myLocationSeq) {
  let promise;
  promise = axios.delete(
    `${Config.default.API_BASE_URL}/mylocation/list/favorite/${myLocationSeq}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 최근검색한동네
export function getMyLocationHistory() {
  let promise;
  promise = axios.get(
    `${Config.default.API_BASE_URL}/mylocation/list/history`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 시구동 검색
export function getMyLocationDong(type, code) {
  let promise;
  promise = axios.get(
    `${Config.default.API_BASE_URL}/mylocation/dong?type=${type}&code=${code}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 시구동 검색
export function getMyLocationDongBcode(bcode) {
  let promise;
  promise = axios.get(
    `${Config.default.API_BASE_URL}/mylocation/dong/${bcode}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 랜드마크 신청
export function postMyLocationLandmark(param) {
  let promise;
  promise = axios.post(
    `${Config.default.API_BASE_URL}/mylocation/landmark`,
    param,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 우리동네 중복체크
export function getMylocationCheck(locationType, locationCode) {
  let promise;
  promise = axios.get(
    `${
      Config.default.API_BASE_URL
    }/mylocation/check?locationType=${locationType}&locationCode=${locationCode}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

export function getLocationVo(lat, lng) {
  let promise;
  promise = axios.get(
    `${
      Config.default.API_BASE_URL
    }/common/location/list/latlng?lat=${lat}&lng=${lng}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

export default myLocation;
