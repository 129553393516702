import { createAction, handleActions } from 'redux-actions';
import moment from 'moment';
import { pender } from 'redux-pender';

import * as UnicroService from '../../actions/service/UnicroService';

// 판매자 거래 상태변경
export const putSellerCancel = createAction(
  UnicroService.default.PUT_SELLER_CANCEL,
  UnicroService.putSellerCancel,
);
export const putSellerAccept = createAction(
  UnicroService.default.PUT_SELLER_ACCEPT,
  UnicroService.putSellerAccept,
);
export const postReturnAccept = createAction(
  UnicroService.default.POST_RETURN_ACCEPT,
  UnicroService.postReturnAccept,
);
export const putRetrunCheck = createAction(
  UnicroService.default.PUT_RETURN_CHECK,
  UnicroService.putRetrunCheck,
);
export const putSellerCancelAccept = createAction(
  UnicroService.default.PUT_SELLER_CANCEL_ACCEPT,
  UnicroService.putSellerCancelAccept,
);
export const putSellerCancelReject = createAction(
  UnicroService.default.PUT_SELLER_CANCEL_REJECT,
  UnicroService.putSellerCancelReject,
);
// 유니크로 배송
export const postSellerDelivery = createAction(
  UnicroService.default.POST_SELLER_DELIVERY,
  UnicroService.postSellerDelivery,
);
export const putSellerDelivery = createAction(
  UnicroService.default.PUT_SELLER_DELIVERY,
  UnicroService.putSellerDelivery,
);
export const getOrderUnicroToken = createAction(
  UnicroService.default.GET_ORDER_UNICRO_TOKEN,
  UnicroService.getOrderUnicroToken,
);

// 안전결제 배송등록 TEMP 데이터 및 RETURN URL
export const setUnicroDeliveryTemp = createAction(
  UnicroService.default.SET_UNICRO_DELIVERY_TEMP,
  UnicroService.setUnicroDeliveryTemp,
);
export const setUnicroDeliveryReturnURL = createAction(
  UnicroService.default.SET_UNICRO_DELIVERY_RETURN_URL,
  UnicroService.setUnicroDeliveryReturnURL,
);

// 구매자 반품 등록
export const postBuyerReturnDelivery = createAction(
  UnicroService.default.POST_BUYER_RETURN_DELIVER,
  UnicroService.postBuyerReturnDelivery,
);
export const putBuyerReturnDelivery = createAction(
  UnicroService.default.PUT_BUYER_RETURN_DELIVER,
  UnicroService.putBuyerReturnDelivery,
);

// 배송지수정
export const getSellerDelivery = createAction(
  UnicroService.default.GET_SELLER_DELIVERY,
  UnicroService.getSellerDelivery,
);
export const getBuyerDelivery = createAction(
  UnicroService.default.GET_BUYER_DELIVERY,
  UnicroService.getBuyerDelivery,
);

const initialState = {
  // 공통으로 사용하는 업데이트 확인
  onSucessOrderStatusUpdate: moment(),
  onFailureOrderStatusUpdate: moment(),
  onFailureMessage: { code: 0, message: '' },

  unicro_token: '',
  unicro_delivery_temp: null,
  unicro_delivery_return_url: '',

  // 배송지수저어 데이터
  delivery_edit_info: {},
};

export default handleActions(
  {
    ...pender({
      type: UnicroService.default.PUT_SELLER_CANCEL,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSucessOrderStatusUpdate: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          onFailureOrderStatusUpdate: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : { code: 500, message: 'SERVER ERROR' },
        };
      },
    }),
    ...pender({
      type: UnicroService.default.PUT_SELLER_ACCEPT,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSucessOrderStatusUpdate: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          onFailureOrderStatusUpdate: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : { code: 500, message: 'SERVER ERROR' },
        };
      },
    }),
    ...pender({
      type: UnicroService.default.POST_RETURN_ACCEPT,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSucessOrderStatusUpdate: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          onFailureOrderStatusUpdate: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : { code: 500, message: 'SERVER ERROR' },
        };
      },
    }),
    ...pender({
      type: UnicroService.default.PUT_RETURN_CHECK,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSucessOrderStatusUpdate: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          onFailureOrderStatusUpdate: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : { code: 500, message: 'SERVER ERROR' },
        };
      },
    }),
    ...pender({
      type: UnicroService.default.POST_SELLER_DELIVERY,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSucessOrderStatusUpdate: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          onFailureOrderStatusUpdate: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : { code: 500, message: 'SERVER ERROR' },
        };
      },
    }),
    ...pender({
      type: UnicroService.default.GET_ORDER_UNICRO_TOKEN,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          unicro_token: data.data,
        };
      },
    }),

    // 구매자 취소요청 응답
    ...pender({
      type: UnicroService.default.PUT_SELLER_CANCEL_ACCEPT,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSucessOrderStatusUpdate: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          onFailureOrderStatusUpdate: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : { code: 500, message: 'SERVER ERROR' },
        };
      },
    }),
    ...pender({
      type: UnicroService.default.PUT_SELLER_CANCEL_REJECT,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSucessOrderStatusUpdate: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          onFailureOrderStatusUpdate: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : { code: 500, message: 'SERVER ERROR' },
        };
      },
    }),
    ...pender({
      type: UnicroService.default.POST_BUYER_RETURN_DELIVER,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSucessOrderStatusUpdate: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          onFailureOrderStatusUpdate: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : { code: 500, message: 'SERVER ERROR' },
        };
      },
    }),

    // 안전결제 배송지 수정 데이터 가져오기
    ...pender({
      type: UnicroService.default.GET_SELLER_DELIVERY,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          delivery_edit_info: data.data,
        };
      },
    }),
    ...pender({
      type: UnicroService.default.GET_BUYER_DELIVERY,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          delivery_edit_info: data.data,
        };
      },
    }),

    // 안전결제 배송지 수정
    ...pender({
      type: UnicroService.default.PUT_SELLER_DELIVERY,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSucessOrderStatusUpdate: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          onFailureOrderStatusUpdate: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : { code: 500, message: 'SERVER ERROR' },
        };
      },
    }),
    ...pender({
      type: UnicroService.default.PUT_BUYER_RETURN_DELIVER,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSucessOrderStatusUpdate: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          onFailureOrderStatusUpdate: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : { code: 500, message: 'SERVER ERROR' },
        };
      },
    }),

    // 안전결제 배송등록
    [UnicroService.default.SET_UNICRO_DELIVERY_TEMP]: (state, action) => {
      return {
        ...state,
        unicro_delivery_temp: action.payload,
      };
    },
    [UnicroService.default.SET_UNICRO_DELIVERY_RETURN_URL]: (state, action) => {
      return {
        ...state,
        unicro_delivery_return_url: action.payload,
      };
    },
  },
  initialState,
);
