/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { string } from 'prop-types';
import { useSelector } from 'react-redux';

import { getImgSrc } from '../../../common/parser';

const BasicImage = props => {
  const domain = useSelector(({ init }) => init.domain);

  const { src } = props;
  const replaceSrc = getImgSrc(src, domain.Image);

  return <img {...props} src={replaceSrc} />;
};

BasicImage.propTypes = {
  src: string.isRequired,
};

export default BasicImage;
