import { createAction, handleActions } from 'redux-actions';

export const Actions = {
  OPEN: 'deliveryPopUp/OPEN',
  CLOSE: 'deliveryPopUp/CLOSE',
  INIT: 'deliveryPopUp/INIT',
};

const initialState = {
  show: false,
  force: false,
  option: false,
};

export const open = createAction(Actions.OPEN);
export const close = createAction(Actions.CLOSE);

export default handleActions(
  {
    [Actions.OPEN]: (state, { payload = false }) => {
      return { show: true && !state.force, option: payload };
    },
    [Actions.CLOSE]: (state, { payload = false }) => {
      return { ...state, show: false, force: payload || state.force };
    },
  },
  initialState,
);
