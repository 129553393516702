import React from 'react';
import { useSelector } from 'react-redux';
import { string, func, bool, oneOfType, node, element } from 'prop-types';
import classNames from 'classnames';

import { authAlert } from '../../../common/dom';

import styles from './AuthButton.module.scss';

const AuthButton = ({
  className,
  onClick,
  onBlocking,
  external,
  redirectUrl,
  disabled,
  children,
}) => {
  const { token } = useSelector(({ userLoginInfo }) => userLoginInfo);

  const clickButton = async e => {
    e.stopPropagation();
    e.preventDefault();

    if (token) {
      onClick();
    } else {
      authAlert({
        external,
        redirectUrl,
      });
    }
  };

  return (
    <button
      className={classNames(className, onBlocking && !token && styles.blocking)}
      type="button"
      onClick={clickButton}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

AuthButton.propTypes = {
  className: string,
  children: oneOfType([string, node, element]),
  onClick: func,
  onBlocking: bool,
  disabled: bool,
  external: string,
  redirectUrl: string,
};

AuthButton.defaultProps = {
  className: '',
  onClick: () => {},
  onBlocking: false,
  disabled: false,
  children: null,
  external: '',
  redirectUrl: null,
};

export default AuthButton;
