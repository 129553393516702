import axios from 'axios';
import config from '../config';
import { getHeaders } from '../apiUtil';

const baseURL = `${config.API_BASE_URL}`;

export const getMainInfo = (isV1 = false, getPath = false) => {
  const url = isV1 ? '/mainpage' : '/v1/mainpage';
  if (getPath) return `${baseURL}${url}`;
  return axios({
    method: 'get',
    url,
    baseURL,
    headers: getHeaders(),
  });
};

export const getMainUserInfo = (getPath = false) => {
  const url = '/mainpage/notice-only/noticeOnly';
  if (getPath) return `${baseURL}${url}`;
  return axios({
    method: 'get',
    url,
    baseURL,
    headers: getHeaders(),
  });
};

export const putBannerHit = bannerSeq => {
  const url = `/mainpage/banner/hit/${bannerSeq}`;
  return axios({
    method: 'put',
    url,
    baseURL,
    headers: getHeaders(),
  });
};

export const putMustReadHit = noticeSeq => {
  const url = `/mainpage/must-read/hit/${noticeSeq}`;
  return axios({
    method: 'put',
    url,
    baseURL,
    headers: getHeaders(),
  });
};

export const putNoticeHit = noticeSeq => {
  const url = `/mainpage/notice/hit/${noticeSeq}`;
  return axios({
    method: 'put',
    url,
    baseURL,
    headers: getHeaders(),
  });
};
