import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';

export const Actions = {
  // recommend
  SET_RECOMMEND: 'STANDARDPRICE/SET_RECOMMEND',
  // main
  SET_MAIN: 'STANDARDPRICE/SET_STANDARD_RESULT',
  // history
  SET_HISTORY_LIST: 'STANDARDPRICE/SET_HISTORY_LIST',
  UPDATE_HISTORY_LIST: 'STANDARDPRICE/UPDATE_HISTORY_LIST',
  CLEAR_HISTORY_LIST: 'STANDARDPRICE/CLEAR_HISTORY_LIST',
  // completeYn
  UPDATE_COMPLETE_YN: 'STANDARDPRICE/UPDATE_COMPLETE_YN',
  UPDATE_EXPOSURES: 'STANDARDPRICE/UPDATE_EXPOSURES',
  // clear
  CLEAR_RECOMMEND_AND_MAIN: 'STANDARDPRICE/CLEAR_RECOMMEND_AND_MAIN',
  CLEAR: 'STANDARDPRICE/CLEAR',
};

const initialState = {
  isCompleteYn: true,
  exposures: {
    adminExposure: null,
    selectModelName: '',
    selectOptionName: '',
  },
  recommend: null,
  main: null,
  historyList: {
    autoSave: true,
    list: null,
  },
};

export const setRecommend = createAction(Actions.SET_RECOMMEND, obj => ({
  data: obj,
}));

export const setMain = createAction(Actions.SET_MAIN, obj => obj);

export const setHistoryList = createAction(
  Actions.SET_HISTORY_LIST,
  obj => obj,
);

export const updateHistoryList = createAction(
  Actions.UPDATE_HISTORY_LIST,
  obj => obj,
);
export const clearHistoryList = createAction(Actions.CLEAR_HISTORY_LIST);

export const updateIsCompleteYn = createAction(
  Actions.UPDATE_COMPLETE_YN,
  bool => bool,
);
export const updateExposures = createAction(
  Actions.UPDATE_EXPOSURES,
  obj => obj,
);
export const clearRecommendAndMain = createAction(
  Actions.CLEAR_RECOMMEND_AND_MAIN,
);

export const clear = createAction(Actions.CLEAR);

export default handleActions(
  {
    [Actions.SET_RECOMMEND]: (state, action) => {
      const { data } = action.payload;
      return produce(state, d => {
        const draft = d;
        draft.exposures.adminExposure = data.adminExposure;
        draft.recommend = {
          historyKeywords: {
            autoSave: data.historyKeywords.autoSave === 'ON',
            list: data.historyKeywords.historyKeywordList.map(
              history => history.keyword,
            ),
          },
          popularWords: data.popularWords,
          recommendKeywords: data.recommendKeywords,
        };
      });
    },
    [Actions.SET_MAIN]: (state, action) => {
      const data = action.payload;

      return produce(state, d => {
        const draft = d;
        draft.exposures.adminExposure = data.adminExposure;
        draft.exposures.selectModelName = data.selectModelName || '';
        draft.exposures.selectOptionName = data.selectOptionName || '';
        draft.main = data;
      });
    },
    [Actions.SET_HISTORY_LIST]: (state, action) => {
      const { autoSave, list } = action.payload;
      return produce(state, d => {
        const draft = d;
        draft.historyList.autoSave = autoSave;
        draft.historyList.list = list;
      });
    },
    [Actions.UPDATE_HISTORY_LIST]: (state, action) => {
      const { autoSave, list } = action.payload;
      return produce(state, d => {
        const draft = d;
        if (typeof autoSave !== 'undefined') {
          draft.historyList.autoSave = autoSave;
        }
        if (typeof list !== 'undefined') {
          draft.historyList.list = list;
        }
      });
    },
    [Actions.CLEAR_HISTORY_LIST]: state => {
      return {
        ...state,
        historyList: initialState.historyList,
      };
    },
    [Actions.UPDATE_COMPLETE_YN]: (state, action) => {
      const bool = action.payload;
      return produce(state, d => {
        const draft = d;
        draft.isCompleteYn = bool;
      });
    },
    [Actions.UPDATE_EXPOSURES]: (state, action) => {
      const data = action.payload;
      return produce(state, d => {
        const draft = d;
        draft.exposures = {
          ...state.exposures,
          ...data,
        };
      });
    },
    [Actions.CLEAR_RECOMMEND_AND_MAIN]: state => {
      return {
        ...state,
        recommend: initialState.recommend,
        main: initialState.recommend,
      };
    },
    [Actions.CLEAR]: () => {
      return { ...initialState };
    },
  },
  initialState,
);
