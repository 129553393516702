import React, { useEffect, Suspense, lazy, useCallback } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Scroll from 'react-scroll';

import useGoBack from '../common/Hook/useGoBack';
import { alert } from '../common/dom';
import {
  interfaceAppLoginPopup,
  interfaceCloseWebView,
} from '../common/webView';

import {
  AlertReducer,
  AuthReducer,
  ToastReducer,
} from '../reducers/actionCreators';

// Router
import SwitchRoute from './common/SwitchRoute';

import useInit from '../common/Hook/useInit';
import useSaveScoll from '../common/Hook/useSaveScroll';
import useHistory from '../common/Hook/useHistory';

import { removelogOutUserStore } from '../common/combineDisPatch';

// toast
import Toast from './common/popup/Toast';
// alret
import AuthAlert from './common/Auth/AuthAlert';
import Alert from './common/popup/Alert';
// loading
import LogoLoading from './common/Loading/LogoLoading';
// popups
import AppDim from './AppDim/AppDim';
import InviteFriends from './common/popup/InviteFriends';
import DeliveryPopUp from './common/popup/DeliveryPopUp';

import pages from '../containers';

import '../asset/scss/swiper.scss';
import '../asset/scss/styles.scss';

const Maintenance = lazy(() => import('../containers/maintenance/Maintenance'));

const App = () => {
  const {
    history: { action },
    location: { pathname, search },
  } = useHistory();

  const goBackHook = useGoBack();

  const { isLoading, isValid } = useInit();
  const { saveScroll } = useSaveScoll();

  const { isApp } = useSelector(({ userLoginInfo }) => userLoginInfo);
  const error = useSelector(({ errors }) => errors);
  const maintenanceData = useSelector(({ maintenance }) => maintenance); // 점검중

  // 로그아웃 및 세팅된 유저 정보 초기화
  const removeUserStore = useCallback((message = '') => {
    removelogOutUserStore();
    if (isApp) {
      interfaceAppLoginPopup();
      interfaceCloseWebView();
    } else {
      ToastReducer.open(message || '다시 로그인해주세요.', true);
      goBackHook('/home', true);
    }
  }, []);

  useEffect(() => {
    const savePathScoll =
      (action === 'POP' || action === 'REPLACE') && saveScroll
        ? saveScroll.get(`${pathname}${search}`) || 0
        : 0;

    const scroll = Scroll.animateScroll;
    scroll.scrollTo(savePathScoll, {
      duration: 0,
      delay: 100,
      smooth: true,
    });
    AlertReducer.close();
    AuthReducer.close();
  }, [pathname]);

  useEffect(() => {
    if (error.code === 400001) {
      // 블랙 회원
      alert({
        text: error.message,
        onClick: () => {
          removelogOutUserStore();
          goBackHook('/', true);
        },
      });
    } else if (error.code === 400999) {
      alert({
        text: error.message || '이용이 제한된 회원입니다.',
        onClick: () => {
          if (pathname !== '/home' && pathname !== '/mypage') goBackHook();
        },
      });
    } else if (error.code === 403) {
      // 재 로그인 요청
      removeUserStore(error.message);
    } else if (!isValid) {
      // 유효하지 않은 토큰
      removeUserStore();
    }
  }, [error, isValid]);

  // 초기 로딩
  if (isLoading) return <LogoLoading isPage />;

  // 점검페이지
  if (maintenanceData.code !== 200)
    return (
      <Suspense fallback={<LogoLoading isPage />}>
        <Maintenance />
      </Suspense>
    );

  return (
    <>
      <Suspense fallback={<LogoLoading isPage />}>
        <Switch>
          {pages.map(page => (
            <SwitchRoute key={page.path} {...page} />
          ))}
          {/* init-api에서 root 도메인 접근시 redirect 되는 페이지 컨트롤 할수 있도록 함 */}
          <Redirect to="/home" />
        </Switch>
      </Suspense>
      <div id="div-portal" />
      <DeliveryPopUp />
      <Alert />
      <AuthAlert />
      <Toast />
      {!isApp && (
        <>
          <InviteFriends />
          <AppDim />
        </>
      )}
    </>
  );
};

export default App;
