import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import moment from 'moment';
import * as Qna from '../../api/base/mypage';

const qnaActions = {
  POST_QNA_READD: 'POST_QNA_READD',
  POST_FAQ_SUGGEST: 'POST_FAQ_SUGGEST',
};

const initialState = {
  qna_detail: [],
  onSuccessPostReadd: moment(),
  onSuccessPostSuggest: moment(),
};

export const postQnaReadd = createAction(
  qnaActions.POST_QNA_READD,
  Qna.postQnaReadd,
);
export const postFaqSuggest = createAction(
  qnaActions.POST_FAQ_SUGGEST,
  Qna.postFaqSuggest,
);

export default handleActions(
  {
    ...pender({
      type: qnaActions.POST_QNA_READD,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSuccessPostReadd: moment(),
          qna_detail: data.data,
        };
      },
    }),
    ...pender({
      type: qnaActions.POST_FAQ_SUGGEST,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSuccessPostSuggest: moment(),
        };
      },
    }),
  },
  initialState,
);
