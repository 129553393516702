import axios from 'axios';
import config from '../config';
import { getHeaders } from '../apiUtil';

const baseURL = `${config.API_BASE_URL}`;

/**
 * 알림 카테고리 호출
 * @param {number} osType : 기기타입, 0: Android, 1: IOS , 2: Web
 */
export function getAlarms(osType = 2) {
  return axios({
    method: 'get',
    url: `/api/v2/alarms`,
    baseURL,
    headers: getHeaders(),
    params: {
      'Os-Type': osType,
    },
  });
}

/**
 * 알림 카테고리 별 목록 호출
 * @param {number} type : 알림 카테고리 type
 * @param {number} osType : 기기타입, 0: Android, 1: IOS , 2: Web
 */
export function getAlarmsByType(type, osType = 2) {
  return axios({
    method: 'get',
    url: `/api/v2/alarms/${type}`,
    baseURL,
    headers: getHeaders(),
    params: {
      'Os-Type': osType,
    },
  });
}

/**
 * 공휴일 가져오기
 */
export function getCalendarHoliday(year, month, getPath = false) {
  if (getPath) {
    return `${baseURL}/api/interlocker/calendar/holidays/${year}/${month}`;
  }

  return axios({
    method: 'get',
    url: `/api/interlocker/calendar/holidays/${year}/${month}`,
    baseURL,
    headers: getHeaders(),
  });
}
