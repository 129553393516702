import axios from 'axios';
import config from '../config';
import { getHeaders } from '../apiUtil';

const baseURL = `${config.API_BASE_URL}`;

/**
 *
 * @param {string | number} productSeq
 */
export function getProductOptionTag(productSeq) {
  return axios({
    method: 'post',
    url: `/product/option/tag/${productSeq}`,
    baseURL,
    headers: getHeaders(),
  });
}

export function getWishList(list) {
  const params = {
    productSeqs: list,
  };
  return axios({
    method: 'post',
    url: `/product/wish/seq`,
    data: params,
    baseURL,
    headers: getHeaders(),
  });
}

export const postWishList = (
  searchWord,
  startIndex,
  searchQuantity,
  searchStartDate,
) => {
  const data = {
    searchWord,
    startIndex,
    searchQuantity,
    searchStartDate,
  };
  return axios({
    method: 'post',
    url: `/product/wish`,
    baseURL,
    data,
    headers: getHeaders(),
  });
};
