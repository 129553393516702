import React from 'react';
import { bool } from 'prop-types';
import classNames from 'classnames';

import BarLoading from './BarLoading';

import { isApp } from '../../../common/webView';
import BasicImage from '../Image/BasicImage';

import logo from '../../../asset/img/logo_full.png';

import styles from './Loading.module.scss';

const LogoLoading = ({ isPage }) => {
  if (isApp()) return <BarLoading isPage={isPage} />;

  return (
    <div className={classNames(styles.logoLoading, isPage && styles.isPage)}>
      <BasicImage src={logo} />
    </div>
  );
};

LogoLoading.propTypes = {
  isPage: bool,
};

LogoLoading.defaultProps = {
  isPage: false,
};

export default LogoLoading;
