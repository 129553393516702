import { createAction, handleActions } from 'redux-actions';
import * as Layout from '../../actions/service/LayoutService';

const initialState = {
  isShowHeader: true,
};

// '중고나라에서 주문한 상품이 있으산가요' 헤더 닫기
export const toggleShowHeader = createAction(
  Layout.default.TOGGLE_SHOW_HEADER,
  Layout.toggleShowHeader,
);

export default handleActions(
  {
    [Layout.default.TOGGLE_SHOW_HEADER]: (state, action) => {
      return {
        ...state,
        isShowHeader: action.payload,
      };
    },
  },
  initialState,
);
