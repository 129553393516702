import axios from 'axios';
import config from '../config';
import { getHeaders } from '../apiUtil';

const baseURL = `${config.API_BASE_URL}`;

export function fileUpload(data) {
  return axios({
    method: 'post',
    url: `/file/upload`,
    data,
    baseURL,
    headers: getHeaders(),
  });
}

// 우리 동네등록 중복 체크
export function getCheckMyLocation(locationType, locationCode) {
  return axios({
    method: 'get',
    url: `/mylocation/check?locationType=${locationType}&locationCode=${locationCode}`,
    baseURL,
    headers: getHeaders(),
  });
}

// 위도 경도로 우리 동네 추가
export function postCurrentMyLocation(lat, lon) {
  return axios({
    method: 'post',
    url: `/mylocation/current?lat=${lat}&lon=${lon}`,
    baseURL,
    headers: getHeaders(),
  });
}

// 시/구/동 검색
export function getSearchDong(type, code) {
  return axios({
    method: 'get',
    url: `/mylocation/dong?type=${type}&code=${code}`,
    baseURL,
    headers: getHeaders(),
  });
}

// 현재 위치로 시,구,동 리스트 조회 API
export function getSearchDongBcode(bcode) {
  return axios({
    method: 'get',
    url: `/mylocation/dong/${bcode}`,
    baseURL,
    headers: getHeaders(),
  });
}

// 랜드마크 요청
export function postRequestLandMark(vo) {
  return axios({
    method: 'post',
    url: `/mylocation/landmark`,
    data: vo,
    baseURL,
    headers: getHeaders(),
  });
}

// 우리 동네 리스트
export function getMyLocationList() {
  return axios({
    method: 'get',
    url: `/mylocation/list`,
    baseURL,
    headers: getHeaders(),
  });
}

// 우리 동네 추가
export function postMyLocationList(locationType, locationCode) {
  return axios({
    method: 'post',
    url: `/mylocation/list?locationType=${locationType}&locationCode=${locationCode}`,
    baseURL,
    headers: getHeaders(),
  });
}

// 우리 동네 삭제
export function deleteMyLocationList(myLocationSeq) {
  return axios({
    method: 'delete',
    url: `/mylocation/list/${myLocationSeq}`,
    baseURL,
    headers: getHeaders(),
  });
}

// 즐겨찾기 추가
export function postMyFavoriteLocation(myLocationSeq) {
  return axios({
    method: 'post',
    url: `/mylocation/list/favorite/${myLocationSeq}`,
    baseURL,
    headers: getHeaders(),
  });
}

// 즐겨찾기 삭제
export function deleteMyFavoriteLocation(myLocationSeq) {
  return axios({
    method: 'delete',
    url: `/mylocation/list/favorite/${myLocationSeq}`,
    baseURL,
    headers: getHeaders(),
  });
}

// 최근 검색한 동네
export function getRecentSearchLocationList() {
  return axios({
    method: 'get',
    url: `/mylocation/list/history`,
    baseURL,
    headers: getHeaders(),
  });
}

// 최근 검색한 동네 삭제
export function deleteRecentSearchLocationList(locationHistorySeq) {
  return axios({
    method: 'delete',
    url: `/mylocation/list/history/${locationHistorySeq}`,
    baseURL,
    headers: getHeaders(),
  });
}

// 우리 동네 반경 검색 대상 행정동 목록
export function getMyLocationRangeDong(lat, lon, type) {
  return axios({
    method: 'get',
    url: `/mylocation/rangedong?lat=${lat}&lon=${lon}&type=${type}`,
    baseURL,
    headers: getHeaders(),
  });
}

// 우리 동네 반경 검색 대상 행정동 + 랜드마크 목록
export function getMyLocationRangeDongLandMark(lat, lon, type) {
  return axios({
    method: 'get',
    url: `/mylocation/rangedonglandmark?lat=${lat}&lon=${lon}&type=${type}`,
    baseURL,
    headers: getHeaders(),
  });
}

export function getMyLocationRangeDongUserCount(lat, lon, type) {
  return axios({
    method: 'get',
    url: `/mylocation/rangeusercount?lat=${lat}&lon=${lon}&type=${type}`,
    baseURL,
    headers: getHeaders(),
  });
}
