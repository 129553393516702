import {
  UserLoginInfoReducer,
  MainReducer,
  MyLocationReducer,
  TownReducer,
  PaymentReducer,
  AccountReducer,
} from '../reducers/actionCreators';

export const settinglogInUserStore = (getInfo = true) => {
  if (getInfo) UserLoginInfoReducer.getInfo();
  MyLocationReducer.getMyLocationList();
  TownReducer.initTownStore();
  PaymentReducer.clear();
  AccountReducer.clear();
};

export const removelogOutUserStore = () => {
  UserLoginInfoReducer.clearInfo();
  MainReducer.clearMainUserInfo();
  MyLocationReducer.clearMyLocationList();
  TownReducer.initTownStore();
  PaymentReducer.clear();
  AccountReducer.clear();
};

export default {
  settinglogInUserStore,
  removelogOutUserStore,
};
