import axios from 'axios';
import config from '../config';
import { getNoHeaders } from '../apiUtil';

const baseURL = `${config.API_BASE_URL}`;

export function getCategoryDepth(seq) {
  return axios({
    method: 'get',
    url: `/common/category/parent/${seq}`,
    baseURL,
    headers: getNoHeaders(),
  });
}

export function getCategoryDepthWithParent(seq) {
  return axios({
    method: 'get',
    url: `/common/category/${seq}/hierarchy`,
    baseURL,
    headers: getNoHeaders(),
  });
}

export function getPeaceCategoty(name = 'new') {
  return axios({
    method: 'get',
    url: `/common/category/peace/${name}`,
    baseURL,
    headers: getNoHeaders(),
  });
}

export const getBankList = (getPath = false) => {
  if (getPath) return `${baseURL}/common/code/bank`;

  return axios({
    method: 'get',
    url: `/common/code/bank`,
    baseURL,
    headers: getNoHeaders(),
  });
};

export const getUnicroBankList = (getPath = false) => {
  if (getPath) return `${baseURL}/common/code/bank/unicro/depositless`;

  return axios({
    method: 'get',
    url: `/common/code/bank/unicro/depositless`,
    baseURL,
    headers: getNoHeaders(),
  });
};

export function tokenValid(token, ignore = false) {
  return axios({
    method: 'get',
    url: `/common/token/valid?token=${token}${ignore ? '&ignore=Y' : ''}`,
    baseURL,
    headers: getNoHeaders(),
  });
}
