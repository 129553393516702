import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import produce from 'immer';

import { postMystoreSell as postMystoreSellApi } from '../../actions/service/MypageService';

import { getWishList as getWishListApi } from '../../api/base/product';

import { getUserInfo } from '../../common/auth';

export const Actions = {
  GET_ALL_SALES_PRODUCTS: 'ALLSALES/GET_ALL_SALES_PRODUCTS',

  RE_SALES_PRODUCT: 'ALLSALES/RE_SALES_PRODUCT',

  DEL_SALES_PRODUCT: 'ALLSALES/DEL_SALES_PRODUCT',

  CLEAR_ALL_SALES_PRODUCTS: 'ALLSALES/CLEAR_ALL_SALES_PRODUCTS',
};

const initialState = {
  storeSeq: -1,
  pageNo: 0,
  searchQuantity: 50,
  isMore: true,
  productList: [],
  adCenterLinkBanner: null,
  error: {
    code: 200,
    message: '',
  },
};

const getItemWishYn = async (productList, productListSeqs) => {
  try {
    const {
      data: { data },
    } = await getWishListApi(productListSeqs);

    const productListWithWish = productList.map(p => {
      const wishYn = data.findIndex(s => s === p.seq || s === p.productSeq);
      return {
        ...p,
        productWishYn: wishYn > -1 ? 1 : 0,
      };
    });
    return productListWithWish;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getProductList = async (storeSeq, pageNo = 0) => {
  try {
    const {
      data: { data },
    } = await postMystoreSellApi(
      storeSeq,
      1,
      pageNo,
      initialState.searchQuantity,
    );
    const loginUserInfo = getUserInfo();
    let items = data.searchResult.map(p => ({
      ...p,
      productWishYn: 0,
    }));
    const adCenterLinkBanner =
      typeof data.adCenterLinkBanner === 'undefined'
        ? null
        : data.adCenterLinkBanner;
    if (
      loginUserInfo &&
      Number(loginUserInfo.storeSeq) !== Number(storeSeq) &&
      items.length > 0
    ) {
      const productListSeqs = items.map(p => p.seq);
      items = await getItemWishYn(items, productListSeqs);
    }
    return {
      storeSeq,
      pageNo,
      items,
      adCenterLinkBanner,
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAllSalesProducts = createAction(
  Actions.GET_ALL_SALES_PRODUCTS,
  getProductList,
);

export const reSalesProduct = createAction(Actions.RE_SALES_PRODUCT, seq => ({
  seq,
}));

export const delProduct = createAction(Actions.DEL_SALES_PRODUCT, seq => ({
  seq,
}));

export const clearTownStore = createAction(Actions.CLEAR_ALL_SALES_PRODUCTS);

export default handleActions(
  {
    ...pender({
      type: Actions.GET_ALL_SALES_PRODUCTS,
      onSuccess: (state, action) => {
        const { storeSeq, pageNo, items, adCenterLinkBanner } = action.payload;
        return produce(state, d => {
          const draft = d;
          draft.storeSeq = storeSeq;
          draft.isMore = items.length >= initialState.searchQuantity;
          draft.pageNo = pageNo + 1;
          draft.productList = [...state.productList, ...items];
          if (pageNo === 0 && typeof adCenterLinkBanner !== 'undefined')
            draft.adCenterLinkBanner = adCenterLinkBanner;
        });
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),
    [Actions.RE_SALES_PRODUCT]: (state, action) => {
      const { seq } = action.payload;

      const findIndex = state.productList.findIndex(p => p.seq === seq);
      const reSaleProduct = state.productList.slice(findIndex, findIndex + 1);

      return produce(state, d => {
        const draft = d;
        draft.productList.splice(findIndex, 1).unshift(reSaleProduct);
      });
    },
    [Actions.DEL_SALES_PRODUCT]: (state, action) => {
      const { seq } = action.payload;

      const findIndex = state.productList.findIndex(p => p.seq === seq);

      return produce(state, d => {
        const draft = d;
        draft.productList.splice(findIndex, 1);
      });
    },
    [Actions.CLEAR_ALL_SALES_PRODUCTS]: () => {
      return { ...initialState };
    },
  },
  initialState,
);
