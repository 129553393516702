import axios from 'axios';
import config from '../config';
import { getHeaders } from '../apiUtil';

const baseURL = `${config.API_BASE_URL}`;

export function fileUpload(data) {
  return axios({
    method: 'post',
    url: `/file/upload`,
    data,
    baseURL,
    headers: getHeaders(),
  });
}

export default {
  fileUpload,
};
