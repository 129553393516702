import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { func, element, object, oneOfType, bool } from 'prop-types';

import { useLocalhostLogin } from '../../common/auth';
import { authAlert } from '../../common/dom';
import { interfaceAppLoginPopup } from '../../common/webView';

import useHistory from '../../common/Hook/useHistory';

import LogoLoading from './Loading/LogoLoading';

const SwitchRoute = ({
  component: Component,
  isPrivate,
  isAccess2,
  isAccess,
  ...rest
}) => {
  const {
    location: { pathname, search },
    replace,
  } = useHistory();

  const { isApp, token } = useSelector(({ userLoginInfo }) => userLoginInfo);
  const useLogin = useLocalhostLogin();

  const renderComponent = props => {
    // 앱 외에 접근 불가 페이지
    if (!useLogin && !isApp) {
      if (typeof isAccess2 !== 'undefined') {
        if (!isAccess2) {
          authAlert({ close: () => replace('/home') });
          return <LogoLoading isPage />;
        }
      } else if (!isAccess) {
        authAlert({ close: () => replace('/home') });
        return <LogoLoading isPage />;
      }
    }

    // 토큰이 있거나 비로그인 페이지
    if (token || !isPrivate) return <Component {...props} />;

    // 토근없고, 로그인 필수 (앱)
    if (isApp) {
      interfaceAppLoginPopup();
      return <LogoLoading isPage />;
    }
    // 토근없고, 로그인 필수 (웹)
    return (
      <Redirect
        to={{
          pathname: '/login',
          search: `?redirect=${pathname.slice(1)}${search}`,
        }}
      />
    );
  };

  return <Route {...rest} render={renderComponent} />;
};

SwitchRoute.propTypes = {
  component: oneOfType([func, object, element]).isRequired,
  isPrivate: bool,
  isAccess: bool,
};

SwitchRoute.defaultProps = {
  isPrivate: false,
  isAccess: true,
};

export default SwitchRoute;
