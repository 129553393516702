import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { AlertReducer } from '../../../reducers/actionCreators';
import PopUp from './PopUp';

import styles from './Alert.module.scss';

const Alert = () => {
  const [callBack, setCallBack] = useState({
    onClick: null,
  });

  const {
    className,
    show,
    text,
    subText,
    onClick,
    bottomText,
    bottomBackground,
    onCancle,
  } = useSelector(({ alert }) => alert);

  let textArr = text;
  if (typeof textArr === 'string') {
    textArr = text.split('\n').map((t, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <p className="f18" key={`alertText_${t}_${i}`}>
        {t}
      </p>
    ));
  }

  let subTextArr = subText;
  if (subText && typeof subText === 'string') {
    subTextArr = subText.split('\n').map((t, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <p className="f16 C979797" key={`alertSubText_${t}_${i}`}>
        {t}
      </p>
    ));
  }

  const footer = Array.isArray(bottomText) ? (
    bottomText.slice(0, 2).map((t, i) => {
      return (
        <button
          key={`alertBottomText_${t}`}
          className={i !== 0 ? styles.orange : ''}
          type="button"
          onClick={() => {
            AlertReducer.close();
            if (i !== 0) setCallBack({ onClick });
            else setCallBack({ onClick: onCancle });
          }}
        >
          <span className="f17">{t}</span>
        </button>
      );
    })
  ) : (
    <button
      className={classNames(bottomBackground === 'black' && styles.blackBg)}
      type="button"
      onClick={() => {
        AlertReducer.close();
        setCallBack({ onClick });
      }}
    >
      <span className="f17">{bottomText}</span>
    </button>
  );

  useEffect(() => {
    if (!show && callBack.onClick !== null) {
      callBack.onClick();
      setCallBack({ onClick: null });
    }
  }, [show]);

  return (
    <PopUp show={show} warpClassName={styles.z600}>
      <div className={classNames(styles.alert, className)}>
        <div className={styles.body}>
          {textArr}
          {subTextArr.length > 0 && <div className="mt8">{subTextArr}</div>}
        </div>
        <div className={styles.footer}>{footer}</div>
      </div>
    </PopUp>
  );
};

export default Alert;
