import axios from 'axios';
import config from '../config';
import { getHeaders } from '../apiUtil';
import { getPriceCalc } from '../../common/number';
import { searchDefault, filterDefault } from '../../common/const';
import { dayString, subTime } from '../../common/time';

const baseURL = `${config.SEARCH_API_BASE_URL}`;

export function searchStoreApi({
  searchQuantity = 20,
  searchWord,
  sort = 0,
  startIndex = 0,
}) {
  const data = {
    searchQuantity,
    searchWord,
    sort,
    startIndex,
  };
  return axios({
    method: 'post',
    url: `/v1/search/stores`,
    data,
    baseURL,
    headers: getHeaders(),
  });
}

export function searchStoreApiV2({
  searchQuantity = 20,
  searchWord,
  sort = 0,
  startIndex = 0,
}) {
  const data = {
    searchQuantity,
    searchWord,
    sort,
    startIndex,
  };
  return axios({
    method: 'post',
    url: `/v2/search/stores`,
    data,
    baseURL,
    headers: getHeaders(),
  });
}

export function searchCategoryProduct({
  categoryName,
  categoryDepth,
  categorySeq,
  searchWord,
  searchStartDate,
  startIndex,
  sort,
  filter,
  searchQuantity = 40,
}) {
  const data = {
    categoryName1: categoryName[0],
    categoryName2: categoryName[1],
    categoryName3: categoryName[2],
    filter: {
      categoryDepth,
      categorySeq,
      color: 'ZZZZZZ',
      dateFilterParameter: {
        sortEndDate: filter.startEndDate > 0 ? dayString('YYYY-MM-DD') : '',
        sortStartDate:
          filter.startEndDate > 0
            ? dayString('YYYY-MM-DD', subTime(filter.startEndDate))
            : '',
      },
      // productCondition: filter.condition,
      // flawedYn: filter.conditionOptions.flawedYn ? 1 : 0,
      // fullPackageYn: filter.conditionOptions.fullPackageYn ? 1 : 0,
      // limitedEditionYn: filter.conditionOptions.limitedEditionYn ? 1 : 0,
      productCondition: filterDefault.condition.productCondition,
      flawedYn: filterDefault.condition.options.flawedYn,
      fullPackageYn: filterDefault.condition.options.fullPackageYn,
      limitedEditionYn: filterDefault.condition.options.limitedEditionYn,
      locations: [],
      maxPrice: getPriceCalc(filter.priceRange[1]),
      minPrice: getPriceCalc(filter.priceRange[0]),
      platformType: 1,
      state: -1,
      tradeType: -1,
    },
    osType: 2,
    searchQuantity,
    searchStartDate,
    searchWord,
    sort,
    startIndex,
    version: 1,
  };
  return axios({
    method: 'post',
    url: `/v25/list/category`,
    data,
    baseURL,
    headers: getHeaders(),
  });
}

export function getLocationProductList(
  lat,
  lon,
  rangeType = 0,
  filter = {},
  sort = {
    date: 0,
    order: 0,
    price: 0,
  },
  index = 0,
) {
  const searchFilter = {
    ...filterDefault,
    ...filter,
    platformType: 0,
  };

  const data = {
    ...searchDefault,
    sort,
    startIndex: index,
    filter: searchFilter,
  };

  return axios({
    method: 'post',
    url: `/v2/search/usedGoods/location/${lon}/${lat}/${rangeType}?hasTab=0`,
    data,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 검색 자동 완성 리스트 : 검색 type_2 와 연결되는 카테고리 항목들을 제시
 * @param {sting} keyword
 */
export function postSearchRecommendCategory(keyword) {
  return axios({
    method: 'post',
    url: `/v2/search/recommend/category`,
    data: { keyword },
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 시세 조회
 * @param {sting} keyword
 * @param {sting} productCondition // productCondition: -1:전체, 0:새것, 1:거의새것, 2:중고
 */
export function postPriceAverage(keyword, productCondition) {
  const data = {
    keyword,
    productCondition,
  };

  return axios({
    method: 'post',
    url: `/v25/search/price/average`,
    data,
    baseURL,
    headers: getHeaders(),
  });
}
// https://stage.api.joongna.com/elastic/type14/0/126.657333/37.424861/1

/**
 * 키워드 자동 완성
 * @param {sting} keyword
 * @param {number} searchQuantity
 */
export function searchAutoCompleteKeyword(keyword, keywordCnt = 10) {
  const data = {
    keyword,
    keywordCnt,
  };
  return axios({
    method: 'post',
    url: `/v25/search/autocomplete/keyword`,
    data,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 통합검색 탭 리스트 조회
 * @param {number} osType
 */
export function searchTotalTab() {
  return axios({
    method: 'get',
    url: `/v26/search/totaltab`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 검색메인 내 탭 '전체' 검색
 * @param {sting} searchWord
 * @param {number} startIndex
 * @param {number} searchQuantity
 */
export function searchTotal({ searchWord, startIndex, searchQuantity = 5 }) {
  const data = {
    osType: 2,
    searchQuantity,
    searchStartTime: dayString('YYYY-MM-DD HH:mm:ss'),
    searchWord,
    startIndex,
  };
  return axios({
    method: 'get',
    url: `/v25/search/totalsearch`,
    data,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 검색메인 내 탭 '중고상품' 검색
 * @param {object} filter
 * @param {number} page
 * @param {number} firstQuantity
 * @param {sting} searchWord
 * @param {number} startIndex

 * @param {number} searchQuantity
 * @param {string} sort
 */
export function searchProduct({
  filter,
  page,
  firstQuantity = 10,
  productFilter = undefined,
  productStates = [0, 1],
  quantity = 10,
  searchQuantity = 10,
  searchWord,
  sort = 'RECOMMEND_SORT',
  startIndex,
}) {
  const dateFilterParameter = {
    sortEndDate: null,
    sortStartDate: null,
  };
  if (filter.dateFilter !== 0) {
    dateFilterParameter.sortEndDate = dayString('YYYY-MM-DD');
    dateFilterParameter.sortStartDate = dayString(
      'YYYY-MM-DD',
      subTime(filter.dateFilter),
    );
  }

  const data = {
    filter: {
      categoryDepth: filter.categoryDepth,
      categorySeq: filter.categorySeq,
      dateFilterParameter,
      // productCondition: filter.productCondition,
      // flawedYn: filter.flawedYn,
      // fullPackageYn: filter.fullPackageYn,
      // limitedEditionYn: filter.limitedEditionYn,
      productCondition: filterDefault.condition.productCondition,
      flawedYn: filterDefault.condition.options.flawedYn,
      fullPackageYn: filterDefault.condition.options.fullPackageYn,
      limitedEditionYn: filterDefault.condition.options.limitedEditionYn,
      locations: filter.locations,
      maxPrice: getPriceCalc(filter.maxPrice),
      minPrice: getPriceCalc(filter.minPrice),
      tradeType: filter.tradeType,
    },
    page,
    firstQuantity,
    productFilter,
    productStates,
    quantity,
    searchQuantity,
    osType: 2,
    searchWord,
    sort,
    startIndex,
    searchStartTime: dayString('YYYY-MM-DD HH:mm:ss'),
  };
  return axios({
    method: 'post',
    url: `/v25/search/product`,
    data,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 검색메인 내 탭 '인증상품' 검색
 * @param {sting} searchWord
 * @param {number} startIndex
 * @param {number} searchQuantity
 * @param {string} sort
 */
export function searchPartnercenter({
  searchWord,
  startIndex,
  searchQuantity = 20,
  sort = 'RECOMMEND_SORT',
}) {
  const data = {
    osType: 2,
    searchQuantity,
    searchStartTime: dayString('YYYY-MM-DD HH:mm:ss'),
    searchWord,
    sort,
    startIndex,
  };
  return axios({
    method: 'post',
    url: `/v25/search/partnercenter`,
    data,
    baseURL,
    headers: getHeaders(),
  });
}
/**
 * 인증상품 판매 중인 '판매중 셀러' 검색
 * @param {sting} masterGoodsSeq
 * @param {number} startIndex
 * @param {number} searchQuantity
 * @param {string} sort
 */
export function searchPartnercenterSeller({
  masterGoodsSeq,
  startIndex,
  searchWord,
  searchQuantity = 20,
}) {
  const data = {
    osType: 2,
    searchQuantity,
    searchWord,
    startIndex,
  };
  return axios({
    method: 'post',
    url: `/v25/search/partnercenter/${masterGoodsSeq}`,
    data,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 검색메인 내 탭 '가게' 검색
 * @param {sting} searchWord
 * @param {number} startIndex
 * @param {number} searchQuantity
 * @param {string} sort
 */
export function searchStore({
  searchWord,
  startIndex,
  searchQuantity = 20,
  sort = 'RECOMMEND_SORT',
}) {
  const data = {
    osType: 2,
    searchQuantity,
    searchStartTime: dayString('YYYY-MM-DD HH:mm:ss'),
    searchWord,
    sort,
    startIndex,
  };
  return axios({
    method: 'post',
    url: `/v25/search/store`,
    data,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 상품목록 호출
 * @param {number} storeSeq // 가게 고유번호
 * @param {number} filterType
 * @param {number} filterType
 */
export function getSearchStoreList(
  storeSeq,
  filterType,
  isCopy,
  startIndex = 0,
) {
  const params = {
    version: 1,
    isSearchSaved: 1,
    sort: {
      date: 0,
      order: 0,
      price: 0,
    },
    startIndex,
    searchWord: '',
    searchQuantity: (startIndex + 1) * 20,
    sellTypes: {
      dealerSells: 1,
      userDistressSells: 1,
      userSells: 1,
    },
    filter: {
      categoryDepth: 0,
      categoryName: '',
      categorySeq: 0,
      categorySeqList: [],
      color: 'ZZZZZZ',
      condition: {
        options: {
          flawedYn: 0,
          fullPackageYn: 0,
          limitedEditionYn: 0,
        },
        productCondition: '-1',
      },
      locations: [],
      maxCarRegDate: '',
      maxPrice: 2000000000,
      maxPriceIndex: 84,
      minCarRegDate: '',
      minPrice: 0,
      minPriceIndex: 0,
      platformType: 1,
      preferredTrade: 0,
      productSectionType: 0,
      searchStartDate: '2020-02-26 01:57:09',
      sortDateIndex: 0,
      sortEndDate: '',
      sortStartDate: '',
      state: '-1',
    },
  };
  return axios({
    method: 'post',
    url: `/v2/search/store/${storeSeq}?consignmentFilter=${filterType}&isCopy=${isCopy}`,
    data: params,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 자주 찾는 검색어 목록
 */
export function searchMainPopularWords(getPath = false) {
  if (getPath) return `${baseURL}/v25/search/main/popular/words`;

  return axios({
    method: 'get',
    url: `/v25/search/main/popular/words`,
    baseURL,
    headers: getHeaders(),
  });
}
/**
 * 자주 찾는 검색어의 상품 목록
 */
export function searchMainPopularProducts(popularWord) {
  const params = {
    popularWord,
  };

  return axios({
    method: 'get',
    url: `/v25/search/main/popular/products`,
    params,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 모바일웹 방문자 데이터 추출
 */
export function sendMwebStateLog(transactionId) {
  return axios({
    method: 'post',
    url: `/v25/search/user/mweb-state/log`,
    baseURL,
    data: {
      actionDetailType: 'MWEB_EXECUTION',
      transactionId,
    },
    headers: getHeaders(),
  });
}

/**
 * GA 데이터 로그 적재
 */
export function postSearchUserGALog(data) {
  return axios({
    method: 'post',
    url: `/v25/search/user/ga/log`,
    baseURL,
    data,
    headers: getHeaders(),
  });
}
