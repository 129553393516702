import axios from 'axios';
import * as Config from '../config';
import { setWish } from './SearchTermService';

const location = {
  GET_ADDR_LIST: 'GET_ADDR_LIST',
  GET_COORDINATE_LIST: 'GET_COORDINATE_LIST',
  GET_LOCATION_INFO: 'GET_LOCATION_INFO',
  GET_LANDMARKE_LIST: 'GET_LANDMARKE_LIST',
  GET_ADDR_LANDMARKE_LIST: 'GET_ADDR_LANDMARKE_LIST',

  GET_USER_LOCATION_INFO: 'GET_USER_LOCATION_INFO',
  GET_USER_LOCATION_LANDMARKE_LIST: 'GET_USER_LOCATION_LANDMARKE_LIST',

  GET_MY_LOCATION_HISTORY: 'GET_MY_LOCATION_HISTORY',
  ADD_MY_LOCATION_HISTORY: 'ADD_MY_LOCATION_HISTORY',
  DELETE_MY_LOCATION_HISTORY: 'DELETE_MY_LOCATION_HISTORY',
  POST_ELASTIC_TYPE4: 'POST_ELASTIC_TYPE4',
};
// 동 이름으로 주변 동/랜드마크 리스트 표출
export function getAddrList(dongName, lat, lon) {
  let promise;
  const token_request_info = {
    dongName,
    lat,
    lon,
  };
  if (dongName != null && dongName != '') {
    promise = axios.post(
      `${Config.default.API_BASE_URL}/common/location/list/address`,
      token_request_info,
      Config.getHeader(Config.default.JSON_TYPE),
    );
  }
  return promise;
}

// 위경도 좌표로 주변 동/랜드마크 리스트 표출
export function getCoordinateList(
  centerLat,
  centerLon,
  leftUpLat,
  leftUpLon,
  rightDownLat,
  rightDownLon,
) {
  const token_request_info = {
    centerLat,
    centerLon,
    leftUpLat,
    leftUpLon,
    rightDownLat,
    rightDownLon,
  };
  let promise;
  promise = axios.post(
    `${Config.default.API_BASE_URL}/common/location/list/coordinate`,
    token_request_info,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 현재 위치 정보
export function getLocationInfo(centerLat, centerLon) {
  let promise;
  promise = axios.get(
    `${
      Config.default.API_BASE_URL
    }/common/location/list/current?lat=${centerLat}&lng=${centerLon}`,
    Config.getHeader2(Config.default.JSON_TYPE),
  );
  return promise;
}

// 동 코드로 근처 랜드마크 찾기
export function getLandmarkList(dongCode) {
  let promise;
  if (dongCode != null && dongCode != '') {
    promise = axios.get(
      `${
        Config.default.API_BASE_URL
      }/common/location/list/${dongCode}/landmark`,
      Config.getHeader(Config.default.JSON_TYPE),
    );
  }
  return promise;
}

// 최근검색한 동네
export function getMyLocationHistory() {
  let promise;
  promise = axios.get(
    `${Config.default.API_BASE_URL}/mylocation/list/history`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 최근검색한 동네 추가
export function addMyLocationHistory(code, locationType) {
  let promise;
  promise = axios.post(
    `${
      Config.default.API_BASE_URL
    }/mylocation/list/history?code=${code}&locationType=${locationType}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 최근검색한 동네 삭제
export function deleteMylocationHistory(locationHistorySeq) {
  let promise;
  promise = axios.delete(
    `${
      Config.default.API_BASE_URL
    }/mylocation/list/history/${locationHistorySeq}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 키워드가 없고, 지역 코드(행정동) 으로 전체 카테고리 검색 / type_4 - location-product 리스트에서 사용
export function postElasticType4(
  type,
  locationCode,
  locationType,
  searchQuantity,
  startIndex,
) {
  const param = {
    filter: {
      categoryDepth: 0,
      categorySeq: 0,
      color: '',
      condition: {
        options: {
          flawedYn: 0,
          fullPackageYn: 0,
          limitedEditionYn: 0,
        },
        productCondition: -1,
      },
      locations: [],
      maxPrice: 0,
      minPrice: 0,
      platformType: 0,
      preferredTrade: 0,
      sortEndDate: null,
      sortStartDate: null,
      state: 0,
    },
    isSearchSaved: 0,
    searchQuantity,
    searchWord: '',
    sort: {
      date: 0,
      order: 0,
      price: 0,
    },
    // 페이지
    startIndex,
  };
  let promise;
  promise = axios
    .post(
      `${
        Config.default.API_BASE_URL
      }/elastic/type4/${type}/${locationCode}/${locationType}`,
      param,
      Config.getHeader(Config.default.JSON_TYPE),
    )
    .then(setWish);
  return promise;
}

export default location;
