export function isNumeric(data) {
  return !Number.isNaN(data);
}

export function addZero(num) {
  return num < 10 ? `0${num}` : `${num}`;
}

export function onlyNumber(string = '') {
  return string.trim().replace(/[^0-9]/g, '');
}

export function stringToNumber(string) {
  return Number(onlyNumber(string));
}

export function numFormat(num) {
  const number = typeof num === 'string' ? stringToNumber(num) : num;
  return Number(number)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    .split('.')[0];
}

export function numberFormatter(num, digits) {
  const si = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' },
  ];
  for (let i = 0; i < si.length; i += 1) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value + 0.1)
          .toFixed(digits)
          .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
      );
    }
  }
  return num.toString();
}

export function getPercentage(x, y) {
  return y < x ? '100%' : `${Math.floor((x / y) * 100)}%`;
}

export const formatAutoPrice = n => {
  const tenThousand = n / 10000;
  const hundredMillion = Math.floor(tenThousand / 10000);
  const underMillion = tenThousand - hundredMillion * 10000;
  let price = '';
  if (hundredMillion > 0) {
    price += `${numFormat(hundredMillion)}억`;
    if (underMillion !== 0) price += ` ${numFormat(underMillion)}만`;
  } else {
    price += `${numFormat(underMillion)}만`;
  }

  return `${price}원`;
};

export const formatPrice = n =>
  Number(n) === 0 ? '무료나눔' : `${numFormat(Number(n))}원`;

// 가격 range slider 에서의 파싱
export const getPriceCalc = value => {
  if (value < 21) return value * 1000 * 5;
  if (value < 31) return (value - 10) * 1000 * 10;
  if (value < 47) return (value - 26) * 1000 * 50;
  if (value < 87) return (value - 36) * 1000 * 100;
  if (value < 97) return (value - 76) * 1000 * 500;
  if (value === 97) return 1000 * 1000 * 250;
  if (value === 98) return 1000 * 1000 * 500;
  if (value === 99) return 1000 * 1000 * 1000;
  return 1000 * 1000 * 1000 * 2;
};

export const phoneFomatter = (num, type = 0) => {
  if (num.length === 11) {
    if (type === 0) {
      return num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
    }
    return num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  }
  if (type === 0) {
    return num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
  }
  return num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};
