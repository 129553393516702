import axios from 'axios';
import config from '../config';
import { getHeaders, getNoHeaders } from '../apiUtil';
import { getOsType } from '../../common/webView';

const baseURL = `${config.API_BASE_URL}`;

/**
 * 사용자 계좌 목록
 */
export const getUserAccountList = (getPath = false) => {
  if (getPath) return `${baseURL}/user/info/account`;

  return axios({
    method: 'get',
    url: `/user/info/account`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 사용자 계좌 등록
 * @param {*} param
 * "accountHolder": "string", 예금주
   "accountNo": "string", 계좌 번호
   "accountSeq": 0, 계좌 seq ( 등록시에는 사용하지 않음 )
   "bankCode": "string", 은행코드
   "bankName": "string", 인행명
   "mainAccountYn": 0 대표계좌 여부 0: 대표아님, 1: 대표계좌
 */
export const postUserAccount = params => {
  const data = {
    ...params,
    accountSeq: 0,
  };

  return axios({
    method: 'post',
    url: `/user/info/account`,
    data,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 사용자 계좌 정보 조회
 * @param {*} accountSeq
 */
export const getUserAccountInfo = accountSeq => {
  return axios({
    method: 'get',
    url: `/user/info/account/${accountSeq}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 사용자 계좌 삭제
 * @param {*} accountSeq
 */
export const delUserAccount = accountSeq => {
  return axios({
    method: 'delete',
    url: `/user/info/account/${accountSeq}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 사용자 대표 계좌
 */
export const getUserMainAccount = () => {
  return axios({
    method: 'get',
    url: `/user/info/account/main`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 사용자 대표 계좌 등록
 * @param {*} accountSeq
 * @param {*} mainAccountYn
 */
export const putUserMainAccount = (accountSeq, mainAccountYn) => {
  return axios({
    method: 'put',
    url: `/user/info/account/main/${accountSeq}/${mainAccountYn}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 사용자 배송지 목록
 */
export const getAddressListApi = (getPath = false) => {
  if (getPath) return `${baseURL}/user/info/delivery`;

  return axios({
    method: 'get',
    url: `/user/info/delivery`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 사용자 배송지 등록
 * @param {string} deliveryAddress : 배송지 주소
 * @param {string} deliveryAddressDetail : 배송지 상세 주소
 * @param {string} deliveryPostNo : 우편번호
 * @param {number} deliverySeq : 배송지 시퀀스, 무조건 0
 * @param {string} deliveryTitle : 배송지 이름
 * @param {string} deliveryUserName : 배송지 유저 이름
 * @param {string} deliveryUserPhoneNo : 핸드폰 번호
 * @param {number} mainAddressYn : 1: 대표, 0: 대표아님
 */
export const postAddAddressApi = params => {
  const data = {
    ...params,
  };
  return axios({
    method: 'post',
    url: `/user/info/delivery`,
    data,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 사용자 배송지 수정
 * @param {string} deliveryAddress : 배송지 주소
 * @param {string} deliveryAddressDetail : 배송지 상세 주소
 * @param {string} deliveryPostNo : 우편번호
 * @param {number} deliverySeq : 배송지 시퀀스, 무조건 0
 * @param {string} deliveryTitle : 배송지 이름
 * @param {string} deliveryUserName : 배송지 유저 이름
 * @param {string} deliveryUserPhoneNo : 핸드폰 번호
 * @param {number} mainAddressYn : 1: 대표, 0: 대표아님
 */
export function putEditAddressApi(deliverySeq, params) {
  const data = {
    ...params,
  };
  return axios({
    method: 'put',
    url: `/user/info/delivery/${deliverySeq}`,
    data,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 사용자 배송지 삭제
 * @param {*} deliverySeq
 */
export const deleteAddressApi = deliverySeq => {
  return axios({
    method: 'delete',
    url: `/user/info/delivery/${deliverySeq}`,
    baseURL,
    headers: getHeaders(),
  });
};

// 사용자 대표 배송지
export const getMainAddressApi = () => {
  return axios({
    method: 'get',
    url: `/user/info/delivery/main`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 회원 Email 등록
 * @param {object} vo <Required>
 *  {
 *    deviceOs {Number}: OS type (0:Android, 1:iOS, 2:mobileWeb)
 *    deviceUuid {String}: 디바이스 고유 아이디
 *    gcmId {String}: gcm/fcm registrationId
 *    marketing {Object}: 마케팅 수신동의 {
 *      agree	{Boolean}: 마케팅 수신동의
 *      emailYn	{Number}: email 수신동의, 0:미동의, 1:동의
 *      pushYn {Number}: push 수신동의, 0:미동의, 1:동의
 *      smsYn {Number}: sms 수신동의, 0:미동의, 1:동의
 *    }
 *    mobileAdId {String}: 핸드폰 AD_ID
 *    mobilians {Object}: 모빌리언스 인증 정보 {
 *      ci {String}: 모빌리언스 CI
 *      commid {String}: 모빌리언스 이동통신사
 *      di {String}: 모빌리언스 DI
 *      foreigner {String}: 모빌리언스 외국인여부 (외국인 : Y)
 *      mac {String}: 모빌리언스 검증키
 *      mobilid {String}: 모빌리언스 거래번호
 *      mstr {String}: 모빌리언스 확장 변수
 *      name {String}: 모빌리언스 이름
 *      no {String}: 모빌리언스 휴대폰번호
 *      resultcd {String}: 모빌리언스 결과코드
 *      sex {String}: 모빌리언스 성별 (남성:M, 여성:F)
 *      signdate {String}: 모빌리언스 결제일자
 *      socialno {String}: 모빌리언스 생년월일
 *    }
 *    nickName {String}: 회원 닉네임
 *    parentMobilians {Object}: 모빌리언스 인증 정보 {
 *      ci {String}: 모빌리언스 CI
 *      commid {String}: 모빌리언스 이동통신사
 *      di {String}: 모빌리언스 DI
 *      foreigner {String}: 모빌리언스 외국인여부 (외국인 : Y)
 *      mac {String}: 모빌리언스 검증키
 *      mobilid {String}: 모빌리언스 거래번호
 *      mstr {String}: 모빌리언스 확장 변수
 *      name {String}: 모빌리언스 이름
 *      no {String}: 모빌리언스 휴대폰번호
 *      resultcd {String}: 모빌리언스 결과코드
 *      sex {String}: 모빌리언스 성별 (남성:M, 여성:F)
 *      signdate {String}: 모빌리언스 결제일자
 *      socialno {String}: 모빌리언스 생년월일
 *    }
 *    profileImgUrl {String}: 회원 프로필 이미지 URL
 *    recommCode {String}: 추천인 코드
 *    userId {String}: 회원 ID(이메일)
 *    userJoinType {Number}: 회원가입유형. 0:이메일, 1:네이버, 2:카카오, 3:페이스북, 5:페이코
 *    userPassword {String}: RSA 회원 비밀번호
 *  }
 */
export function joinEmail(vo) {
  return axios({
    method: 'post',
    url: `/user/email/join`,
    data: vo,
    baseURL,
    headers: getNoHeaders(),
  });
}

/**
 * 회원 Email 로그인
 * @param {object} vo  <Required>
 *  {
 *    deviceOs {Number}: OS type (0:Android, 1:iOS, 2:mobileWeb)
 *    deviceUuid {String}: 디바이스 고유 아이디
 *    gcmId {String}: gcm/fcm registrationId
 *    mobileAdId {String}: 핸드폰 AD_ID
 *    password {String}: RSA 비밀번호
 *    userId {String}: 이메일
 *  }
 */
export function loginEmail(vo) {
  return axios({
    method: 'post',
    url: `/user/email/login`,
    data: vo,
    baseURL,
    headers: getNoHeaders(),
  });
}

/**
 * Email 로그인 문자인증 요청
 * @param {object} vo  <Required>
 *  {
 *    password {String}: RSA 비밀번호
 *    userId {String}: 이메일
 *  }
 */
export function getAuthSMS(vo) {
  return axios({
    method: 'post',
    url: `/v2/user/request/sms`,
    data: vo,
    baseURL,
    headers: getNoHeaders(),
  });
}

/**
 * Email 로그인 처리
 * @param {object} vo  <Required>
 *  {
 *    password {String}: RSA 비밀번호
 *    userId {String}: 이메일
 *    verificationCode {String}: 인증번호
 *  }
 */
export function loginSmsEmail(vo) {
  return axios({
    method: 'post',
    url: `/v2/user/email/login`,
    data: vo,
    baseURL,
    headers: getNoHeaders(),
  });
}

/**
 * 로그아웃
 */
export function logout() {
  return axios({
    method: 'post',
    url: `/user/logout`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 이메일, 닉네임, 추천코드, 중복 확인 및 이메일 연동 여부 확인
 * 등록된 이메일, 닉네임, 추천코드의 수 return, 이메일 연동 여부 확인인 경우 1일 경우 이메일 연동, 0일 경우 이메일 연동 하지 않음
 * @param {object} vo <Required>
 *  {
 *    data {String}: 이메일 or 닉네임 or 추천코드, 이메일 연동여부 확인시 해당 이메일
 *    type {Number}: 1 : 이메일, 2 : 닉네임, 3: 추천코드, 4: 이메일 연동여부
 *  }
 */
export function overlap(vo) {
  return axios({
    method: 'post',
    url: `/user/overlap`,
    data: vo,
    baseURL,
    headers: getNoHeaders(),
  });
}

/**
 * 파트너센터 회원 Email 등록
 * JWT 토큰 return
 * @param {*} partnerCenterMemNo <Required>
 * @param {*} vo <Required>
 *  {
 *    deviceOs {Number}: OS type (0:Android, 1:iOS, 2:mobileWeb)
 *    deviceUuid {String}: 디바이스 고유 아이디
 *    gcmId {String}: gcm/fcm registrationId
 *    marketing {Object}: 마케팅 수신동의 {
 *      agree	{Boolean}: 마케팅 수신동의
 *      emailYn	{Number}: email 수신동의, 0:미동의, 1:동의
 *      pushYn {Number}: push 수신동의, 0:미동의, 1:동의
 *      smsYn {Number}: sms 수신동의, 0:미동의, 1:동의
 *    }
 *    mobileAdId {String}: 핸드폰 AD_ID
 *    mobilians {Object}: 모빌리언스 인증 정보 {
 *      ci {String}: 모빌리언스 CI
 *      commid {String}: 모빌리언스 이동통신사
 *      di {String}: 모빌리언스 DI
 *      foreigner {String}: 모빌리언스 외국인여부 (외국인 : Y)
 *      mac {String}: 모빌리언스 검증키
 *      mobilid {String}: 모빌리언스 거래번호
 *      mstr {String}: 모빌리언스 확장 변수
 *      name {String}: 모빌리언스 이름
 *      no {String}: 모빌리언스 휴대폰번호
 *      resultcd {String}: 모빌리언스 결과코드
 *      sex {String}: 모빌리언스 성별 (남성:M, 여성:F)
 *      signdate {String}: 모빌리언스 결제일자
 *      socialno {String}: 모빌리언스 생년월일
 *    }
 *    nickName {String}: 회원 닉네임
 *    parentMobilians {Object}: 모빌리언스 인증 정보 {
 *      ci {String}: 모빌리언스 CI
 *      commid {String}: 모빌리언스 이동통신사
 *      di {String}: 모빌리언스 DI
 *      foreigner {String}: 모빌리언스 외국인여부 (외국인 : Y)
 *      mac {String}: 모빌리언스 검증키
 *      mobilid {String}: 모빌리언스 거래번호
 *      mstr {String}: 모빌리언스 확장 변수
 *      name {String}: 모빌리언스 이름
 *      no {String}: 모빌리언스 휴대폰번호
 *      resultcd {String}: 모빌리언스 결과코드
 *      sex {String}: 모빌리언스 성별 (남성:M, 여성:F)
 *      signdate {String}: 모빌리언스 결제일자
 *      socialno {String}: 모빌리언스 생년월일
 *    }
 *    profileImgUrl {String}: 회원 프로필 이미지 URL
 *    recommCode {String}: 추천인 코드
 *    userId {String}: 회원 ID(이메일)
 *    userJoinType {Number}: 회원가입유형. 0:이메일, 1:네이버, 2:카카오, 3:페이스북, 5:페이코
 *    userPassword {String}: RSA 회원 비밀번호
 *  }
 */
export function joinPartnercenter(partnerCenterMemNo, vo) {
  return axios({
    method: 'post',
    url: `/user/partnercenter/${partnerCenterMemNo}/join`,
    data: vo,
    baseURL,
    headers: getNoHeaders(),
  });
}

/**
 * 회원 SNS 등록
 * @param {*} vo <Required>
 *  {
 *    accessToken {String}: access token
 *    deviceOs {Number}: OS type (0:Android, 1:iOS, 2:mobileWeb)
 *    deviceUuid {String}: 디바이스 고유 아이디
 *    gcmId {String}: gcm/fcm registrationId
 *    marketing {Object}: 마케팅 수신동의 {
 *      agree	{Boolean}: 마케팅 수신동의
 *      emailYn	{Number}: email 수신동의, 0:미동의, 1:동의
 *      pushYn {Number}: push 수신동의, 0:미동의, 1:동의
 *      smsYn {Number}: sms 수신동의, 0:미동의, 1:동의
 *    }
 *    mobileAdId {String}: 핸드폰 AD_ID
 *    mobilians {Object}: 모빌리언스 인증 정보 {
 *      ci {String}: 모빌리언스 CI
 *      commid {String}: 모빌리언스 이동통신사
 *      di {String}: 모빌리언스 DI
 *      foreigner {String}: 모빌리언스 외국인여부 (외국인 : Y)
 *      mac {String}: 모빌리언스 검증키
 *      mobilid {String}: 모빌리언스 거래번호
 *      mstr {String}: 모빌리언스 확장 변수
 *      name {String}: 모빌리언스 이름
 *      no {String}: 모빌리언스 휴대폰번호
 *      resultcd {String}: 모빌리언스 결과코드
 *      sex {String}: 모빌리언스 성별 (남성:M, 여성:F)
 *      signdate {String}: 모빌리언스 결제일자
 *      socialno {String}: 모빌리언스 생년월일
 *    }
 *    nickName {String}: 회원 닉네임
 *    parentMobilians {Object}: 모빌리언스 인증 정보 {
 *      ci {String}: 모빌리언스 CI
 *      commid {String}: 모빌리언스 이동통신사
 *      di {String}: 모빌리언스 DI
 *      foreigner {String}: 모빌리언스 외국인여부 (외국인 : Y)
 *      mac {String}: 모빌리언스 검증키
 *      mobilid {String}: 모빌리언스 거래번호
 *      mstr {String}: 모빌리언스 확장 변수
 *      name {String}: 모빌리언스 이름
 *      no {String}: 모빌리언스 휴대폰번호
 *      resultcd {String}: 모빌리언스 결과코드
 *      sex {String}: 모빌리언스 성별 (남성:M, 여성:F)
 *      signdate {String}: 모빌리언스 결제일자
 *      socialno {String}: 모빌리언스 생년월일
 *    }
 *    profileImgUrl {String}: 회원 프로필 이미지 URL
 *    recommCode {String}: 추천인 코드
 *    userId {String}: 회원 ID(이메일)
 *    userJoinType {Number}: 회원가입유형. 0:이메일, 1:네이버, 2:카카오, 3:페이스북, 5:페이코
 *    userPassword {String}: RSA 회원 비밀번호
 *  }
 */
export function joinSns(vo) {
  return axios({
    method: 'post',
    url: `/user/sns/join`,
    data: vo,
    baseURL,
    headers: getNoHeaders(),
  });
}

/**
 * 회원 Email 로그인
 * @param {object} vo  <Required>
 *  {
 *    accessToken {String}: access token
 *    deviceOs {Number}: OS type (0:Android, 1:iOS, 2:mobileWeb)
 *    deviceUuid {String}: 디바이스 고유 아이디
 *    gcmId {String}: gcm/fcm registrationId
 *    joinType {Number}: 회원가입유형. 0:이메일, 1:네이버, 2:카카오, 3:페이스북, 5:페이코
 *    mobileAdId {String}: 핸드폰 AD_ID
 *    refreshToken {String}: refresh token
 *    uniqueId {String}: OAUTH 고유 ID
 *  }
 */
export function loginSns(vo) {
  return axios({
    method: 'post',
    url: `/user/sns/login`,
    data: vo,
    baseURL,
    headers: getNoHeaders(),
  });
}

export const postAddDelivery = data => {
  return axios({
    method: 'post',
    url: `/user/info/delivery`,
    data,
    baseURL,
    headers: getNoHeaders(),
  });
};

/**
 * 비밀번호 변경
 * @param {
 *  mobilians: {
 *    ci: string,
 *    commid: string,
 *    di: string,
 *    foreigner: string,
 *    mac: string,
 *    mobilid": string,
 *    mstr: string,
 *    name: string,
 *    no: string,
 *    resultcd: string,
 *    sex: string,
 *    signdate: string,
 *    socialno: string
 *  },
 *  user: {
 *    userId: string,
 *    userOldPassword: string,
 *    userPassword: encPassword(password),
 *  }
 * } param
 */
export function postPasswordChange(param) {
  return axios({
    method: 'post',
    url: `/user/find/password/change`,
    data: param,
    baseURL,
    headers: getNoHeaders(),
  });
}

/**
 * 회원 실명 조회
 */
export const getUserName = (getPath = false) => {
  if (getPath) return `${baseURL}/user/info/name`;

  return axios({
    method: 'get',
    url: `/user/info/name`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 회원 추천인 코드 조회
 */

export const getRecommendCode = (getPath = false) => {
  if (getPath) return `${baseURL}/user/info/recomm_code`;

  return axios({
    method: 'get',
    url: `/user/info/recomm_code`,
    baseURL,
    headers: getNoHeaders(),
  });
};

export function cafeLevelUp(type) {
  return axios({
    method: 'post',
    url: `/user/state/cafe/level/apply/${type}`,
    baseURL,
    headers: getHeaders(),
  });
}

export function getUserInfo(token) {
  if (token && token !== '') {
    return axios({
      method: 'get',
      url: `/user/state/info`,
      baseURL,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Os-Type': getOsType(),
      },
    });
  }

  return axios({
    method: 'get',
    url: `/user/state/info`,
    baseURL,
    headers: getHeaders(),
  });
}

export function getUserCurrentState() {
  return axios({
    method: 'get',
    url: `/user/state/current`,
    baseURL,
    headers: getHeaders(),
  });
}

export function putLinkCafe(userSnsVO) {
  return axios({
    method: 'put',
    url: `/user/state/link/cafe`,
    data: JSON.stringify(userSnsVO),
    baseURL,
    headers: getHeaders(),
  });
}

export function putChangePhoneExpireState(mobilians, token) {
  return axios({
    method: 'put',
    url: `/v2/user/state/phone/expire`,
    data: mobilians,
    baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Os-Type': getOsType(),
    },
  });
}

export const putReleaseRest = (data, token) => {
  return axios({
    method: 'put',
    url: `/v2/user/state/rest`,
    data,
    baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Os-Type': getOsType(),
    },
  });
};
