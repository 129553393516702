import { dayString } from './time';

export const searchDefault = {
  isSearchSaved: 0,
  searchQuantity: 20,
  searchWord: '',
  sort: {
    date: 0,
    order: 0,
    price: 0,
  },
  startIndex: 0,
};

export const filterDefault = {
  categoryDepth: 0,
  categorySeq: 0,
  color: 'ZZZZZZ',
  condition: {
    options: {
      flawedYn: 0,
      fullPackageYn: 0,
      limitedEditionYn: 0,
    },
    productCondition: -1,
  },
  consignmentYn: 0,
  locations: [],
  maxPrice: 1000 * 1000 * 1000 * 2,
  minPrice: 0,
  platformType: 1, // 사업별 구분, 1: 중고나라, 2: 오토, 3: 평화시장
  preferredTrade: 0,
  sortEndDate: '',
  sortStartDate: '',
  state: -1,
  categorySeqList: [],
  searchStartDate: dayString('YYYY-MM-DD HH:mm:ss'),
  productSectionType: 0, // 0 고정, 0: 해당없음, 10: 새상품, 20: 구제상품: 30: 중고폰
};

// 통합검색 url 치환값
export const searchTabUrls = [
  { name: 'total_v2', url: '/search-list/total' },
  { name: 'app_product_v1', url: '/search-list/product' },
  { name: 'product_v2', url: '/search-list/product' },
  { name: 'partner_center_v2', url: '/search-list/partnerCenter' },
  { name: 'store_v2', url: '/search-list/store' },
];

// 필터 '상품상태' 초기값
export const conditionsList = [
  { name: 'all', label: '전체', value: -1 },
  { name: 'new', label: '미개봉', value: 0 },
  { name: 'almostNew', label: '거의새것', value: 1 },
  { name: 'used', label: '중고', value: 2 },
];

// 필터 '상품상태-추가옵션' 초기값
export const conditionOptionsList = [
  { name: 'limitedEditionYn', label: '한정판 상품', value: 1 },
  { name: 'fullPackageYn', label: '모든 구성품', value: 1 },
  { name: 'flawedYn', label: '하자있음', value: 1 },
];

// 필터 '가격' 초기값
export const priceRangeOption = {
  max: 100,
  min: 0,
  step: 1,
};

// 필터 '등록기간' 초기값
export const periodList = [
  { name: 'all', label: '전체', value: 0 },
  { name: '1', label: '1일', value: 1 },
  { name: '3', label: '3일', value: 3 },
  { name: '7', label: '7일', value: 7 },
  { name: '14', label: '14일', value: 14 },
  { name: '30', label: '한달', value: 30 },
  { name: '90', label: '3개월', value: 90 },
];

// 필터 '거래방법' 초기값
export const howToTrade = [
  { name: 'dealAll', label: '모두가능', value: 0 },
  { name: 'dealDirect', label: '직거래', value: 1 },
  { name: 'dealDelivery', label: '택배거래', value: 2 },
];

// 검색필터 초기값
export const searchFilterDefault = {
  location: null,
  category: 0,
  dateFilter: 0,

  productCondition: -1,
  flawedYn: 0,
  fullPackageYn: 0,
  limitedEditionYn: 0,
  maxPrice: 100,
  minPrice: 0,
  tradeType: 0,
};

export const defaultCoordinate = {
  lat: 37.49536669081603,
  lon: 127.0330653618506,
};

export const orderByList = [
  {
    key: 'orderBy_New',
    text: '최신순',
    value: 'new',
  },
  {
    key: 'orderBy_Low',
    text: '낮은 가격순',
    value: 'low',
  },
  {
    key: 'orderBy_High',
    text: '높은 가격순',
    value: 'high',
  },
  {
    key: 'orderBy_status',
    text: '상품상태순',
    value: 'status',
  },
];
export const orderBySearchCategory = [
  {
    key: 'orderBy_New',
    text: '최신순',
    value: 'RECENT_SORT',
  },
  {
    key: 'orderBy_Low',
    text: '낮은 가격순',
    value: 'PRICE_ASC_SORT',
  },
  {
    key: 'orderBy_High',
    text: '높은 가격순',
    value: 'PRICE_DESC_SORT',
  },
  {
    key: 'orderBy_status',
    text: '상품상태순',
    value: 'PRODUCT_ASC_SORT',
  },
];

export const orderByProduct = [
  {
    key: 'orderBy_Recommend',
    text: '추천순',
    value: 'RECOMMEND_SORT',
  },
  {
    key: 'orderBy_New',
    text: '최신순',
    value: 'RECENT_SORT',
  },
  {
    key: 'orderBy_Low',
    text: '낮은 가격순',
    value: 'PRICE_ASC_SORT',
  },
  {
    key: 'orderBy_High',
    text: '높은 가격순',
    value: 'PRICE_DESC_SORT',
  },
];
export const orderByStore = [
  {
    key: 'orderBy_Favorite',
    text: '추천순',
    value: 'favorite',
  },
  {
    key: 'orderBy_Grade',
    text: '등급 높은 순',
    value: 'grade',
  },
  {
    key: 'orderBy_Follower',
    text: '단골 많은 순',
    value: 'follower',
  },
];

export const colorCode = [
  {
    code: 'ZZZZZZ',
    name: '선택안함',
    label: '없음',
  },
  {
    code: 'FF0000',
    name: '빨강색',
  },
  {
    code: 'FF9100',
    name: '주황색',
  },
  {
    code: 'FFEB46',
    name: '노랑색',
  },
  {
    code: '1E821E',
    name: '초록색',
  },
  {
    code: '0000FF',
    name: '파랑색',
  },
  {
    code: '8572EE',
    name: '보라색',
  },
  {
    code: 'FF9E9B',
    name: '분홍색',
  },
  {
    code: 'E1B771',
    name: '베이지색',
  },
  {
    code: '8B4513',
    name: '갈색',
  },
  {
    code: 'FFFFFF',
    name: '흰색',
  },
  {
    code: '646464',
    name: '회색',
  },
  {
    code: '000000',
    name: '검은색',
  },
  {
    code: 'XXXXXX',
    name: '혼합',
  },
];

export const swiperParam = {
  default: {
    resistanceRatio: 0, // 이동 불가능시 저항의 크기
    freeMode: false, // 한개의 슬라이드 씩 이동
    threshold: 5,
  },
  space: {
    spaceBetween: 15, // item 사이의 간격
    slidesOffsetBefore: 15, // 스와이퍼 좌측 여백
    slidesOffsetAfter: 15, // 스와이퍼 우측 여백
  },
  pagingCount: {
    pagination: {
      el: '.swiper-pagination.numbering',
      type: 'fraction',
      renderFraction: (current, total) => {
        return `<span class="${current}"></span> / <span class="${total}"></span>`;
      },
    },
  },
  pagingDot: {},
  tab: {
    slidesPerView: 'auto', // css에서 처리
    freeMode: true, // 헌번에 여러개가 움직임
    nested: true, // 중복 터치이벤트 차단
    spaceBetween: 8,
    shouldSwiperUpdate: true,
  },
};

export const productConditionList = [
  { condition: -1, name: '전체', gaLocation: '/all' },
  { condition: 2, name: '중고', gaLocation: '/used' },
  { condition: 1, name: '거의새것', gaLocation: '/new' },
  { condition: 0, name: '미개봉', gaLocation: '/unboxing' },
];

export const searchType = {
  default: '검색',
  popularKeyword: '인기검색어',
  consignSeller: '인증상품',
  myTagKeyword: '마이태그키워드',
  recentKeyword: '최근검색어',
  recommendKeyword: '추천검색어',
  suggestKeyword: '제안검색어',
};

// 판/구매내역 필터 : 기간
export const orderHistoryFilters = {
  datePeriod: [
    {
      text: '최근 1년',
      value: '',
    },
    {
      text: '1주일',
      value: '1',
    },
    {
      text: '1개월',
      value: '2',
    },
    {
      text: '3개월',
      value: '3',
    },
    {
      text: '6개월',
      value: '4',
    },
  ], // 검색기간 (API Response Key)
  dealType: [
    {
      text: '전체',
      value: '',
    },
    {
      text: '택배거래',
      value: 'POST',
    },
    {
      text: '직거래',
      value: 'MEET',
    },
  ], // 거래방법 (API Response Key)
  orderStatus: [
    { text: '전체', value: '', showDealType: 'ALL' },
    { text: '무통장입금예정', value: '09', showDealType: 'POST' },
    { text: '무통장입금취소', value: '08', showDealType: 'POST' },
    { text: '결제완료', value: '01', showDealType: 'ALL' },
    { text: '주문확인/배송준비', value: '11', showDealType: 'ALL' },
    { text: '주문확인/배송준비', value: '21', showDealType: 'ALL' },
    { text: '주문확인/배송준비', value: '84', showDealType: 'ALL' },
    { text: '배송중', value: '02', showDealType: 'POST' },
    { text: '배송완료', value: '23', showDealType: 'POST' },
    { text: '거래완료', value: '03', showDealType: 'ALL' },
    { text: '거래완료', value: '50', showDealType: 'ALL' },
    { text: '거래완료', value: '60', showDealType: 'ALL' },
    { text: '거래완료', value: '51', showDealType: 'ALL' },
    { text: '거래취소', value: '59', showDealType: 'ALL' },
    { text: '거래취소', value: '81', showDealType: 'ALL' },
    { text: '거래취소', value: '82', showDealType: 'ALL' },
    { text: '반품신청', value: '05', showDealType: 'POST' },
    { text: '반품수락', value: '06', showDealType: 'POST' },
    { text: '반품배송중', value: '07', showDealType: 'POST' },
    { text: '반품완료', value: '10', showDealType: 'POST' },
    { text: '관리자 취소', value: '83', showDealType: 'ALL' },
  ], // 주문상태 (API Response Key)
  isPaymentPending: [
    {
      text: '정산보류',
      value: 'true',
    },
  ], // 정산상태 (API Response Key)
};

// 시세조회 api 분기위한 타입
export const actionDetailType = {
  MARKET_PRICE_PRODUCT_DETAIL: {
    text: 'MARKET_PRICE_PRODUCT_DETAIL',
    api: 'PRODUCT',
  },
  MARKET_PRICE_PRODUCT_REGISTER: {
    text: 'MARKET_PRICE_PRODUCT_REGISTER',
    api: 'PRODUCT',
  },
  MARKET_PRICE_MAIN: { text: 'MARKET_PRICE_MAIN', api: 'MAIN' },
  MARKET_PRICE_CAFE: { text: 'MARKET_PRICE_CAFE', api: 'MAIN' },
  MARKET_PRICE_DETAIL_VIEW: { text: 'MARKET_PRICE_DETAIL_VIEW', api: 'MAIN' },
  MARKET_PRICE_SEARCH: { text: 'MARKET_PRICE_SEARCH', api: 'MAIN' },
};

export const searchPriceKeywordSource = {
  LATEST_KEYWORD: 'LATEST_KEYWORD', // 최근검색어
  SUGGESTED_KEYWORD: 'SUGGESTED_KEYWORD', // 자동완성 키워드
  INPUT_KEYWORD: 'INPUT_KEYWORD', // 검색어 직접 입력
  RELATED_KEYWORD: 'RELATED_KEYWORD', // 연관 키워드
  POPULAR_KEYWORD: 'POPULAR_KEYWORD', // 인기 키워드
};

// 결제 관련 약관
export const paymentTerms = {
  UNICRO_SERVICE: {
    text: '유니크로 이용약관 동의',
    printType: 'IFRAME',
    content: 'https://m.unicro.co.kr/static/html/jn_join_agree_v4.jsp',
  },
  UNICRO_COLLECT_AND_SERVICE: {
    text: '유니크로 개인정보 수집 및 이용안내',
    printType: 'IFRAME',
    content: 'https://m.unicro.co.kr/static/html/jn_join_privacy_v4.jsp',
  },
  UNICRO_COLLECT_AND_CONSIGN: {
    text: '유니크로 개인정보 수집 및 위탁 안내',
    printType: 'IFRAME',
    content: 'https://m.unicro.co.kr/static/html/jn_join_privacyConsig_v4.jsp',
  },
  PERSONAL_INFO_COLLECT: {},
};

// appsFlyer 전용 함수. 결제수단에 대한 텍스트 생성.
export const makePaymentOption = paymentDetail => {
  if (paymentDetail === 'SETTLE_CARD') {
    return '중고나라페이 / 카드결제';
  }
  if (paymentDetail === 'SETTLE_DEPOSITLESS') {
    return '중고나라페이 / 무통장입금';
  }
  if (paymentDetail === 'SETTLE_BANK_TRANSFER') {
    return '중고나라페이 / 계좌이체';
  }
  if (paymentDetail === 'KB_APP_CARD') {
    return 'KB국민카드';
  }
  if (paymentDetail === 'UNICRO_CARD') {
    return '유니크로 / 카드결제';
  }
  if (paymentDetail === 'UNICRO_DEPOSITLESS') {
    return '유니크로 / 무통장입금';
  }
  return '유니크로 / 계좌이체';
};
