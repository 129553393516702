import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import useHistory from './useHistory';
import useGoBack from './useGoBack';

import {
  InitReducer,
  UserLoginInfoReducer,
  MyLocationReducer,
  GeoLocationReducer,
} from '../../reducers/actionCreators';

import { getToken, saveToken, removeToken, useLocalhostLogin } from '../auth';
import { saveSessionStorage, getQueryStringObj } from '../util';
import { interfaceGetAppInfo } from '../webView';
import { historyModify, alert } from '../dom';
import { dayString } from '../time';

import { removelogOutUserStore } from '../combineDisPatch';

import { config } from '../api';
import { getUserCurrentState } from '../../api/base/user';
import { tokenValid } from '../../api/base/common';

import * as Common from '../../js/common';

// 로직 확실하게 파악 후, 삭제필요
const WILD = {
  history: [],
  pop: [],
};

const useInit = () => {
  const {
    history,
    location: { pathname, search },
  } = useHistory();

  const goBackHook = useGoBack();
  const useLogin = useLocalhostLogin();

  const { isApp, isLocalhost } = useSelector(
    ({ userLoginInfo }) => userLoginInfo,
  );

  const [isLoading, setIsLoading] = useState(true);
  const [isFetch, setIsFetch] = useState(false);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (pathname !== '/webview-cache') {
      const his = { ...history };
      const { action } = history; // action: PUSH, POP

      const isPop = action === 'POP';
      if (isPop) {
        WILD.history.shift(); // history: remove
      }

      const isEmpty = WILD.history.length === 0;
      if (isEmpty) {
        WILD.history.unshift(his); // history: add
      } else if (isPop) {
        WILD.pop.unshift(his); // pop: add
      } else {
        WILD.history.unshift(his); // history: add
        WILD.pop.length = 0; // pop: clear
      }
    }
  }, [history]);

  useEffect(() => {
    // 토큰 유효성 체크
    const validTokenCheck = async token => {
      try {
        const {
          data: { data },
        } = await tokenValid(token, true);
        if (!data) {
          return false;
        }
        return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    };

    if (!isLoading) {
      const token = getToken();
      if (isApp) {
        (async () => {
          if (token) {
            const valid = await validTokenCheck(token);
            if (valid) {
              saveToken(token);
              if (pathname.includes('/product-regist')) {
                const {
                  data: { data: userState },
                } = await getUserCurrentState();
                if (userState.userStatus === 3) {
                  alert({
                    text: `활동 정지 상태입니다.\n${dayString(
                      'YYYY.MM.DD',
                      userState.blockEndDate,
                    )} 이후\n정상적으로 이용 가능합니다.`,
                    onClick: () => {
                      if (pathname !== '/home' && pathname !== '/mypage')
                        goBackHook();
                    },
                  });
                }
              }
            } else {
              removeToken();
            }
            setIsValid(valid);
          }
        })();
      } else if (!useLogin && token) removeToken();
    }
  }, [pathname, isLoading]);

  useEffect(() => {
    const init = async () => {
      try {
        await InitReducer.appInit();
        if (getToken()) {
          await UserLoginInfoReducer.getInfo();
          if (!isApp) MyLocationReducer.getMyLocationList();
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsFetch(false);
        setIsLoading(false);

        // 로직 확실하게 파악 후, 삭제필요
        Common.setHeight();
      }
    };
    if (isFetch) {
      init();
    }
  }, [isFetch]);

  useEffect(() => {
    const responseAppEventListner = data => {
      if (data.Authorization) {
        saveToken(data.Authorization);
      } else {
        removeToken();
        removelogOutUserStore();
      }
      const result = data;
      delete result.Authorization;
      saveSessionStorage('jnAppInfo', { ...result });
      setIsFetch(true);
      window.removeEventListener('message', responseAppEventListner, false);
    };
    // 앱 정보 요청 및 세팅
    const initAppData = async () => {
      if (isApp) {
        window.responseAppEventListner = responseAppEventListner;
        window.addEventListener('message', responseAppEventListner, false);
        // 앱에 정보 요청하는 인터페이스 호출
        interfaceGetAppInfo();
      } else {
        UserLoginInfoReducer.clearInfo(false);
        setIsFetch(true);
      }
    };

    if (!isApp) historyModify(pathname, search);

    const { origin } = window.location;
    if (origin !== config.DEFAULT_URL && !isApp && !isLocalhost) {
      window.location.replace(`${config.DEFAULT_URL}${pathname}${search}`);
    } else if (pathname !== '/webview-cache') {
      window.WILD = WILD;
      if (isApp) {
        const { mlat, mlng } = getQueryStringObj(search);
        if (mlat && mlng) {
          GeoLocationReducer.setInAppGeoLocation({ mlat, mlng });
        }
      }
      setIsLoading(true);
      initAppData();
    } else {
      setIsLoading(false);
    }
  }, []);

  return {
    isLoading,
    isValid,
  };
};

export default useInit;
