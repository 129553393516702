import axios from 'axios';
import * as Config from '../config';
import * as Common from '../../js/common';

const mypage = {
  GET_NOTICE: 'mypage/GET_NOTICE',
  // 판매내역
  GET_ORDER_SALE: 'mypage/GET_ORDER_SALE',
  GET_ORDER_SALE_DETAIL: 'mypage/GET_ORDER_SALE_DETAIL',
  SET_ORDER_SALE_RESET: 'mypage/SET_ORDER_SALE_RESET',
  GET_ORDER_PRODUCT: 'mypage/GET_ORDER_PRODUCT',
  // 택배 관리
  GET_DELIVERY_PRODUCT: 'mypage/GET_DELIVERY_PRODUCT',
  GET_DELIVERY_RECEIVE: 'mypage/GET_DELIVERY_RECEIVE',
  GET_DELIVERY_SEND: 'mypage/GET_DELIVERY_SEND',
  GET_DELIVERY_DETAIL: 'mypage/GET_DELIVERY_DETAIL',
  GET_DELIVERY_HISTORY: 'mypage/GET_DELIVERY_HISTORY',
  CLEAR_DELIVERY_HISTORY: 'mypage/CLEAR_DELIVERY_HISTORY',
  GET_SEND_PRODUCT_LIST: 'mypage/GET_SEND_PRODUCT_LIST',
  CLEAR_SEND_PRODUCT_LIST: 'mypage/CLEAR_SEND_PRODUCT_LIST',

  // 마이페이지 인포
  GET_MYPAGE: 'mypage/GET_MYPAGE',
  // 마이페이지 팔로우
  GET_FOLLOWER: 'mypage/GET_FOLLOWER',
  GET_FOLLOWING: 'mypage/GET_FOLLOWING',
  DELETE_FOLLOW: 'mypage/DELETE_FOLLOW',
  POST_FOLLOW: 'mypage/POST_FOLLOW',

  // 마이스토어 셋팅
  GET_MYSTORE_INFO: 'mypage/GET_MYSTORE_INFO',
  CLEAR_MYSTORE_INFO: 'mypage/CLEAR_MYSTORE_INFO',
  PUT_MYSTORE_SET: 'mypage/PUT_MYSTORE_SET',
  PUT_MYSTORE_CATEGORY_SET: 'mypage/PUT_MYSTORE_CATEGORY_SET',
  MY_STORE_SELL_CLEAR: 'mypage/MY_STORE_SELL_CLEAR',
  POST_MYSTORE_SELL: 'mypage/POST_MYSTORE_SELL',
  GET_MYSTORE_SHARE: 'mypage/GET_MYSTORE_SHARE',
  GET_MYSTORE_FRIEND: 'mypage/GET_MYSTORE_FRIEND',

  // 마이페이지 후기 리스트
  GET_REVIEW_LIST: 'mypage/GET_REVIEW_LIST',
  PUT_REVIEW_LIKE: 'mypage/PUT_REVIEW_LIKE',
  DELETE_REVIEW_LIKE: 'mypage/DELETE_REVIEW_LIKE',
  DELETE_REVIEW: 'mypage/DELETE_REVIEW',

  // 마이페이지 알림 설정
  GET_PUSH_SETTING: 'mypage/GET_PUSH_SETTING',
  PUT_PUSH_SETTING: 'mypage/PUT_PUSH_SETTING',
  PUT_PUSH_SETTING_FOLLOW: 'mypage/PUT_PUSH_SETTING_FOLLOW',
  PUT_PUSH_SETTING_FOLLOW_ALL: 'mypage/PUT_PUSH_SETTING_FOLLOW_ALL',
  PUT_MYSTORE_PROFILE: 'mypage/PUT_MYSTORE_PROFILE',

  // 마이페이지 카페 레벨업 신청
  POST_CAFELEVEL_APPLY: 'mypage/POST_CAFELEVEL_APPLY',

  // 판매 상세 리셋
  SET_ORDER_SALE_DETAIL_INIT: 'mypage/SET_ORDER_SALE_DETAIL_INIT',

  // 편의점택배 상세 리셋
  SET_DELIVERY_DETAIL_INIT: 'mypage/SET_DELIVERY_DETAIL_INIT',

  // 상품 상태값 변경
  SET_PRODUCT_STATE: 'mypage/SET_PRODUCT_STATE',
  SET_PRODUCT_DELETE: 'mypage/SET_PRODUCT_DELETE',

  // 알림설정 - 키워드 알림 설정
  GET_KEYWORD_NOTIFYSET: 'mypage/GET_KEYWORD_NOTIFYSET',
  POST_KEYWORD_REGIST: 'mypage/POST_KEYWORD_REGIST',
  PUT_KEYWORD_MODIFY: 'mypage/PUT_KEYWORD_MODIFY',
  DELETE_KEYWORD_NOTIFY: 'mypage/DELETE_KEYWORD_NOTIFY',

  // 이전상품 판매리스트
  POST_BEFORE_SELL_LIST: 'mypage/POST_BEFORE_SELL_LIST',
};

export function getNotice() {
  const promise = axios.get(
    `${
      Config.default.API_BASE_URL
    }/store/menu/notice/list?page=0&count_per_page=10`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 판매내역 조회
export function getOrderSale(pageNumber, orderStatus, productTitle) {
  const promise = axios.get(
    `${
      Config.default.API_BASE_URL
    }/mypage/order/sale/list?pageNumber=${pageNumber}${
      orderStatus ? `&orderStatus=${orderStatus}` : ''
    }${productTitle ? `&productTitle=${productTitle}` : ''}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// export function getOrderSale(clickCount, orderStatus, productTitle) {
//   const promise = axios.get(
//     `${Config.default.API_BASE_URL}/mypage/order/sale?clickCount=${clickCount}${
//       orderStatus ? `&orderStatus=${orderStatus}` : ''
//     }${productTitle ? `&productTitle=${productTitle}` : ''}`,
//     Config.getHeader(Config.default.JSON_TYPE),
//   );
//   return promise;
// }

// 판매내역 초기화
export function setOrderSaleReset(result) {
  return result;
}

// 판매내역 상세
export function getOrderSaleDetail(orderNo) {
  const promise = axios.get(
    `${
      Config.default.API_BASE_URL
    }/mypage/order/sale/detail?orderNo=${orderNo}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 배송조회 시 상품 정보 조회
export function getOrderProduct(orderNo) {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/mypage/order/product/${orderNo}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 배송조회 시 상품 정보 조회
export function getDeliveryProduct(deliverySeq) {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/mypage/delivery/product/${deliverySeq}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 받은 택배 목록
export function getDeliveryReceive() {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/mypage/delivery/receive`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 보낸 택배 목록
export function getDeliverySend() {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/mypage/delivery/send`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 편의점 택배 상세
export function getDeliveryDetail(type, deliverySeq) {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/mypage/delivery/${type}/${deliverySeq}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );

  return promise;
}

// 택배 배송 조회
export function getDeliveryHistory(deliveryCorpCode, invoiceNo) {
  invoiceNo = invoiceNo.replace(/[^0-9]/g, ''); // filter

  const promise = axios.get(
    `${Config.default.API_BASE_URL}/delivery/${deliveryCorpCode}/${invoiceNo}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 보낼 상품 신청
export function getSendProductList(page, quantity) {
  const promise = axios.get(
    `${
      Config.default.API_BASE_URL
    }/v2/delivery/product/list?page=${page}&pageSize=${quantity}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 마이스토어 정보 조회
export function getMystoreInfo(storeSeq) {
  const seenProduct = Common.getSeenProduct();
  if (storeSeq) {
    const header =
      localStorage.getItem('jn_access_token') != null &&
      localStorage.getItem('jn_access_token') != ''
        ? Config.getHeader(Config.default.JSON_TYPE)
        : Config.default.JSON_HEADER;
    const promise = axios.get(
      `${Config.default.API_BASE_URL}/mystore?storeSeq=${storeSeq}`,
      header,
    );
    return promise;
  }
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/mystore?seenProduct=${seenProduct}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 마이스토어 셋팅
export function putMystoreSet(type, content) {
  const promise = axios.put(
    `${
      Config.default.API_BASE_URL
    }/mystore/mypage/setting/${type}?content=${content}`,
    null,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 마이스토어 카테고리 셋팅
export function putMystoreCategorySet(seq) {
  const promise = axios.put(
    `${Config.default.API_BASE_URL}/mystore/category/${seq}`,
    null,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 마이페이지 인포
export function getMypage() {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/v2/my-store/mypage?osType=3&deviceType=2`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 마이페이지 수익정보
export function getProfitDetail() {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/v2/my-store/mypage/profitDetail`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 마이페이지 팔로워/팔로잉 조회
export function getFollower(followType) {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/mypage/follow/${followType}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
export function getFollowing(followType) {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/mypage/follow/${followType}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 마이페이지 팔로잉 해제
export function deleteFollow(follow) {
  const promise = axios.delete(
    `${Config.default.API_BASE_URL}/mypage/follow/${follow}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 마이페이지 팔로잉 하기
export function postFollow(follow) {
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/mypage/follow/${follow}`,
    '',
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 마이스토어 후기 전체보기
export function getReviewList(storeSeq, sortType) {
  const promise = axios.get(
    `${
      Config.default.API_BASE_URL
    }/mystore/review/${storeSeq}?sortType=${sortType}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 마이스토어 후기 좋아요/싫어요
export function putReviewLike(orderNo, likeType) {
  const promise = axios.put(
    `${Config.default.API_BASE_URL}/mystore/review/${orderNo}/like/${likeType}`,
    null,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 마이스토어 후기 좋아요/싫어요 삭제
export function deleteReviewLike(orderNo, likeType) {
  const promise = axios.delete(
    `${Config.default.API_BASE_URL}/mystore/review/${orderNo}/like/${likeType}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 마이스토어 후기 삭제
export function deleteReview(orderNo) {
  const promise = axios.delete(
    `${Config.default.API_BASE_URL}/mystore/review/${orderNo}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 마이스토어 판매 리스트
export function postMystoreSell(storeSeq, type, startIndex, searchQuantity) {
  const vo = {
    filter: {
      categoryDepth: 0,
      categorySeq: 0,
      color: '',
      condition: {
        options: {
          flawedYn: 0,
          fullPackageYn: 0,
          limitedEditionYn: 0,
        },
        productCondition: -1,
      },
      // "locations": [
      //   {
      //     "locationCode": "string",
      //     "locationType": 0
      //   }
      // ],
      maxPrice: 0,
      minPrice: 0,
      preferredTrade: 0,
      // "sortEndDate": "",
      // "sortStartDate": "",
      state: 0,
    },
    isSearchSaved: 0,
    searchQuantity,
    searchWord: '',
    sort: {
      date: 0,
      order: 0,
      price: 0,
    },
    startIndex: startIndex * searchQuantity,
  };
  let header = Config.getHeader(Config.default.JSON_TYPE);
  if (
    localStorage.getItem('jn_access_token') != null &&
    localStorage.getItem('jn_access_token') != ''
  ) {
  } else {
    header = Config.default.JSON_HEADER;
  }
  const promise = axios.post(
    `${Config.default.SEARCH_API_BASE_URL}/v2/search/store/${storeSeq}`,
    vo,
    header,
  );
  return promise;
}
// 상품 등록 시 이전 판매리스트
export function postBeforeSellList(type, startIndex) {
  const vo = {
    filter: {
      categoryDepth: 0,
      categorySeq: 0,
      color: '',
      condition: {
        options: {
          flawedYn: 0,
          fullPackageYn: 0,
          limitedEditionYn: 0,
        },
        productCondition: -1,
      },
      // "locations": [
      //   {
      //     "locationCode": "string",
      //     "locationType": 0
      //   }
      // ],
      maxPrice: 0,
      minPrice: 0,
      preferredTrade: 0,
      // "sortEndDate": "",
      // "sortStartDate": "",
      state: 0,
    },
    isSearchSaved: 0,
    searchQuantity: 50,
    searchWord: '',
    sort: {
      date: 0,
      order: 0,
      price: 0,
    },
    startIndex,
  };
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/mystore/sell/${type}`,
    vo,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 마이페이지 알림 설정 조회
export function getPushSetting() {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/mypage/pushSetting`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 마이페이지 알림 설정
export function putPushSetting(pushYn, pushType, currentPushType) {
  let setting = '';
  if (pushYn == 0) {
    setting = 1;
  } else if (pushYn == 1) {
    setting = 0;
  }
  let vo = {};
  if (pushType == 'receiveReplyYn') {
    vo = {
      privacyEndTime: '',
      privacyStartTime: '',
      receiveReplyYn: setting,
      receiveWishYn: currentPushType.receiveWishYn,
      receiveFollowerYn: currentPushType.receiveFollowerYn,
      receiveChattingYn: currentPushType.receiveChattingYn,
      receiveDeliveryYn: currentPushType.receiveDeliveryYn,
      receiveFollowingProductYn: currentPushType.receiveFollowingProductYn,
      receiveKeywordYn: currentPushType.receiveKeywordYn,
    };
  } else if (pushType == 'receiveWishYn') {
    vo = {
      privacyEndTime: '',
      privacyStartTime: '',
      receiveWishYn: setting,
      receiveReplyYn: currentPushType.receiveReplyYn,
      receiveFollowerYn: currentPushType.receiveFollowerYn,
      receiveChattingYn: currentPushType.receiveChattingYn,
      receiveDeliveryYn: currentPushType.receiveDeliveryYn,
      receiveFollowingProductYn: currentPushType.receiveFollowingProductYn,
      receiveKeywordYn: currentPushType.receiveKeywordYn,
    };
  } else if (pushType == 'receiveFollowerYn') {
    vo = {
      privacyEndTime: '',
      privacyStartTime: '',
      receiveFollowerYn: setting,
      receiveReplyYn: currentPushType.receiveReplyYn,
      receiveWishYn: currentPushType.receiveWishYn,
      receiveChattingYn: currentPushType.receiveChattingYn,
      receiveDeliveryYn: currentPushType.receiveDeliveryYn,
      receiveFollowingProductYn: currentPushType.receiveFollowingProductYn,
      receiveKeywordYn: currentPushType.receiveKeywordYn,
    };
  } else if (pushType == 'receiveChattingYn') {
    vo = {
      privacyEndTime: '',
      privacyStartTime: '',
      receiveChattingYn: setting,
      receiveFollowerYn: currentPushType.receiveFollowerYn,
      receiveReplyYn: currentPushType.receiveReplyYn,
      receiveWishYn: currentPushType.receiveWishYn,
      receiveDeliveryYn: currentPushType.receiveDeliveryYn,
      receiveFollowingProductYn: currentPushType.receiveFollowingProductYn,
      receiveKeywordYn: currentPushType.receiveKeywordYn,
    };
  } else if (pushType == 'receiveDeliveryYn') {
    vo = {
      privacyEndTime: '',
      privacyStartTime: '',
      receiveDeliveryYn: setting,
      receiveFollowerYn: currentPushType.receiveFollowerYn,
      receiveReplyYn: currentPushType.receiveReplyYn,
      receiveWishYn: currentPushType.receiveWishYn,
      receiveChattingYn: currentPushType.receiveChattingYn,
      receiveFollowingProductYn: currentPushType.receiveFollowingProductYn,
      receiveKeywordYn: currentPushType.receiveKeywordYn,
    };
  } else if (pushType == 'receiveAllYn') {
    vo = {
      privacyEndTime: '',
      privacyStartTime: '',
      receiveAllYn: setting,
      // "receivePrivacyYn": 0,
      receiveReplyYn: setting,
      receiveWishYn: setting,
      receiveFollowerYn: setting,
      receiveChattingYn: setting,
      receiveDeliveryYn: setting,
      receiveFollowingProductYn: setting,
      receiveKeywordYn: setting,
    };
  } else if (pushType == 'receiveKeywordYn') {
    vo = {
      privacyEndTime: '',
      privacyStartTime: '',
      receiveKeywordYn: setting,
      receiveDeliveryYn: currentPushType.receiveDeliveryYn,
      receiveFollowerYn: currentPushType.receiveFollowerYn,
      receiveReplyYn: currentPushType.receiveReplyYn,
      receiveWishYn: currentPushType.receiveWishYn,
      receiveChattingYn: currentPushType.receiveChattingYn,
      receiveFollowingProductYn: currentPushType.receiveFollowingProductYn,
    };
  } else if (pushType == 'receiveFollowingProductYn') {
    vo = {
      privacyEndTime: '',
      privacyStartTime: '',
      receiveDeliveryYn: currentPushType.receiveDeliveryYn,
      receiveFollowerYn: currentPushType.receiveFollowerYn,
      receiveReplyYn: currentPushType.receiveReplyYn,
      receiveWishYn: currentPushType.receiveWishYn,
      receiveChattingYn: currentPushType.receiveChattingYn,
      receiveFollowingProductYn: setting,
      receiveKeywordYn: currentPushType.receiveKeywordYn,
    };
  }
  const promise = axios.put(
    `${Config.default.API_BASE_URL}/mypage/pushSetting`,
    vo,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 알림설정 스토어별 알림 설정 조회
export function getPushSettingFollow() {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/mypage/pushSetting/follow`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 알림설정 스토어별 알림 설정
export function putPushSettingFollow(followed, type) {
  if (type == 0) {
    type = 1;
  } else if (type == 1) {
    type = 0;
  }
  const promise = axios.put(
    `${
      Config.default.API_BASE_URL
    }/mypage/pushSetting/follow/${followed}/${type}`,
    '',
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 알림설정 스토어 전체 알림 설정
export function putPushSettingFollowAll(type) {
  const promise = axios.put(
    `${Config.default.API_BASE_URL}/mypage/pushSetting/follow/all/${type}`,
    '',
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 마이스토어 공유하기
export function getMystoreShare(storeSeq) {
  if (storeSeq) {
    const promise = axios.get(
      `${
        Config.default.API_BASE_URL
      }/mystore/share?storeSeq=${storeSeq}&targetStoreSeq?=${storeSeq}`,
      Config.default.JSON_HEADER,
    );
    return promise;
  }
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/mystore/share`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 마이페이지 친구초대
export function getMystoreFriend() {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/mystore/friend`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 마이페이지 프로필 이미지 변경
export function putMystoreProfile(imgUrl, isProfile) {
  let profile = '';
  let background = '';
  if (isProfile == 0) {
    background = imgUrl;
  } else if (isProfile == 1) {
    profile = imgUrl;
  }
  const promise = axios.put(
    `${
      Config.default.API_BASE_URL
    }/mystore/profile?profile=${profile}&background=${background}`,
    '',
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 마이페이지 카페등업 신청
export function postCafeLevelApply(type) {
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/user/state/cafe/level/apply/${type}`,
    '',
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 알림설정 - 키워드 알림 설정
export function getKeywordNotifySet() {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/mypage/pushSetting/keyword`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 키워드 등록
export function postKeywordRegist(vo) {
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/mypage/pushSetting/keyword`,
    vo,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 키워드 수정
export function putKeywordModify(userKeywordSeq, vo) {
  const promise = axios.put(
    `${
      Config.default.API_BASE_URL
    }/mypage/pushSetting/keyword/${userKeywordSeq}`,
    vo,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 키워드 삭제
export function deleteKeywordNotify(userKeywordSeq) {
  const promise = axios.delete(
    `${
      Config.default.API_BASE_URL
    }/mypage/pushSetting/keyword/${userKeywordSeq}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 판매 상세 리셋
export function setOrderSaleDetailInit(result) {
  return result;
}

// 편의점택배 상세 리셋
export function setDeliveryDetailInit(result) {
  return result;
}

// 상품 상태값 변경
export function setProductState(result) {
  return result;
}

// 상품 삭제
export function setProductDelete(result) {
  return result;
}

// 사기 이력 조회 화면 > 스토어 정보
export function getMystoreInfoCheckFraud(searchStoreSeq) {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/mystore/info/${searchStoreSeq} `,
    Config.default.JSON_HEADER,
  );
  return promise;
}

export default mypage;
