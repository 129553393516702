import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import dayjs from 'dayjs';
import produce from 'immer';

import * as Category from '../../actions/service/CategoryService';

const initialState = {
  list: [],
  hash: {},
  store: { categoryInfo: undefined },
  onSuccessGetCategory: dayjs(),
};

export const getCategory = createAction(
  Category.default.GET_CATEGORY,
  Category.getCategory,
);
export const getCategoryDepth = createAction(
  Category.default.GET_CATEGORY_DEPTH,
  Category.getCategoryDepth,
);
export const storeCategory = createAction(
  Category.default.STORE_CATEGORY,
  item => item,
);
export const toggleCategory = createAction(
  Category.default.TOGGLE_CATEGORY,
  Category.toggleCategory,
);
export const resetCategory = createAction(Category.default.RESET_CATEGORY);

export default handleActions(
  {
    ...pender({
      type: Category.default.GET_CATEGORY,
      onSuccess: (state, { payload }) => {
        const { category, totalCount } = payload.data.data;
        if (category.length === 0) {
          return state;
        }

        const { depth, parentSeq } = payload;

        return produce(state, draft => {
          // hash
          category.forEach((item, i) => {
            const newItem = { ...item, categoryDepth: depth };
            draft.hash[item.categorySeq] = newItem;
            category[i] = newItem;
          });

          // isDefault and categoryAll
          category.forEach((item, i) => {
            const newItem = { ...item, isDefault: true };
            category[i] = newItem;
          });

          const categoryAll = {
            categoryName: '카테고리 전체',
            categoryProductCount: totalCount,
            categorySeq: parentSeq,
            childYn: 0,
            parentSeq,
            categoryDepth: depth - 1,
            isDefault: false,
          };
          category.unshift(categoryAll);

          draft.list[parentSeq] = category;
          draft.onSuccessGetCategory = dayjs();
        });
      },
    }),
    [Category.default.GET_CATEGORY_DEPTH]: (oldState, action) => {
      const state = { ...oldState };
      return state;
    },
    [Category.default.STORE_CATEGORY]: (oldState, action) => {
      const state = { ...oldState };
      const item = action.payload;
      state.store.categoryInfo = item;
      return state;
    },
    [Category.default.TOGGLE_CATEGORY]: (state, { payload }) => {
      const { depth, parentSeq, parentUlSeq } = payload;

      return produce(state, draft => {
        if (depth === 2) {
          const realDepth = 0;
          const key = state.list[realDepth].findIndex(
            data => data.categorySeq === parentSeq,
          );
          const setOpen = !state.list[realDepth][key].isOpen;

          draft.list = state.list.map((dep2, i) => {
            dep2.map((dep3, j) => {
              if (typeof dep3.isOpen !== 'undefined') {
                dep3.isOpen = false;
              }
              return dep3;
            });
            return dep2;
          });
          draft.list[realDepth][key].isOpen = setOpen;
        } else if (depth === 3) {
          const realDepth = parentUlSeq;

          draft.list[realDepth] = state.list[realDepth].map(v => {
            if (v.categorySeq === parentSeq) {
              v.isOpen = typeof v.isOpen === 'undefined' ? true : !v.isOpen;
            } else {
              v.isOpen = false;
            }
            return v;
          });
        }
      });
    },
    [Category.default.RESET_CATEGORY]: (state, { payload }) => {
      return produce(state, draft => {
        draft.list = state.list.map((dep2, i) => {
          dep2.map(dep3 => {
            if (typeof dep3.isOpen !== 'undefined') {
              dep3.isOpen = false;
            }
            return dep3;
          });
          return dep2;
        });
      });
    },
  },
  initialState,
);
