import { createAction, handleActions } from 'redux-actions';

const initialState = {
  sectionTabStyle: {
    width: '300%',
    transform: '',
    transition: 'all 0.8s ease',
    WebkitTransition: 'all 0.8s ease',
    MozTransition: 'all 0.8s ease',
    msTransition: 'all 0.8s ease',
    OTransition: 'all 0.8s ease',
    display: 'block'
  },
  magicLineStyle: {
    width: '0px',
    left: '0px',
    transform: '',
    transition: 'all 0.8s ease',
    WebkitTransition: 'all 0.8s ease',
    MozTransition: 'all 0.8s ease',
    msTransition: 'all 0.8s ease',
    OTransition: 'all 0.8s ease',
    display: 'block'
  },
  mapViewBtnStyle: {
    display: 'none'
  },
  outerWrapStyle: {}
};

const SET_INDEX = "SET_INDEX";
const INIT_OUTER_WRAP = "INIT_OUTER_WRAP";
const TEST = "TEST";

export const setIndex = createAction(SET_INDEX, i => i);
export const initOuterWrap = createAction(INIT_OUTER_WRAP);
export const test = createAction(TEST);

export default handleActions({
  [SET_INDEX]: (prevState, { payload: i }) => {
    const state = { ...prevState };

    const transform = `translateX(${(-33.33 * i)}%)`;
    state.sectionTabStyle = { ...state.sectionTabStyle, transform };

    const span = window.document.querySelectorAll('#ul-tab-inner li span')[i];
    const width = `${span.offsetWidth}px`;
    const left = `${span.offsetLeft}px`;
    state.magicLineStyle = { ...state.magicLineStyle, width, left };

    const display = (i == 1) ? 'block' : 'none';
    state.mapViewBtnStyle = { ...state.mapViewBtnStyle, display };

    return state;
  },
  [INIT_OUTER_WRAP]: prevState => {
    // foo(prevState);

    const state = { ...prevState };

    const outerWrap = document.getElementById('outerWrap');

    state.outerWrapStyle = { ...state.outerWrapStyle };
    Object.keys(outerWrap.style).filter(key => {
      return (isNaN(Number(key)) && outerWrap.style[key]);
    }).map(key => {
      const value = outerWrap.style[key];
      state.outerWrapStyle[key] = value;
    });

    return state;
  },
  [TEST]: prevState => {
    const state = { ...prevState };
    lockBody(state);
    return state;
  }
}, initialState);

function lockBody(state) {
  state.outerWrapStyle = {
    ...state.outerWrapStyle,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    prevScrollTop: (window.pageYOffset) ? window.pageYOffset : 0
  };
}