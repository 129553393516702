import axios from 'axios';
import config from '../config';
import { getHeaders } from '../apiUtil';

const baseURL = `${config.API_BASE_URL}`;

/**
 * 가맹점 리스트 v1
 */

export const getFranchiseList = (getPath = false) => {
  if (getPath) return `${baseURL}/acl/franchise`;
  return axios({
    method: 'get',
    url: `/acl/franchise`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 가맹점 전체 리스트 v2
 */

export const getStoresAllList = (getPath = false) => {
  if (getPath) return `${baseURL}/v1/acl/main`;
  return axios({
    method: 'get',
    url: `/v1/acl/main`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 가맹점 전체 지역별 리스트
 */

export const getStoreAreaList = (seq, getPath = false) => {
  if (getPath) return `${baseURL}/v1/acl/main/area/${seq}`;
  return axios({
    method: 'get',
    url: `/v1/acl/main/area/${seq}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 가맹점 상세
 */

export const getFranchiseDetail = (seq, getPath = false) => {
  if (getPath) return `${baseURL}/acl/franchise/${seq}`;
  return axios({
    method: 'get',
    url: `/acl/franchise/${seq}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 메인
 */

export const getMobileMain = (getPath = false) => {
  if (getPath) return `${baseURL}/acl/mainpage`;
  return axios({
    method: 'get',
    url: `/acl/mainpage`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 중고폰 매입 리스트
 */

export const getMobilePriceList = (getPath = false) => {
  if (getPath) return `${baseURL}/acl/mobile/price`;
  return axios({
    method: 'get',
    url: `/acl/mobile/price`,
    baseURL,
    headers: getHeaders(),
  });
};
