import axios from 'axios';
import * as Config from '../config';
import { searchWordVODefault } from '../../js/common';

import { searchStoreApi } from '../../api/search/search';

// action.type
const search_term = {
  // MAIN
  MY_LOCATION_SEARCH: 'SEARCH/MY_LOCATION_SEARCH',
  SEARCH_STORE: 'SEARCH/SEARCH_STORE',

  REAL_TIME_SEARCH: 'SEARCH/REAL_TIME_SEARCH',
  INIT_RECENT: 'SEARCH/INIT_RECENT',
  TOGGLE_RECENT: 'SEARCH/TOGGLE_RECENT',
  DELETE_RECENT: 'SEARCH/DELETE_RECENT',
  CLEAR_RECENT: 'SEARCH/CLEAR_RECENT',
  RECENT_SEARCH: 'SEARCH/RECENT_SEARCH',
  RECOMMEND_SEARCH: 'SEARCH/RECOMMEND_SEARCH',
  CONSIGN_SEARCH: 'SEARCH/CONSIGN_SEARCH',
  RECOMMEND_GOODS_SEARCH: 'SEARCH/RECOMMEND_GOODS_SEARCH',
  SUGGEST_SEARCH: 'SEARCH/SUGGEST_SEARCH',
  SUGGEST_SELECT: 'SEARCH/SUGGEST_SELECT',
  KEYWORD_SEARCH: 'SEARCH/KEYWORD_SEARCH',
  KEYWORD_SET: 'SEARCH/KEYWORD_SET',
  MY_TAG_SEARCH: 'SEARCH/MY_TAG_SEARCH',
  MAIN_BANNER_SEARCH: 'SEARCH/MAIN_BANNER_SEARCH',
  CATEGORY_SEARCH: 'SEARCH/CATEGORY_SEARCH',
  STASH_SEARCH_WORD_VO: 'SEARCH/STASH_SEARCH_WORD_VO',
  SET_VIEW_TYPE: 'SEARCH/SET_VIEW_TYPE',
  SET_CATEGORY_INFO: 'SEARCH/SET_CATEGORY_INFO',

  // 나중에 그냥 redux에서 빼기 -> 지금은 일정
  POST_FOLLOW: 'SEARCH/POST_FOLLOW',
  DELETE_FOLLOW: 'SEARCH/DELETE_FOLLOW',
};

// POST /elastic/stores/types/{type} 스토어 검색
export function searchStore(searchWord) {
  const promise = searchStoreApi(searchWord);
  return promise;
}

// 우리 동네 검색: MY_LOCATION_SEARCH
export function myLocation(
  name,
  myLocationVO,
  listType,
  searchWordVO,
  callback,
  isAppend,
) {
  if (myLocationVO == undefined || Object.keys(myLocationVO).length == 0)
    return false;

  const { locationCode, locationType } = myLocationVO;

  // 19-10-28 서버측 요청 - 임시
  searchWordVO.filter.platformType = 0;

  const searchData = { ...searchWordVO, filter: { ...searchWordVO.filter } };
  searchData.filter.color = searchData.filter.color.join(',');

  const promise = axios
    .post(
      `${
        Config.default.API_BASE_URL
      }/elastic/type4/${listType}/${locationCode}/${locationType}`,
      searchData,
      Config.getHeader(Config.default.JSON_TYPE),
    )
    .then(setWish)
    .then(result => {
      return {
        name,
        list: result.data.data,
        searchWordVO,
        listType,
        totalCnt: result.data.data.length ? result.data.data[0].totalCnt : 0,
        parameter: { myLocationVO },
        callback,
        isAppend,
      };
    });

  return promise;
}

export function myLocation2(
  name,
  myLocationVO,
  listType,
  searchWordVO,
  callback,
  isAppend,
) {
  if (myLocationVO === undefined || Object.keys(myLocationVO).length === 0)
    return false;

  const { lon, lat, locationType } = myLocationVO;

  const searchData = { ...searchWordVO, filter: { ...searchWordVO.filter } };
  searchData.filter.color = searchData.filter.color.join(',');

  const promise = axios
    .post(
      `${
        Config.default.API_BASE_URL
      }/elastic/type14/${listType}/${lon}/${lat}/${locationType}`,
      searchData,
      Config.getHeader2(Config.default.JSON_TYPE),
    )
    .then(setWish)
    .then(result => {
      return {
        name,
        list: result.data.data,
        searchWordVO,
        listType,
        totalCnt: result.data.data.length ? result.data.data[0].totalCnt : 0,
        parameter: { myLocationVO },
        callback,
        isAppend,
      };
    });

  return promise;
}

export function popularKeyword(
  name,
  listType,
  searchWordVO,
  callback,
  isAppend,
) {
  const header =
    localStorage.getItem('jn_access_token') != null &&
    localStorage.getItem('jn_access_token') != ''
      ? Config.getHeader(Config.default.JSON_TYPE)
      : Config.default.JSON_HEADER;

  const searchData = { ...searchWordVO, filter: { ...searchWordVO.filter } };
  searchData.filter.color = searchData.filter.color.join(',');

  const promise = axios
    .post(
      `${Config.default.API_BASE_URL}/elastic/type2/${listType}`,
      searchData,
      header,
    )
    .then(setWish)
    .then(result => {
      return {
        name,
        list: result.data.data,
        searchWordVO,
        listType,
        totalCnt: result.data.data.length ? result.data.data[0].totalCnt : 0,
        callback,
        isAppend,
      };
    });

  return promise;
}

export function consignSeller(
  name,
  listType,
  searchWordVO,
  callback,
  isAppend,
) {
  const searchData = { ...searchWordVO, filter: { ...searchWordVO.filter } };
  searchData.filter.color = searchData.filter.color.join(',');

  const promise = axios
    .post(
      `${Config.default.API_BASE_URL}/elastic/type6/${listType}`,
      searchData,
      Config.default.JSON_HEADER,
    )
    .then(setWish)
    .then(result => {
      return {
        name,
        list: result.data.data,
        searchWordVO,
        listType,
        totalCnt: result.data.data.length ? result.data.data[0].totalCnt : 0,
        callback,
        isAppend,
      };
    });

  return promise;
}

export function myTagKeyword(name, listType, searchWordVO, callback, isAppend) {
  const searchData = { ...searchWordVO, filter: { ...searchWordVO.filter } };
  searchData.filter.color = searchData.filter.color.join(',');

  const promise = axios
    .post(
      `${Config.default.API_BASE_URL}/elastic/v2/type17/${listType}`,
      searchData,
      Config.getHeader(Config.default.JSON_TYPE),
    )
    .then(setWish)
    .then(result => {
      return {
        name,
        list: result.data.data,
        searchWordVO,
        listType,
        totalCnt: result.data.data.length ? result.data.data[0].totalCnt : 0,
        callback,
        isAppend,
      };
    });

  return promise;
}

export function recentKeyword(
  name,
  listType,
  searchWordVO,
  callback,
  isAppend,
) {
  const searchData = { ...searchWordVO, filter: { ...searchWordVO.filter } };
  searchData.filter.color = searchData.filter.color.join(',');

  const promise = axios
    .post(
      `${Config.default.API_BASE_URL}/elastic/type2/${listType}`,
      searchData,
      Config.getHeader(Config.default.JSON_TYPE),
    )
    .then(setWish)
    .then(result => {
      return {
        name,
        list: result.data.data,
        searchWordVO,
        listType,
        totalCnt: result.data.data.length ? result.data.data[0].totalCnt : 0,
        callback,
        isAppend,
      };
    });

  return promise;
}

export function recommendKeyword(
  name,
  listType,
  searchWordVO,
  callback,
  isAppend,
) {
  const header =
    localStorage.getItem('jn_access_token') != null &&
    localStorage.getItem('jn_access_token') != ''
      ? Config.getHeader(Config.default.JSON_TYPE)
      : Config.default.JSON_HEADER;

  const searchData = { ...searchWordVO, filter: { ...searchWordVO.filter } };
  searchData.filter.color = searchData.filter.color.join(',');

  const promise = axios
    .post(
      `${Config.default.API_BASE_URL}/elastic/type2/${listType}`,
      searchData,
      header,
    )
    .then(setWish)
    .then(result => {
      return {
        name,
        list: result.data.data,
        searchWordVO,
        listType,
        totalCnt: result.data.data.length ? result.data.data[0].totalCnt : 0,
        callback,
        isAppend,
      };
    });

  return promise;
}

export function suggestKeyword(
  name,
  listType,
  searchWordVO,
  callback,
  isAppend,
) {
  const header =
    localStorage.getItem('jn_access_token') !== null &&
    localStorage.getItem('jn_access_token') !== ''
      ? Config.getHeader(Config.default.JSON_TYPE)
      : Config.default.JSON_HEADER;

  if (
    searchWordVO.filter.categoryDepth === 0 &&
    searchWordVO.filter.categorySeq === 0
  ) {
    let totalCnt = 0;

    const searchData = { ...searchWordVO, filter: { ...searchWordVO.filter } };
    searchData.filter.color = searchData.filter.color.join(',');

    const promise = axios
      .post(
        `${Config.default.SEARCH_API_BASE_URL}/v2/search/usedGoods?hasTab=0`,
        searchData,
        header,
      )
      .then(result => {
        const items = {
          data: {
            data: result.data.data.items,
          },
        };
        totalCnt = result.data.data.totalSize;
        return setWish(items);
      })
      .then(result => {
        return {
          name,
          list: result.data.data,
          searchWordVO,
          listType,
          totalCnt,
          callback,
          isAppend,
        };
      });

    return promise;
  }

  const searchData = { ...searchWordVO, filter: { ...searchWordVO.filter } };
  searchData.filter.color = searchData.filter.color.join(',');

  const promise = axios
    .post(
      `${Config.default.API_BASE_URL}/elastic/type2/${listType}`,
      searchData,
      header,
    )
    .then(setWish)
    .then(result => {
      return {
        name,
        list: result.data.data,
        searchWordVO,
        listType,
        totalCnt: result.data.data.length ? result.data.data[0].totalCnt : 0,
        callback,
        isAppend,
      };
    });

  return promise;
}

export function allCategory(name, listType, searchWordVO, callback, isAppend) {
  const header =
    localStorage.getItem('jn_access_token') != null &&
    localStorage.getItem('jn_access_token') != ''
      ? Config.getHeader(Config.default.JSON_TYPE)
      : Config.default.JSON_HEADER;

  const searchData = { ...searchWordVO, filter: { ...searchWordVO.filter } };
  searchData.filter.color = searchData.filter.color.join(',');
  const promise = axios
    .post(
      `${Config.default.API_BASE_URL}/elastic/type3/${listType}`,
      searchData,
      header,
    )
    .then(setWish)
    .then(result => {
      return {
        name,
        list: result.data.data,
        searchWordVO,
        listType,
        totalCnt: result.data.data.length ? result.data.data[0].totalCnt : 0,
        callback,
        isAppend,
      };
    });

  return promise;
}

export function recommendGoods(
  name,
  listType,
  searchWordVO,
  callback,
  isAppend,
) {
  const header =
    localStorage.getItem('jn_access_token') != null &&
    localStorage.getItem('jn_access_token') != ''
      ? Config.getHeader(Config.default.JSON_TYPE)
      : Config.default.JSON_HEADER;

  const searchData = { ...searchWordVO, filter: { ...searchWordVO.filter } };
  searchData.filter.color = searchData.filter.color.join(',');

  const promise = axios
    .post(
      `${Config.default.API_BASE_URL}/elastic/type7/${listType}`,
      searchData,
      header,
    )
    .then(setWish)
    .then(result => {
      return {
        name,
        list: result.data.data,
        searchWordVO,
        listType,
        totalCnt: result.data.data.length ? result.data.data[0].totalCnt : 0,
        callback,
        isAppend,
      };
    });

  return promise;
}

export function peaceGoods(name, searchWordVO, callback, isAppend) {
  const header =
    localStorage.getItem('jn_access_token') != null &&
    localStorage.getItem('jn_access_token') != ''
      ? Config.getHeader(Config.default.JSON_TYPE)
      : Config.default.JSON_HEADER;

  const searchData = { ...searchWordVO, filter: { ...searchWordVO.filter } };
  searchData.filter.color = searchData.filter.color.join(',');

  const promise = axios
    .post(`${Config.default.API_BASE_URL}/elastic/type7/1`, searchData, header)
    .then(setWish)
    .then(result => {
      return {
        name,
        list: result.data.data,
        searchWordVO,
        totalCnt: result.data.data.length ? result.data.data[0].totalCnt : 0,
        callback,
        isAppend,
      };
    });

  return promise;
}

// 실시간 검색어 조회
export function real_time_search() {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/search/real-time`,
    Config.default.JSON_HEADER,
  );
  return promise;
}

// 최근 검색어 처음 가져오기
export function init_recent() {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/search/user/recent`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 최근 검색어 삭제하기
export function delete_recent(i) {
  const promise = axios.delete(
    `${Config.default.API_BASE_URL}/search/user/word/${i}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// CLEAR_RECENT: 최근 검색어 전체 삭제하기
export function clearRecent() {
  const promise = axios.delete(
    `${Config.default.API_BASE_URL}/search/user/word`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

export function recentSearch(recentItem) {
  const categoryInfo = {};

  const keyword = JSON.stringify({ keyword: recentItem.searchWord });
  return axios
    .post(
      `${Config.default.API_BASE_URL}/elastic/word/suggest`,
      keyword,
      Config.getHeader(Config.default.JSON_TYPE),
    )
    .then(result => {
      const list = result.data.data;
      const categorySeqName = [
        '',
        'category1Seq',
        'category2Seq',
        'category3Seq',
      ];

      categoryInfo.categoryName = recentItem.categoryName || '';
      categoryInfo.categoryProductCount = list
        .filter(
          item =>
            recentItem.categorySeq == 0 ||
            item[categorySeqName[recentItem.categoryDepth]] ==
              recentItem.categorySeq,
        )
        .reduce((acc, item) => acc + parseInt(item.prodCnt), 0);

      return { categoryInfo };
    });
}

// 추천 검색어
export function elastic_recommend_search(value) {
  const header =
    localStorage.getItem('jn_access_token') != null &&
    localStorage.getItem('jn_access_token') != ''
      ? Config.getHeader(Config.default.JSON_TYPE)
      : Config.default.JSON_HEADER;
  const keyword = JSON.stringify({ keyword: value });
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/elastic/word/recommend`,
    keyword,
    header,
  );
  return promise;
}

// 위탁 상품 검색: CONSIGN_SEARCH
export function consignSearch(listType, searchWordVO) {
  const searchData = { ...searchWordVO, filter: { ...searchWordVO.filter } };
  searchData.filter.color = searchData.filter.color.join(',');

  const promise = axios
    .post(
      `${Config.default.API_BASE_URL}/elastic/type6/${listType}`,
      searchData,
      Config.default.JSON_HEADER,
    )
    .then(setWish);
  return promise;
}

// 저격상품(=추천상품) 검색: RECOMMEND_GOODS_SEARCH
export function recommendGoodsSearch(listType, searchWordVO, isAppend) {
  const header =
    localStorage.getItem('jn_access_token') != null &&
    localStorage.getItem('jn_access_token') != ''
      ? Config.getHeader(Config.default.JSON_TYPE)
      : Config.default.JSON_HEADER;

  const searchData = { ...searchWordVO, filter: { ...searchWordVO.filter } };
  searchData.filter.color = searchData.filter.color.join(',');

  const promise = axios
    .post(
      `${Config.default.API_BASE_URL}/elastic/type7/${listType}`,
      searchData,
      header,
    )
    .then(setWish)
    .then(result => ({ ...result, isAppend }));
  return promise;
}

// 자동검색완성 리스트(카테고리)
export function elastic_suggest_search(value) {
  const keyword = JSON.stringify({ keyword: value });
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/elastic/word/suggest`,
    keyword,
    Config.default.JSON_HEADER,
  );
  return promise;
}

// 키워드로 검색
export function elastic_search_type2(listType, searchWordVO) {
  const header =
    localStorage.getItem('jn_access_token') != null &&
    localStorage.getItem('jn_access_token') != ''
      ? Config.getHeader(Config.default.JSON_TYPE)
      : Config.default.JSON_HEADER;

  const searchData = { ...searchWordVO, filter: { ...searchWordVO.filter } };
  searchData.filter.color = searchData.filter.color.join(',');

  const promise = axios
    .post(
      `${Config.default.API_BASE_URL}/elastic/type2/${listType}`,
      searchData,
      header,
    )
    .then(setWish)
    .then(result => ({ ...result, parameter: { listType, searchWordVO } }));
  return promise;
}

// 마이태그 상품 검색: MY_TAG_SEARCH
export function myTagSearch(mytag) {
  const promise = Promise.all(
    mytag.map(item => myTagSearchPromise(item.keywordName)),
  );
  return promise;
}

function myTagSearchPromise(keywordName) {
  const searchWord = keywordName;
  const listType = 1;
  const searchQuantity = 10;
  const searchWordVO = { ...searchWordVODefault(), searchWord, searchQuantity };

  const searchData = { ...searchWordVO, filter: { ...searchWordVO.filter } };
  searchData.filter.color = searchData.filter.color.join(',');

  const promise = axios
    .post(
      `${Config.default.API_BASE_URL}/elastic/v2/type13/${listType}`,
      searchData,
      Config.getHeader(Config.default.JSON_TYPE),
    )
    // .then(setWish)
    .then(result => {
      result.totalCnt =
        result.data.data.length == 0 ? 0 : result.data.data[0].totalCnt;
      return { ...result, parameter: { searchWord } };
    });
  return promise;
}

// 카테고리로 검색: CATEGORY_SEARCH
export function elastic_search_type3(listType, searchWordVO) {
  const searchData = { ...searchWordVO, filter: { ...searchWordVO.filter } };
  searchData.filter.color = searchData.filter.color.join(',');

  const promise = axios
    .post(
      `${Config.default.API_BASE_URL}/elastic/type3/${listType}`,
      searchData,
      Config.getHeader(Config.default.JSON_TYPE),
    )
    .then(setWish)
    .then(result => ({ ...result, parameter: { listType, searchWordVO } }));
  return promise;
}

// 배너 아이템 가져오기
export function main_banner_search() {
  const promise = axios.get(`${Config.default.API_BASE_URL}/promotion/list`);
  return promise;
}

// POST /v1/search/price/average 시세 조회
export function postPriceAverage(keyword, productCondition) {
  // productCondition: -1:전체, 0:새것, 1:거의새것, 2:중고
  const promise = axios.post(
    `${Config.default.SEARCH_API_BASE_URL}/v1/search/price/average`,
    { keyword, productCondition },
    Config.default.JSON_HEADER,
  );
  return promise;
}

export default search_term;

// util
export async function setWish(result) {
  if (!localStorage.jn_access_token) return result;

  const header = Config.getHeader(Config.default.JSON_TYPE);

  const list = result.data.data;
  const productSeqs = list.reduce((acc, cur) => {
    if (cur.seq) acc.push(cur.seq);

    return acc;
  }, []);

  if (productSeqs.length > 0) {
    const wishResult = await axios.post(
      `${Config.default.API_BASE_URL}/product/wish/seq`,
      { productSeqs },
      header,
    );
    const wishList = wishResult.data.data;
    const wishMap = wishList.reduce((map, seq) => {
      map[seq] = true;
      return map;
    }, {});
    list.forEach(item => (item.wishYn = wishMap[item.seq] ? 1 : 0));
  } else {
    list.map(item => ({
      ...item,
      wishYn: 0,
    }));
  }

  return result;
}

export function getSearchList(
  name,
  listType,
  searchWordVO,
  callback,
  isAppend,
) {
  const header =
    localStorage.getItem('jn_access_token') !== null &&
    localStorage.getItem('jn_access_token') !== ''
      ? Config.getHeader(Config.default.JSON_TYPE)
      : Config.default.JSON_HEADER;

  let totalCnt = 0;

  // 리팩토링 필요
  const searchData = { ...searchWordVO, filter: { ...searchWordVO.filter } };
  searchData.filter.color = searchData.filter.color.join(',');
  const promise = axios
    .post(
      `${Config.default.SEARCH_API_BASE_URL}/v2/search/usedGoods?hasTab=0`,
      searchData,
      header,
    )
    .then(result => {
      const items = {
        data: {
          data: result.data.data.items,
        },
      };
      totalCnt = result.data.data.totalSize;
      return setWish(items);
    })
    .then(result => {
      return {
        name,
        list: result.data.data,
        searchWordVO,
        listType,
        totalCnt,
        callback,
        isAppend,
      };
    });

  return promise;
}
