import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';

import {
  getMyTag as getMyTagApi,
  postMyTag as postMyTagApi,
  deleteMyTag as deleteMyTagApi,
} from '../../api/base/mytag';

export const Actions = {
  GET_MY_TAG_LIST: 'MYTAG/GET_MY_TAG_LIST',
  ADD_MY_TAG: 'MYTAG/ADD_MY_TAG',
  DELETE_MY_TAG: 'MYTAG/DELETE_MY_TAG',
  CLEAR_ERROR: 'MYTAG/CLEAR_ERROR',
};

const initialState = {
  isFetched: false,
  myTagList: [],
  error: {
    code: 200,
    message: '',
  },
};

async function postMyTag(word) {
  try {
    await postMyTagApi(word);
    const result = await getMyTagApi();
    return result;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function deleteMyTag(seq) {
  try {
    await deleteMyTagApi(seq);
    const result = await getMyTagApi();
    return result;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getMyTagList = createAction(Actions.GET_MY_TAG_LIST, getMyTagApi);
export const addMyTag = createAction(Actions.ADD_MY_TAG, postMyTag);
export const delMyTag = createAction(Actions.DELETE_MY_TAG, deleteMyTag);
export const clearError = createAction(Actions.CLEAR_ERROR);

export default handleActions(
  {
    ...pender({
      type: Actions.GET_MY_TAG_LIST,
      onSuccess: (state, action) => {
        const {
          data: { data: myTagList },
        } = action.payload;
        return {
          ...state,
          isFetched: true,
          myTagList,
          error: initialState.error,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),
    ...pender({
      type: Actions.ADD_MY_TAG,
      onSuccess: (state, action) => {
        const {
          data: { data: myTagList },
        } = action.payload;
        return {
          ...state,
          isFetched: true,
          myTagList,
          error: initialState.error,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),
    ...pender({
      type: Actions.DELETE_MY_TAG,
      onSuccess: (state, action) => {
        const {
          data: { data: myTagList },
        } = action.payload;
        return {
          ...state,
          isFetched: true,
          myTagList,
          error: initialState.error,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),
    [Actions.CLEAR_ERROR]: state => {
      return {
        ...state,
        error: initialState.error,
      };
    },
  },
  initialState,
);
