import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import useHistory from '../../../common/Hook/useHistory';
import { InviteFriendsReducer } from '../../../reducers/actionCreators';
import PopUp from './PopUp';

import { tracking } from '../../../common/util';
import { pathNameToString } from '../../../common/parser';
import { sendKakaoLink } from '../../../common/share';
import kakaoIcon from '../../../asset/img/icon/ic_kakao_link.png';
import styles from './InviteFriends.module.scss';

const InviteFriends = () => {
  const {
    location: { pathname },
  } = useHistory();
  const show = useSelector(({ inviteFriends }) => inviteFriends.show);
  const code = useSelector(({ inviteFriends }) => inviteFriends.code);
  const nickName = useSelector(({ userLoginInfo }) => userLoginInfo.nickName);
  const userId = useSelector(({ userLoginInfo }) => userLoginInfo.userId);
  const [isMount, setIsMount] = useState(false);

  function close() {
    InviteFriendsReducer.close();
  }

  function inviteKakao() {
    const pathName = pathNameToString();

    tracking(`B_${pathName}_친구초대_카카오톡초대`);

    const data = {
      title: `${nickName}님이 초대메시지를 보냈습니다.`,
      description: `추천코드 : ${code}`,
      imageUrl:
        'https://img2.joongna.com/mystore/share/img_jn_url_share%401x.png',
      link: `https://www.joongna.com`,
      btn_title: '입국하러가기',
    };
    sendKakaoLink(data);
  }

  useEffect(() => {
    setIsMount(true);
  }, []);

  useEffect(() => {
    if (isMount) {
      close();
    }
  }, [pathname]);

  useEffect(() => {
    if (userId !== '' && show) {
      InviteFriendsReducer.getCode();
    }
  }, [userId, show]);

  return (
    <PopUp show={show}>
      <div className={styles.inviteFriends}>
        <div className={styles.body}>
          <h4>3초 만에 끝나는 친구 초대!</h4>
          <p>누구나 돈버는 중고나라로</p>
          <p>쉽고 빠르게 친구를 초대해보세요!</p>
          <div className={styles.codeWrap}>{code}</div>
          <button
            type="button"
            className={styles.inviteKakao}
            onClick={inviteKakao}
          >
            <img src={kakaoIcon} alt="카카오 친구초대" />
            <p>카카오톡으로 초대</p>
          </button>
        </div>
        <button className={styles.footer} type="button" onClick={close}>
          확인
        </button>
      </div>
    </PopUp>
  );
};

export default InviteFriends;
