import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

const useHistoryHook = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const routeMatch = useRouteMatch();

  const push = (path = '/home', state = {}) => {
    history.push(path, {
      from: `${location.pathname}${location.search}`,
      ...state,
    });
  };

  const replace = (path = '/home', state = {}) => {
    history.replace(path, {
      from: `${location.pathname}${location.search}`,
      ...state,
    });
  };

  return {
    history,
    location,
    params,
    routeMatch,
    push,
    replace,
  };
};

export default useHistoryHook;
