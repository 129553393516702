import { useRef, useState, useEffect } from 'react';

import useHistory from './useHistory';

const useSaveScroll = () => {
  const {
    location: { pathname, search },
  } = useHistory();

  const tempScroll = useRef(null);
  const [saveScroll, setSaveScroll] = useState(new Map());

  useEffect(() => {
    const saveScrollEvent = () => {
      if (window.scrollY > 0) {
        tempScroll.current = window.scrollY;
      }
    };
    window.addEventListener('scroll', saveScrollEvent);
    return () => {
      setSaveScroll(
        new Map(
          saveScroll.set(`${pathname}${search}`, tempScroll.current || 0),
        ),
      );
      tempScroll.current = 0;
      window.removeEventListener('scroll', saveScrollEvent);
    };
  }, [pathname, search]);

  return {
    saveScroll,
  };
};

export default useSaveScroll;
