import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import produce from 'immer';

import {
  getSearchCarinfoByCarno,
  getOptionList,
  getMakerList,
  getClassModelList,
  getModelList,
  getGradeList,
  getSubGradeList,
} from '../../api/auto/carinfo';
import {
  postGarageAdd,
  putGarageUpdate,
  getGarageItem,
} from '../../api/auto/garage';

import { numFormat } from '../../common/number';

export const Actions = {
  CLEAR: 'autoRegist/CLEAR',
  GET_CARINFO_SEARCH_CAR_INFO_BY_CAR_NO:
    'autoRegist/GET_CARINFO_SEARCH_CAR_INFO_BY_CAR_NO',
  GET_CARINFO_OPTION_LIST: 'autoRegist/GET_CARINFO_OPTION_LIST',
  GET_CARINFO_MAKER_LIST: 'autoRegist/GET_CARINFO_MAKER_LIST',
  GET_CARINFO_CLASS_MODEL_LIST: 'autoRegist/GET_CARINFO_CLASS_MODEL_LIST',
  GET_CARINFO_MODEL_LIST: 'autoRegist/GET_CARINFO_MODEL_LIST',
  GET_CARINFO_GRADE_LIST: 'autoRegist/GET_CARINFO_GRADE_LIST',
  GET_CARINFO_SUB_GRADE_LIST: 'autoRegist/GET_CARINFO_SUB_GRADE_LIST',
  SET_FIELD_UPDATE: 'autoRegist/SET_FIELD_UPDATE',
  POST_GARAGE_ADD: 'autoRegist/POST_GARAGE_ADD',
  PUT_GARAGE_UPDATE: 'autoRegist/PUT_GARAGE_UPDATE',
  GET_GARAGE_ITEM: 'autoRegist/GET_GARAGE_ITEM',
};

// store 초기화
export const clear = createAction(Actions.CLEAR);

// 차량 신규 등록
export const garageAdd = createAction(Actions.POST_GARAGE_ADD, postGarageAdd);

// 차량 정보 수정
export const garageUpdate = createAction(
  Actions.PUT_GARAGE_UPDATE,
  putGarageUpdate,
);

// 차량번호로 차량정보 조회
export const garageItem = createAction(Actions.GET_GARAGE_ITEM, getGarageItem);

// 차량번호로 차량정보 조회
export const getCarInfoSearchCarInfoByCarNo = createAction(
  Actions.GET_CARINFO_SEARCH_CAR_INFO_BY_CAR_NO,
  getSearchCarinfoByCarno,
);

// 차량 옵션정보 조회
export const getCarInfoOptionList = createAction(
  Actions.GET_CARINFO_OPTION_LIST,
  getOptionList,
);

// 제조사 목록 호출
export const getCarInfoMakerList = createAction(
  Actions.GET_CARINFO_MAKER_LIST,
  getMakerList,
);

// 대표 모델 목록 호출
export const getCarInfoClassModelList = createAction(
  Actions.GET_CARINFO_CLASS_MODEL_LIST,
  getClassModelList,
);

// 모델 목록 호출
export const getCarInfoModelList = createAction(
  Actions.GET_CARINFO_MODEL_LIST,
  getModelList,
);

// 등급 목록 호출
export const getCarInfoGradeList = createAction(
  Actions.GET_CARINFO_GRADE_LIST,
  getGradeList,
);

// 세부 등급 목록 호출
export const getCarInfoSubGradeList = createAction(
  Actions.GET_CARINFO_SUB_GRADE_LIST,
  getSubGradeList,
);

// 데이터 변경
export const setFieldUpdate = createAction(Actions.SET_FIELD_UPDATE, obj => ({
  obj,
}));

const initialState = {
  carInfo: {
    // 상품 번호
    productSeq: undefined,

    // 차량 등록 진행 상태 : 1~5 차고 입고중, 6 차고 입고 완료, 7~8 판매 등록 중, 9 판매중 (9로 세팅할 경우 판매 상품으로 등록됨)
    garageRegStep: 1,

    /* step 1 */
    // 자동차 번호
    carNo: '',

    // 최초등록일(YYYYMM01)
    carRegDate: '',

    // 연식
    groupYear: '',

    // 제조사
    makerNo: '',
    makerName: '',

    // 대표 모델
    classModelNo: '',
    classModelName: '',

    // 모델
    modelNo: '',
    modelName: '',

    // 등급
    bsNo: '',
    bsName: '',

    // 세부 등급
    bdNo: '',
    bdName: '',

    /* step 2 */
    // 변속기 (코드 CD012, 1: 자동 2: 수동 3: 세미오토)
    transType: 0,

    // 연료 (코드 CD013, 1: 휘발유 2: 경유 3: 하이브리드 4: LPG 5: 전기 6: CNG)
    fuelType: 0,

    /* step 3 */
    // 색상 코드(CD011)
    productColor: '',

    /* step 4 */
    // 차량 옵션 목록
    productOptionCodeList: [
      // '',
    ],

    /* step 5 */
    // 주행거리
    distance: '0',

    // 사고 여부 1: 무사고, 2:사고, 3:모름
    accidentType: 0,

    /* step 6 */
    // 상품 이미지 or 동영상 주소
    media: [
      // {
      //   mediaSort: 0,
      //   mediaStatus: 0,
      //   mediaType: 0,
      //   mediaUrl: '',
      //   productMediaSeq: 0,
      //   thumbnailUrl: '',
      //   waterMarkUrl: '',
      //   watermarkYn: 0
      // }
    ],

    /* step 7 */
    // 상품가격
    productPrice: '0',

    /* step 8 */
    // 소유자 정보
    ownerType: 0,
    ownerName: '',

    // 차량 할부 상태
    carPaymentStatus: 0,

    /* step 9 */
    // 상품 상세 설명
    productDescription: '',

    // 거래 희망지역 법정동코드
    locations: [
      // {
      //   dongCode: '',
      //   lat: 0,
      //   locationCode: '',
      //   locationName: '',
      //   locationType: 0,
      //   lon: 0,
      //   userCount: 0
      // }
    ],

    // 추가 정보
    mainCarStatus: 0,
    onWaterMark: false,
  },
  makerList: [],
  classModelList: [],
  modelList: [],
  gradeList: [],
  subGradeList: [],
  optionList: [],
  error: {
    code: 200,
    message: '',
  },
};

export default handleActions(
  {
    [Actions.CLEAR]: () => {
      return { ...initialState };
    },
    // 차량번호로 차량정보 조회
    ...pender({
      type: Actions.GET_CARINFO_SEARCH_CAR_INFO_BY_CAR_NO,
      onSuccess: (state, action) => {
        const {
          data: {
            data: {
              carNum: carNo,
              carRegDate,
              groupYear,
              makerNo,
              makerName,
              classModelNo,
              classModelName,
              modelNo,
              modelName,
              bsNo,
              bsName,
              bdNo,
              bdName,
            },
          },
        } = action.payload;

        const carInfo = {
          ...state.carInfo,
          carNo,
          carRegDate,
          groupYear,
          makerNo,
          makerName,
          classModelNo,
          classModelName,
          modelNo,
          modelName,
          bsNo,
          bsName,
          bdNo,
          bdName,
        };
        return {
          ...state,
          carInfo,
          error: initialState.error,
        };
      },

      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),
    // 차량번호로 차량정보 조회
    ...pender({
      type: Actions.GET_CARINFO_OPTION_LIST,
      onSuccess: (state, action) => {
        const {
          data: { data: optionList },
        } = action.payload;

        return {
          ...state,
          optionList,
          error: initialState.error,
        };
      },

      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),
    // 차량정보 조회
    ...pender({
      type: Actions.GET_GARAGE_ITEM,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        const carInfo = {
          ...data.data,
          distance: numFormat(data.data.distance),
          productPrice: numFormat(data.data.productPrice / 10000),
        };
        if (data.data.carNum) {
          carInfo.carNo = data.data.carNum;
        }
        return {
          ...state,
          carInfo,
          error: initialState.error,
        };
      },

      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),
    // 신규 등록
    ...pender({
      type: Actions.POST_GARAGE_ADD,
      onSuccess: (state, action) => {
        const { data } = action.payload;

        return produce(state, d => {
          const draft = d;
          draft.carInfo = {
            ...state.carInfo,
            productSeq: data.data,
          };
          draft.error = initialState.error;
        });
      },

      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),
    // 정보 수정
    ...pender({
      type: Actions.PUT_GARAGE_UPDATE,
      onSuccess: (state, action) => {
        const { data } = action.payload;

        return produce(state, d => {
          const draft = d;
          draft.carInfo = {
            ...state.carInfo,
            productSeq: data.data,
          };
          draft.error = initialState.error;
        });
      },

      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),
    // 제조사 목록 호출
    ...pender({
      type: Actions.GET_CARINFO_MAKER_LIST,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          makerList: data.data,
          classModelList: [],
          modelList: [],
          gradeList: [],
          subGradeList: [],
          error: initialState.error,
        };
      },

      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),
    // 대표 모델 목록 호출
    ...pender({
      type: Actions.GET_CARINFO_CLASS_MODEL_LIST,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          classModelList: data.data,
          modelList: [],
          gradeList: [],
          subGradeList: [],
          error: initialState.error,
        };
      },

      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),
    // 모델 목록 호출
    ...pender({
      type: Actions.GET_CARINFO_MODEL_LIST,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          modelList: data.data,
          gradeList: [],
          subGradeList: [],
          error: initialState.error,
        };
      },

      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),
    // 등급 목록 호출
    ...pender({
      type: Actions.GET_CARINFO_GRADE_LIST,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        let gradeList = null;
        if (data.data.length > 0) gradeList = data.data;
        return {
          ...state,
          gradeList,
          subGradeList: [],
          error: initialState.error,
        };
      },

      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),
    // 세부 등급 목록 호출
    ...pender({
      type: Actions.GET_CARINFO_SUB_GRADE_LIST,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        let subGradeList = null;
        if (data.data.length > 0) subGradeList = data.data;
        return {
          ...state,
          subGradeList,
          error: initialState.error,
        };
      },

      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),
    [Actions.SET_FIELD_UPDATE]: (state, action) => {
      const { obj } = action.payload;
      return produce(state, d => {
        const draft = d;
        draft.carInfo = { ...state.carInfo, ...obj };
        draft.error = initialState.error;
      });
    },
  },
  initialState,
);
