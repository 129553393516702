import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import moment from 'moment';
import produce from 'immer';

import * as Common from '../../js/common';
import { saveToken } from '../../common/auth';
import * as Login from '../../actions/service/LoginService';
// import moment = require('moment');

const initialState = {
  token: '',
  mobilians_info: {
    // PASS앱 에서 넘어온 결과값
    mobilians: {
      // 서버에 넘겨야할 데이터
      ci: '',
      commid: '',
      di: '',
      foreigner: '',
      mac: '',
      mobilid: '',
      mstr: '',
      name: '',
      no: '',
      resultcd: '',
      sex: '',
      signdate: '',
      socialno: '',
    },
    parentAgreeNeedYn: 0,
    userStatus: {
      emailJoin: 0, // 1: 연동이력 존재, 0:없음
      emailStatus: 0, // 이메일 연동상태 1:활성화, 0:비활성화, null:존재하지않음
      facebookJoin: 0, // 1: 연동이력 존재, 0:없음
      facebookStatus: 0, // 페이스북 연동상태 1:활성화, 0:비활성화, null:존재하지않음
      kakaoJoin: 0, // 1: 연동이력 존재, 0:없음
      kakaoStatus: 0, // 카카오 연동상태 1:활성화, 0:비활성화, null:존재하지않음
      naverCafeStatus: 0, // 네이버카페 연동상태
      naverJoin: 0, // 1: 연동이력 존재, 0:없음
      naverStatus: 0, // 네이버 연동상태 1:활성화, 0:비활성화, null:존재하지않음
      userId: '',
      userJoinType: 0, // 가입된 경로 - 0: 이메일, 1:네이버, 2:카카오, 3:페이스북
      userSeq: 0,
      userStatus: 0, // 회원상태 - 0: 정상, 1:탈퇴, 2:블랙, 3:정지, 4:관리자삭제, 5:전화번호만료, 6:휴면
    },
    parentMobilians: {
      ci: '',
      commid: '',
      di: '',
      foreigner: '',
      mac: '',
      mobilid: '',
      mstr: '',
      name: '',
      no: '',
      resultcd: '',
      sex: '',
      signdate: '',
      socialno: '',
    },
  },
  email_join: false,
  error: {
    isError: false,
    errorMessage: '',
    errorCode: 0,
    moment: moment(),
  },
  login_info: {},
  onSuccessGetUserInfo: moment(),
  find_id: '',
  returnUrl: '',
  sns_link: { meta: { message: '' }, data: {} },
  user_info: { meta: { message: '' }, data: {} },
  user_name: { meta: { message: '' }, data: '' },
  user_status: {},
  user_marketing: {},

  onSuccessPutUserPhone: moment(),
};

export const getMobiliansInfo = createAction(
  Login.default.MOBILIANS_INFO,
  Login.getMobiliansInfo,
);
export const getParentMobiliansInfo = createAction(
  Login.default.PARENT_MOBILIANS_INFO,
);
export const joinEmail = createAction(
  Login.default.EMAIL_JOIN,
  Login.joinEmail,
);
// ReturnUrl 저장
export const set_returnUrl = createAction(
  Login.default.SET_RETURN_URL,
  Login.set_returnUrl,
);
// 아이디 찾기
export const find_id = createAction(Login.default.FIND_ID, Login.find_id);
// 회원정보 가져오기
export const get_userinfo = createAction(
  Login.default.GET_USERINFO,
  Login.get_userinfo,
);
// 회원 실명 조회
export const get_username = createAction(
  Login.default.GET_USERNAME,
  Login.get_username,
);
// 휴대폰 변경
export const put_user_state_phone = createAction(
  Login.default.PUT_USER_STATE_PHONE,
  Login.put_user_state_phone,
);
// 휴대폰 만료 해제
export const put_phone_expire = createAction(
  Login.default.PUT_PHONE_EXPIRE,
  Login.put_phone_expire,
);
// 휴면회원 해제
export const put_rest = createAction(Login.default.PUT_REST, Login.put_rest);

// 마케팅 수신 동의 설정 (SMS, PUSH, E-MAIL)
export const userMarketingApi = createAction(
  Login.default.PUT_USER_MARKETING,
  Login.putUserMarketing,
);

// 토큰 파싱
const convertToken = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export default handleActions(
  {
    // 이메일 가입
    ...pender({
      type: Login.default.EMAIL_JOIN,
      onPending: (state, action) => {
        return {
          email_join: false,
          ...state,
        };
      },
      onSuccess: (state, action) => {
        const { data } = action.payload;
        const login_info = convertToken(data);
        return {
          ...state,
          token: data,
          email_join: true,
          login_info,
        };
      },
      onFailure: (state, action) => {
        return {
          ...state,
          email_join: false,
          error: {
            isError: true,
            errorMessage: action.payload.response.data.meta.message,
          },
        };
      },
    }),

    // 아이디 찾기
    ...pender({
      type: Login.default.FIND_ID,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          find_id: data,
        };
      },
    }),

    // 회원정보 가져오기
    ...pender({
      type: Login.default.GET_USERINFO,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          user_info: data,
          user_status: data.data.status,
          user_marketing: data.data.marketing,
          onSuccessGetUserInfo: moment(),
        };
      },
      onFailure: (state, action) => {
        const { response } = action.payload;

        if (response) {
          const error = response.data.meta
            ? {
                isError: true,
                errorCode: response.data.meta.code,
                errorMessage: response.data.meta.message,
                moment: moment(),
              }
            : {
                isError: true,
                errorCode: response.data.error.status,
                errorMessage: response.data.error.code,
                moment: moment(),
              };

          return {
            ...state,
            error,
          };
        }

        return {
          ...state,
          error: {
            isError: true,
            errorMessage: 'Network Error',
            errorCode: 999,
            moment: moment(),
          },
        };
      },
    }),

    // 회원 실명 조회
    ...pender({
      type: Login.default.GET_USERNAME,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          user_name: data,
        };
      },
    }),

    // 모빌리언스 정보
    [Login.default.MOBILIANS_INFO]: (state, action) => {
      return {
        ...state,
        mobilians_info: { ...action.payload },
      };
    },
    // 법정대리인 모빌리언스 정보
    [Login.default.PARENT_MOBILIANS_INFO]: (state, action) => {
      return {
        ...state,
        mobilians_info: {
          ...state.mobilians_info,
          parentMobilians: { ...action.payload },
        },
      };
    },

    // RETURN_URL 저장
    [Login.default.SET_RETURN_URL]: (state, action) => {
      return {
        ...state,
        returnUrl: action.payload,
      };
    },
    ...pender({
      type: Login.default.PUT_USER_STATE_PHONE,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        // 토큰값 셋팅
        saveToken(data.data);
        return {
          ...state,
          onSuccessPutUserPhone: moment(),
        };
      },
    }),
    ...pender({
      type: Login.default.PUT_PHONE_EXPIRE,
      onSuccess: state => ({ ...state }),
    }),
    ...pender({
      type: Login.default.PUT_REST,
      onSuccess: state => ({ ...state }),
    }),
    // 마케팅 수신 동의 설정
    ...pender({
      type: Login.default.PUT_USER_MARKETING,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        const userMarketing = { ...state.user_marketing };
        if (data.data.type === 0) {
          userMarketing.smsYn = data.data.isAccept ? 1 : 0;
        } else if (data.data.type === 1)
          userMarketing.emailYn = data.data.isAccept ? 1 : 0;
        else if (data.data.type === 2)
          userMarketing.pushYn = data.data.isAccept ? 1 : 0;
        saveToken(data.data.token);
        return produce(state, d => {
          const draft = d;
          draft.user_marketing = userMarketing;
        });
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            errorMessage: data.meta.message,
            errorCode: data.meta.code,
          },
        };
      },
    }),
  },
  initialState,
);
