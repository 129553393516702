import iconv from 'iconv-lite';
import _ from 'lodash';
import { config } from './api';
import { isIOS, isAndroid, isiPhone } from './device';
import { isApp } from './webView';

export const APP_PACKAGE = process.env.REACT_APP_APP_PACKAGE;
export const APP_SCHEMA = process.env.REACT_APP_SCHEMA;
export const APP_USE_LOGIN_TEST = process.env.REACT_APP_USE_LOGIN_TEST;

export const Android = 'android';
export const Ios = 'ios';
export const Web = 'web';

export function isEmptyObject(obj) {
  return Object.getOwnPropertyNames(obj).length === 0;
}

export const setCookie = (cookieName, value, days) => {
  const exdate = new Date();
  exdate.setDate(exdate.getDate() + days);
  // 설정 일수만큼 현재시간에 만료값으로 지정
  const cookieValue =
    escape(value) +
    (days === null ? '' : `;    expires=${exdate.toUTCString()}`);
  window.document.cookie = `${cookieName}=${cookieValue}`;
};

export const getCookie = cookieName => {
  let x;
  let y;
  const val = window.document.cookie.split(';');
  for (let i = 0; i < val.length; i += 1) {
    x = val[i].substr(0, val[i].indexOf('='));
    y = val[i].substr(val[i].indexOf('=') + 1);
    x = x.replace(/^\s+|\s+$/g, ''); // 앞과 뒤의 공백 제거하기
    if (x === cookieName) {
      return unescape(y); // unescape로 디코딩 후 값 리턴
    }
  }
  return null;
};

export const saveSessionStorage = (name, data) => {
  window.sessionStorage.setItem(name, JSON.stringify(data));
};
export const removeSessionStorage = name => {
  window.sessionStorage.removeItem(name);
};
export const getSessionStorage = name => {
  const data = JSON.parse(sessionStorage.getItem(name));
  return data;
};

// 현재 url 가져오기
export const getPath = () => {
  const {
    location: { pathname, search },
  } = window;
  return `${pathname}${search}`;
};

export function toUTF8(string) {
  const check = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

  if (!check.test(string)) {
    const buf = iconv.encode(string, 'iso-8859-1');
    const str = iconv.decode(buf, 'utf8');
    return str;
  }
  return string;
}

export function decodeURIComponentSafe(keyword) {
  if (!keyword) {
    return keyword;
  }
  try {
    return decodeURIComponent(keyword);
  } catch (e) {
    console.log(e);
    return decodeURIComponent(
      keyword.replace(/%(?![0-9a-fA-F][0-9a-fA-F]+)/g, '%25'),
    );
  }
}

// 파라미터 파싱
export function getQueryStringObjSafe(query = window.location.search) {
  try {
    const returnParam = {};
    if (query) {
      const obj = query.substring(1).split('&');
      for (const item in obj) {
        const key_value = obj[item].split('=');
        const key = key_value[0].toLowerCase();
        let value = key_value[1];
        value = value.replace(/%(?![0-9a-fA-F][0-9a-fA-F]+)/g, '%25');
        returnParam[`${key}`] = decodeURIComponent(toUTF8(value));
      }
    }
    return returnParam;
  } catch (e) {
    console.log(e);
    return '';
  }
}

// 파라미터 파싱
export function getQueryStringObj(query = window.location.search) {
  try {
    const returnParam = {};
    if (query) {
      const obj = query.substring(1).split('&');
      for (const item in obj) {
        const key_value = obj[item].split('=');
        const key = key_value[0].toLowerCase();
        const value = key_value[1];
        returnParam[`${key}`] = decodeURIComponent(toUTF8(value));
      }
    }
    return returnParam;
  } catch (e) {
    console.log(e);
    return getQueryStringObjSafe(query);
  }
}

export function getQueryStringSafe(name, url = window.location.href) {
  try {
    const results = new RegExp(`[?&]${name}=([^&#]*)`).exec(url);
    if (results === null) {
      return '';
    }
    let result = results[1];
    if (result.indexOf('+') > 0) {
      result = decodeURI(results[1]).replace(/\+/g, ' ');
    }
    result = result.replace(/%(?![0-9a-fA-F][0-9a-fA-F]+)/g, '%25');
    return decodeURIComponent(toUTF8(result)) || '';
  } catch (e) {
    console.log(e);
    return '';
  }
}

export function getQueryString(name, url = window.location.href) {
  try {
    const results = new RegExp(`[?&]${name}=([^&#]*)`).exec(url);
    if (results === null) {
      return '';
    }
    let result = results[1];
    if (result.indexOf('+') > 0) {
      result = decodeURI(results[1]).replace(/\+/g, ' ');
    }
    return decodeURIComponent(toUTF8(result)) || '';
  } catch (e) {
    console.log(e);
    return getQueryStringSafe(name, url);
  }
}

export function setQueryString(filters) {
  let queryStr = '';
  Object.keys(filters).forEach(key => {
    if (filters[key] !== '') {
      queryStr = queryStr
        ? `${queryStr}&${key}=${filters[key]}`
        : `?${key}=${filters[key]}`;
    }
  });

  return queryStr;
}

export function objToQueryString(obj) {
  if (typeof obj !== 'object' || isEmptyObject(obj)) return '';
  return `?${Object.keys(obj)
    .map(key => `${key}=${obj[key]}`)
    .join('&')}`;
}

export function removeQueryString(name, url = window.location.href) {
  let queryList = [];
  const queryString = url.indexOf('?') > -1 ? url.split('?')[1] : '';

  if (queryString !== '') {
    queryList = queryString.split('&');
    const queryIndex = queryList.findIndex(q => {
      return q.split('=')[0] === name;
    });
    if (queryIndex > -1) {
      queryList.splice(queryIndex, 1);
    }

    if (queryList.length) return `?${queryList.join('&')}`;
  }
  return '';
}

// json 형태로 입력 받은 데이터로 현재 쿼리스트링에 이미 값이 있으면 대체, 값이 없다면 추가, 값이 null or undefined일 경우 삭제
export const replaceQueryString = (
  inputQuery = {},
  url = window.location.href,
) => {
  const oldQueryString = url.indexOf('?') > -1 ? url.split('?')[1] : '';
  if (_.isObject(inputQuery)) {
    let newQueryObj = {};

    // old query string to new query obj
    if (oldQueryString !== '') {
      const queryList = oldQueryString.split('&');
      _.map(queryList, query => {
        const splitQuery = query.split('=');
        newQueryObj[splitQuery[0]] = splitQuery[1];
      });
    }

    // change or insert inputed query to new obj
    newQueryObj = { ...newQueryObj, ...inputQuery };

    // generate new query string
    let newQueryString = '';
    const newKeys = _.keys(newQueryObj);
    _.map(newKeys, key => {
      if (
        newQueryObj[key] !== undefined &&
        newQueryObj[key] !== null &&
        newQueryObj[key] !== ''
      ) {
        if (newQueryString === '')
          newQueryString = `?${key}=${newQueryObj[key]}`;
        else newQueryString = `${newQueryString}&${key}=${newQueryObj[key]}`;
      }
    });
    return newQueryString;
  }
  return `?${oldQueryString}`;
};

// GA 태깅
export function tracking(eventName) {
  // // os type 확인
  // let osType = 'web';
  // if (isAndroidApp()) {
  //   // android
  //   osType = 'aos';
  // } else if (isIOSApp()) {
  //   // ios
  //   osType = 'ios';
  // }
  // // storeSeq 확인
  // const loginInfo = localStorage.getItem('jn_login_info')
  //   ? JSON.parse(localStorage.getItem('jn_login_info'))
  //   : null;
  // // ga태깅
  // if (window.gtag) {
  //   window.gtag('event', `${eventName}_${osType}`, {
  //     event_category: `${eventName}_${osType}`,
  //     storeSeq:
  //       loginInfo !== null && loginInfo.storeSeq != null
  //         ? loginInfo.storeSeq
  //         : '',
  //   });
  // }
}

/**
 * os 체크
 */
export function checkOs() {
  if (isAndroid()) return Android;
  if (isiPhone()) return Ios;
  return Web;
}

export function numFormat(num) {
  return Number(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    .split('.')[0];
}

export function addZero(num) {
  return num < 10 ? `0${num}` : `${num}`;
}

export function getPercentage(x, y) {
  return y < x ? '100%' : `${Math.floor((x / y) * 100)}%`;
}

/**
 *
 */

export function openExternalLink(ExternalPath, onlyWeb = false) {
  if (!onlyWeb && isApp()) {
    // android & ios

    // window.location.href = `jnapps3://?openweb=${ExternalPath}`;
    window.location.href = `jnapps3://?openbrowser=${ExternalPath}`;
  } else {
    // web
    if (window.navigator.userAgent.indexOf('Chrome') > -1) {
      window.open(ExternalPath, '_blank');
    } else if (window.navigator.userAgent.indexOf('Safari') > -1) {
      window.location.href = ExternalPath;
    } else {
      window.open(ExternalPath, '_blank');
    }
  }
}

export function getParameterByName(n, url = window.location.href) {
  const name = n.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function parseDeepOpenToUrl(url) {
  return typeof url === 'string'
    ? url.replace('jnapps3://?openbrowser=', '')
    : '';
}

export function goApp(AppUrl) {
  let timer;
  let goAppInterval;
  const appstoreUrl = 'https://itunes.apple.com/app/id896515652';

  if (isIOS()) {
    const clearTimer = () => {
      clearInterval(goAppInterval);
      clearTimeout(timer);
    };

    const isActiveBrowser = () => {
      // 앱 설치 되어있음
      if (document.webkitHidden || document.hidden) {
        clearTimer();
      }
    };
    window.location.href = `${APP_SCHEMA}${AppUrl}`;

    goAppInterval = setInterval(isActiveBrowser, 500);

    timer = setTimeout(() => {
      // 앱 미설치로 봄
      clearInterval(goAppInterval);
      if (window.confirm('앱스토어로 이동하시겠습니까?')) {
        window.location.href = appstoreUrl;
      }
    }, 2000);
  } else if (isAndroid()) {
    // 안드로이드 일때
    const intentUrl = [
      `intent${AppUrl}#Intent`,
      `scheme=${APP_SCHEMA}`,
      `package=${APP_PACKAGE}`,
      `S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.elz.secondhandstore`,
      'end',
    ].join(';');
    window.location.href = intentUrl;
  } else {
    window.open('https://www.joongna.com/', '_blank');
  }
}

export function parseExternalInApp(string) {
  let result = string;

  if (!result.includes('jnapps3://?') && result.includes('http')) {
    // 1. 단, href 가 https://forms.gle/LN4SZzbEBByFDKKc9 라면, 내부로 띄운다.
    // -> 수정됨 https://docs.google.com/forms/d/e/1FAIpQLSc7LjtE3aC81nS0TmVDKjn0xBCyanvAA9FdLhmWwFLxEQkDHw/viewform
    // -> short Link 쓰면 intent url 로 변경되어 안드로이드에서 이상 발생
    // 19-10-02 기획팀 실장님 요청
    if (
      string ===
      'https://docs.google.com/forms/d/e/1FAIpQLSc7LjtE3aC81nS0TmVDKjn0xBCyanvAA9FdLhmWwFLxEQkDHw/viewform'
    ) {
      result = result.replace('http', 'jnapps3://?openweb=http');
    } else {
      result = result.replace('http', 'jnapps3://?openbrowser=http');
    }
  }
  return result;
}

export function getImageSrcSet(images, value) {
  // 항상 images는 small, medium, large 세개의 값을 갖는다.

  return `${images.small[value]} 3x, ${images.medium[value]} 2x, ${
    images.large[value]
  } 1x`;
}

export function isDeepLink(url) {
  return url.startsWith(`://?`) || url.startsWith(APP_SCHEMA);
}

// 파트너센터 url이 맞는지 유무 판별
export function isPartnerCenterUrl(url) {
  if (url.length === 0) return false;
  const partnerCenterUrl = config.PARTNER_CENTER_URL.split(',');
  return partnerCenterUrl.some(pc => {
    return url.indexOf(pc) > -1;
  });
}

// 파트너센터 mobile url로 변환
export function changeMobilePartnerCenterUrl(url) {
  if (url.indexOf('//m.') > -1) {
    return `${url.replace('/goods_view.php', '/joonggo_goods_view.php')}`;
  }
  return `${url
    .replace('//www.', '//')
    .replace('//', '//m.')
    .replace('/goods_view.php', '/joonggo_goods_view.php')}`;
}

// 파트너센터 url
export function partnerParsing(url) {
  if (isPartnerCenterUrl(url)) {
    return changeMobilePartnerCenterUrl(url);
  }
  return url;
}

export function mergeArrayOfObject(arr1, arr2, key) {
  return arr1
    .filter(ar1 => !arr2.find(ar2 => ar1[key] === ar2[key]))
    .concat(arr2);
}

// 추후에 hook 으로 전부 변환시 삭제 ( 기존 class 형에서 사용하기 위함 )
export const addKakaoMap = onLoad => {
  if (window.kakao && window.kakao.maps) onLoad();
  else {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${
      config.KAKAO_API_KEY
    }&libraries=services,clusterer&autoload=false`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      const { kakao } = window;
      kakao.maps.load(() => {
        onLoad();
      });
    };
  }
};

/**
 * 배열 탐색 함수
 * @param {array} arr // 탐색할 배열
 * @param {*} key // 비교하려는 값이 저장되어있는 객체의 키
 * @param {string} val // 찾으려는 값
 */
// 탐색해서 값 가져오는 걸 굳이 index가 아닌, 값 자체를 가져오면 더 편하겠다
export const findArrayIndex = (arr, key, val) => {
  const idx = arr.findIndex(data => data[key] === val);
  return idx < 0 ? 0 : idx;
};

/**
 * 배열을 나누는 함수
 */
export const divideArray = (arr = [], n = 3) => {
  const array = [...arr];
  return new Array(Math.ceil(array.length / n))
    .fill()
    .map(() => array.splice(0, n));
};

export const randomString = (strLen = 15) => {
  let returnText = '';
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
  for (let i = 0; i < strLen; i += 1) {
    returnText += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return returnText;
};
