import axios from 'axios';
import config from '../config';
import { getHeaders } from '../apiUtil';
import { fileUpload } from './file';

const baseURL = `${config.API_BASE_URL}`;

/**
 * 댓글 삭제
 * @param {*} type
 * @param {*} commentSeq
 */
export function deleteComment(type, commentSeq) {
  return axios({
    method: 'delete',
    url: `/comment/${type}/${commentSeq}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 댓글 리스트 조회
 * @param {조회타입 (이벤트: event, 타임공구: timeDeal)} type
 * @param {타입 일련번호(이벤트: eventSeq, 타임공구: timeDealSeq)} typeSeq
 * @param {시작 인덱스, 0 이상, default 0} startIndex
 * @param {검색 수량, 1 이상, default 20} searchQuantity
 */
export function getCommentList(
  type,
  typeSeq,
  startIndex = 0,
  searchQuantity = 20,
) {
  return axios({
    method: 'get',
    url: `/comment/${type}/${typeSeq}?startIndex=${startIndex}&searchQuantity=${searchQuantity}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 댓글 페이지 조회
 * @param {조회타입 (이벤트: event, 타임공구: timeDeal)} type
 * @param {타입 일련번호(이벤트: eventSeq, 타임공구: timeDealSeq)} typeSeq
 * @param {query string 으로 받은 댓글 번호} commentSeq
 */
export function getCommentPage(type, typeSeq, commentSeq) {
  return axios({
    method: 'get',
    url: `/comment/${type}/${typeSeq}/${commentSeq}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 *
 * @param {
 *  type
 *  typeSeq
 *  commentContent
 *  imgData
 *  commentGroupSeq
 *  parentStoreSeq
 * } param
 */
export async function postComment({
  type,
  typeSeq,
  commentContent,
  imgData,
  commentGroupSeq,
  parentStoreSeq,
}) {
  let imgUrl = '';
  if (imgData) {
    const {
      data: { data },
    } = await fileUpload(imgData);
    imgUrl = data[0].mediaUrl;
  }
  const postData = {
    commentContent,
    imgUrl,
    commentGroupSeq,
    parentCommentStoreSeq: parentStoreSeq,
  };
  const promise = axios({
    method: 'post',
    url: `/comment/${type}/${typeSeq}`,
    data: postData,
    baseURL,
    headers: getHeaders(),
  });
  return promise;
}

/**
 * 댓글 수정
 * @param {
 *  type
 *  typeSeq
 *  commentSeq
 *  parentStoreSeq
 *  commentContent
 *  imgData
 * } param
 */
export async function editComment({
  type,
  typeSeq,
  commentSeq,
  parentStoreSeq,
  commentContent,
  imgUrl,
  imgData,
}) {
  let newImgUrl = '';
  if (imgUrl && imgUrl.startsWith('https://img2.joongna.com')) {
    newImgUrl = imgUrl.replace('https://img2.joongna.com', '');
  } else if (
    imgUrl &&
    imgUrl.startsWith('https://img-joonggonara.akamaized.net')
  ) {
    newImgUrl = imgUrl.replace('https://img-joonggonara.akamaized.net', '');
  } else if (imgData) {
    const {
      data: { data },
    } = await fileUpload(imgData);
    newImgUrl = data[0].mediaUrl;
  }
  const postData = { parentStoreSeq, commentContent, imgUrl: newImgUrl };
  return axios({
    method: 'put',
    url: `/comment/${type}/${typeSeq}/${commentSeq}`,
    data: postData,
    baseURL,
    headers: getHeaders(),
  });
}
