import axios from 'axios';
import * as Config from '../config';

const login = {
  ENCRYPTION: 'login/ENCRYPTION',
  MOBILIANS_INFO: 'login/MOBILIANS_INFO',
  PARENT_MOBILIANS_INFO: 'login/PARENT_MOBILIANS_INFO',
  EMAIL_JOIN: 'login/EMAIL_JOIN',
  SET_RETURN_URL: 'login/SET_RETURN_URL',
  FIND_ID: 'login/FIND_ID',
  GET_USERINFO: 'login/GET_USERINFO',
  GET_USERNAME: 'login/GET_USERNAME',
  PUT_PHONE_EXPIRE: 'login/PUT_PHONE_EXPIRE',
  PUT_USER_STATE_PHONE: 'login/PUT_USER_STATE_PHONE',
  PUT_REST: 'login/PUT_REST',
  PUT_USER_MARKETING: 'login/PUT_USER_MARKETING',
};

// 모빌리언스 객체
export function getMobiliansInfo(info) {
  return info;
}

// 이메일 회원가입
export function joinEmail(info, mobilians_info) {
  // 현재 부모 모빌리언스에 본인 모빌리언스 정보를 넣고 있음 (추후 수정 필요)
  const UserEmailJoinVO = {
    mobileAdId: false,
    deviceOs: 2,
    deviceUuid: false,
    gcmId: false,
    userId: info.email,
    userJoinType: 0,
    mobilians: mobilians_info.mobilians,
    parentMobilians: mobilians_info.mobilians,
    nickName: info.nickname,
    userPassword: info.password,
    profileImgUrl: false,
    recommCode: false,
  };
  let pw = info.password;

  UserEmailJoinVO.recommCode =
    info.recommend_code.length > 0 ? info.recommend_code : false;

  return axios
    .get(
      `${Config.default.API_BASE_URL}/test/cipher/cipher/rsa/enc?plain=${pw}`,
    )
    .then(result_pw => {
      pw = result_pw.data.data;
      UserEmailJoinVO.userPassword = pw;
      return axios.post(
        `${Config.default.API_BASE_URL}/user/email/join`,
        JSON.stringify(UserEmailJoinVO),
        Config.default.JSON_HEADER,
      );
    });
}

// RETURN URL 저장
export function set_returnUrl(url) {
  return url;
}

// 아이디 찾기
export function find_id(mobilansVO) {
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/user/find/id`,
    JSON.stringify(mobilansVO),
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 회원정보 가져오기
export function get_userinfo(jn_access_token) {
  jn_access_token = jn_access_token || localStorage.getItem('jn_access_token');

  const promise = axios.get(
    `${Config.default.API_BASE_URL}/user/state/info`,
    Config.getJSONHeader(jn_access_token),
  );
  return promise;
}

// 회원 실명 조회
export function get_username() {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/user/info/name`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 휴대폰 변경
export function put_user_state_phone(mobilians_info) {
  const header =
    localStorage.getItem('jn_access_token') != null &&
    localStorage.getItem('jn_access_token') != ''
      ? Config.getHeader(Config.default.JSON_TYPE)
      : Config.default.JSON_HEADER;
  const promise = axios.put(
    `${Config.default.API_BASE_URL}/user/state/phone/`,
    JSON.stringify(mobilians_info),
    header,
  );
  return promise;
}

// 휴대폰 만료 해제
export function put_phone_expire(mobilians_info, jn_access_token) {
  const promise = axios.put(
    `${Config.default.API_BASE_URL}/v2/user/state/phone/expire`,
    JSON.stringify(mobilians_info),
    Config.getJSONHeader(jn_access_token),
  );
  return promise;
}

// 휴면회원 해제
export function put_rest(mobilians_info, jn_access_token) {
  const promise = axios.put(
    `${Config.default.API_BASE_URL}/v2/user/state/rest`,
    JSON.stringify(mobilians_info),
    Config.getJSONHeader(jn_access_token),
  );
  return promise;
}

/**
 * 마케팅 수신동의 설정
 * type: sms:0, email:1, push:2
 * agree: Y:1, N:0
 */
export function putUserMarketing(type, agree) {
  const promise = axios.put(
    `${Config.default.API_BASE_URL}/v2/user/state/marketing/${type}/${agree}`,
    null,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

export default login;
