import axios from 'axios';
import config from '../config';
import { getHeaders } from '../apiUtil';

const baseURL = `${config.AUTO_API_BASE_URL}`;

/**
 * 내 차고의 차량 목록 조회
 * @param {boolean} getPath
 */
export function getGarageList(getPath = false) {
  if (getPath) return `${baseURL}/garage/list`;
  return axios({
    method: 'get',
    url: `/garage/list`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 차량 등록
 * @param {Object} data
 * @param {boolean} getPath
 */
export function postGarageAdd(data, getPath = false) {
  if (getPath) return `${baseURL}/garage/add`;
  return axios({
    method: 'post',
    url: `/garage/add`,
    baseURL,
    data,
    headers: getHeaders(),
  });
}

/**
 * 내 차량 정보 수정
 * @param {Object} data
 * @param {boolean} getPath
 */
export function putGarageUpdate(data, getPath = false) {
  if (getPath) return `${baseURL}/garage/add`;
  return axios({
    method: 'put',
    url: `/garage/update`,
    baseURL,
    data,
    headers: getHeaders(),
  });
}

/**
 * 내 차량 정보 조회
 * @param {Number} productSeq
 * @param {boolean} getPath
 */
export function getGarageItem(productSeq, getPath = false) {
  if (getPath) return `${baseURL}/garage/${productSeq}`;
  return axios({
    method: 'get',
    url: `/garage/${productSeq}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 차 담보 대출 조회
 * @param {Number} productSeq
 * @param {Number} osType
 * @param {string} idNo
 * @param {string} name
 * @param {string} commId
 * @param {string} mobilid
 * @param {string} no
 * @param {string} ci
 * @param {Number} usePrivacyYn
 * @param {Number} useCreditYn
 */
export function postGarageAutoloanInquiry2(data) {
  return axios({
    method: 'post',
    url: `/garage/autoLoan/inquiry2`,
    baseURL,
    data,
    headers: getHeaders(),
  });
}
