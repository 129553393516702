import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import axios from 'axios';
import { getOsType } from '../../common/webView';
import * as Config from '../../actions/config';
import { getAlarms, getAlarmsByType } from '../../api/base/api';

// store
const initialState = {
  alarmCount: 0,
  adCenterLinkBanner: null,
  notice: { seq: 0, title: '' },
  types: [],
  alarms: {},
};

// action
const GET_ALARM_TAB = 'NOTIFY/GET_ALARM_TAB';
const GET_ALARM_LIST = 'NOTIFY/GET_ALARM_LIST';
const DELETE_ALARM = 'NOTIFY/DELETE_ALARM';
const ALARM_COUNT = 'NOTIFY/ALARM_COUNT';

export const getAlarmTab = createAction(GET_ALARM_TAB, () => {
  const promise = getAlarms();
  return promise;
});
export const getAlarmList = createAction(GET_ALARM_LIST, type => {
  const promise = getAlarmsByType(type);
  return promise;
});
export const deleteAlarm = createAction(
  DELETE_ALARM,
  (alarmSeq, alarmTypeName) =>
    axios
      .delete(
        `${Config.default.API_BASE_URL}/alarm/${alarmSeq}`,
        Config.getJSONHeader(),
      )
      .then(() => {
        return {
          alarmSeq,
          alarmTypeName,
        };
      }),
);
export const alarmCount = createAction(ALARM_COUNT, () =>
  axios.get(
    `${
      Config.default.API_BASE_URL
    }/api/v2/alarms/unread?Os-Type=${getOsType()}`,
    Config.getJSONHeader(),
  ),
);

// reducer
export default handleActions(
  {
    ...pender({
      type: GET_ALARM_TAB,
      onSuccess: (prevState, action) => {
        const state = { ...prevState };

        const {
          data: {
            data: { notice, types },
          },
        } = action.payload;

        state.notice = notice;
        state.types = types;

        return state;
      },
    }),
    ...pender({
      type: GET_ALARM_LIST,
      onSuccess: (prevState, action) => {
        const state = { ...prevState };

        const {
          data: {
            data: { alarms, banner, request },
          },
        } = action.payload;

        state.alarms[request.type] = alarms;
        state.adCenterLinkBanner = banner;

        return state;
      },
    }),

    ...pender({
      type: DELETE_ALARM,
      onSuccess: prevState => {
        const state = { ...prevState };
        return { ...state };
      },
    }),
    ...pender({
      type: ALARM_COUNT,
      onSuccess: (state, action) => {
        const alarmCnt = action.payload.data.data;
        return { ...state, alarmCount: alarmCnt };
      },
    }),
  },
  initialState,
);
