import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import TouchBackend from 'react-dnd-touch-backend';
import HTML5Backend from 'react-dnd-html5-backend';
import actions from './reducers/actions';

import { isDesktop } from './common/device';
import store from './reducers';
import App from './components/App';

const hasNative =
  document && (document.elementsFromPoint || document.msElementsFromPoint);

function getDropTargetElementsAtPoint(x, y, dropTargets) {
  return dropTargets.filter(t => {
    const rect = t.getBoundingClientRect();
    return (
      x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top
    );
  });
}

const backendOptions = {
  getDropTargetElementsAtPoint: !hasNative && getDropTargetElementsAtPoint,
};

import('axios').then(axios => {
  // axios interceptor
  axios.interceptors.response.use(
    response => {
      const {
        data: { meta },
      } = response;
      if (meta && meta.code === 777) {
        store.dispatch({
          type: actions.maintenance.SET_MAINTENANCE,
          payload: {
            response: response.data,
          },
        });
        return Promise.reject(response);
      }
      return response;
    },
    error => {
      if (error.response) {
        store.dispatch({ type: actions.errors.SET_ERROR, payload: error });
      }

      return Promise.reject(error);
    },
  );
});

class Joongna extends Component {
  render() {
    return (
      <Provider store={store}>
        <DndProvider
          backend={isDesktop() ? HTML5Backend : TouchBackend}
          options={backendOptions}
        >
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </DndProvider>
      </Provider>
    );
  }
}

export default Joongna;
