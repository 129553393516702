import { Actions as maintenance } from './modules/maintenance';
import { Actions as errors } from './modules/errors';
import { Actions as init } from './modules/init';
import { Actions as main } from './modules/main';

import { Actions as product } from './modules/productV2';
import { Actions as autoInstalment } from './modules/autoInstalment';
import { Actions as search } from './modules/search';
import { Actions as allSales } from './modules/allSales';
import { Actions as myLocation } from './modules/myLocationV2';
import { Actions as town } from './modules/town';
import { Actions as ticketCoupon } from './modules/ticketCoupon';
import { Actions as follow } from './modules/follow';
import { Actions as store } from './modules/store';
import { Actions as mytag } from './modules/mytag';
import { Actions as myWishList } from './modules/myWishList';
import { Actions as userLoginInfo } from './modules/userLoginInfo';
import { Actions as inviteFriends } from './modules/inviteFriends';
import { Actions as deliveryPopUp } from './modules/deliveryPopUp';
import { Actions as account } from './modules/account';
import { Actions as payment } from './modules/payment';
import { Actions as orderHistory } from './modules/orderHistory';
import { Actions as mobileFranchise } from './modules/mobileFranchise';
import { Actions as geoLocation } from './modules/geoLocation';
import { Actions as autoRegist } from './modules/autoRegist';
import { Actions as standardPrice } from './modules/standardPrice';

const ACTION = {
  maintenance,
  errors,
  init,
  main,
  product,
  userLoginInfo,
  search,
  allSales,
  myLocation,
  town,
  ticketCoupon,
  inviteFriends,
  autoInstalment,
  follow,
  store,
  mytag,
  myWishList,
  deliveryPopUp,
  account,
  payment,
  orderHistory,
  mobileFranchise,
  geoLocation,
  autoRegist,
  standardPrice,
};

export default ACTION;
