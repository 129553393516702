import { createAction, handleActions } from 'redux-actions';
import { runInThisContext } from 'vm';

const initialState = {
  outerWrapStyle: {},
  dimPopupStyle: {},
  onClickGray: new Function(),
  onClickBlack: new Function(),
  text: '',
  grayLabel: '',
  blackLabel: '',
  param: '',
  type: 1, //1: 기본, 2: 레이어 팝업 내에서의 호출
  // popup: '' //for type 2
};

const defaultSettings = {
  onClickGray: new Function(),
  onClickBlack: new Function(),
  text: '',
  grayLabel: '취소',
  blackLabel: '확인',
  param: '',
  type: 1,
  // popup: ''
};

const INIT_OVERLAY = 'overlay/INIT_OVERLAY';
const OPEN_OVERLAY = 'overlay/OPEN_OVERLAY';
const CLOSE_OVERLAY = 'overlay/CLOSE_OVERLAY';

export const initOverlay = createAction(INIT_OVERLAY);
export const openOverlay = createAction(OPEN_OVERLAY, settings => settings); //settings: text, grayLabel, blackLabel, onClickGray, onClickBlack, param, type, popup
export const closeOverlay = createAction(CLOSE_OVERLAY);

export default handleActions(
  {
    [INIT_OVERLAY]: () => {
      const state = { ...initialState };
      const outerWrap = document.getElementById('outerWrap');
      state.outerWrapStyle = {};

      Object.keys(outerWrap.style)
        .filter(key => {
          return isNaN(Number(key)) && outerWrap.style[key];
        })
        .map(key => {
          const value = outerWrap.style[key];
          state.outerWrapStyle[key] = value;
        });

      return state;
    },
    [OPEN_OVERLAY]: (prevState, { payload }) => {
      if (payload.type == 2) {
        const popup = payload.popup;
        delete payload.popup;

        popup.style.position = 'fixed';
        popup.style.left = 0;
        popup.style.right = 0;
      }

      const state = { ...prevState, ...defaultSettings, ...payload };

      state.outerWrapStyle = {
        ...state.outerWrapStyle,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        margin: 'auto',
      };
      state.dimPopupStyle = { display: 'block' };

      return state;
    },
    [CLOSE_OVERLAY]: prevState => {
      const state = { ...prevState };

      if (state.type == 1) {
        state.outerWrapStyle = {
          ...state.outerWrapStyle,
          position: 'relative',
        };
      }
      state.dimPopupStyle = { display: 'none' };

      return state;
    },
  },
  initialState,
);
