import { createAction, handleActions } from 'redux-actions';

const initialState = {};

const BACKUP = 'backup/BACKUP';
const CLEAR = 'backup/CLEAR';

export const backup = createAction(BACKUP, (name, subState) => ({
  name,
  subState,
}));
export const clear = createAction(CLEAR, name => ({ name }));

export default handleActions(
  {
    [BACKUP]: (state, action) => {
      const { name, subState } = action.payload;
      return { ...state, [name]: subState };
    },
    [CLEAR]: (state, action) => {
      const { name } = action.payload;
      return { ...state, [name]: undefined };
    },
  },
  initialState,
);
