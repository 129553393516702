import axios from 'axios';
import * as Config from '../config';

const transfer = {
  GET_ORDER: 'GET_ORDER',
  CHANGE_UNICRO_VO: 'CHANGE_UNICRO_VO',
  UNICRO_START: 'UNICRO_START',
  UNICRO_PAYMENT_CHANGE: 'UNICRO_PAYMENT_CHANGE',
  CHANGE_AGREE_STATE: 'CHANGE_AGREE_STATE',
  TRANSFER_SELECT: 'TRANSFER_SELECT',
  UNICRO_ORDER_COMPLETED: 'UNICRO_ORDER_COMPLETED',
  PAYCO_ORDER_COMPLETED: 'PAYCO_ORDER_COMPLETED',
  PAYMENT_PAYCO: 'PAYMENT_PAYCO',
  PAYMENT_KAKAO: 'PAYMENT_KAKAO',
  KAKAO_ORDER_COMPLETED: 'KAKAO_ORDER_COMPLETED',
  ORDER_TRANSFER_CHAT: 'ORDER_TRANSFER_CHAT',

  // 토스
  PAYMENT_TOSS: 'PAYMENT_TOSS',
  PAYMENT_TOSS_RESULT: 'PAYMENT_TOSS_RESULT',
};

// 상품 결제 시작
export function get_order(productNo) {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/v2/order/${productNo}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );

  return promise;
}

// 유니크로 결제 객체 변경
export function change_unicro_VO(VO) {
  return VO;
}

// 상품 결제 유니크로 결제 시작
export function unicro_start(VO) {
  const orderUnicroVO = JSON.stringify(VO);
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/v2/order/unicro`,
    orderUnicroVO,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 약관동의 상태 변경
export function change_agree_state(state) {
  return state;
}

// 유니크로 결제 객체 생성
export function unicro_payment_change(VO) {
  const paymentVO = {
    unicroToken: VO.unicroToken,
    userId: VO.userId,
    itemNo: VO.itemNo,
    aspItemNo: VO.aspItemNo,
    aspTradeNo: VO.aspTradeNo,
    aspCateNo: VO.aspCateNo,
    itemName: VO.itemName,
    itemPrice: VO.itemPrice,
    payway: VO.payway,
    return_url: '',
    deliveryPayType: VO.deliveryPayType,
    bankCd: VO.bankCd,
    trCodeOpt: VO.trCodeOpt,
    cashHhpNo: VO.cashHhpNo,
    cashTaxNo: VO.cashTaxNo,
    buyrName: VO.buyrName,
    buyrMail: VO.buyrMail,
    buyrHhpNo: VO.buyrHhpNo,
    deliveryZip: VO.deliveryZip,
    deliveryAddress1: VO.deliveryAddress1,
    deliveryAddress2: VO.deliveryAddress2,
    accountName: VO.accountName,
    accountBankNo: VO.accountBankNo,
    accountNo: VO.accountNo,
  };

  return paymentVO;
}

// 유니크로 결제완료
export function unicro_order_completed(
  asp_trade_no,
  trade_no,
  payway_cd,
  result,
  register_date,
  va_date,
  bank_cd,
  account,
  buy_amt,
  seller_amt,
  bank_card_name,
  item_name,
) {
  const promise = axios.get(
    `${
      Config.default.API_BASE_URL
    }/order/unicro/completed?aspTradeNo=${asp_trade_no}&tradeNo=${trade_no}&paywayCd=${payway_cd}&result=${result}&registDate=${register_date}&vaDate=${va_date}&bankCd=${bank_cd}&account=${account}&buyAmt=${buy_amt}&sellerAmt=${seller_amt}&bankCardName=${bank_card_name}&itemName=${item_name}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );

  return promise;
}

// 페이코 결제완료
export function payco_order_completed(orderNo) {
  const promise = axios.get(
    `${
      Config.default.API_BASE_URL
    }/order/transfer/payco/completed?orderNo=${orderNo}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );

  return promise;
}

// 즉시송금 선택
/*
  참고: 이 API는 두 가지 일을 합니다.
  1) seller의 즉시송금 paymentType 지원 여부 확인
  2) 1이 true일 경우, seller의 즉시송금 transfer_seller_info 조회
*/
export function transfer_select(orderNo, paymentType, moment) {
  const promise = axios
    .get(
      `${
        Config.default.API_BASE_URL
      }/order/transfer?orderNo=${orderNo}&orderType=${paymentType}`,
      Config.getHeader(Config.default.JSON_TYPE),
    )
    .then(result => ({ ...result, moment }));
  return promise;
}

// 페이코 결제하기
export function payment_payco(orderNo, userDeliverySeq) {
  const VO = {
    orderNo,
    userDeliverySeq,
  };
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/order/transfer/payco`,
    JSON.stringify(VO),
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 카카오페이  결제하기
export function payment_kakao(orderNo, userDeliverySeq, returnURl) {
  const VO = {
    orderNo,
    userDeliverySeq,
    redirectUrl: returnURl,
  };
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/order/transfer/kakaopay`,
    JSON.stringify(VO),
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 카카오페이 결제완료
export function kakao_order_completed(orderNo, token) {
  const promise = axios.get(
    `${
      Config.default.API_BASE_URL
    }/order/transfer/kakaopay/completed?orderNo=${orderNo}&token=${token}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 즉시 송금 채팅 확인
export function order_transfer_chat(orderNo, orderType) {
  const VO = { orderNo, orderType };
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/order/transfer/chat`,
    VO,
    Config.getJSONHeader(),
  );
  return promise;
}

// 토스 결제하기
export function payment_toss(orderNo, userDeliverySeq) {
  const VO = {
    orderNo,
    userDeliverySeq,
  };
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/order/transfer/toss`,
    JSON.stringify(VO),
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 토스 결제확인
export function payment_toss_result(orderNo) {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/order/transfer/toss/result/${orderNo}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

export default transfer;
