/**
 * 완전 삭제 목표
 */
import { createAction, handleActions } from 'redux-actions';
import moment from 'moment';
import { pender } from 'redux-pender';
import * as Product from '../../actions/service/ProductService';

const initialState = {
  askDefaultAddress: false,

  product_tag: '',
  product_tag_list: [],
  productWishSeq: [],
  onSuccessWishSeq: moment(),
  productHideList: [],
  onSuccessDoHide: moment(),

  error: { code: 0, message: '', moment: moment() },

  // 등록 대기 상품
  waitingList: [],

  isShowHeader: true,
};

// 액션 생성자 비동기 처리
export const clear = createAction(Product.default.CLEAR, () => {});

export const getProductOptionTag = createAction(
  Product.default.GET_PRODUCT_OPTION_TAG,
  Product.getProductOptionTag,
);

export const postProductOptionTagList = createAction(
  Product.default.POST_PRODUCT_OPTION_TAG_LIST,
  Product.postProductOptionTagList,
);

export const postProductWishSeq = createAction(
  Product.default.POST_PRODUCT_WISH_SEQ,
  Product.postProductWishSeq,
);
export const postProductWishControl = createAction(
  Product.default.POST_PRODUCT_WISH_CONTROL,
  Product.postProductWishControl,
);

// 사용자 숨김 리스트
export const postProductHide = createAction(
  Product.default.POST_PRODUCT_HIDE,
  Product.postProductHide,
);

// 사용자 숨김 처리
export const postProductDoHide = createAction(
  Product.default.POST_PRODUCT_DO_HIDE,
  Product.postProductDoHide,
);

export const setProdectDetailHeader = createAction(
  Product.default.SET_PRODECT_DETAIL_HEADER,
  Product.setProdectDetailHeader,
);

export default handleActions(
  {
    [Product.default.CLEAR]: (state, action) => {
      return { ...initialState, isShowHeader: state.isShowHeader };
    },

    ...pender({
      type: Product.default.GET_PRODUCT_OPTION_TAG,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          product_tag: data.data,
        };
      },
    }),
    ...pender({
      type: Product.default.POST_PRODUCT_OPTION_TAG_LIST,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          product_tag_list: data.data,
        };
      },
    }),
    ...pender({
      //
      type: Product.default.POST_PRODUCT_WISH_SEQ,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          productWishSeq: data.data,
          onSuccessWishSeq: moment(),
        };
      },
    }),
    ...pender({
      // 사용자 숨김 리스트
      type: Product.default.POST_PRODUCT_HIDE,
      onSuccess: (state, action) => {
        const { data } = action.payload;

        data.data.map(item => {
          item.price = item.price.toLocaleString();
          item.viewCount = item.viewCount.toLocaleString();

          // 상품업데이트 시간
          const diffSec = moment().diff(
            moment(item.sortDate, 'YYYY-MM-DD HH:mm:ss'),
            'second',
          );
          if (diffSec < 10) {
            // 10초전
            item.sortDateKor = '방금전';
          } else if (diffSec < 60) {
            // 10초~60초미만
            item.sortDateKor = `${diffSec}초 전`;
          } else if (diffSec < 60 * 60) {
            // 1분~60분 미만
            item.sortDateKor = `${parseInt(diffSec / 60)}분 전`;
          } else if (diffSec < 60 * 60 * 24) {
            // 1시간~24시간 미만
            item.sortDateKor = `${parseInt(diffSec / 60 / 60)}시간 전`;
          } else if (diffSec < 60 * 60 * 24 * 30) {
            // 1일 30일 미만
            item.sortDateKor = `${parseInt(diffSec / 60 / 60 / 24)}일 전`;
          } else {
            item.sortDateKor =
              item.sortDate.length >= 16
                ? item.sortDate.substr(0, 16)
                : item.sortDate;
          }

          // 판매상태
          if (item.state == 0) {
            item.stateClass = '';
          } else if (item.state == 1) {
            item.stateClass = 'reserve';
          } else if (item.state == 2) {
            item.stateClass = 'hold';
          } else if (item.state == 3) {
            item.stateClass = 'complete';
          } else if (item.state == 4) {
            item.stateClass = '';
          } else if (item.state == 5) {
            item.stateClass = 'cutoff';
          } else if (item.state == 6) {
            item.stateClass = 'cutoff';
          }

          // 숨김처리 텍스트
          if (item.productHiddenStatus == 5) {
            item.productHiddenStatusKor = '관리자에 의해';
          } else if (item.productHiddenStatus == 6) {
            item.productHiddenStatusKor = '다수의 사용자 신고로';
          }
        });
        return {
          ...state,
          productHideList: data.data,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: { ...data.meta, moment: moment() },
        };
      },
    }),
    ...pender({
      // 숨김 , 해제 실행
      type: Product.default.POST_PRODUCT_DO_HIDE,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSuccessDoHide: moment(),
        };
      },
    }),

    [Product.default.SET_PRODECT_DETAIL_HEADER]: (state, action) => {
      return {
        ...state,
        isShowHeader: action.payload,
      };
    },
  },
  initialState,
);
