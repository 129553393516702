import React, { Component } from 'react';
import { string, number, bool } from 'prop-types';

class IcClose extends Component {
  render() {
    const { className, width, height, fillColor, isThin } = this.props;

    return isThin ? (
      <svg width={width} height={height} viewBox="0 0 16 16">
        <path
          fill={fillColor}
          d="M12.242 3.09c.187-.135.45-.118.618.05.187.187.187.49 0 .677L8.677 8l4.183 4.183c.187.187.187.49 0 .677-.168.168-.43.185-.618.05l-.06-.05L8 8.677 3.817 12.86l-.06.05c-.186.135-.449.118-.617-.05-.187-.187-.187-.49 0-.677L7.323 8 3.14 3.817c-.187-.187-.187-.49 0-.677.168-.168.43-.185.618-.05l.06.05L8 7.323l4.183-4.183z"
          transform="translate(-343 -205) translate(0 200) translate(20) translate(238 5) translate(85)"
        />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 20 20"
        className={className}
        xmlSpace="preserve"
      >
        <path
          fill={fillColor}
          d="M14.98,13.68L11.3,10l3.68-3.67c0.36-0.36,0.36-0.94,0-1.3c-0.36-0.36-0.95-0.36-1.3,0L10,8.69L6.32,5.02
	c-0.36-0.36-0.95-0.36-1.3,0c-0.36,0.36-0.36,0.95,0,1.3L8.69,10l-3.67,3.68c-0.17,0.17-0.27,0.41-0.27,0.65s0.1,0.48,0.27,0.65
	c0.36,0.36,0.94,0.36,1.3,0L10,11.3l3.68,3.68c0.17,0.17,0.41,0.27,0.65,0.27c0,0,0,0,0,0c0.25,0,0.48-0.1,0.65-0.27
	c0.17-0.17,0.27-0.4,0.27-0.65C15.25,14.08,15.15,13.85,14.98,13.68z"
        />
      </svg>
    );
  }
}

IcClose.propTypes = {
  className: string,
  width: number,
  height: number,
  fillColor: string,
  isThin: bool,
};

IcClose.defaultProps = {
  className: '',
  width: 10,
  height: 10,
  fillColor: '#ffffff',
  isThin: false,
};

export default IcClose;
