import React, { Fragment } from 'react';
import { config } from './api';
import { getUserInfo } from './auth';
import { getGA, getLoginType } from './GA';
import { isDesktop } from './device';
import { isApp, isAndroidApp, isIOSApp, getAppDatabyStorage } from './webView';

import {
  getQueryString,
  getQueryStringObj,
  replaceQueryString,
  removeQueryString,
  isPartnerCenterUrl,
  findArrayIndex,
} from './util';
import { isNumeric, numFormat, getPriceCalc } from './number';
import { searchTabUrls } from './const';

export const APP_SCHEMA = process.env.REACT_APP_SCHEMA;

export function pathNameToString(pathname = window.location.pathname) {
  // 계속해서 추가 해야함
  switch (pathname) {
    case '/home':
      return '메인';
    case '/mypage':
      return '마이페이지';
    default:
      return '메인';
  }
}

export function partnerLinkToMobile(url) {
  let reUrl = '';
  if (typeof url === 'string') {
    reUrl = url
      .replace('/www.', '/m.')
      .replace('/partnercenter', '/m.partnercenter');
  }
  return reUrl;
}

/**
 *
 * 주의사항
 * 파트너센터 페이지 특성상
 * 웹페이지가 없어서 웹 페이지에서도 모바일 페이지가 떠야하는 경우가 있습니다.
 * 해당 웹 페이지가 있는지 체크 후 사용 부탁드립니다.
 */
export function partnerLinkToDesktop(url) {
  let reUrl = '';
  if (typeof url === 'string') {
    reUrl = url
      .replace('/m.', '/')
      .replace('/m.partnercenter', '/partnercenter');
  }
  return reUrl;
}

// 딥링크 -> 웹링크
export const parseLinkDeepToWeb = (link, option) => {
  let returnLink = link;

  // 1. 웹뷰 경우
  if (returnLink.startsWith('jnapps3://?openweb=')) {
    returnLink = returnLink.replace('jnapps3://?openweb=', '');
  }
  // 1-1. 웹뷰인데 파트너센터일경우
  if (returnLink.startsWith('jnapps3://?partnerlink=')) {
    returnLink = returnLink.replace('jnapps3://?partnerlink=', '');
  }
  // 2. 새창으로 웹브라우저 경우
  if (returnLink.startsWith('jnapps3://?openbrowser=')) {
    returnLink = returnLink.replace('jnapps3://?openbrowser=', '');
  }
  // 3. 그 외의 경우
  // 딥 링크 정의 문서 순서와 동일하게 정리 중입니다.
  if (returnLink.startsWith('jnapps3://?applink=main')) {
    // 메인
    returnLink = `/`;
  } else if (returnLink.startsWith('jnapps3://?saleproductList=')) {
    // 메인 > 판매상품 전체보기
    returnLink = `/allproduct-list/${returnLink.replace(
      'jnapps3://?saleproductList=',
      '',
    )}`;
  } else if (returnLink.startsWith('jnapps3://?applink=manageprd')) {
    // 메인 > 판매상품 전체보기
    returnLink = `/allproduct-list`;
  } else if (returnLink.startsWith('jnapps3://?searchword=')) {
    // 검색 > 검색어로 검색 뷰 이동
    const urlWord = getQueryString('searchword', returnLink);
    const urlTab = getQueryString('searchtab', returnLink);
    const cafeOrder = urlTab === 'cafe' ? 2 : 1;

    returnLink = `/search-list/product?searchword=${urlWord}&cafeOrder=${cafeOrder}`;
  } else if (returnLink.startsWith('jnapps3://?productseq=')) {
    const data = getQueryStringObj(returnLink.replace('jnapps3://', ''));
    // 상품 상세
    if (returnLink.includes('commentseq')) {
      returnLink = `/product-detail/${data.productseq}?commentseq=${
        data.commentseq
      }`;
    } else {
      returnLink = `/product-detail/${data.productseq}`;
    }
  } else if (returnLink.startsWith('jnapps3://?categoryseq=')) {
    // 카테고리
    let categoryseq = getQueryString('categoryseq', returnLink);
    if (categoryseq.indexOf('?') > -1) {
      categoryseq = categoryseq.replace('?', '&');
    }
    returnLink = `/search-category?category=${categoryseq}`;
  } else if (returnLink.startsWith('jnapps3://?applink=salelist')) {
    // 내정보 > 판매내역 리스트
    returnLink = `/order-history/list/sell`;
  } else if (returnLink.startsWith('jnapps3://?saleseq=')) {
    const orderNo = getQueryString('saleseq', returnLink);
    // 내정보 > 판매내역 상세
    returnLink = `/order-history/detail/sell?no=${orderNo}`;
  } else if (returnLink.startsWith('jnapps3://?applink=buylist')) {
    // 내정보 > 구매내역 리스트
    returnLink = `/order-history/list/buy`;
  } else if (returnLink.startsWith('jnapps3://?buyseq=')) {
    const orderNo = getQueryString('buyseq', returnLink);
    // 내정보 > 구매내역 상세
    returnLink = `/order-history/detail/buy?no=${orderNo}`;
  } else if (returnLink.startsWith('jnapps3://?applink=cvs')) {
    // 내정보 > 택배 관리
    returnLink = `/cvs`;
  } else if (returnLink.startsWith('jnapps3://?sendcvs=')) {
    // 내정보 > 편의점 보낸 택배 상세
    returnLink = `/cvs-detail/send/${returnLink.replace(
      'jnapps3://?sendcvs=',
      '',
    )}`;
  } else if (returnLink.startsWith('jnapps3://?receivecvs=')) {
    // 내정보 > 편의점 받는 택배 상세
    returnLink = `/cvs-detail/send/${returnLink.replace(
      'jnapps3://?receivecvs=',
      '',
    )}`;
  } else if (returnLink.startsWith('jnapps3://?storeseq=')) {
    // 스토어 > 스토어 상세
    returnLink = `/store/${returnLink.replace('jnapps3://?storeseq=', '')}`;
  } else if (returnLink.startsWith('jnapps3://?applink=reviewlist')) {
    // 스토어 > 나의 후기 리스트
    returnLink = isNumeric(option.storeSeq)
      ? `/store-reviews/${option.storeSeq}`
      : '/store-reviews';
  } else if (returnLink.startsWith('jnapps3://?orderNo=')) {
    // 스토어 > 후기 작성하기(IOS만 사용)
    returnLink = `/review/${returnLink.replace('jnapps3://?orderNo=', '')}`;
  } else if (returnLink.startsWith('jnapps3://?repairShop=')) {
    // 오토 > 정비소 -> 무료혜택 탭 // store 눌렀을때 이동 url
    // 오토 > 정비소 -> 할인 탭
    // 오토 > 정비소 -> 이용후기
    // 동일 동작
    returnLink = `${returnLink.replace(
      'jnapps3://?repairShop=',
      '/auto/repairShop/',
    )}`;
    returnLink = returnLink.replace('&', '?');
  } else if (returnLink.startsWith('jnapps3://?eventseq=')) {
    // 이벤트 > 이벤트 상세
    // 이벤트 > 이벤트 상세 답글화면
    // 동일 동작
    returnLink = `/event-detail/${returnLink.replace(
      'jnapps3://?eventseq=',
      '',
    )}`;
  } else if (returnLink.startsWith('jnapps3://?pointevent=')) {
    // 이벤트 > 포인트 이벤트(seq 가 1= 룰렛, 2: 츨첵)
    returnLink = `${returnLink.replace(
      'jnapps3://?pointevent=',
      '/point-event/',
    )}`;
    returnLink = returnLink.replace('&', '?');
  } else if (returnLink.startsWith('jnapps3://?applink=notice')) {
    // 공지사항
    returnLink = '/cs/notice/service';
  } else if (returnLink.startsWith('jnapps3://?noticeseq=')) {
    // 공지사항 상세
    returnLink = `/cs/notice/service/${returnLink.replace(
      'jnapps3://?noticeseq=',
      '',
    )}`;
  } else if (returnLink.startsWith('jnapps3://?applink=faq')) {
    // 고객센터 > 고객센터 FAQ 리스트
    returnLink = '/cs/main/web';
  } else if (returnLink.startsWith('jnapps3://?applink=infouse')) {
    // 고객센터 > 이용안내 리스트
    returnLink = '/cs/info';
  } else if (returnLink.startsWith('jnapps3://?infoseq=')) {
    // 고객센터 > 이용안내 상세
    returnLink = `/cs/info-detail/${returnLink.replace(
      'jnapps3://?infoseq=',
      '',
    )}`;
  } else if (returnLink.startsWith('jnapps3://?applink=safeguide')) {
    // 고객센터 > 안전결제 이용안내 상세
    returnLink = `/cs/info-detail/8`;
  } else if (returnLink.startsWith('jnapps3://?applink=qaseq')) {
    // 고객센터 > 1:1문의 (tab:문의하기)
    returnLink = `/cs/qna?tab=on`;
  } else if (returnLink.startsWith('jnapps3://?qaseq=')) {
    // 고객센터 > 1:1문의 답변상세
    returnLink = `/cs/qna-detail/${returnLink.replace(
      'jnapps3://?qaseq=',
      '',
    )}`;
  } else if (returnLink.startsWith('jnapps3://?applink=opinion')) {
    // 고객센터 > 제안하기
    returnLink = `/cs/qna-reinquiry/suggest`;
  } else if (returnLink.startsWith('jnapps3://?applink=agreeguide')) {
    // 이용약관 > 중고나라 이용약관
    returnLink = `/terms`;
  } else if (returnLink.startsWith('jnapps3://?applink=terms')) {
    // 이용약관 > 중고나라 이용약관
    returnLink = `/privacy-policy`;
  } else if (returnLink.startsWith('jnapps3://?applink=settlement')) {
    // 정산관리
    returnLink = '/mysettlement';
  } else if (returnLink.startsWith('jnapps3://?searchPriceWord')) {
    // 시세조회
    returnLink = returnLink.replace(
      'jnapps3://?searchPriceWord=',
      '/search/price?searchWord=',
    );
  }

  // -----------------
  // 정리 구분선입니다 위는 정리햇고 밑은 정리 안했고
  // -----------------
  if (returnLink.startsWith('jnapps3://?shareweb=')) {
    // 공유기능포함된웹뷰
    returnLink = returnLink.replace('jnapps3://?shareweb=', '');
    const arrLink = returnLink.split('&title=');
    arrLink.pop();
    returnLink = arrLink.join('&title=');
  } else if (returnLink.startsWith('jnapps3://?plan=')) {
    // 기획전
    returnLink = `${returnLink.replace('jnapps3://?plan=', '/plan/')}`;
    returnLink = returnLink.replace('&', '?');
  } else if (returnLink.startsWith(config.DEFAULT_URL)) {
    returnLink = returnLink.replace(config.DEFAULT_URL, '');
  }
  return `${returnLink}`;
};

// 웹 링크 -> 딥링크
export function parseDeepLink(link, mustApp = false) {
  let returnLink = link;
  const links = [
    {
      web: '/store/',
      app: `://?storeseq=`,
    },
    {
      web: '/cs/qna-detail/',
      app: `://?qaseq=`,
    },
    {
      web: '/cs/qna-reinquiry/suggest',
      app: `://?applink=opinion`,
    },
    {
      web: '/cs/qna',
      app: `://?applink=qaseq`,
    },
    {
      web: '/member/dispute',
      app: `://?applink=qaseq`,
    },
    {
      web: '/product-detail/',
      app: `://?productseq=`,
    },
    {
      web: '/search-category',
      app: `://?applink=categorysearch`,
    },
    {
      web: '/cs/notice/service',
      app: `://?applink=notice`,
    },
    {
      web: '/cs/info/web',
      app: `://?applink=infouse`,
    },
    {
      web: '/mystore',
      app: `://?applink=mystore`,
    },
    {
      web: '/event-detail/',
      app: `://?eventseq=`,
    },
    {
      web: '/privacy-policy',
      app: `://?applink=terms`,
    },
    {
      web: '/terms',
      app: `://?applink=agreeguide`,
    },
    {
      web: '/point-event/',
      app: `://?pointevent=`,
    },
    {
      web: '/allproduct-list/',
      app: `://?saleproductList=`,
    },
    {
      web: '/peace',
      app: `://?applink=peaceTab`,
    },
    {
      web: '/location',
      app: `://?applink=ourhometab`,
    },
    {
      web: '/mytag',
      app: `://?applink=mytagtab`,
    },
    {
      web: '/auto/product-regist',
      app: `://?applink=autoregist`,
    },
    {
      web: '/product-regist',
      app: `://?applink=productreg`,
    },
    {
      web: '/auto/product-detail/',
      app: `://?autoproductseq=`,
    },
    {
      web: '/auto/product-edit/',
      app: `://?autoedit=`,
    },
    {
      web: '/auto/garage',
      app: `://?applink=garagelist`,
    },
    {
      web: '/mypage',
      app: `://?applink=sellerrevenue`,
    },
    {
      web: '/auto/repairShop/',
      app: `://?repairShop=`,
    },
    {
      web: '/plan/',
      app: `://?plan=`,
    },
    {
      web: '/order-history/list/sell',
      app: `://?applink=salelist`,
    },
    {
      web: '/order-history/detail/sell?no=',
      app: `://?saleseq=`,
    },
    {
      web: '/order-history/list/buy',
      app: `://?applink=buylist`,
    },
    {
      web: '/order-history/detail/buy?no=',
      app: `://?buyseq=`,
    },
    {
      web: '/notify',
      app: `://?applink=alarm`,
    },
    {
      web: '/maintab/town',
      app: `://?applink=ourhometab`,
    },
    {
      web: '/maintab/ticket',
      app: `://?applink=ticket`,
    },
    {
      web: '/search-category',
      app: `://?applink=categorySearch`,
    },
    {
      web: '/category-seller',
      app: `://?applink=searchmain`,
    },
    {
      web: '/search-list',
      app: `://?`,
    },
    {
      web: '/search/price',
      app: `://?searchPriceWord=`,
    },
    {
      web: '/home',
      app: `://?applink=main`,
    },
  ];

  // 이미 딥 링크면 패스
  if (returnLink.startsWith(`://?`) || returnLink.startsWith(APP_SCHEMA)) {
    return { deepLink: true, link: returnLink };
  }

  const findIndex = links.findIndex(l => returnLink.startsWith(l.web));

  // 딥 링크가 있을 경우
  if (findIndex > -1) {
    if (returnLink.includes('/category-seller')) {
      // 검색 : 카테고리 셀러
      returnLink = links[findIndex].app;
    } else if (returnLink.includes('/product-detail')) {
      // 상품상세
      returnLink = returnLink.replace('?', '&');
      returnLink = returnLink.replace(
        links[findIndex].web,
        links[findIndex].app,
      );
    } else if (returnLink.includes('/cs/qna?')) {
      // 1:1 문의하기
      returnLink = returnLink
        .replace('?', '&')
        .replace('/cs/qna', '://?applink=qaseq');
    } else if (links[findIndex].web === '/auto/repairShop/') {
      // 정비소
      const tab = getQueryString('tab');
      if (tab) returnLink = returnLink.replace('?', '&');
      else returnLink = `${returnLink}&tab=free`;
      returnLink = returnLink.replace(
        links[findIndex].web,
        links[findIndex].app,
      );
    } else if (links[findIndex].web === '/search-category') {
      // 카테고리 검색
      const appLinkV1 = returnLink
        .replace('/search-category?', '')
        .replace('category=', 'categoryseq=')
        .replace('?', '&');
      const appLinkV2 = returnLink
        .replace('/search-category?', 'categorysearch&')
        .replace('category=', 'seq=')
        .replace('categoryname=', 'name=')
        .replace('searchword=', 'keyword=')
        .replace('?', '&');
      const userAgent = getAppDatabyStorage();

      if (isAndroidApp()) {
        if (userAgent === null) returnLink = `://?${appLinkV1}`;
        else returnLink = `://?applink=${appLinkV2}`;
      } else if (isIOSApp()) {
        const version = userAgent['App-Version'].split('.').join('');
        if (Number(version) < 443) returnLink = `://?${appLinkV1}`;
        else returnLink = `://?applink=${appLinkV2}`;
      } else {
        returnLink = `://?applink=${appLinkV2}`;
      }
    } else if (links[findIndex].web === '/search-list') {
      // 통합 검색
      const appLink = returnLink.replace('/search-list', '').split('?');
      const searchword = getQueryString('searchword', returnLink);
      const keywordSource = getQueryString('keywordSource', returnLink);

      let query = replaceQueryString(
        {
          searchtab: appLink[0].replace('/', '') || 'total_v2',
          searchword,
          keywordSource,
        },
        appLink,
      );
      if (query.startsWith('?')) query = query.slice(1);

      returnLink = `://?${query}`;
    } else if (links[findIndex].web === '/plan/') {
      // 기획전
      returnLink = returnLink.replace('?', '&');
      returnLink = returnLink.replace(
        links[findIndex].web,
        links[findIndex].app,
      );
    } else if (returnLink.includes('/notice/service')) {
      // 거래제한품목 확인하기 MWEB -> MWEB
      return { deepLink: false, link: returnLink };
    } else if (returnLink.includes('/search/price')) {
      // 시세 조회 앱 설치 팝업 WEB -> APP
      const searchWord = getQueryString('searchWord', returnLink);
      const newQuery = removeQueryString('searchWord', returnLink).replace(
        '?',
        '&',
      );
      returnLink = returnLink.replace('?', '&');
      returnLink = `${links[findIndex].app}${searchWord}${newQuery}`;
    } else {
      const commentSeq = getQueryString('commentseq');
      if (commentSeq) {
        returnLink = `${returnLink.replace(
          links[findIndex].web,
          links[findIndex].app,
        )}&commentseq=${commentSeq}`;
      } else {
        returnLink = returnLink.replace(
          links[findIndex].web,
          links[findIndex].app,
        );
      }
    }
    return { deepLink: true, link: returnLink };
  }

  if (mustApp) {
    returnLink = `://?applink=main`;
    return { deepLink: true, link: returnLink };
  }

  return { deepLink: false, link: returnLink };
}

export function parseIternalLink(url) {
  return url.replace(config.DEFAULT_URL, '');
}

export function partnercenterUrlToDevice(url) {
  if (isDesktop()) return url.replace('/m.', '/');
  return url;
}

/**
 * 외부링크, 내부링크인지 체크하여 url을 수정해 return
 */
export function checkExternalWebLink(url, loginInfo) {
  const option = getUserInfo();

  // 우선 딥링크라면 웹 url 로 변경함
  const setWebUrl = parseIternalLink(
    url.startsWith('jnapps3://')
      ? parseLinkDeepToWeb(url, option)
      : url.replace(config.DEFAULT_URL, ''),
  );

  if (setWebUrl.startsWith('/')) {
    return {
      external: false,
      isPartner: false,
      url: setWebUrl,
    };
  }

  const isPartner = isPartnerCenterUrl(setWebUrl);

  if (isPartner) {
    const hasQueryString = setWebUrl.indexOf('?') > -1;
    const gaValue = getGA();

    const jnLoginType =
      option && loginInfo
        ? `&jnLoginType=${getLoginType(true, loginInfo.loginType)}`
        : '';

    const partnerUrl = `${partnercenterUrlToDevice(setWebUrl)}${
      hasQueryString ? '&' : '?'
    }_ga=${gaValue}${jnLoginType}`;
    return {
      external: true,
      isPartner: true,
      url: partnerUrl,
    };
  }

  return {
    external: true,
    isPartner: false,
    url: setWebUrl,
  };
}

export const getImgSrc = (src, cdn = config.CDN_URL) => {
  if (typeof src === 'string') {
    const replaceSrc = src.replace('{image}', cdn);

    return replaceSrc.startsWith('/') && !replaceSrc.startsWith('/static/media')
      ? `${cdn}${replaceSrc}`
      : replaceSrc;
  }

  return '';
};

export const getExtenalLink = url => {
  return isApp() ? `jnapps3://?openbrowser=${url}` : url;
};

/**
 * 검색 페이지 URL에서 파라미터 가져오기 ( 없으면 기본값 할당 )
 * @param {string} search // queryString을 추출할 문자열
 */
export const getSearchParams = search => {
  const filterParams = [
    'searchword',
    'order',
    'cafeOrder',
    'showComplete',
    'category',
    'dateFilter',
    'flawedYn',
    'fullPackageYn',
    'limitedEditionYn',
    'maxPrice',
    'minPrice',
    'productCondition',
    'tradeType',
  ];

  const obj = filterParams.reduce((acc, cur) => {
    const value = getQueryString(cur, search);
    if (cur === 'searchword') {
      acc.searchWord = value.trim();
    } else if (cur === 'order') {
      acc.searchOrder = value;
    } else if (cur === 'cafeOrder') {
      acc.cafeOrder = value;
    } else if (cur === 'showComplete') {
      acc.showComplete = value;
    } else if (cur === 'category') {
      acc.searchCategory = value.length > 0 ? Number(value) : 0;

      // if (cur === 'searchword' || cur === 'order' ||cur === 'dateFilter') {
    } else if (cur === 'dateFilter') {
      acc[cur] = value;
    } else if (cur === 'maxPrice') {
      acc[cur] = value.length > 0 ? Number(value) : 100;
    } else if (cur === 'productCondition' || cur === 'tradeType') {
      acc[cur] = value.length > 0 ? Number(value) : -1;
    } else if (value.length > 0) {
      acc[cur] = Number(value);
    } else {
      acc[cur] = 0;
    }
    return acc;
  }, {});

  return obj;
};

/**
 * 검색 페이지 URL에서 필터에서 사용 중인 파라미터 가져오기
 * @param {string} search // queryString을 추출할 문자열
 */
export const getUsedSearchParams = search => {
  const filterParams = [
    'location',
    'category',
    'dateFilter',
    // 'productCondition',
    // 'flawedYn',
    // 'fullPackageYn',
    // 'limitedEditionYn',
    'maxPrice',
    'minPrice',
    'tradeType',
  ];

  const obj = filterParams.reduce((acc, cur) => {
    const value = getQueryString(cur, search);
    if (value.length !== 0) {
      acc[cur] = Number(value);
    }
    return acc;
  }, {});

  return obj;
};

/**
 * originURL에 changeParam을 병합하는 함수로, 검색 url에서 파라미터가 하나만 바뀌는 경우에 사용된다
 * @param {string} changeParam // 추가 또는 교체하려는 파라미터와 키를 입력한다. ex) key=${val}
 * @param {string} originURL // 이동할 페이지의 베이스가 될 url을 입력한다. ex) window.location.search
 * @param {string} removeKey // 제외하고 싶은 키 값을 입력한다. ex) 'order'
 */
export const changeSearchWordURL = (
  changeParam,
  originURL = null,
  removeKey = null,
) => {
  let newURL = `?${changeParam}`;

  if (originURL !== null) {
    const key = changeParam.split('=')[0];
    const url = originURL.indexOf('?') === 0 ? originURL.slice(1) : originURL;
    const data = url
      .split('&')
      .map(d => {
        if (d.indexOf(key) > -1) return '';
        if (removeKey !== null && d.indexOf(removeKey) > -1) return '';
        return `&${d}`;
      })
      .join('');

    if (data.length > 0) newURL += `${data}`;
  }
  return newURL;
};

export const priceInfomation = (data, min = 0, max = 100) => {
  if (min === 0 && max === 0) {
    return '무료나눔';
  }

  if (min === data.min && max === data.max) {
    return '전체 가격';
  }

  if (min > data.min && max === data.max) {
    const minPrice = getPriceCalc(min);
    return `${numFormat(minPrice)}원 이상`;
  }

  if (min === data.min && max < data.max) {
    const maxPrice = getPriceCalc(max);
    return `${numFormat(maxPrice)}원 이하`;
  }

  if (min === max) {
    const minPrice = getPriceCalc(min);
    return `${numFormat(minPrice)}원`;
  }

  let text = '';

  if (min > data.min) {
    const minPrice = getPriceCalc(min);
    text += `${numFormat(minPrice)}원`;
  }

  if (max < data.max) {
    if (text.length > 0) text += ` ~ `;
    const maxPrice = getPriceCalc(max);
    text += `${numFormat(maxPrice)}원`;
  }
  return text;
};

/**
 * 검색어 하이라이트 처리
 * @param {*} originText // 검색할 대상 문자열
 * @param {*} findText // 찾을 문자열
 * @param {*} classname  // 찾은 문자에 적용할 스타일
 */
export const highLighting = (originText, findText, classname) => {
  try {
    const fintKeyword = findText.replace(/ /gi, '|');
    const pattern = new RegExp(fintKeyword, 'gi');
    const result = originText.replace(pattern, `<span>$&</span>`);

    const data = result.split('<span>').map((d, i) => {
      if (d.indexOf('</span>') > -1) {
        const res = d.split('</span>');
        const key = res[0];
        const other = res[1];

        return (
          <Fragment key={`${originText}-${`${key}-${i}`}`}>
            <span className={classname}>{key}</span>
            {other}
          </Fragment>
        );
      }
      return <Fragment key={`${originText}-${`space_${i}`}`}>{d}</Fragment>;
    });
    return data;
  } catch (e) {
    return originText;
  }
};

export const underscoreParser = word => {
  return word
    .replace(/(?:^|\.?)([A-Z])/g, (x, y) => {
      return `_${y.toLowerCase()}`;
    })
    .replace(/^_/, '');
};

export const camelCaseParser = word => {
  return word
    .replace(/_([a-z])/g, (x, y) => {
      return `${y.toUpperCase()}`;
    })
    .replace(/^_/, '');
};

export const objectToQueryString = data => {
  return `?${Object.entries(data)
    .map(d => d.join('='))
    .join('&')}`;
};

export const arrayToNewString = (arr, joinText = '', lastText = '') => {
  const text = `${arr.filter(val => Boolean(val)).join(joinText)}`;
  return `${text}${text ? lastText : ''}`;
};

/**
 * 정리 - 20.10.05
 */

// 딥링크인지 판별
export const isDeepLink = url => {
  return url.startsWith('jnapps3://');
};

// 중나 도메인인지 판별
export const isJnDomain = url => {
  return url.startsWith(config.DEFAULT_URL) || url.startsWith('/');
};

// domain path만 뽑아내기
export const removeJnDomain = url => {
  return url.replace(config.DEFAULT_URL, '');
};

// 웹 링크 -> 딥링크
export function toDeepLink(link, isExternal = false, mustApp = false) {
  if (isExternal) {
    if (mustApp) {
      return `${APP_SCHEMA}://?applink=main`;
    }
    return `${APP_SCHEMA}://?openbrowser=${link}`;
  }

  let webLink = removeJnDomain(link);
  let deepLink = '://?applink=main';

  const links = [
    {
      web: '/search-list/',
      app: `://?`,
    },
    {
      web: '/store/',
      app: `://?storeseq=`,
    },
    {
      web: '/cs/qna-detail/',
      app: `://?qaseq=`,
    },
    {
      web: '/cs/qna',
      app: `://?applink=qaseq`,
    },
    {
      web: '/product-detail/',
      app: `://?productseq=`,
    },
    {
      web: '/cs/notice/service',
      app: `://?applink=notice`,
    },
    {
      web: '/cs/info/web',
      app: `://?applink=infouse`,
    },
    {
      web: '/mystore',
      app: `://?applink=mystore`,
    },
    {
      web: '/event-detail/',
      app: `://?eventseq=`,
    },
    {
      web: '/privacy-policy',
      app: `://?applink=terms`,
    },
    {
      web: '/terms',
      app: `://?applink=agreeguide`,
    },
    {
      web: '/point-event/',
      app: `://?pointevent=`,
    },
    {
      web: '/allproduct-list/',
      app: `://?saleproductList=`,
    },
    {
      web: '/allproduct-list',
      app: `://?applink=manageprd`,
    },
    {
      web: '/peace',
      app: `://?applink=peaceTab`,
    },
    {
      web: '/location',
      app: `://?applink=ourhometab`,
    },
    {
      web: '/mytag',
      app: `://?applink=mytagtab`,
    },
    {
      web: '/auto/product-regist',
      app: `://?applink=autoregist`,
    },
    {
      web: '/product-regist',
      app: `://?applink=productreg`,
    },
    {
      web: '/auto/product-detail/',
      app: `://?autoproductseq=`,
    },
    {
      web: '/auto/product-edit/',
      app: `://?autoedit=`,
    },
    {
      web: '/auto/garage',
      app: `://?applink=garagelist`,
    },
    {
      web: '/mypage',
      app: `://?applink=sellerrevenue`,
    },
    {
      web: '/auto/repairShop/',
      app: `://?repairShop=`,
    },
    {
      web: '/plan/',
      app: `://?plan=`,
    },
    {
      web: '/search-category',
      app: `://?applink=categorySearch`,
    },
  ];

  const findIndex = links.findIndex(l => webLink.startsWith(l.web));

  // 딥 링크가 있을 경우
  if (findIndex > -1) {
    // 정비소
    if (links[findIndex].web === '/auto/repairShop/') {
      const tab = getQueryString('tab', link);
      webLink = tab ? webLink.replace('?', '&') : `${webLink}&tab=free`;
    } else if (links[findIndex].web === '/search-list/') {
      // 통합 검색
      const searchword = getQueryString('searchword', webLink);
      const keywordSource = getQueryString('keywordSource', webLink);

      const query = replaceQueryString({
        searchword,
        keywordSource,
      });

      webLink = `://${query}`;
    } else if (links[findIndex].web === '/search-category') {
      // 카테고리 검색
      const appLink = webLink
        .replace('/search-category?', 'categoryseq=')
        .replace('category=', '')
        .replace('searchword=', 'keyword=')
        .replace('?', '&');

      webLink = `://?${appLink}`;
    }

    deepLink = webLink.replace(links[findIndex].web, links[findIndex].app);
  } else if (isJnDomain(link)) {
    deepLink = `://?openweb=${link}`;
  }

  const commentSeq = getQueryString('commentseq', link);
  if (commentSeq) {
    deepLink = `${deepLink}&commentseq=${commentSeq}`;
  }
  return `${APP_SCHEMA}${deepLink}`;
}

export const parseLink = url => {
  const isDeep = isDeepLink(url);
  const webLink = isDeep ? parseLinkDeepToWeb(url) : url;
  const isExternal = !isJnDomain(webLink);
  const mwebLink = isExternal ? webLink : removeJnDomain(webLink);
  const deepLink = isDeep ? url : toDeepLink(url, isExternal);
  const isHashLink = mwebLink.indexOf('#') > 0;

  // 등록되지 않은 딥링크의 경우, 이동시킬 모웹화면이 없으므로 앱유도 알럿을 노출
  const recommendToUseApp = deepLink === mwebLink;

  return {
    origin: url,
    isExternal,
    isHashLink,
    mwebLink,
    deepLink,
    recommendToUseApp,
  };
};
