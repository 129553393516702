import { handleActions } from 'redux-actions';

const initialState = {};

// 리듀서
export default handleActions(
  {
    NONE: (state, action) => {
      return state;
    },
  },
  initialState,
);
