import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import moment from 'moment';

import { postInstallment } from '../../api/auto/product';

import * as Auto from '../../actions/service/AutoService';

const initialState = {
  option: undefined,
  car_info: [],
  get_car_info_success: moment(),
  autoloan_info: [],
  autoloan_info_success: moment(),
  reqsms_success: moment(),
  garage_list: [],
  delete_garage_success: moment(),
  get_close_loanbanner: moment(),

  installment_info: {},
  installment_fail_message: '',

  installment_reqsms_info: '',
  installment_reqsms_fail_message: '',
};

// 대출 진행 문자 받기
export const postAutoLoanReqsms = createAction(
  Auto.default.POST_AUTOLOAN_REQSMS,
  Auto.postAutoLoanReqsms,
);

export const initOptionList = createAction(
  Auto.default.INIT_OPTION_LIST,
  Auto.carinfoOptionList,
);

// 내 차고 차량 목록 조회
export const getGarageList = createAction(
  Auto.default.GET_GARAGE_LIST,
  Auto.getGarageList,
);

// 내 차고 차량 삭제
export const deleteGarage = createAction(
  Auto.default.DELETE_GARAGE,
  Auto.deleteGarage,
);

// 대출안내 문구 7일간 안보기
export const getCloseLoanBanner = createAction(
  Auto.default.GET_CLOSE_LOANBANNER,
  Auto.getCloseLoanBanner,
);

// 할부 조회
export const postAutoLoanInstallment = createAction(
  Auto.default.POST_AUTOLOAN_INSTALLMENT,
  postInstallment,
);
// 할부 진행 문자 받기
export const postAutoLoanInstallmentReqsms = createAction(
  Auto.default.POST_AUTOLOAN_INSTALLMENT_REQSMS,
  Auto.postAutoLoanInstallmentReqsms,
);

export default handleActions(
  {
    ...pender({
      type: Auto.default.INIT_OPTION_LIST,
      onSuccess: (state, action) => ({
        ...state,
        option: action.payload.data.data,
      }),
    }),

    // 대출 진행 문자 받기
    ...pender({
      type: Auto.default.POST_AUTOLOAN_REQSMS,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          reqsms_success: moment(),
        };
      },
    }),

    // 내 차고 차량목록 조회
    ...pender({
      type: Auto.default.GET_GARAGE_LIST,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        data.data.map(item => {
          // 가데이터 테스트
          // item.garageRegStep = 8
          // item.productPrice = 1000
          // item.thumbnailUrl = ''

          // 최대 대출금액 , 표시
          if (item.maxLoanAmount > 1000) {
            item.maxLoanAmount = item.maxLoanAmount.toLocaleString();
          }

          // 스텝에 따라 차량 상태명 변경
          // if(item.garageRegStep){
          //   if(item.garageRegStep >= 1 && item.garageRegStep <= 5){
          //     item.garageRegStepKor = '차고등록중'
          //   }else if(item.garageRegStep == 6){
          //     item.garageRegStepKor = '차고입고완료'
          //   }else if(item.garageRegStep > 6){
          //     item.garageRegStepKor = '차고입고완료'
          //   }
          // else if(item.garageRegStep == 7 || item.garageRegStep == 8){
          //   item.garageRegStepKor = '판매 등록 중'
          // }else if(item.garageRegStep == 9){
          //   item.garageRegStepKor = '판매중'
          // }
          // }

          // 스텝에 따라 차량 상태명 변경
          if (item.garageRegStep) {
            if (item.garageRegStep < 9) {
              item.garageRegStepKor = '보유 차량';
            } else if (item.garageRegStep == 9) {
              if (item.productStatus == 1) {
                item.garageRegStepKor = '직거래 예약중';
              } else if (item.productStatus == 2) {
                item.garageRegStepKor = '직거래 판매보류';
              } else if (item.productStatus == 3) {
                item.garageRegStepKor = '직거래 판매완료';
              } else if (item.productStatus == 4) {
                item.garageRegStepKor = '보유차량';
              } else {
                item.garageRegStepKor = '직거래 판매중';
              }
            }
          }
        });
        return {
          ...state,
          garage_list: data.data,
        };
      },
    }),

    // 내차고 차량 삭제
    ...pender({
      type: Auto.default.DELETE_GARAGE,
      onSuccess: state => {
        return {
          ...state,
          delete_garage_success: moment(),
        };
      },
    }),

    // 대출안내 문구 7일간 안보기
    ...pender({
      type: Auto.default.GET_CLOSE_LOANBANNER,
      onSuccess: state => {
        return {
          ...state,
          get_close_loanbanner: moment(),
        };
      },
    }),

    // 할부 조회
    ...pender({
      type: Auto.default.POST_AUTOLOAN_INSTALLMENT,
      onSuccess: (state, action) => {
        const { data } = action.payload;

        // 대출가능금액 만원단위 끊기
        if (data.data.loanAmount > 10000) {
          data.data.loanAmount = (
            data.data.loanAmount / 10000
          ).toLocaleString();
        }
        return {
          ...state,
          installment_info: data.data,
          installment_fail_message: '',
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          installment_fail_message: data.meta.message,
        };
      },
    }),

    // 할부 진행 문자 받기
    ...pender({
      type: Auto.default.POST_AUTOLOAN_INSTALLMENT_REQSMS,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          installment_reqsms_info: data.data,
          installment_reqsms_fail_message: '',
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          installment_reqsms_fail_message: data.meta.message,
        };
      },
    }),
  },
  initialState,
);
