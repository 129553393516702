import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import produce from 'immer';

import { config } from '../../common/api';
import { getQueryString } from '../../common/util';
import { getUserInfo } from '../../common/auth';

import {
  getStoreInfoV2 as getStoreInfoApi,
  postStoreTagProduct as postStoreTagProductApi,
  putStoreNotice as putStoreNoticeApi,
  delStoreShowCase as delStoreShowCaseApi,
  putChangeImage as putChangeImageApi,
} from '../../api/base/mystore';

import { getWishList as getWishListApi } from '../../api/base/product';

export const Actions = {
  GET_STORE_INFO: 'STORE/GET_STORE_INFO',

  CHANGE_STORE_FOLLOW: 'STORE/CHANGE_STORE_FOLLOW',

  CHANGE_PROFILE_IMAGE: 'STORE/CHANGE_PROFILE_IMAGE',
  CHANGE_BACKGROUND_IMAGE: 'STORE/CHANGE_BACKGROUND_IMAGE',

  CHANGE_STORE_DESC: 'STORE/CHANGE_STORE_DESC',
  CHANGE_STORE_RESPONSE_TIME: 'STORE/CHANGE_STORE_RESPONSE_TIME',

  CHANGE_STORE_SHOWCASE_PRODUCT: 'STORE/CHANGE_STORE_SHOWCASE_PRODUCT',
  CHANGE_STORE_SHOWCASE_USE: 'STORE/CHANGE_STORE_SHOWCASE_USE',

  APPEND_NOTICE_ITEM: 'STORE/APPEND_NOTICE_ITEM',

  GET_TAG_PRODUCT_LIST: 'STORE/GET_TAG_PRODUCT_LIST',

  GET_PRODUCT_WISH: 'STORE/GET_PRODUCT_WISH',
  DELETE_SHOWCASE_PRODUCT: 'STORE/DELETE_SHOWCASE_PRODUCT',

  DELETE_PRODUCT: 'STORE/DELETE_PRODUCT',

  CLEAR_STORE: 'STORE/CLEAR_STORE',
  RESET_TAG_PRODUCT_PAGE_NO: 'STORE/RESET_TAG_PRODUCT_PAGE_NO',
};

const initialState = {
  // 에러

  info: {
    badgeImgUrl: '', // 레벨 뱃지 이미지
    customCount: 0, // 단골 수
    followingYn: 0, // 단골 여부 ( 0: 단골 아님, 1: 단골 )
    isShowcaseUse: true, // 내 가게 전시 사용 여부
    levelInfo: '', // 레벨 설정
    partnerCenterRegDate: [], // 파트너센터 연동일
    partnerCenterSellerYn: 0, // 파트너센터 연동 여부 ( 0: 미연동, 1: 연동 )
    profileImageUrl: '', // 프로필 이미지
    responseAvailableTime: '', // 응대 가능 시간
    sellerCategoryYn: 1, // 셀러전문카테고리 설정 여부
    showCaseProducts: [], // 내 가게 전시 상품
    showcaseTitle: '', // 내 가게 전시 제목
    storeBenefit: 0, // 가게 수익
    storeDesc: '', // 내 가게 소개
    storeGrade: 0, // 평점
    storeImgUrl: '', // 내 가게 이미지
    storeLevel: '', // 내 가게 레벨
    storeName: '', // 내 가게 이름
    storeNotices: [], // 공지사항
    storeSeq: -1, // 가게 Seq
    storeTags: [], // 내 가게 태그
    visitTodayCount: 0, // 오늘 방문자 수
    visitTotalCount: 0, // 누적 방문자 수
  },

  tagProductPageNo: 0,
  totalCount: 0,
  productExist: false, // 태그 아이템 존재 유무
  tagProductList: [], // 태그 아이템 리스트
  adCenterLinkBanner: null, // 광고주센터 배너 정보
  error: {
    code: 200,
    message: '',
  },
};

function checkVisitedStore(storeSeq) {
  const isMyInfo = getUserInfo();

  if (isMyInfo && isMyInfo.storeSeq === storeSeq) return 0;

  // 현재 세션에서 방문한 기록이 있는 가게를 불러온다.
  const session = JSON.parse(window.sessionStorage.getItem('visitStore')) || [];

  return session.findIndex(s => s === storeSeq) > -1 ? 0 : 1;
}

async function getItemFollowingYn(productList, productListSeqs) {
  try {
    const {
      data: { data },
    } = await getWishListApi(productListSeqs);

    const productListWithWish = productList.map(p => {
      const followingYn = data.findIndex(
        s => s === p.seq || s === p.productSeq,
      );
      return {
        ...p,
        followingYn: followingYn > -1 ? 1 : 0,
      };
    });
    return productListWithWish;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getStoreInfoWithWish(storeSeq) {
  try {
    const visitYn = checkVisitedStore(storeSeq);

    const {
      data: { data },
    } = await getStoreInfoApi(storeSeq, visitYn);
    const productListSeqs = data.showCaseProducts.map(p => p.productSeq);

    if (productListSeqs.length > 0) {
      const productListWithWish = await getItemFollowingYn(
        data.showCaseProducts,
        productListSeqs,
      );

      if (visitYn === 1) {
        const session =
          JSON.parse(window.sessionStorage.getItem('visitStore')) || [];
        const nextSession = [...session, storeSeq];

        window.sessionStorage.setItem(
          'visitStore',
          JSON.stringify(nextSession),
        );
      }

      return {
        ...data,
        showCaseProducts: productListWithWish,
      };
    }

    const productListWithWish = data.showCaseProducts.map(p => ({
      ...p,
      followingYn: 0,
    }));

    return {
      ...data,
      showCaseProducts: productListWithWish,
    };
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getTagItemWithWish(storeSeq, parameter, pageNo) {
  try {
    const {
      data: {
        data: { productExist, productList },
      },
    } = await postStoreTagProductApi(storeSeq, parameter, pageNo);
    const productListSeqs = productList.map(p => p.seq);

    if (productListSeqs.length > 0) {
      const productListWithWish = await getItemFollowingYn(
        productList,
        productListSeqs,
      );

      return {
        pageNo,
        productExist,
        productList: productListWithWish,
      };
    }
    const productListWithWish = productList.map(p => ({
      ...p,
      followingYn: 0,
    }));
    return {
      pageNo,
      productExist,
      productList: productListWithWish,
    };
  } catch (e) {
    return Promise.reject(e);
  }
}

async function deleteShowCaseProduct(storeSeq, productSeq) {
  try {
    await delStoreShowCaseApi(storeSeq, productSeq);
    return {
      productSeq,
    };
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getStoreInfo = createAction(
  Actions.GET_STORE_INFO,
  getStoreInfoWithWish,
);

export const changeStoreFollow = createAction(Actions.CHANGE_STORE_FOLLOW);

export const chageStoreImage = createAction(
  Actions.CHANGE_BACKGROUND_IMAGE,
  putChangeImageApi,
);

export const chageProfileImage = createAction(
  Actions.CHANGE_PROFILE_IMAGE,
  putChangeImageApi,
);

export const changeStoreDesc = createAction(Actions.CHANGE_STORE_DESC);

export const changeStoreResponseTime = createAction(
  Actions.CHANGE_STORE_RESPONSE_TIME,
);

export const changeStoreShowCaseProduct = createAction(
  Actions.CHANGE_STORE_SHOWCASE_PRODUCT,
);

export const changeStoreShowCaseUse = createAction(
  Actions.CHANGE_STORE_SHOWCASE_USE,
);

export const appendNoticeItme = createAction(
  Actions.APPEND_NOTICE_ITEM,
  putStoreNoticeApi,
);

export const getTagItemList = createAction(
  Actions.GET_TAG_PRODUCT_LIST,
  getTagItemWithWish,
);

export const delStoreShowCase = createAction(
  Actions.DELETE_SHOWCASE_PRODUCT,
  deleteShowCaseProduct,
);

export const deleteProduct = createAction(Actions.DELETE_PRODUCT);

export const clearStore = createAction(Actions.CLEAR_STORE);
export const resetTagProductPageNo = createAction(
  Actions.RESET_TAG_PRODUCT_PAGE_NO,
);

export default handleActions(
  {
    ...pender({
      type: Actions.GET_STORE_INFO,
      onSuccess: (state, action) => {
        const data = action.payload;
        const adCenterLinkBanner =
          typeof data.adCenterLinkBanner === 'undefined'
            ? null
            : data.adCenterLinkBanner;
        return {
          ...state,
          info: data,
          adCenterLinkBanner,
          error: initialState.error,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),

    [Actions.CHANGE_STORE_FOLLOW]: (state, action) => {
      const nextFollow = action.payload;
      return produce(state, d => {
        const draft = d;
        draft.info.followingYn = nextFollow;
        draft.info.customCount =
          nextFollow > 0
            ? state.info.customCount + 1
            : state.info.customCount - 1;
      });
    },

    ...pender({
      type: Actions.CHANGE_PROFILE_IMAGE,
      onSuccess: (state, action) => {
        const {
          config: { url },
        } = action.payload;

        const profileImageUrl = `${config.CDN_URL}${getQueryString(
          'profile',
          url,
        )}`;
        return produce(state, d => {
          const draft = d;
          draft.info.profileImageUrl = profileImageUrl;
          draft.error = initialState.error;
        });
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),

    ...pender({
      type: Actions.CHANGE_BACKGROUND_IMAGE,
      onSuccess: (state, action) => {
        const {
          config: { url },
        } = action.payload;

        const storeImgUrl = `${config.CDN_URL}${getQueryString(
          'background',
          url,
        )}`;
        return produce(state, d => {
          const draft = d;
          draft.info.storeImgUrl = storeImgUrl;
          draft.error = initialState.error;
        });
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),

    [Actions.CHANGE_STORE_DESC]: (state, action) => {
      const introduce = action.payload;
      return produce(state, d => {
        const draft = d;
        draft.info.storeDesc = introduce;
      });
    },

    [Actions.CHANGE_STORE_RESPONSE_TIME]: (state, action) => {
      const responseTime = action.payload;
      return produce(state, d => {
        const draft = d;
        draft.info.responseAvailableTime = responseTime;
      });
    },

    [Actions.CHANGE_STORE_SHOWCASE_PRODUCT]: (state, action) => {
      const isShowCaseProduct = action.payload;
      return produce(state, d => {
        const draft = d;
        draft.info.showCaseProducts = isShowCaseProduct;
      });
    },

    [Actions.CHANGE_STORE_SHOWCASE_USE]: (state, action) => {
      const isShowCaseUse = action.payload;
      return produce(state, d => {
        const draft = d;
        draft.info.isShowcaseUse = isShowCaseUse;
      });
    },

    ...pender({
      type: Actions.GET_TAG_PRODUCT_LIST,
      onSuccess: (state, action) => {
        const { pageNo, productExist, productList } = action.payload;
        return produce(state, d => {
          const draft = d;
          draft.tagProductPageNo = pageNo + 1;
          draft.totalCount = productList[0] ? productList[0].totalCnt : 0;
          draft.productExist = productExist;
          if (pageNo === 0) draft.tagProductList = productList;
          else draft.tagProductList.push(...productList);
          draft.error = initialState.error;
        });
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),

    ...pender({
      type: Actions.DELETE_SHOWCASE_PRODUCT,
      onSuccess: (state, action) => {
        const { productSeq } = action.payload;

        const findIndex = state.info.showCaseProducts.findIndex(
          s => s.productSeq === productSeq,
        );
        if (findIndex > -1) {
          return produce(state, d => {
            const draft = d;
            draft.info.showCaseProducts.splice(findIndex, 1);
          });
        }

        return {
          ...state,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),

    [Actions.DELETE_PRODUCT]: (state, action) => {
      const productSeq = action.payload;

      const scFindIndex = state.info.showCaseProducts.findIndex(
        s => s.productSeq === productSeq,
      );
      const nFindIndex = state.info.storeNotices.findIndex(
        s => s.productSeq === productSeq,
      );
      const tpFindIndex = state.tagProductList.findIndex(
        s => s.seq === productSeq,
      );

      return produce(state, d => {
        const draft = d;
        if (scFindIndex > -1)
          draft.info.showCaseProducts.splice(scFindIndex, 1);
        if (nFindIndex > -1) draft.info.storeNotices.splice(nFindIndex, 1);
        if (tpFindIndex > -1) {
          draft.tagProductList.splice(tpFindIndex, 1);
          draft.totalCount = state.totalCount > 0 ? state.totalCount - 1 : 0;
        }
      });
    },

    [Actions.CLEAR_STORE]: () => {
      return { ...initialState };
    },

    [Actions.RESET_TAG_PRODUCT_PAGE_NO]: (state, action) => {
      return produce(state, d => {
        const draft = d;
        draft.tagProductPageNo = 0;
      });
    },
  },
  initialState,
);
