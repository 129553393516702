import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import produce from 'immer';

import {
  getOrderHistoryList as getOrderHistoryListApi,
  getCurrentOrderState as getCurrentOrderStateApi,
  postJoongnaPayAgree,
} from '../../api/base/order';
import { getUserMainAccount as getUserMainAccountApi } from '../../api/base/user';

import { orderHistoryFilters } from '../../common/const';

export const Actions = {
  GET_ORDER_HISTORY_LIST: 'ORDERHISTORY/GET_ORDER_HISTORY_LIST',
  DELETE_ORDER_HISTORY: 'ORDERHISTORY/DELETE_ORDER_HISTORY',
  UPDATE_HISTORY_LIST: 'ORDERHISTORY/UPDATE_HISTORY_LIST',
  UPDATE_FILTER: 'ORDERHISTORY/UPDATE_FILTER',
  UPDATE_UNICRO_AGREE: 'ORDERHISTORY/UPDATE_UNICRO_AGREE',
  UPDATE_KB_SELLER: 'ORDERHISTORY/UPDATE_KB_SELLER',
  UPDATE_JOONGNAPAY_SELLER: 'ORDERHISTORY/UPDATE_JOONGNAPAY_SELLER',
  SET_HAS_MAIN_ACCOUNT: 'ORDERHISTORY/SET_HAS_MAIN_ACCOUNT',
  UPDATE_MAIN_ACCOUNT: 'ORDERHISTORY/UPDATE_MAIN_ACCOUNT',
};

const initialState = {
  isUnicroSeller: false,
  isKbSeller: false,
  isJoongnaPaySeller: false,
  hasAccountData: false,
  updateOrder: null,
  buyData: {
    totalCount: 0,
    isLoading: true,
    list: [],
    showMoreBtn: true,
    isfilterUpdate: false,
    filterQuery: {
      searchWord: '', // 검색어
      datePeriod: orderHistoryFilters.datePeriod[0].value, // 검색기간
      dealType: orderHistoryFilters.dealType[0].value, // 거래방법
      isPaymentPending: '', // 판매보류상태
      orderStatus: orderHistoryFilters.orderStatus[0].value, // 주문상태
    },
  },
  sellData: {
    totalCount: 0,
    isLoading: true,
    list: [],
    showMoreBtn: true,
    isfilterUpdate: false,
    filterQuery: {
      searchWord: '', // 검색어
      datePeriod: orderHistoryFilters.datePeriod[0].value, // 검색기간
      dealType: orderHistoryFilters.dealType[0].value, // 거래방법
      isPaymentPending: '', // 판매보류상태
      orderStatus: orderHistoryFilters.orderStatus[0].value, // 주문상태
    },
  },
};

// 거래내역 리스트
async function getOrderHistoryList(
  type = 'buy',
  {
    searchWord = '',
    orderStatus = '',
    datePeriod = '',
    dealType = '',
    isPaymentPending = '',
  },
  startIndex,
  searchQuantity,
) {
  try {
    const {
      data: { data },
    } = await getOrderHistoryListApi(
      type,
      {
        title: searchWord,
        status: orderStatus,
        period: datePeriod,
        dealType,
        isPaymentPending: isPaymentPending === 'true',
      },
      startIndex,
      searchQuantity,
    );

    return {
      type,
      startIndex,
      data,
      queryData: {
        searchWord,
        orderStatus,
        datePeriod,
        dealType,
        isPaymentPending: isPaymentPending === 'true',
      },
    };
  } catch (e) {
    return Promise.reject(e);
  }
}

// 대표계좌 정보 조회
async function inqueryMainAccountAPI() {
  try {
    const {
      data: { data },
    } = await getUserMainAccountApi();
    return data && (data.accountSeq !== 0 || data.mainAccountYn !== 0);
  } catch (e) {
    return Promise.reject(e);
  }
}

// 최신 상태 확인
async function getCurrentOrderState(type = 'buy', status) {
  try {
    const {
      data: { data },
    } = await getCurrentOrderStateApi(status);
    return { type, data };
  } catch (e) {
    return Promise.reject(e);
  }
}

// 거래내역 리스트 불러오기
export const getHistoryList = createAction(
  Actions.GET_ORDER_HISTORY_LIST,
  getOrderHistoryList,
);

// 거래내역 삭제
export const deleteOrderHistory = createAction(
  Actions.DELETE_ORDER_HISTORY,
  (type, orderNo) => ({ type, orderNo }),
);

// 거래내역 리스트 갱신
export const updateHistoryList = createAction(
  Actions.UPDATE_HISTORY_LIST,
  getCurrentOrderState,
);

// 필터 저장
export const updateFilter = createAction(
  Actions.UPDATE_FILTER,
  (type, filters) => ({ type, filters }),
);

// 대표계좌 유무
export const checkHasMainAccount = createAction(
  Actions.SET_HAS_MAIN_ACCOUNT,
  inqueryMainAccountAPI,
);

// 대표계좌 추가
export const updateMainAccount = createAction(
  Actions.UPDATE_MAIN_ACCOUNT,
  (hasMainAccount, updateOrderSeq) => ({ hasMainAccount, updateOrderSeq }),
);

// 유니크로 약관 동의 시, 데이터 갱신
export const updateUnicroAgree = createAction(Actions.UPDATE_UNICRO_AGREE);

// kb판매자 등록 시, 데이터 갱신
export const updateKbSeller = createAction(Actions.UPDATE_KB_SELLER);

// kb판매자 등록 시, 데이터 갱신
export const updateJoongnaPaySeller = createAction(
  Actions.UPDATE_JOONGNAPAY_SELLER,
  postJoongnaPayAgree,
);

export default handleActions(
  {
    // 정보 받아오기
    ...pender({
      type: Actions.GET_ORDER_HISTORY_LIST,
      onSuccess: (state, action) => {
        const { type, startIndex, data, queryData } = action.payload;
        if (type === 'buy')
          return produce(state, d => {
            const draft = d;
            const list =
              startIndex === 0
                ? data.orderHistoryList
                : [...state.buyData.list, ...data.orderHistoryList];
            draft.buyData.totalCount = data.totalCount;
            draft.buyData.list = list;
            draft.buyData.isfilterUpdate = false;
            draft.buyData.showMoreBtn = data.totalCount > list.length;
            draft.buyData.filterQuery = {
              ...state.buyData.filterQuery,
              ...queryData,
            };
            if (state.buyData.isLoading) draft.buyData.isLoading = false;
          });

        return produce(state, d => {
          const draft = d;
          const list =
            startIndex === 0
              ? data.orderHistoryList
              : [...state.sellData.list, ...data.orderHistoryList];

          draft.sellData.totalCount = data.totalCount;
          draft.sellData.list = list;
          draft.sellData.isfilterUpdate = false;
          draft.sellData.showMoreBtn = data.totalCount > list.length;
          draft.sellData.filterQuery = {
            ...state.sellData.filterQuery,
            ...queryData,
          };
          if (state.sellData.isLoading) draft.sellData.isLoading = false;
          if (data.isUnicroSeller) draft.isUnicroSeller = data.isUnicroSeller;
          if (data.isKbSeller) draft.isKbSeller = data.isKbSeller;
          if (data.isJoongnaPaySeller)
            draft.isJoongnaPaySeller = data.isJoongnaPaySeller;
        });
      },
      onFailure: state => {
        return {
          ...state,
        };
      },
    }),
    [Actions.DELETE_ORDER_HISTORY]: (state, action) => {
      const { type, orderNo } = action.payload;
      if (type === 'buy') {
        return produce(state, d => {
          const draft = d;
          const index = state.buyData.list.findIndex(
            b => b.orderNo === orderNo,
          );
          if (index !== -1) draft.buyData.list.splice(index, 1);
        });
      }
      return produce(state, d => {
        const draft = d;
        const index = state.sellData.list.findIndex(s => s.orderNo === orderNo);
        if (index !== -1) draft.sellData.list.splice(index, 1);
      });
    },
    ...pender({
      type: Actions.UPDATE_HISTORY_LIST,
      onSuccess: (state, action) => {
        const { type, data } = action.payload;
        if (type === 'sell') {
          return produce(state, d => {
            const draft = d;
            const index = state.sellData.list.findIndex(
              s => s.orderNo === data.orderNo,
            );

            if (index !== -1) draft.sellData.list[index] = { ...data };
          });
        }
        if (type === 'buy') {
          return produce(state, d => {
            const draft = d;
            const index = state.buyData.list.findIndex(
              b => b.orderNo === data.orderNo,
            );

            if (index !== -1) draft.buyData.list[index] = { ...data };
          });
        }
      },
      onFailure: state => {
        return {
          ...state,
        };
      },
    }),
    [Actions.UPDATE_FILTER]: (state, action) => {
      const { type, filters } = action.payload;
      if (type === 'buy')
        return produce(state, d => {
          const draft = d;
          draft.buyData.isfilterUpdate = true;
          draft.buyData.filterQuery = {
            ...state.buyData.filterQuery,
            ...filters,
          };
        });

      return produce(state, d => {
        const draft = d;
        draft.sellData.isfilterUpdate = true;
        draft.sellData.filterQuery = {
          ...state.sellData.filterQuery,
          ...filters,
        };
      });
    },
    ...pender({
      type: Actions.SET_HAS_MAIN_ACCOUNT,
      onSuccess: (state, action) => {
        const hasAccount = action.payload;
        return produce(state, d => {
          const draft = d;
          draft.hasAccountData = hasAccount;
        });
      },
      onFailure: state => {
        return {
          ...state,
        };
      },
    }),
    [Actions.UPDATE_MAIN_ACCOUNT]: (state, action) => {
      const { hasMainAccount, updateOrderSeq } = action.payload;
      return produce(state, d => {
        const draft = d;
        draft.hasAccountData = hasMainAccount;
        draft.updateOrder = updateOrderSeq;
      });
    },
    [Actions.UPDATE_UNICRO_AGREE]: state => {
      return produce(state, d => {
        const draft = d;
        draft.isUnicroSeller = true;
      });
    },
    [Actions.UPDATE_KB_SELLER]: state => {
      return produce(state, d => {
        const draft = d;
        draft.isKbSeller = true;
      });
    },
    ...pender({
      type: Actions.UPDATE_JOONGNAPAY_SELLER,
      onSuccess: (state, action) => {
        const { status } = action.payload;
        return produce(state, d => {
          const draft = d;
          if (status === 200) {
            draft.isJoongnaPaySeller = true;
          }
        });
      },
      onFailure: state => {
        return {
          ...state,
        };
      },
    }),
  },
  initialState,
);
