import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';

// 추후에는 내 위치도 여기서 다 관리하자
export const Actions = {
  SET_NONE_GEO_ALERT: 'GEOLOCATION/SET_NONE_GEO_ALERT',
  SET_IN_APP_GEO_LOCATION: 'GEOLOCATION/SET_INAPP_GEO_LOCATION',
  CLEAR: 'GEOLOCATION/CLEAR',
};

export const setInAppGeoLocation = createAction(
  Actions.SET_IN_APP_GEO_LOCATION,
  data => ({ data }),
);

export const setNoneGeoAlert = createAction(
  Actions.SET_NONE_GEO_ALERT,
  bool => ({ bool }),
);

export const clear = createAction(Actions.CLEAR);

const initialState = {
  inAppGeoLocationData: {
    mlat: null,
    mlng: null,
  },
  noneGeoAlert: true,
};

export default handleActions(
  {
    [Actions.SET_IN_APP_GEO_LOCATION]: (state, action) => {
      const { data } = action.payload;

      return produce(state, d => {
        const draft = d;
        draft.inAppGeoLocationData = data;
      });
    },

    [Actions.SET_NONE_GEO_ALERT]: (state, action) => {
      const { bool } = action.payload;

      return produce(state, d => {
        const draft = d;
        draft.noneGeoAlert = bool;
      });
    },

    [Actions.CLEAR]: () => {
      return {
        ...initialState,
      };
    },
  },
  initialState,
);
