import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import produce from 'immer';
import { filterDefault } from '../../common/const';

import {
  ticketsProductsApi,
  getTicketLocationList,
  getTicketProductList,
} from '../../api/tickets/tickets';

import { getWishList as getWishListApi } from '../../api/base/product';

import { getToken } from '../../common/auth';

export const Actions = {
  GET_TICKETCOUPON_SCROLL: 'TICKETCOUPON/GET_TICKETCOUPON_SCROLL',
  GET_TICKETCOUPON_BANNER_CATEGORY:
    'TICKETCOUPON/GET_TICKETCOUPON_BANNER_CATEGORY',
  GET_TICKETCOUPON_TOWNS: 'TICKETCOUPON/GET_TICKETCOUPON_TOWNS',
  GET_TICKETCOUPON_PRODUCTS: 'TICKETCOUPON/GET_TICKETCOUPON_PRODUCTS',
  CLEAR_TICKETCOUPON_PRODUCTS: 'TICKETCOUPON/CLEAR_TICKETCOUPON_PRODUCTS',
};

const initialState = {
  isInit: false,
  scrollHeight: 0,
  banners: [],
  categories: [],
  towns: [],
  index: 0,
  isMore: true,
  products: [],
  searchStartDate: filterDefault.searchStartDate,
  error: {
    code: 200,
    message: '',
  },
};

// 티켓/쿠폰의 배너, 카테고리 상품를 불러옴
const bannerAndCategoryList = async () => {
  try {
    const {
      data: {
        data: { banners, categories },
      },
    } = await ticketsProductsApi();
    return {
      banners,
      categories,
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

// 추가한 지역들을 불러옴
const getTownList = async () => {
  try {
    const {
      data: {
        data: { towns },
      },
    } = await getTicketLocationList();
    return {
      towns,
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

const getItemWishYn = async (productList, productListSeqs) => {
  try {
    const {
      data: { data },
    } = await getWishListApi(productListSeqs);

    const productListWithWish = productList.map(p => {
      const wishYn = data.findIndex(s => s === p.seq || s === p.productSeq);
      return {
        ...p,
        wishYn: wishYn > -1 ? 1 : 0,
      };
    });
    return productListWithWish;
  } catch (e) {
    return Promise.reject(e);
  }
};

// 추가한 지역의 상품 리스트를 불러옴
const getTownProductList = async (
  lat,
  lon,
  type,
  quantity,
  startDate,
  index,
) => {
  try {
    const {
      data: {
        data: { items: data },
      },
    } = await getTicketProductList(lat, lon, type, quantity, startDate, index);

    let items = data.map(p => ({
      ...p,
      wishYn: 0,
    }));

    if (getToken() && items.length > 0) {
      const productListSeqs = items.map(p => p.seq);
      items = await getItemWishYn(items, productListSeqs);
    }

    return {
      items,
      index,
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

// 스크롤 기억
export const setScroll = createAction(
  Actions.GET_TICKETCOUPON_SCROLL,
  height => {
    return { height };
  },
);

export const getTicketCouponBanners = createAction(
  Actions.GET_TICKETCOUPON_BANNER_CATEGORY,
  bannerAndCategoryList,
);

export const getTicketCouponTowns = createAction(
  Actions.GET_TICKETCOUPON_TOWNS,
  getTownList,
);

export const getTicketCouponTownProducts = createAction(
  Actions.GET_TICKETCOUPON_PRODUCTS,
  getTownProductList,
);

export const clearTicketCouponTownProducts = createAction(
  Actions.CLEAR_TICKETCOUPON_PRODUCTS,
);

export default handleActions(
  {
    [Actions.GET_TICKETCOUPON_SCROLL]: (state, { payload: { height } }) => {
      return { ...state, scrollHeight: height };
    },
    ...pender({
      type: Actions.GET_TICKETCOUPON_BANNER_CATEGORY,
      onSuccess: (state, action) => {
        const { banners, categories } = action.payload;
        return {
          ...state,
          isInit: true,
          banners,
          categories,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),
    ...pender({
      type: Actions.GET_TICKETCOUPON_TOWNS,
      onSuccess: (state, action) => {
        const { towns } = action.payload;
        if (towns.length > 0) {
          return {
            ...state,
            towns,
          };
        }
        return {
          ...state,
          towns,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),
    ...pender({
      type: Actions.GET_TICKETCOUPON_PRODUCTS,
      onSuccess: (state, action) => {
        const { items, index } = action.payload;
        return produce(state, d => {
          const draft = d;
          draft.isMore = items.length >= 30;
          draft.index = index + 1;
          draft.products = [...state.products, ...items];
        });
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),

    [Actions.CLEAR_TICKETCOUPON_PRODUCTS]: state => {
      return {
        ...state,
        towns: initialState.towns,
        index: initialState.index,
        isMore: initialState.isMore,
        products: initialState.products,
      };
    },
  },
  initialState,
);
