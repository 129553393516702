import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { createLogger } from 'redux-logger';
import ReduxThunk from 'redux-thunk';
import penderMiddleware from 'redux-pender';
import modules from './modules';

const configure = () => {
  // const middleware = applyMiddleware(createLogger(), ReduxThunk, penderMiddleware());
  const middleware = applyMiddleware(ReduxThunk, penderMiddleware());
  const store = createStore(
    modules,
    process.env.NODE_ENV === 'development'
      ? composeWithDevTools(middleware)
      : middleware,
  );
  return store;
};

export default configure;
