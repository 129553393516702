import { createAction, handleActions } from 'redux-actions';

const OPEN = 'auth/OPEN';
const CLOSE = 'auth/CLOSE';

const initialState = {
  show: false,
  has: () => {},
  not: () => {},
  close: () => {},
  redirectUrl: null,
  external: '',
};

export const open = createAction(OPEN);
export const close = createAction(CLOSE);

export default handleActions(
  {
    [OPEN]: (state, { payload }) => {
      return payload
        ? {
            ...state,
            show: true,
            has: payload.has || initialState.has,
            not: payload.not || initialState.not,
            close: payload.close || initialState.close,
            redirectUrl: payload.redirectUrl || initialState.redirectUrl,
            external: payload.external || initialState.external,
          }
        : { ...initialState, show: true };
    },
    [CLOSE]: () => {
      return { ...initialState };
    },
  },
  initialState,
);
