import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';

import { postWishList } from '../../api/base/product';

import { dayString } from '../../common/time';

export const Actions = {
  GET_MY_WISH_LIST: 'MY_WISH/GET_MY_WISH_LIST',
};

const initialState = {
  isGet: false,
  isMore: true,

  searchWord: '',
  startIndex: 0,
  searchQuantity: 20,
  searchStartDate: '',

  totalCount: 0,
  wishList: [],
  error: {
    code: 200,
    message: '',
  },
};

const getMyWishListF = async (
  searchWord = '',
  startIndex = 0,
  quantity = 20,
  startDate,
) => {
  try {
    const searchStartDate = startIndex === 0 ? dayString() : startDate;
    const {
      data: { data: list },
    } = await postWishList(searchWord, startIndex, quantity, searchStartDate);
    return {
      isGet: true,

      searchWord,
      startIndex,
      searchQuantity: quantity,
      searchStartDate,

      list,
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getMyWishList = createAction(
  Actions.GET_MY_WISH_LIST,
  getMyWishListF,
);

export default handleActions(
  {
    // init 정보 받아오기
    ...pender({
      type: Actions.GET_MY_WISH_LIST,
      onSuccess: (state, action) => {
        const {
          isGet,
          searchWord,
          startIndex,
          searchQuantity,
          searchStartDate,
          list,
        } = action.payload;

        const wishList = startIndex === 0 ? list : [...state.wishList, ...list];
        const isMore = list.length >= searchQuantity;
        const totalCount = wishList.length > 0 ? wishList[0].totalCnt : 0;
        return {
          ...state,
          isGet,
          isMore,
          searchWord,
          startIndex: startIndex + 1,
          searchQuantity,
          searchStartDate,
          totalCount,
          wishList,
        };
      },

      onFailure: (state, action) => {
        const { response } = action.payload;

        if (response) {
          const error = response.data.meta
            ? {
                code: response.data.meta.code,
                message: response.data.meta.message,
              }
            : {
                code: response.data.error.status,
                message: response.data.error.code,
              };

          return {
            ...state,
            error,
          };
        }

        return {
          ...state,
          error: {
            code: 999,
            message: 'Network Error',
          },
        };
      },
    }),
  },
  initialState,
);
