import * as Device from 'react-device-detect';

export const isDesktop = () => {
  return !Device.isMobile;
};

export const isMobile = () => {
  return Device.isMobile;
};

/**
 * android 판별
 */
export function isAndroid() {
  return Device.isAndroid;
}

/**
 * ios 판별
 */
export function isiPhone() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
}

export function isIOS() {
  return Device.isIOS;
}

export function isMobileSafari() {
  return Device.isMobileSafari;
}

export function isSafari() {
  return Device.isSafari;
}

export function isChrome() {
  return Device.isChrome;
}

export function isChromium() {
  return Device.isChromium;
}
