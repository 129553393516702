import axios from 'axios';
import config from '../config';
import { getHeaders } from '../apiUtil';

const baseURL = `${config.TICKETS_API_BASE_URL}`;

/**
 * 짝수 배너 + 티켓/쿠폰 카테고리별 상품 리스트 (각 최대 10개)
 */
export function ticketsProductsApi() {
  return axios({
    method: 'get',
    url: `/v2/tickets/total/products`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 배너 클릭 수 집계
 */
export function ticketsBannerClickApi(bannerSeq) {
  return axios({
    method: 'put',
    url: `/v2/tickets/banners/${bannerSeq}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내가 등록한 장소 리스트
 */
export function getTicketLocationList() {
  return axios({
    method: 'get',
    url: `/v1/tickets/places`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 우리동네의 티켓/쿠폰 상품 리스트
 */
export function getTicketProductList(
  latitude = '',
  longitude = '',
  radiusType = 0,
  searchQuantity = 30,
  searchStartDate = '',
  startIndex = 0,
) {
  const data = {
    latitude,
    longitude,
    radiusType,
    searchQuantity,
    searchStartDate,
    startIndex,
  };

  return axios({
    method: 'post',
    url: `/v2/tickets/places/total/products`,
    data,
    baseURL,
    headers: getHeaders(),
  });
}
