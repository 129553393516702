import { createAction, handleActions } from 'redux-actions';

const OPEN = 'toast/OPEN';
const PEND = 'toast/PEND';
const CLEAR = 'toast/CLEAR';

const initialState = {
  text: '',
  force: false,
  time: new Date(),
  setFunc: null,
};

export const open = createAction(
  OPEN,
  (text, force = false, setFunc = null) => {
    return {
      text,
      force,
      setFunc,
    };
  },
);
export const pend = createAction(PEND, text => text);
export const clear = createAction(CLEAR);

export default handleActions(
  {
    [OPEN]: (state, { payload: { text, force, setFunc } }) => {
      const time = new Date();
      return { ...state, text, force, time, setFunc };
    },
    [PEND]: (state, { payload: text }) => {
      return { ...state, text };
    },
    [CLEAR]: state => {
      const text = '';
      const setFunc = null;
      return { ...state, text, setFunc };
    },
  },
  initialState,
);
