import axios from 'axios';
import * as Config from '../config';

const unicro = {
  PUT_SELLER_CANCEL: 'PUT_SELLER_CANCEL',
  PUT_SELLER_ACCEPT: 'PUT_SELLER_ACCEPT',
  POST_RETURN_ACCEPT: 'POST_RETURN_ACCEPT',
  PUT_RETURN_CHECK: 'PUT_RETURN_CHECK',
  // 판매자 배송등록
  POST_SELLER_DELIVERY: 'POST_SELLER_DELIVERY',
  PUT_SELLER_DELIVERY: 'PUT_SELLER_DELIVERY',
  GET_ORDER_UNICRO_TOKEN: 'GET_ORDER_UNICRO_TOKEN',

  // 구매자 거래 취소 요청에대한 응답
  PUT_SELLER_CANCEL_ACCEPT: 'PUT_SELLER_CANCEL_ACCEPT',
  PUT_SELLER_CANCEL_REJECT: 'PUT_SELLER_CANCEL_REJECT',

  // 안전결제 배송등록 TEMP 데이터 및 RETURN URL
  SET_UNICRO_DELIVERY_TEMP: 'SET_UNICRO_DELIVERY_TEMP',
  SET_UNICRO_DELIVERY_RETURN_URL: 'SET_UNICRO_DELIVERY_RETURN_URL',

  // 구매자 반품 배송 등록
  POST_BUYER_RETURN_DELIVER: 'POST_BUYER_RETURN_DELIVER',
  PUT_BUYER_RETURN_DELIVER: 'PUT_BUYER_RETURN_DELIVER',

  // 배송지수정
  GET_SELLER_DELIVERY: 'GET_SELLER_DELIVERY',
  GET_BUYER_DELIVERY: 'GET_BUYER_DELIVERY',
};

// 판매자 거래 취소
export function putSellerCancel(param) {
  const promise = axios.put(
    `${Config.default.API_BASE_URL}/order/unicro/seller/cancel`,
    param,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 판매자 판매 수락
export function putSellerAccept(orderNo) {
  const promise = axios.put(
    `${Config.default.API_BASE_URL}/order/unicro/seller/accept/${orderNo}`,
    null,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 판매자 반품 수락
export function postReturnAccept(param) {
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/order/unicro/seller/return/accept`,
    param,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 판매자 반품 확인
export function putRetrunCheck(orderNo) {
  const promise = axios.put(
    `${Config.default.API_BASE_URL}/order/unicro/seller/delivery/${orderNo}`,
    null,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 판매자 배송등록
export function postSellerDelivery(param) {
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/order/unicro/seller/delivery`,
    param,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 판매자 배송등록
export function putSellerDelivery(param) {
  const promise = axios.put(
    `${Config.default.API_BASE_URL}/order/unicro/seller/delivery`,
    param,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 유니크로 판매자 배송정보수정 페이지 정보
export function getSellerDelivery(orderNo) {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/order/unicro/seller/delivery/${orderNo}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 유니크로 회원 토큰
export function getOrderUnicroToken() {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/order/unicro/token`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 유니크로 구매자의 구매취소요청 수락
export function putSellerCancelAccept(orderNo) {
  const promise = axios.put(
    `${
      Config.default.API_BASE_URL
    }/order/unicro/seller/cancel/accept/${orderNo}`,
    null,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 유니크로 구매자의 구매취소요청 거절
export function putSellerCancelReject(orderNo) {
  const promise = axios.put(
    `${
      Config.default.API_BASE_URL
    }/order/unicro/seller/cancel/reject/${orderNo}`,
    null,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 유니크로 배송 일시 데이터 저장
export function setUnicroDeliveryTemp(TempData) {
  return TempData;
}

// 유니크로 배송 return URL 저장
export function setUnicroDeliveryReturnURL(returnURL) {
  return returnURL;
}

// 구매자 반품 배송 등록
export function postBuyerReturnDelivery(param) {
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/order/unicro/buyer/return/delivery`,
    param,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 구매자 반품 배송 등록 수정
export function putBuyerReturnDelivery(param) {
  const promise = axios.put(
    `${Config.default.API_BASE_URL}/order/unicro/buyer/return/delivery`,
    param,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 구매자  배송정보수정 페이지 정보
export function getBuyerDelivery(orderNo) {
  const promise = axios.get(
    `${
      Config.default.API_BASE_URL
    }/order/unicro/buyer/return/delivery/${orderNo}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

export default unicro;
