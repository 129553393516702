import React from 'react';
import classNames from 'classnames';
import { shape, bool, func, string } from 'prop-types';

import PopUp from './PopUp';
import ShareForm from '../ShareForm';

import styles from './SharePopUp.module.scss';

const SharePopUp = ({
  show,
  shareData,
  close,
  className,
  footerClassName,
  buttonText,
}) => {
  return (
    <PopUp show={show} contentClassName={classNames(styles.share, className)}>
      <ShareForm shareData={shareData} className="pd_v30 bg_white" />
      <button
        type="button"
        onClick={close}
        className={classNames(styles.closeBtn, footerClassName)}
      >
        {buttonText}
      </button>
    </PopUp>
  );
};

SharePopUp.propTypes = {
  show: bool,
  shareData: shape().isRequired,
  close: func,
  className: string,
  footerClassName: string,
  buttonText: string,
};

SharePopUp.defaultProps = {
  show: false,
  close: () => {},
  className: '',
  footerClassName: '',
  buttonText: '취소',
};

export default SharePopUp;
