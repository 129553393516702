import {
  getQueryStringObj,
  removeSessionStorage,
  APP_USE_LOGIN_TEST,
} from './util';
import { isAndroidApp, getAppDatabyStorage } from './webView';

// localhost에서 로그인 가능하도록 처리
export const useLocalhostLogin = () => APP_USE_LOGIN_TEST === 'true';

export const getToken = () => localStorage.getItem('jn_access_token') || '';

export const getUserInfo = () => {
  if (localStorage.getItem('jn_login_info')) {
    const info = JSON.parse(localStorage.getItem('jn_login_info'));
    if (info.nickName) info.nickName = decodeURIComponent(info.nickName);
    if (info.profileImgUrl)
      info.profileImgUrl = decodeURIComponent(info.profileImgUrl);
    return info;
  }
  return {};
};

export const removeToken = () => {
  localStorage.removeItem('jn_access_token');
  localStorage.removeItem('jn_login_info');
  removeSessionStorage('jnAppInfo');
};

export const convertToken = (token = getToken()) => {
  try {
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
    }
    removeToken();
    return null;
  } catch (e) {
    return null;
  }
};

export const saveToken = token => {
  localStorage.setItem('jn_access_token', token);
  const info = JSON.stringify(convertToken(token));
  if (info.nickName) info.nickName = decodeURIComponent(info.nickName);
  if (info.profileImgUrl)
    info.profileImgUrl = decodeURIComponent(info.profileImgUrl);
  localStorage.setItem('jn_login_info', info);
};

export const getTokenValue = key => {
  try {
    const token = convertToken();
    if (token) {
      return token[key];
    }
    return null;
  } catch (e) {
    return null;
  }
};

// token 있을시 로그인값 저장
export const setParamLoginInfo = () => {
  // IOS, AOS userAgent로 비로그인(FE-512) 에서 통합
  const userAgentData = getAppDatabyStorage();

  if (userAgentData && userAgentData.Authorization) {
    saveToken(userAgentData.Authorization);
    return true;
  }

  // AOS 구 버전에서 param으로 토큰 전달, 강업 이후 삭제 필요
  const param = getQueryStringObj();
  const isToken = param.token;

  if (isToken) {
    saveToken(param.token);
    return true;
  }

  // 여기서부터는 앱에서 토큰 주지 않을 경우
  const token = getToken();
  // AOS 구 버전에서 모웹 내부 링크 이동시 토큰을 지우지 않기 위해 분기처리, 강업 이후 삭제 필요
  if (isAndroidApp() && !userAgentData && token) {
    return true;
  }

  // 토큰 지워버리기
  removeToken();
  return false;
};
