import { bindActionCreators } from 'redux';

import * as maintenanceActions from './modules/maintenance';
import * as errorsActions from './modules/errors';
import * as mainActions from './modules/main';
import * as initActions from './modules/init';

import * as categoryActions from './modules/category';
import * as loginActions from './modules/login';
import * as findInfoActions from './modules/findinfo';
import * as searchCategoryActions from './modules/searchCategory';
import * as searchActions from './modules/search';
import * as productActions from './modules/product';
import * as productV2Actions from './modules/productV2';
import * as allSalesActions from './modules/allSales';
import * as locationActions from './modules/location';
import * as myLocationActions from './modules/myLocation';
import * as myLocationV2Actions from './modules/myLocationV2';
import * as AddressActions from './modules/address';
import * as PaymentOldActions from './modules/paymentOld';
import * as PaymentActions from './modules/payment';
import * as MytagActions from './modules/mytag';
import * as MyWishListActions from './modules/myWishList';
import * as StandardPriceActions from './modules/standardPrice';

import * as AccountActions from './modules/account';
import * as EventActions from './modules/event';
import * as MypageActions from './modules/mypage';
import * as UnicroActions from './modules/unicro';
import * as DeliveryActions from './modules/delivery';
import * as FaqActions from './modules/faq';

import * as QnaActions from './modules/qna';
import * as UseinfoActions from './modules/useinfo';
import * as UserActions from './modules/user';
import * as ToastActions from './modules/toast';
import * as StyleActions from './modules/style';
import * as OverlayActions from './modules/overlay';
import * as NotifyActions from './modules/notify';
import * as BackupActions from './modules/backup';
import * as SubActionActions from './modules/subAction';
import * as followActions from './modules/follow';
import * as storeActions from './modules/store';
import * as TownActions from './modules/town';
import * as TicketCouponActions from './modules/ticketCoupon';
import * as MileageActions from './modules/mileage';
import * as AutoActions from './modules/auto';
import * as AutoSalesActions from './modules/autoSales';
import * as AutoRegistActions from './modules/autoRegist';
import * as AutoInstalmentActions from './modules/autoInstalment';

import * as CommentActions from './modules/comment';
import * as AlertActions from './modules/alert';
import * as AuthActions from './modules/auth';
import * as InviteFriendsActions from './modules/inviteFriends';
import * as LayoutActions from './modules/layout';

import * as userLoginInfoActions from './modules/userLoginInfo';
import * as deliveryPopUpActions from './modules/deliveryPopUp';
import * as orderHistoryActions from './modules/orderHistory';

import * as mobileFranchiseActions from './modules/mobileFranchise';
import * as geoLocationActions from './modules/geoLocation';

import store from './index';

const { dispatch } = store;

export const MaintenanceReducer = bindActionCreators(
  maintenanceActions,
  dispatch,
);
export const ErrorsReducer = bindActionCreators(errorsActions, dispatch);

export const MainReducer = bindActionCreators(mainActions, dispatch);
export const InitReducer = bindActionCreators(initActions, dispatch);

export const CategoryActions = bindActionCreators(categoryActions, dispatch);
export const LoginReducer = bindActionCreators(loginActions, dispatch);
export const FindInfoReducer = bindActionCreators(findInfoActions, dispatch);
export const SearchCategoryReducer = bindActionCreators(
  searchCategoryActions,
  dispatch,
);
export const SearchReducer = bindActionCreators(searchActions, dispatch);
export const ProductReducer = bindActionCreators(productActions, dispatch);
export const ProductV2Reducer = bindActionCreators(productV2Actions, dispatch);
export const AllSalesReducer = bindActionCreators(allSalesActions, dispatch);
export const LocationActions = bindActionCreators(locationActions, dispatch);
export const MyLocationActions = bindActionCreators(
  myLocationActions,
  dispatch,
);
export const MyLocationReducer = bindActionCreators(
  myLocationV2Actions,
  dispatch,
);
export const AddressReducer = bindActionCreators(AddressActions, dispatch);

export const PaymentOldReducer = bindActionCreators(
  PaymentOldActions,
  dispatch,
);
export const PaymentReducer = bindActionCreators(PaymentActions, dispatch);
export const MytagReducer = bindActionCreators(MytagActions, dispatch);
export const MyWishListReducer = bindActionCreators(
  MyWishListActions,
  dispatch,
);
export const StandardPriceReducer = bindActionCreators(
  StandardPriceActions,
  dispatch,
);
export const AccountReducer = bindActionCreators(AccountActions, dispatch);
export const EventReducer = bindActionCreators(EventActions, dispatch);
export const MypageReducer = bindActionCreators(MypageActions, dispatch);
export const UnicroReducer = bindActionCreators(UnicroActions, dispatch);
export const DeliveryReducer = bindActionCreators(DeliveryActions, dispatch);
export const FaqReducer = bindActionCreators(FaqActions, dispatch);
export const QnaReducer = bindActionCreators(QnaActions, dispatch);
export const UseinfoReducer = bindActionCreators(UseinfoActions, dispatch);
export const UserReducer = bindActionCreators(UserActions, dispatch);
export const ToastReducer = bindActionCreators(ToastActions, dispatch);
export const StyleReducer = bindActionCreators(StyleActions, dispatch);
export const OverlayReducer = bindActionCreators(OverlayActions, dispatch);
export const NotifyReducer = bindActionCreators(NotifyActions, dispatch);
export const BackupReducer = bindActionCreators(BackupActions, dispatch);
export const SubActionReducer = bindActionCreators(SubActionActions, dispatch);
export const FollowReducer = bindActionCreators(followActions, dispatch);
export const StoreReducer = bindActionCreators(storeActions, dispatch);
export const TownReducer = bindActionCreators(TownActions, dispatch);
export const TicketCouponReducer = bindActionCreators(
  TicketCouponActions,
  dispatch,
);
export const MileageReducer = bindActionCreators(MileageActions, dispatch);
export const AutoReducer = bindActionCreators(AutoActions, dispatch);
export const AutoSalesReducer = bindActionCreators(AutoSalesActions, dispatch);
export const AutoRegistReducer = bindActionCreators(
  AutoRegistActions,
  dispatch,
);
export const AutoInstalmentReducer = bindActionCreators(
  AutoInstalmentActions,
  dispatch,
);

export const CommentReducer = bindActionCreators(CommentActions, dispatch);
export const AlertReducer = bindActionCreators(AlertActions, dispatch);
export const AuthReducer = bindActionCreators(AuthActions, dispatch);
export const InviteFriendsReducer = bindActionCreators(
  InviteFriendsActions,
  dispatch,
);
export const LayoutReducer = bindActionCreators(LayoutActions, dispatch);

export const UserLoginInfoReducer = bindActionCreators(
  userLoginInfoActions,
  dispatch,
);

export const DeliveryPopUpReducer = bindActionCreators(
  deliveryPopUpActions,
  dispatch,
);

export const OrderHistoryReducer = bindActionCreators(
  orderHistoryActions,
  dispatch,
);

export const MobileFranchiseReducer = bindActionCreators(
  mobileFranchiseActions,
  dispatch,
);

export const GeoLocationReducer = bindActionCreators(
  geoLocationActions,
  dispatch,
);
