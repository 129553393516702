import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import produce from 'immer';
import moment from 'moment';
import * as Event from '../../actions/service/EventService';

export const Actions = {
  GET_EVENT_LIST: 'EVENT/GET_EVENT_LIST',
};

const initialState = {
  ongoingIndex: 0,
  ongoingEvents: null,
  endedIndex: 0,
  endedEvents: null,
  event_detail: {},
  event_join: {
    giftResult: 1, // 상품 결과 값 0: 당첨, 1: 응모완료, 2: 당첨안됨, 3: error
    eventGiftVO: {
      eventGiftName: '',
      eventGiftPoint: 0,
      eventGiftType: 0,
      winnerImage: '',
      winnerMessage: '',
    },
    errorMessage: '',
  },

  event_winner: [],

  ev2Log: {
    resultLog: 0,
    stampCount: 0,
    attendDayList: [],
    todayYn: false,
  },
};

export const getEventList = createAction(
  Actions.GET_EVENT_LIST,
  ({ isEnded, data, startIndex }) => ({ isEnded, data, startIndex }),
);

export const clear = createAction(Event.default.CLEAR);
export const getEventDetail = createAction(
  Event.default.GET_EVENT_DETAIL,
  Event.getEventDetail,
);
export const postEventJoin = createAction(
  Event.default.POST_EVENT_JOIN,
  Event.postEventJoin,
);

export const getEventWinner = createAction(
  Event.default.GET_EVENT_WINNER,
  Event.getEventWinner,
);
export const getEv2Attend = createAction(
  Event.default.GET_EV2_ATTEND,
  Event.getEv2Attend,
);
export const postEv2Attend = createAction(
  Event.default.POST_EV2_ATTEND,
  Event.postEv2Attend,
);
export const postEv2AttendTicket = createAction(
  Event.default.POST_EV2_ATTEND_TICKET,
  Event.postEv2AttendTicket,
);
export const setEv2AttendTicket = createAction(
  Event.default.SET_EV2_ATTEND_TICKET,
  Event.setEv2AttendTicket,
);
export const getEv2AttendYn = createAction(
  Event.default.GET_EV2_ATTEND_YN,
  Event.getEv2AttendYn,
);
export const updateEventDetailAuction = createAction(
  Event.default.UPDATE_EVENT_DETAIL_AUCTION,
);

export default handleActions(
  {
    [Actions.GET_EVENT_LIST]: (state, action) => {
      const { isEnded, data, startIndex } = action.payload;

      return produce(state, d => {
        const draft = d;
        if (data && data.length > 0) {
          if (isEnded) {
            draft.endedIndex = startIndex + 1;
            draft.endedEvents =
              state.endedEvents === null
                ? [...data]
                : [...state.endedEvents, ...data];
          } else {
            draft.ongoingIndex = startIndex + 1;
            draft.ongoingEvents =
              state.ongoingEvents === null
                ? [...data]
                : [...state.ongoingEvents, ...data];
          }
        }
      });
    },
    [Event.default.CLEAR]: () => {
      return { ...initialState };
    },
    ...pender({
      type: Event.default.GET_EVENT_DETAIL,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        //  data.data.eventType = 3
        return {
          ...state,
          event_detail: data.data,
        };
      },
      onFailure: (state, action) => {
        const result = {
          giftResult: 3, // 상품 결과 값 0: 당첨, 1: 응모완료, 2: 당첨안됨, 3: error
          eventGiftVO: {
            eventGiftName: '',
            eventGiftPoint: 0,
            eventGiftType: 0,
            winnerImage: '',
            winnerMessage: '',
          },
          errorMessage: action.payload.response.data.meta.message,
        };
        return {
          ...state,
          event_join: result,
        };
      },
    }),
    ...pender({
      type: Event.default.POST_EVENT_JOIN,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        if (!data.data.eventGiftVO) {
          data.data.eventGiftVO = {
            eventGiftName: '',
            eventGiftPoint: 0,
            eventGiftType: 0,
            winnerImage: '',
            winnerMessage: '',
          };
        }
        return {
          ...state,
          event_join: data.data,
        };
      },
      onFailure: (state, action) => {
        const result = {
          giftResult: 3, // 상품 결과 값 0: 당첨, 1: 응모완료, 2: 당첨안됨, 3: error
          eventGiftVO: {
            eventGiftName: '',
            eventGiftPoint: 0,
            eventGiftType: 0,
            winnerImage: '',
            winnerMessage: '',
          },
          errorMessage: action.payload.response.data.meta.message,
        };
        return {
          ...state,
          event_join: result,
        };
      },
    }),
    ...pender({
      type: Event.default.GET_EVENT_WINNER,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        data.data.forEach(dateformat => {
          dateformat.exposeStartDate = moment(
            dateformat.exposeStartDate,
          ).format('YYYY-MM-DD');
          dateformat.exposeEndDate = moment(dateformat.exposeEndDate).format(
            'YYYY-MM-DD',
          );
        });

        return {
          ...state,
          event_winner: data.data,
        };
      },
    }),

    ...pender({
      type: Event.default.GET_EV2_ATTEND,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          ev2Log: {
            ...state.ev2Log,
            ...data.data,
          },
        };
      },
    }),
    ...pender({
      type: Event.default.POST_EV2_ATTEND,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return data.data.resultLog === 0
          ? {
              ...state,
              ev2Log: {
                ...state.ev2Log,
                resultLog: 0,
                stampCount: state.ev2Log.stampCount + 1,
              },
            }
          : {
              ...state,
              ev2Log: {
                ...state.ev2Log,
                resultLog: data.data,
              },
            };
      },
    }),
    ...pender({
      type: Event.default.POST_EV2_ATTEND_TICKET,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          ev2Log: {
            ...state.ev2Log,
            resultLog: data.data,
          },
        };
      },
    }),
    [Event.default.SET_EV2_ATTEND_TICKET]: (state, action) => {
      return {
        ...state,
        ev2Log: {
          ...state.ev2Log,
          stampCount: state.ev2Log.stampCount + action.payload,
        },
      };
    },
    ...pender({
      type: Event.default.GET_EV2_ATTEND_YN,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        let todayYn = true;
        if (data.data === 0) todayYn = false;
        return {
          ...state,
          ev2Log: {
            ...state.ev2Log,
            todayYn,
          },
        };
      },
    }),

    [Event.default.UPDATE_EVENT_DETAIL_AUCTION]: (state, action) => {
      const data = action.payload;

      return produce(state, d => {
        const draft = d;
        draft.event_detail.auction = data;
      });
    },
  },
  initialState,
);
