import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import moment from 'moment';
import * as Faq from '../../actions/service/FaqService';

const initialState = {
  faq_category : [],
  faq_list : [],
  faq_search_list : [],
}


export const getFaqCategory = createAction(Faq.default.GET_FAQ_CATEGORY, Faq.getFaqCategory);
export const getFaqList = createAction(Faq.default.GET_FAQ_LIST, Faq.getFaqList);
export const getFaqSearch = createAction(Faq.default.GET_FAQ_SEARCH, Faq.getFaqSearch);
export const putFaqHit = createAction(Faq.default.PUT_FAQ_HIT, Faq.putFaqHit);
export const setFaqSearchInit = createAction(Faq.default.SET_FAQ_SEARCH_INIT, Faq.setFaqSearchInit);

export default handleActions({
  ...pender({
    type: Faq.default.GET_FAQ_CATEGORY,
    onSuccess: (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        faq_category : data.data,
      };
    },
  }),
  ...pender({
    type: Faq.default.GET_FAQ_LIST,
    onSuccess: (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        faq_list : data.data,
      };
    },
  }),
  ...pender({
    type: Faq.default.GET_FAQ_SEARCH,
    onSuccess: (state, action) => {
      const { data } = action.payload;
      // if(data.data.length> 0){
      //   const tt = [
      //     {
      //       "faqSeq": 9,
      //       "category": "스토어",
      //       "faqQuestion": "스토어 FAQㄹㅇ",
      //       "faqAnswer": "<p>스토어 FAQㅇㄹ</p>"
      //     }
      //   ]
      //   data.data = tt.concat(data.data)
      // }
      return {
        ...state,
        faq_search_list : data.data,
      };
    },
  }),
  ...pender({
    type: Faq.default.PUT_FAQ_HIT,
    onSuccess: (state, action) => {
      return {
        ...state
      };
    },
  }),


  [Faq.default.SET_FAQ_SEARCH_INIT]: (state, action) => {
    return {
      ...state,
      faq_search_list: []
    }
  },
}, initialState)