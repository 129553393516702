import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { string, shape } from 'prop-types';
import classNames from 'classnames';

import AuthButton from '../Auth/AuthButton';

const HeaderButton = ({ className, option }) => {
  const { isApp } = useSelector(({ userLoginInfo }) => userLoginInfo);

  const isShowButton = useMemo(() => {
    if (option === null) return false;
    if (!option.isOnlyApp) return true;

    return option.isOnlyApp && isApp;
  }, [option]);

  if (!isShowButton) return null;

  return option.isAuthed ? (
    <AuthButton
      className={className}
      onClick={option.onClick}
      disabled={option.disabled}
    >
      {option.name}
    </AuthButton>
  ) : (
    <button
      type="button"
      className={classNames(className, option.iconClassName)}
      onClick={option.onClick}
      disabled={option.disabled}
    >
      {option.name}
    </button>
  );
};

HeaderButton.propTypes = {
  className: string,
  option: shape({}),
};

HeaderButton.defaultProps = {
  className: '',
  option: null,
};
export default HeaderButton;
