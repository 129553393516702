/**
 * 완전 삭제 목표
 */
import axios from 'axios';
import * as Config from '../config';

const product = {
  CLEAR: 'product/CLEAR',

  POST_PRODUCT_WISH_SEQ: 'product/POST_PRODUCT_WISH_SEQ',
  POST_PRODUCT_WISH_CONTROL: 'product/POST_PRODUCT_WISH_CONTROL',
  GET_PRODUCT_OPTION_TAG: 'product/GET_PRODUCT_OPTION_TAG',
  POST_PRODUCT_OPTION_TAG_LIST: 'product/POST_PRODUCT_OPTION_TAG_LIST',
  POST_PRODUCT_HIDE: 'product/POST_PRODUCT_HIDE',
  POST_PRODUCT_DO_HIDE: 'product/POST_PRODUCT_DO_HIDE',

  SET_PRODECT_DETAIL_HEADER: 'product/SET_PRODECT_DETAIL_HEADER',
};

// 상품 찜하기
export function postProductWish(productSeq) {
  const token_request_info = {
    productSeq,
  };
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/product/wish/${productSeq}`,
    token_request_info,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 상품 찜 seq리스트
export function postProductWishSeq(productSeqs) {
  const param = {
    productSeqs,
  };
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/product/wish/seq`,
    param,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 상품 찜하기(ON/OFF)
export function postProductWishControl(productSeq, wishYn) {
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/product/wish/${productSeq}/${wishYn}`,
    '',
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 상품 태그
export function getProductOptionTag(productSeq) {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/product/option/tag/${productSeq}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 상품 태그 리스트
export function postProductOptionTagList(param) {
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/product/option/tag/list`,
    param,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 상품 숨김 리스트
export function postProductHide() {
  const vo = {
    searchQuantity: 0,
    searchWord: '',
    startIndex: 0,
  };
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/product/hide`,
    vo,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 상품 숨김 해제
export function postProductDoHide(productSeq, hiddenYn) {
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/product/hide/${productSeq}/${hiddenYn}`,
    '',
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

/**
 * 교체 필요함
 */
// 상품 판매완료 상태값 변경
export function putProductSaleStatusChange(productSeq, storeSeq, type) {
  const productStatus = 3; // productStatus = 3:판매완료
  const promise = axios
    .put(
      `${
        Config.default.API_BASE_URL
      }/product/sale/${productSeq}/${storeSeq}/${type}`,
      null,
      Config.getHeader(Config.default.JSON_TYPE),
    )
    .then(result => {
      return { ...result, parameter: { productSeq, productStatus } };
    });
  return promise;
}

export default product;

// util
export async function setWish(result) {
  const header =
    localStorage.getItem('jn_access_token') != null &&
    localStorage.getItem('jn_access_token') != ''
      ? Config.getHeader(Config.default.JSON_TYPE)
      : Config.default.JSON_HEADER;

  const item = result.data.data;
  const productSeqs = [item.productSeq];
  const wishResult = await axios.post(
    `${Config.default.API_BASE_URL}/product/wish/seq`,
    { productSeqs },
    header,
  );
  const wishList = wishResult.data.data;
  if (wishList.length && wishList[0] == item.productSeq) {
    item.wish.wishYn = 1;
  }

  return result;
}

// 상품상세 추가 헤더 부분
export function setProdectDetailHeader(isShow) {
  return isShow;
}
