import React, { forwardRef, useState, useCallback } from 'react';
import {
  string,
  func,
  shape,
  oneOfType,
  node,
  element,
  bool,
} from 'prop-types';
import classNames from 'classnames';

import useGoBack from '../../../common/Hook/useGoBack';
import useGA from '../../../common/GA';

import SharePopUp from '../popup/SharePopUp';
import IcArrowLine from '../Svg/IcArrowLine';

import { isEmptyObject } from '../../../common/util';

import HeaderButton from './HeaderButton';

import styles from './Header.module.scss';

const Header = forwardRef(
  (
    {
      title,
      shareIcon,
      shareData,
      children,
      displaynone,
      hidden,
      hiddenBg,
      hiddenLine,
      hiddenTitle,
      hiddenLeftIcon,
      link,
      onClick,
      className,
      titleLeft,
      leftBtnOption,
      rightBtnOption,
    },
    ref,
  ) => {
    const goBackHook = useGoBack();
    const { sendGA } = useGA();

    const [showModal, setShowModal] = useState(false);

    const shareModalShow = useCallback(() => {
      const { GA } = shareData;
      sendGA({
        ...GA,
        event: 'ga_event',
        action: '버튼클릭',
        label: '공유하기',
      });
      setShowModal(true);
    }, [shareData]);

    const closeShareModal = useCallback(() => {
      setShowModal(false);
    }, []);

    // title: 헤더 타이틀
    // goBack: 뒤로가기
    // shareData: 공유하기
    // link: bool값이 넘어오며 기본 false 입니다. 헤더 타이틀이 버튼 형태일 경우 true로 바꿔주시면 됩니다.
    // onClick: link 타이틀일 경우 함수 부르기

    return (
      <header
        className={classNames(
          styles.headerWrap,
          displaynone && styles.displaynone,
          hidden && styles.hidden,
          hiddenLine && styles.hiddenLine,
          hiddenBg && styles.hiddenBg,
          className,
        )}
        ref={ref}
      >
        <div className={classNames(styles.wrap)}>
          {/* 왼쪽 정렬의 레이아웃 */}
          <div
            className={classNames(styles.left, hiddenLeftIcon && styles.hidden)}
          >
            <HeaderButton
              className="iconBtn"
              option={{
                name: leftBtnOption.name || '뒤로가기',
                iconClassName: leftBtnOption.iconClassName || 'icBack',
                onClick: leftBtnOption.onClick || goBackHook,
                isAuthed: leftBtnOption.isAuthed || false,
                disabled: leftBtnOption.disabled || false,
                isOnlyApp: leftBtnOption.isOnlyApp || false,
              }}
            />
          </div>
          {/* 중앙 정렬의 레이아웃: 아래 div안으로 왼쪽 위치한 버튼들 넣어주세요 */}
          <div
            className={classNames(styles.center, titleLeft && styles.titleLeft)}
          >
            {/* 링크 헤더 타이틀인지 체크 */}
            {!link ? (
              <div
                className={classNames(
                  styles.title,
                  hiddenTitle && styles.hiddenTitle,
                )}
              >
                {title}
              </div>
            ) : (
              <button
                type="button"
                className={classNames(`relative pd_r20`, styles.title)}
                onClick={onClick}
              >
                {title}
                <IcArrowLine className="abs_v_r0" />
              </button>
            )}
          </div>
          {/* 오른쪽 정렬의 레이아웃: 아래 div안으로 오른쪽 위치한 버튼들 넣어주세요 */}
          <div className={styles.right}>
            {!isEmptyObject(shareData) && (
              <HeaderButton
                className="iconBtn"
                option={{
                  name: '공유하기',
                  iconClassName: shareIcon,
                  onClick: shareModalShow,
                  isAuthed: false,
                  disabled: false,
                  isOnlyApp: false,
                }}
              />
            )}
            {rightBtnOption !== null && (
              <HeaderButton
                className="iconBtn"
                option={{
                  name: rightBtnOption.name || '닫기',
                  iconClassName: rightBtnOption.iconClassName || 'icClose',
                  onClick: rightBtnOption.onClick || goBackHook,
                  isAuthed: rightBtnOption.isAuthed || false,
                  disabled: rightBtnOption.disabled || false,
                  isOnlyApp: rightBtnOption.isOnlyApp || false,
                }}
              />
            )}
          </div>
        </div>
        {children}
        <SharePopUp
          show={showModal}
          footerClassName={classNames(
            shareData.type === '내가게' && styles.storeShare,
          )}
          shareData={shareData}
          close={closeShareModal}
          buttonText="취소"
        />
      </header>
    );
  },
);

Header.propTypes = {
  title: oneOfType([node, element, string]),
  link: bool,
  shareIcon: string,
  shareData: shape({
    type: string,
    text: string,
    kakao: shape({
      title: string,
      description: string,
      imageUrl: string,
      link: string,
      btn_title: string,
    }),
    facebook: string,
    url: string,
  }),
  children: oneOfType([node, element]),
  onClick: func,
  displaynone: bool,
  hidden: bool,
  hiddenLine: bool,
  hiddenLeftIcon: bool,
  hiddenBg: bool,
  hiddenTitle: bool,
  className: string,
  titleLeft: bool,
  leftBtnOption: shape({
    name: string,
    iconClassName: string,
    onClick: func,
    isAuthed: bool,
    disabled: bool,
    isOnlyApp: bool,
  }),
  rightBtnOption: shape({
    name: string,
    iconClassName: string,
    onClick: func,
    isAuthed: bool,
    disabled: bool,
  }),
};

Header.defaultProps = {
  title: '중고나라',
  link: false,
  shareIcon: 'icShare',
  shareData: {},
  children: null,
  onClick: () => {},
  displaynone: false,
  hidden: false,
  hiddenLine: false,
  hiddenLeftIcon: false,
  hiddenBg: false,
  hiddenTitle: false,
  className: '',
  titleLeft: false,
  leftBtnOption: {},
  rightBtnOption: null,
};

export default Header;
