import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';

const Actions = {
  ONCHANGE_DELIVERY_TITLE: 'ADDRESS/ONCHANGE_DELIVERY_TITLE',
  ONCHANGE_DELIVERY_USER_NAME: 'ADDRESS/ONCHANGE_DELIVERY_USER_NAME',
  ONCHANGE_DELIVERY_PHONE_NO: 'ADDRESS/ONCHANGE_DELIVERY_PHONE_NO',
  ONCHANGE_DELIVERY_ADDRESS_DETAIL: 'ADDRESS/ONCHANGE_DELIVERY_ADDRESS_DETAIL',
  ONCHANGE_MAIN_ADDRESS_YN: 'ADDRESS/ONCHANGE_MAIN_ADDRESS_YN',
  CLEAR_REGIST_TEMP_ADDRESS: 'ADDRESS/CLEAR_TEMP_ADDRESS', // 사용자 배송지 등록시 임시 저장 클리어
  SET_SELECT_ADDRESS: 'ADDRESS/SET_SELECT_ADDRESS', // 사용자 배송지 등록시 주소 검색에서 선택된 값 전달
  SET_PAYMENT_TEMP_ADDRESS: 'ADDRESS/SET_PAYMENT_TEMP_ADDRESS', // 주문/결제 배송지 임시 저장
  CLEAR_PAYMENT_TEMP_ADDRESS: 'ADDRESS/CLEAR_PAYMENT_TEMP_ADDRESS', // 주문/결제 배송지 임시 저장 클리어
};

const initialState = {
  tempPaymentData: {
    deliverySeq: -1,
    deliveryTitle: '',
    deliveryUserName: '',
    deliveryUserPhoneNo: '',
    deliveryAddress: '',
    deliveryAddressDetail: '',
    deliveryPostNo: '',
    mainAddressYn: 1,
  },
  tempRegistData: {
    deliverySeq: -1,
    deliveryTitle: '',
    deliveryUserName: '',
    deliveryUserPhoneNo: '',
    deliveryAddress: '',
    deliveryAddressDetail: '',
    deliveryPostNo: '',
    mainAddressYn: 1,
  },
};

// ----------------------------------------------------

// 배송지 추가 > 주소 검색시 임시 저장 클리어
export const clearRegistTempAddress = createAction(
  Actions.CLEAR_REGIST_TEMP_ADDRESS,
);

// 배송지 추가 > 배송지명 변경 시
export const onChangeDeliveryTitle = createAction(
  Actions.ONCHANGE_DELIVERY_TITLE,
  title => title,
);

// 배송지 추가 > 유저 이름 변경 시
export const onChangeDeliveryUserName = createAction(
  Actions.ONCHANGE_DELIVERY_USER_NAME,
  name => name,
);

// 배송지 추가 > 연락처 변경 시
export const onChangeDeliveryPhoneNo = createAction(
  Actions.ONCHANGE_DELIVERY_PHONE_NO,
  phoneNO => phoneNO,
);

// 배송지 추가 > 사용자 상세주소 변경 시
export const onChangeDeliveryAddressDetail = createAction(
  Actions.ONCHANGE_DELIVERY_ADDRESS_DETAIL,
  address => address,
);

// 배송지 추가 > 대표 배송지 변경 시
export const onChangMainAddressYn = createAction(
  Actions.ONCHANGE_MAIN_ADDRESS_YN,
  ynBool => ynBool,
);
// 사용자 배송지 등록시 주소 검색에서 선택된 값 전달
export const setSelectAddress = createAction(
  Actions.SET_SELECT_ADDRESS,
  selectData => selectData,
);

// 주문/결제 또는 택배 관련 페이지 > 배송지 정보 임시 저장
export const setPaymentTempAddress = createAction(
  Actions.SET_PAYMENT_TEMP_ADDRESS,
  tempData => tempData,
);

// 주문/결제 > 배송지 정보 임시 저장 클리어
export const clearPaymentTempAddress = createAction(
  Actions.CLEAR_PAYMENT_TEMP_ADDRESS,
);

// ----------------------------------------------------

export default handleActions(
  {
    [Actions.CLEAR_REGIST_TEMP_ADDRESS]: state => {
      return produce(state, d => {
        const draft = d;
        draft.tempRegistData = initialState.tempRegistData;
      });
    },
    [Actions.ONCHANGE_DELIVERY_TITLE]: (state, action) => {
      const value = action.payload;
      return produce(state, d => {
        const draft = d;
        draft.tempRegistData.deliveryTitle = value;
      });
    },
    [Actions.ONCHANGE_DELIVERY_USER_NAME]: (state, action) => {
      const value = action.payload;
      return produce(state, d => {
        const draft = d;
        draft.tempRegistData.deliveryUserName = value;
      });
    },
    [Actions.ONCHANGE_DELIVERY_PHONE_NO]: (state, action) => {
      const value = action.payload;
      return produce(state, d => {
        const draft = d;
        draft.tempRegistData.deliveryUserPhoneNo = value;
      });
    },
    [Actions.ONCHANGE_DELIVERY_ADDRESS_DETAIL]: (state, action) => {
      const value = action.payload;
      return produce(state, d => {
        const draft = d;
        draft.tempRegistData.deliveryAddressDetail = value;
      });
    },
    [Actions.ONCHANGE_MAIN_ADDRESS_YN]: (state, action) => {
      const value = action.payload;
      return produce(state, d => {
        const draft = d;
        draft.tempRegistData.mainAddressYn = value;
      });
    },
    [Actions.SET_SELECT_ADDRESS]: (state, action) => {
      const { deliveryAddress, deliveryPostNo } = action.payload;

      return produce(state, d => {
        const draft = d;
        draft.tempRegistData.deliveryAddress = deliveryAddress;
        draft.tempRegistData.deliveryPostNo = deliveryPostNo;
      });
    },
    [Actions.SET_PAYMENT_TEMP_ADDRESS]: (state, action) => {
      const {
        deliverySeq,
        deliveryTitle,
        mainAddressYn,
        deliveryAddress,
        deliveryAddressDetail,
        deliveryPostNo,
        deliveryUserPhoneNo,
        deliveryUserName,
      } = action.payload;

      return produce(state, d => {
        const draft = d;
        draft.tempPaymentData.deliverySeq = deliverySeq;
        draft.tempPaymentData.deliveryTitle = deliveryTitle;
        draft.tempPaymentData.mainAddressYn = mainAddressYn;
        draft.tempPaymentData.deliveryAddress = deliveryAddress;
        draft.tempPaymentData.deliveryAddressDetail = deliveryAddressDetail;
        draft.tempPaymentData.deliveryPostNo = deliveryPostNo;
        draft.tempPaymentData.deliveryUserPhoneNo = deliveryUserPhoneNo;
        draft.tempPaymentData.deliveryUserName = deliveryUserName;
      });
    },
    [Actions.CLEAR_PAYMENT_TEMP_ADDRESS]: state => {
      return produce(state, d => {
        const draft = d;
        draft.tempPaymentData = initialState.tempPaymentData;
      });
    },
  },
  initialState,
);
