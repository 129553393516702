import React from 'react';
import { string, shape } from 'prop-types';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';

import useGA from '../../common/GA';

import { ToastReducer } from '../../reducers/actionCreators';

import Image from './Image/Image';

import { sendKakaoLink, sendFacebooklink } from '../../common/share';

import styles from './ShareForm.module.scss';

import kakaoIcon from '../../asset/img/icon/ic_share_kakao.png';
import faceBookIcon from '../../asset/img/icon/ic_share_facebook.png';
import urlIcon from '../../asset/img/icon/ic_share_url.png';

const ShareForm = ({ className, shareData }) => {
  const { sendGA } = useGA();
  const onClickSendKakaoLink = () => {
    const { GA, kakao } = shareData;

    sendGA({
      ...GA,
      event: 'ga_event',
      action: '공유하기',
      label: '카카오톡공유하기',
    });

    const shareUrl = `${kakao.link}${
      kakao.link.indexOf('?') > -1 ? '&' : '?'
    }brsource=kakao`;
    sendKakaoLink({
      ...kakao,
      link: shareUrl,
    });
  };

  // 페이스북 링크 전송(공유)
  const onClickSendFacebookLink = () => {
    const { GA, facebook } = shareData;
    sendGA({
      ...GA,
      event: 'ga_event',
      action: '공유하기',
      label: '페이스북공유하기',
    });
    const shareUrl = `${facebook}${
      facebook.indexOf('?') > -1 ? '&' : '?'
    }brsource=facebook`;
    sendFacebooklink(shareUrl);
  };

  const onClickCopyUrl = () => {
    const { GA, url } = shareData;
    sendGA({
      ...GA,
      event: 'ga_event',
      action: '공유하기',
      label: 'URL공유하기',
    });
    const shareUrl = `${url}${url.indexOf('?') > -1 ? '&' : '?'}brsource=url`;
    copy(shareUrl);
    ToastReducer.open('URL이 복사되었습니다.');
  };

  let shareTitle = `친구들에게 ${shareData.text} 공유하기!`;
  if (shareData.type === '내가게') {
    shareTitle = '해당 가게를 친구들에게 공유하기!';
  }

  return (
    <>
      {shareData.bannerUrl !== undefined && (
        <Image
          src={shareData.bannerUrl}
          alt={shareData.bannerTitle || ''}
          className={styles.bannerImg}
          showTitle
          noExpand
        />
      )}
      <div className={classNames(className, 'relative')}>
        {shareData.profileImg && (
          <Image
            src={shareData.profileImg}
            alt={shareData.storeName}
            className={styles.profileImg}
            circle
            noExpand
          />
        )}
        {shareData.type === '내가게' && (
          <div className={styles.shareStore}>
            <p className="f18 mt30">{shareData.storeName}</p>
            <p className="f16 mt10 c_gray">저의 가게에 놀러오세요!</p>
          </div>
        )}
        {typeof shareData.eventTitle !== 'undefined' && (
          <div className={styles.shareEvent}>
            <p className="f18">{shareData.eventTitle}</p>
          </div>
        )}
        <p className={styles.shareTitle}>{shareTitle}</p>
        <div className={styles.icons}>
          <button type="button" onClick={onClickSendKakaoLink}>
            <img
              className={styles.icon}
              src={kakaoIcon}
              alt="카카오톡공유하기"
            />
          </button>
          <button type="button" onClick={onClickSendFacebookLink}>
            <img
              className={styles.icon}
              src={faceBookIcon}
              alt="페이스북공유하기"
            />
          </button>
          <button type="button" onClick={onClickCopyUrl}>
            <img className={styles.icon} src={urlIcon} alt="URL공유하기" />
          </button>
        </div>
      </div>
    </>
  );
};

ShareForm.propTypes = {
  shareData: shape({
    type: string,
    storeName: string,
    profileImg: string,
    text: string,
    kakao: shape({
      title: string,
      description: string,
      imageUrl: string,
      link: string,
      btn_title: string,
    }),
    facebook: string,
    url: string,
  }).isRequired,
  className: string,
};

ShareForm.defaultProps = {
  className: '',
};

export default ShareForm;
