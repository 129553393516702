import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';

import {
  getCsInfo as getCsInfoApi,
  getCsInfoDetail as getCsInfoDetailApi,
} from '../../api/base/mypage';

export const Actions = {
  GET_CSINFO: 'CS/GET_CSINFO',
  GET_CSINFO_DETAIL: 'CS/GET_CSINFO_DETAIL',
};

// 이용안내
export const getCsInfo = createAction(Actions.GET_CSINFO, getCsInfoApi);
export const getCsInfoDetail = createAction(
  Actions.GET_CSINFO_DETAIL,
  getCsInfoDetailApi,
);

const initialState = {
  csInfoList: [],
  csInfoDetail: {},
};

export default handleActions(
  {
    ...pender({
      type: Actions.GET_CSINFO,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          csInfoList: data.data,
        };
      },
    }),
    ...pender({
      type: Actions.GET_CSINFO_DETAIL,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          csInfoDetail: {
            ...state.csInfoDetail,
            [data.data.seq]: data.data,
          },
        };
      },
    }),
  },
  initialState,
);
