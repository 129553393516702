import { isApp, interfaceCloseWebView } from '../webView';
import useHistory from './useHistory';

const useGoBack = () => {
  const {
    history,
    location: { pathname, search, state },
  } = useHistory();

  const goBack = (redirect = '/home', notBack = false) => {
    if (!notBack && state && state.from) history.goBack();
    else if (isApp()) {
      interfaceCloseWebView();
    } else if (!redirect) {
      history.goBack();
    } else if (typeof redirect === 'number') {
      history.go(redirect);
    } else if (typeof redirect === 'string') {
      history.push(redirect, {
        from: `${pathname}${search}`,
      });
    } else {
      history.push('/home', {
        from: `${pathname}${search}`,
      });
    }
  };

  return goBack;
};

export default useGoBack;
