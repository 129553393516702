import { createAction, handleActions } from 'redux-actions';

export const Actions = {
  SET_ERROR: 'error/SET_ERROR',
  CLEAR_ERROR: 'error/CLEAR_ERROR',
};

const initialState = {
  code: 200,
  message: '',
};

export const setError = createAction(Actions.SET_ERROR);
export const clearError = createAction(Actions.CLEAR_ERROR);

export default handleActions(
  {
    [Actions.SET_ERROR]: (state, { payload }) => {
      const { response } = payload;

      if (!response) {
        return {
          code: 500,
          message: 'Network Error',
        };
      }

      if (response.data.meta) {
        return {
          code: response.data.meta.code,
          message: response.data.meta.message,
        };
      }
      return {
        code: response.data.error.status,
        message: response.data.error.code,
      };
    },
    [Actions.CLEAR_ERROR]: () => {
      return { ...initialState };
    },
  },
  initialState,
);
