import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import moment from 'moment';
import * as Search from '../../actions/service/SearchTermService';
import { postFollow, deleteFollow } from '../../actions/service/MypageService';
import { searchDefault, filterDefault } from '../../common/const';

// 초기값
const initialState = {
  // MAIN
  my_location: { meta: {}, data: [], myLocationVO: undefined },

  real_time: { meta: {}, data: { list: [], rankDate: '' } },
  recent_term: { meta: {}, data: [], isSearchSaved: 1 },
  recommend_search: { meta: {}, data: [] },
  consign_search: { meta: {}, data: [] },
  recommend_goods_search: { meta: {}, data: [] },
  suggest_search: { meta: {}, data: [], index: -1 },
  my_tag_search: {}, // key: searchWord, value: {list, totalCnt}
  search_result: { meta: {}, data: [], searchWord: '' },
  main_banner: { meta: {}, data: [] },
  stash: { suggestSearchIndex: -1, searchWordVO: {}, listType: 0 },
  result: {
    list: [],
    searchWordVO: undefined,
    listType: 0,
    viewType: 'type1',
    categoryInfo: { categoryName: '', categoryProductCount: 0 },
  },

  onFailDeleteRecent: moment(),
  onFailDeleteRecentError: { message: '', code: 0 },

  search_store: { totalCnt: 0, list: [] },

  categoryData: {},
};

const initialFilter = {
  categoryDepth: 0,
  categorySeq: 0,
  color: ['ZZZZZZ'],
  condition: {
    options: {
      flawedYn: 0,
      fullPackageYn: 0,
      limitedEditionYn: 0,
    },
    productCondition: -1,
  },
  locations: [],
  maxPrice: 0,
  minPrice: 0,
  platformType: 1, // 사업별 구분, 1: 중고나라, 2: 오토, 3: 평화시장
  preferredTrade: 0,
  sortEndDate: '',
  sortStartDate: '',
  state: -1,
  categorySeqList: [],
  productSectionType: 0, // 0 고정, 0: 해당없음, 10: 새상품, 20: 구제상품: 30: 중고폰
};

// action
const searchAction = {
  INIT_SEARCH: 'search/INIT_SEARCH',
  CLEAR_SEARCH: 'search/CLEAR_SEARCH',
  SET_TOTAL_COUNT: 'search/SET_TOTAL_COUNT',
  TOGGLE_VIEW_TYPE: 'search/TOGGLE_VIEW_TYPE',
  SET_SORT_TYPE: 'search/SET_SORT_TYPE',
  SET_FILTER: 'search/SET_FILTER',
  SET_SEARCH_WORD: 'search/SET_SEARCH_WORD',
  SET_CATEGORY: 'search/SET_CATEGORY',
  APPEND: 'search/APPEND',

  MY_LOCATION: 'search/MY_LOCATION',
  MY_LOCATION2: 'search/MY_LOCATION2',
  POPULAR_KEYWORD: 'search/POPULAR_KEYWORD',
  CONSIGN_SELLER: 'search/CONSIGN_SELLER',
  MY_TAG_KEYWORD: 'search/MY_TAG_KEYWORD',
  RECENT_KEYWORD: 'search/RECENT_KEYWORD',
  RECOMMEND_KEYWORD: 'search/RECOMMEND_KEYWORD',
  SUGGEST_KEYWORD: 'search/SUGGEST_KEYWORD',
  ALL_CATEGORY: 'search/ALL_CATEGORY',
  RECOMMEND_GOODS: 'search/RECOMMEND_GOODS',
  PEACE_GOODS: 'search/PEACE_GOODS',

  CATEGORY_DATA: 'search/CATEGORY_DATA',
};

// 액션 생성자 비동기 처리
export const categoryData = createAction(
  searchAction.CATEGORY_DATA,
  name => name,
);
export const initSearch = createAction(searchAction.INIT_SEARCH, name => name);
export const clearSearch = createAction(
  searchAction.CLEAR_SEARCH,
  name => name,
);
export const setTotalCount = createAction(
  searchAction.SET_TOTAL_COUNT,
  (name, totalCount) => ({ name, totalCount }),
);
export const toggleViewType = createAction(
  searchAction.TOGGLE_VIEW_TYPE,
  (name, type) => ({
    name,
    type,
  }),
);
export const setSortType = createAction(
  searchAction.SET_SORT_TYPE,
  (name, sort) => ({
    name,
    sort,
  }),
);
export const setFilter = createAction(
  searchAction.SET_FILTER,
  (name, filter) => ({
    name,
    filter,
  }),
);
export const setSearchWord = createAction(
  searchAction.SET_SEARCH_WORD,
  (name, searchWord) => ({ name, searchWord }),
);
export const setCategory = createAction(
  searchAction.SET_CATEGORY,
  (name, categoryInfo, categoryName) => ({ name, categoryInfo, categoryName }),
);
export const append = createAction(searchAction.APPEND, name => name);

export const myLocation = createAction(
  searchAction.MY_LOCATION,
  Search.myLocation,
);
export const myLocation2 = createAction(
  searchAction.MY_LOCATION2,
  Search.myLocation2,
);
export const popularKeyword = createAction(
  searchAction.POPULAR_KEYWORD,
  Search.getSearchList,
);
export const consignSeller = createAction(
  searchAction.CONSIGN_SELLER,
  Search.consignSeller,
);
export const myTagKeyword = createAction(
  searchAction.MY_TAG_KEYWORD,
  Search.myTagKeyword,
);
export const recentKeyword = createAction(
  searchAction.RECENT_KEYWORD,
  Search.getSearchList,
);
export const recommendKeyword = createAction(
  searchAction.RECOMMEND_KEYWORD,
  Search.getSearchList,
);
export const suggestKeyword = createAction(
  searchAction.SUGGEST_KEYWORD,
  Search.getSearchList,
);
export const allCategory = createAction(
  searchAction.ALL_CATEGORY,
  Search.getSearchList,
);
export const recommendGoods = createAction(
  searchAction.RECOMMEND_GOODS,
  Search.recommendGoods,
);
export const peaceGoods = createAction(
  searchAction.PEACE_GOODS,
  Search.peaceGoods,
);

// export const deleteItem = createAction(action.DELETE_ITEM, productSeq => productSeq);

// MAIN
// export const myLocationSearch = createAction(Search.default.MY_LOCATION_SEARCH, Search.myLocationSearch);

export const real_search_term = createAction(
  Search.default.REAL_TIME_SEARCH,
  Search.real_time_search,
);
export const init_recent = createAction(
  Search.default.INIT_RECENT,
  Search.init_recent,
);
export const toggle_recent = createAction(Search.default.TOGGLE_RECENT);
export const delete_recent = createAction(
  Search.default.DELETE_RECENT,
  Search.delete_recent,
);
export const clearRecent = createAction(
  Search.default.CLEAR_RECENT,
  Search.clearRecent,
);
export const recentSearch = createAction(
  Search.default.RECENT_SEARCH,
  Search.recentSearch,
);
export const elastic_recommend_search = createAction(
  Search.default.RECOMMEND_SEARCH,
  Search.elastic_recommend_search,
);
export const consignSearch = createAction(
  Search.default.CONSIGN_SEARCH,
  Search.consignSearch,
);
export const recommendGoodsSearch = createAction(
  Search.default.RECOMMEND_GOODS_SEARCH,
  Search.recommendGoodsSearch,
);
export const elastic_suggest_search = createAction(
  Search.default.SUGGEST_SEARCH,
  Search.elastic_suggest_search,
);
export const elastic_search_type2 = createAction(
  Search.default.KEYWORD_SEARCH,
  Search.getSearchList,
);
export const elastic_search_type3 = createAction(
  Search.default.CATEGORY_SEARCH,
  Search.elastic_search_type3,
);
export const myTagSearch = createAction(
  Search.default.MY_TAG_SEARCH,
  Search.myTagSearch,
);
export const main_banner_search = createAction(
  Search.default.MAIN_BANNER_SEARCH,
  Search.main_banner_search,
);
export const suggest_select = createAction(
  Search.default.SUGGEST_SELECT,
  index => index,
);
export const keyword_set = createAction(
  Search.default.KEYWORD_SET,
  searchWord => searchWord,
);
export const stashSearchWordVo = createAction(
  Search.default.STASH_SEARCH_WORD_VO,
  searchWordVO => searchWordVO,
);
export const setViewType = createAction(Search.default.SET_VIEW_TYPE);
export const setCategoryInfo = createAction(
  Search.default.SET_CATEGORY_INFO,
  categoryInfo => categoryInfo,
);
export const searchStore = createAction(
  Search.default.SEARCH_STORE,
  Search.searchStore,
);

// 나중에 그냥 redux에서 빼기 -> 지금은 일정
export const postFollowSearch = createAction(
  Search.default.POST_FOLLOW,
  postFollow,
);
export const deleteFollowSearch = createAction(
  Search.default.DELETE_FOLLOW,
  deleteFollow,
);

export default handleActions(
  {
    [searchAction.CATEGORY_DATA]: (prevState, action) => {
      const state = { ...prevState };
      state.categoryData = action.payload;
      return state;
    },
    [searchAction.INIT_SEARCH]: (prevState, action) => {
      const state = { ...prevState };
      const name = action.payload;

      const searchObj = {
        name,
        list: [],
        searchWordVO: {
          ...searchDefault,
          filter: {
            ...filterDefault,
            color: ['ZZZZZZ'],
          },
        },
        listType: 2,
        viewType: 'type2',
        totalCnt: 0,
        callback: undefined,
        parameter: {},
        isAppend: false,
        categoryName: undefined,
        moment: moment(),
      };

      searchObj.searchWordVO.filter.searchStartDate = moment().format(
        'YYYY-MM-DD HH:mm:ss',
      );

      // 검색 이슈로 삭제됨 20-01-16 // 호민누나 바보
      // searchObj.searchWordVO.filter.sortEndDate = moment().format('YYYY-MM-DD');
      // searchObj.searchWordVO.filter.sortStartDate = '2018-07-04';
      searchObj.searchWordVO.searchWord = '';
      state[name] = searchObj;
      return state;
    },

    [searchAction.CLEAR_SEARCH]: (prevState, action) => {
      const state = { ...prevState };

      const name = action.payload;
      delete state[name];

      return state;
    },

    [searchAction.SET_TOTAL_COUNT]: (prevState, action) => {
      const state = { ...prevState };
      const { name, totalCount } = action.payload;

      state[name].totalCnt = totalCount;

      return state;
    },

    [searchAction.TOGGLE_VIEW_TYPE]: (prevState, action) => {
      const state = { ...prevState };
      const { name, type } = action.payload;
      state[name] = { ...state[name] };
      state[name].viewType = type;
      state[name].moment = moment();
      return state;
    },

    [searchAction.SET_SORT_TYPE]: (prevState, action) => {
      const state = { ...prevState };
      const { name, sort } = action.payload;

      const searchObj = state[name];
      searchObj.searchWordVO.sort = sort;

      searchObj.searchWordVO.startIndex = 0;
      searchObj.isAppend = false;
      searchObj.moment = moment();

      window.setTimeout(searchObj.callback, 1);

      return state;
    },

    [searchAction.SET_FILTER]: (prevState, action) => {
      const state = { ...prevState };
      const { name, filter } = action.payload;

      const searchObj = state[name];
      searchObj.searchWordVO.filter = {
        ...state[name].searchWordVO.filter,
        ...filter,
      };

      searchObj.searchWordVO.startIndex = 0;
      searchObj.isAppend = false;
      searchObj.moment = moment();

      window.setTimeout(searchObj.callback, 1);

      return state;
    },

    [searchAction.SET_SEARCH_WORD]: (prevState, action) => {
      const state = { ...prevState };
      const { name, searchWord } = action.payload;

      const searchObj = state[name];
      searchObj.searchWordVO = {
        ...searchDefault,
        filter: {
          ...filterDefault,
          color: ['ZZZZZZ'],
        },
      };
      searchObj.searchWordVO.searchWord = searchWord;
      searchObj.categoryName = undefined;
      searchObj.isAppend = false;
      searchObj.moment = moment();

      window.setTimeout(searchObj.callback, 1);

      return state;
    },

    [searchAction.SET_CATEGORY]: (prevState, action) => {
      const state = { ...prevState };
      const { name, categoryInfo, categoryName } = action.payload;

      const searchObj = state[name];
      searchObj.categoryName = categoryName;
      searchObj.searchWordVO.filter = initialFilter;
      searchObj.searchWordVO.filter.categoryDepth = categoryInfo.categoryDepth;
      searchObj.searchWordVO.filter.categorySeq = categoryInfo.categorySeq;
      searchObj.searchWordVO.startIndex = 0;
      searchObj.isAppend = false;
      searchObj.moment = moment();

      window.setTimeout(searchObj.callback, 1);

      return state;
    },

    [searchAction.APPEND]: (prevState, action) => {
      const state = { ...prevState };
      const name = action.payload;

      const searchObj = state[name];
      searchObj.searchWordVO.startIndex++;
      searchObj.isAppend = true;
      searchObj.moment = moment();

      window.setTimeout(searchObj.callback);

      return state;
    },

    ...pender({
      type: searchAction.MY_LOCATION,
      onSuccess: (prevState, action) => {
        const state = { ...prevState };

        const searchObj = action.payload;
        const { name, isAppend } = searchObj;
        const prevSearchObj = { ...state[name] };
        if (isAppend) {
          searchObj.list = [...prevSearchObj.list, ...searchObj.list];
        }
        state[name] = { ...prevSearchObj, ...searchObj };

        return state;
      },
    }),
    ...pender({
      type: searchAction.MY_LOCATION2,
      onSuccess: (prevState, action) => {
        const state = { ...prevState };

        const searchObj = action.payload;
        const { name, isAppend } = searchObj;
        const prevSearchObj = { ...state[name] };
        if (isAppend) {
          searchObj.list = [...prevSearchObj.list, ...searchObj.list];
        }
        state[name] = { ...prevSearchObj, ...searchObj };

        return state;
      },
    }),
    ...pender({
      type: searchAction.POPULAR_KEYWORD,
      onSuccess: (prevState, action) => {
        const state = { ...prevState };

        const searchObj = action.payload;
        const { name, isAppend } = searchObj;
        const prevSearchObj = { ...state[name] };
        if (isAppend) {
          searchObj.list = [...prevSearchObj.list, ...searchObj.list];
        }
        state[name] = { ...prevSearchObj, ...searchObj };

        return state;
      },
    }),
    ...pender({
      type: searchAction.CONSIGN_SELLER,
      onSuccess: (prevState, action) => {
        const state = { ...prevState };

        const searchObj = action.payload;
        const { name, isAppend } = searchObj;
        const prevSearchObj = { ...state[name] };
        if (isAppend) {
          searchObj.list = [...prevSearchObj.list, ...searchObj.list];
        }
        state[name] = { ...prevSearchObj, ...searchObj };

        return state;
      },
    }),
    ...pender({
      type: searchAction.MY_TAG_KEYWORD,
      onSuccess: (prevState, action) => {
        const state = { ...prevState };

        const searchObj = action.payload;
        const { name, isAppend } = searchObj;
        const prevSearchObj = { ...state[name] };
        if (isAppend) {
          searchObj.list = [...prevSearchObj.list, ...searchObj.list];
        }
        state[name] = { ...prevSearchObj, ...searchObj };

        return state;
      },
    }),
    ...pender({
      type: searchAction.RECENT_KEYWORD,
      onSuccess: (prevState, action) => {
        const state = { ...prevState };

        const searchObj = action.payload;
        const { name, isAppend } = searchObj;
        const prevSearchObj = { ...state[name] };
        if (isAppend) {
          searchObj.list = [...prevSearchObj.list, ...searchObj.list];
        }
        state[name] = { ...prevSearchObj, ...searchObj };

        return state;
      },
    }),
    ...pender({
      type: searchAction.RECOMMEND_KEYWORD,
      onSuccess: (prevState, action) => {
        const state = { ...prevState };

        const searchObj = action.payload;
        const { name, isAppend } = searchObj;
        const prevSearchObj = { ...state[name] };
        if (isAppend) {
          searchObj.list = [...prevSearchObj.list, ...searchObj.list];
        }
        state[name] = { ...prevSearchObj, ...searchObj };

        return state;
      },
    }),
    ...pender({
      type: searchAction.SUGGEST_KEYWORD,
      onSuccess: (prevState, action) => {
        const state = { ...prevState };

        const searchObj = action.payload;
        const { name, isAppend } = searchObj;
        const prevSearchObj = { ...state[name] };
        if (isAppend) {
          searchObj.list = [...prevSearchObj.list, ...searchObj.list];
        }
        state[name] = { ...prevSearchObj, ...searchObj };

        return state;
      },
    }),
    ...pender({
      type: searchAction.ALL_CATEGORY,
      onSuccess: (prevState, action) => {
        const state = { ...prevState };

        const searchObj = action.payload;
        const { name, isAppend } = searchObj;
        const prevSearchObj = { ...state[name] };
        if (isAppend) {
          searchObj.list = [...prevSearchObj.list, ...searchObj.list];
        }
        state[name] = { ...prevSearchObj, ...searchObj };

        return state;
      },
    }),
    ...pender({
      type: searchAction.RECOMMEND_GOODS,
      onSuccess: (prevState, action) => {
        const state = { ...prevState };

        const searchObj = action.payload;
        const { name, isAppend } = searchObj;
        const prevSearchObj = { ...state[name] };
        if (isAppend) {
          searchObj.list = [...prevSearchObj.list, ...searchObj.list];
        }
        state[name] = { ...prevSearchObj, ...searchObj };

        return state;
      },
    }),
    ...pender({
      type: searchAction.PEACE_GOODS,
      onSuccess: (prevState, action) => {
        const state = { ...prevState };

        const searchObj = action.payload;
        const { name, isAppend } = searchObj;
        const prevSearchObj = { ...state[name] };
        if (isAppend) {
          searchObj.list = [...prevSearchObj.list, ...searchObj.list];
        }
        state[name] = { ...prevSearchObj, ...searchObj };
        return state;
      },
    }),

    ...pender({
      type: Search.default.REAL_TIME_SEARCH,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          real_time: data,
        };
      },
    }),
    ...pender({
      type: Search.default.INIT_RECENT,
      onSuccess: (state, { payload: { data } }) => {
        const recent_term = data;
        recent_term.isSearchSaved =
          parseInt(localStorage.isSearchSaved) == 0 ? 0 : 1;
        return { ...state, recent_term };
      },
    }),
    [Search.default.TOGGLE_RECENT]: oldState => {
      const state = { ...oldState };

      const isSearchSaved = state.recent_term.isSearchSaved == 0 ? 1 : 0;
      localStorage.isSearchSaved = isSearchSaved;
      state.recent_term.isSearchSaved = isSearchSaved;

      return state;
    },
    ...pender({
      type: Search.default.DELETE_RECENT,
      onSuccess: state => {
        return state;
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          onFailDeleteRecent: moment(),
          onFailDeleteRecentError: data.meta,
        };
      },
    }),
    ...pender({
      type: Search.default.CLEAR_RECENT,
      onSuccess: state => {
        return state;
      },
    }),
    ...pender({
      type: Search.default.RECENT_SEARCH,
      onSuccess: (prevState, action) => {
        const state = { ...prevState };
        state.result.categoryInfo = action.payload.categoryInfo;
        return state;
      },
    }),
    ...pender({
      type: Search.default.RECOMMEND_SEARCH,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          recommend_search: data,
        };
      },
    }),
    ...pender({
      type: Search.default.CONSIGN_SEARCH,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          consign_search: data,
        };
      },
    }),
    ...pender({
      type: Search.default.RECOMMEND_GOODS_SEARCH,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          recommend_goods_search: data,
        };
      },
    }),
    ...pender({
      type: Search.default.SUGGEST_SEARCH,
      onSuccess: (state, action) => {
        const { data } = action.payload;

        const list = data.data;
        list.forEach(item => {
          const categoryDepth =
            item.category3Seq != item.category2Seq
              ? 3
              : item.category2Seq != item.category1Seq
              ? 2
              : 1;
          const categorySeq = item[`category${categoryDepth}Seq`];
          item.categoryInfo = { categoryDepth, categorySeq };
        });

        const categoryAll = {
          category1Seq: 0,
          category2Seq: 0,
          category3Seq: 0,
          categoryName: '카테고리 전체',
          prodCnt: list.reduce((item, acc) => item.prodCnt + acc, 0),
          categoryInfo: { categoryDepth: 0, categorySeq: 0 },
        };

        data.data = [categoryAll, ...list];

        return {
          ...state,
          suggest_search: { ...state.suggest_search, ...data },
        };
      },
    }),
    ...pender({
      type: Search.default.KEYWORD_SEARCH,
      onSuccess: (prevState, action) => {
        const list = action.payload.data.data;
        const state = { ...prevState };
        state.result = { ...state.result, ...action.payload.parameter, list };

        return state;
      },
    }),
    ...pender({
      type: Search.default.CATEGORY_SEARCH,
      onSuccess: (prevState, action) => {
        const list = action.payload.data.data;
        const state = { ...prevState };

        state.result = { ...state.result, ...action.payload.parameter, list };

        return state;
      },
    }),
    ...pender({
      type: Search.default.MY_TAG_SEARCH,
      onSuccess: (prevState, { payload: list }) => {
        const state = { ...prevState };

        list.map(result => {
          const { searchWord } = result.parameter;
          const item = {
            list: result.data.data,
            totalCnt: result.totalCnt,
          };
          state.my_tag_search[searchWord.toUpperCase()] = item;
        });

        return state;
      },
    }),
    ...pender({
      type: Search.default.MAIN_BANNER_SEARCH,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          main_banner: data,
        };
      },
    }),
    ...pender({
      type: Search.default.SEARCH_STORE,
      onSuccess: (state, action) => {
        const {
          data: {
            data: { items },
          },
        } = action.payload;
        // 일단 모웹에서는 광고 가게는 뺀다.
        const notAd = items.filter(i => i.ad === 0);
        const totalCnt = notAd.length;
        const search_store = { totalCnt, list: notAd };
        return { ...state, search_store };
      },
    }),
    [Search.default.SUGGEST_SELECT]: (prevState, { payload: index }) => {
      const suggestItem = prevState.suggest_search.data[index];

      const categoryInfo = {
        categoryName: suggestItem.categoryName,
        categoryProductCount: suggestItem.prodCnt,
      };

      const state = { ...prevState };
      state.result.categoryInfo = categoryInfo;
      return state;
    },
    [Search.default.KEYWORD_SET]: (state, { payload: searchWord }) => {
      return {
        ...state,
        search_result: { ...state.search_result, searchWord },
      };
    },
    [Search.default.STASH_SEARCH_WORD_VO]: (
      state,
      { payload: searchWordVO },
    ) => {
      return { ...state, stash: { ...state.stash, searchWordVO } };
    },
    [Search.default.SET_VIEW_TYPE]: prevState => {
      const state = { ...prevState };
      state.result.viewType =
        state.result.viewType == 'type1'
          ? 'type2'
          : state.result.viewType == 'type2'
          ? 'type1'
          : '';
      return state;
    },
    [Search.default.SET_CATEGORY_INFO]: (
      prevState,
      { payload: categoryInfo },
    ) => {
      const state = { ...prevState };
      state.result.categoryInfo = categoryInfo;
      return state;
    },

    // 나중에 다 뺄것
    ...pender({
      type: Search.default.POST_FOLLOW,
      onSuccess: (state, action) => {
        const requestUrl = action.payload.config.url.split('/');

        const storeSeq = Number(requestUrl[requestUrl.length - 1]);

        const newSearchStore = { ...state.search_store };
        const findStore = newSearchStore.list.findIndex(
          l => l.storeSeq === storeSeq,
        );

        if (findStore > -1) {
          newSearchStore.list[findStore].followingYn = 1;
          return { ...state, search_store: newSearchStore };
        }
        return { ...state };
      },
    }),

    ...pender({
      type: Search.default.DELETE_FOLLOW,
      onSuccess: (state, action) => {
        const requestUrl = action.payload.config.url.split('/');

        const storeSeq = Number(requestUrl[requestUrl.length - 1]);

        const newSearchStore = { ...state.search_store };
        const findStore = newSearchStore.list.findIndex(
          l => l.storeSeq === storeSeq,
        );

        if (findStore > -1) {
          newSearchStore.list[findStore].followingYn = 0;
          return { ...state, search_store: newSearchStore };
        }
        return { ...state };
      },
    }),
  },
  initialState,
);
