import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import produce from 'immer';

import { getLocationProductList as getLocationProductListApi } from '../../api/search/search';
import { getWishList as getWishListApi } from '../../api/base/product';

import { getToken } from '../../common/auth';

import { orderByList } from '../../common/const';

export const Actions = {
  GET_TOWN_COORDINATE: 'TOWN/GET_TOWN_COORDINATE',
  RESET_TOWN_COORDINATE: 'TOWN/RESET_TOWN_COORDINATE',
  GET_TOWN_PRODUCTLIST: 'TOWN/GET_TOWN_PRODUCTLIST',

  CHANGE_TOWN_PRODUCT_WISH: 'TOWN/CHANGE_TOWN_PRODUCT_WISH',

  CLEAR_TOWN_STORE: 'TOWN/CLEAR_TOWN_STORE',

  INIT_TOWN_STORE: 'TOWN/INIT_TOWN_STORE',
};

const initialState = {
  lat: -1,
  lon: -1,
  locationName: '',
  rangCount: 0,
  index: 0,
  isMore: true,
  totalSize: 0,
  productList: [],
  error: {
    code: 200,
    message: '',
  },
};

const getItemWishYn = async (productList, productListSeqs) => {
  try {
    const {
      data: { data },
    } = await getWishListApi(productListSeqs);

    const productListWithWish = productList.map(p => {
      const wishYn = data.findIndex(s => s === p.seq || s === p.productSeq);
      return {
        ...p,
        productWishYn: wishYn > -1 ? 1 : 0,
      };
    });
    return productListWithWish;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getProductList = async ({
  lat,
  lon,
  rangeType = 0,
  filter = {},
  order = orderByList[0],
  index = 0,
}) => {
  try {
    const {
      data: { data },
    } = await getLocationProductListApi(
      lat,
      lon,
      rangeType,
      filter,
      order,
      index,
    );

    let items = data.items.map(p => ({
      ...p,
      productWishYn: 0,
    }));

    if (getToken() && items.length > 0) {
      const productListSeqs = items.map(p => p.seq);
      items = await getItemWishYn(items, productListSeqs);
    }

    return {
      index,
      items,
      totalSize: data.totalSize,
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

// const getProductList = async (
//   lat,
//   lon,
//   rangeType = 0,
//   filter = {},
//   order = orderByList[0],
//   index = 0,
// ) => {
//   try {
//     const {
//       data: {
//         data: { items, totalSize },
//       },
//     } = await getLocationProductListApi(
//       lat,
//       lon,
//       rangeType,
//       filter,
//       order,
//       index,
//     );

//     return {
//       index,
//       items,
//       totalSize,
//     };
//   } catch (e) {
//     return Promise.reject(e);
//   }
// };

export const resetTownCoordinate = createAction(Actions.RESET_TOWN_COORDINATE);

export const getTownCoordinate = createAction(
  Actions.GET_TOWN_COORDINATE,
  (lat, lon) => ({ lat: Number(lat), lon: Number(lon) }),
);

export const getTownProductList = createAction(
  Actions.GET_TOWN_PRODUCTLIST,
  getProductList,
);

export const changeTownProductWish = createAction(
  Actions.CHANGE_TOWN_PRODUCT_WISH,
  (seq, value) => ({ seq, value }),
);

export const clearTownStore = createAction(Actions.CLEAR_TOWN_STORE);

export const initTownStore = createAction(Actions.INIT_TOWN_STORE);

export default handleActions(
  {
    [Actions.RESET_TOWN_COORDINATE]: state => {
      const lat = state.lat > 0 ? state.lat : -1;
      const lon = state.lon > 0 ? state.lon : -1;
      const nextState = lat > 0 && lon > 0 ? state : initialState;

      return {
        ...state,
        lat,
        lon,
        locationName: nextState.locationName,
        rangCount: nextState.rangCount,
        index: nextState.index,
        isMore: nextState.isMore,
        totalSize: nextState.totalSize,
        productList: nextState.productList,
      };
    },
    [Actions.GET_TOWN_COORDINATE]: (state, action) => {
      const { lat, lon } = action.payload;
      return {
        ...initialState,
        lat,
        lon,
        locationName: initialState.locationName,
        rangCount: initialState.rangCount,
        index: initialState.index,
        isMore: initialState.isMore,
        totalSize: initialState.totalSize,
        productList: initialState.productList,
      };
    },
    // ...pender({
    //   type: Actions.GET_TOWN_PRODUCTLIST,
    //   onSuccess: (state, action) => {
    //     const { index, items, totalSize } = action.payload;
    //     const nextItems = mergeArrayOfObject(state.productList, items, 'seq');
    //     return {
    //       ...state,
    //       isMore: items.length >= 20,
    //       index: index + 1,
    //       totalSize,
    //       productList: nextItems,
    //     };
    //   },
    //   onFailure: (state, action) => {
    //     const { data } = action.payload.response;
    //     return {
    //       ...state,
    //       error: {
    //         code: data.meta.code,
    //         message: data.meta.message,
    //       },
    //     };
    //   },
    // }),
    ...pender({
      type: Actions.GET_TOWN_PRODUCTLIST,
      onSuccess: (state, action) => {
        const { index, items, totalSize } = action.payload;
        return produce(state, d => {
          const draft = d;
          draft.isMore = items.length >= 20;
          draft.index = index + 1;
          draft.totalSize = totalSize;
          draft.productList = [...state.productList, ...items];
        });
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),
    [Actions.CHANGE_TOWN_PRODUCT_WISH]: (state, action) => {
      const { seq, value } = action.payload;
      const findItemIndex = state.productList.findIndex(p => p.seq === seq);
      if (findItemIndex > -1) {
        const changeProductListWish = produce(state.productList, d => {
          const draft = d;
          draft[findItemIndex].productWishYn = value;
        });
        return { ...state, productList: changeProductListWish };
      }

      return { ...state };
    },
    [Actions.CLEAR_TOWN_STORE]: state => {
      return { ...initialState, lat: state.lat, lon: state.lon };
    },
    [Actions.INIT_TOWN_STORE]: () => {
      return { ...initialState };
    },
  },
  initialState,
);
