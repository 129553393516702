import axios from 'axios';

import { pointApi } from '../../common/api';
import { getQueryString } from '../../js/common';

const mileage = {
  GET_TOTAL: 'mileage/GET_TOTAL',
  GET_LIST: 'mileage/GET_LIST',
  POINT_ADD: 'mileage/POINT_ADD',
  SET_MY_POINT_CALC: 'mileage/SET_MY_POINT_CALC',
  SET_MY_USE_TRY_POINT_CALC: 'mileage/SET_MY_USE_TRY_POINT_CALC',
  POST_COMPLETE_ORDER: 'mileage/POST_COMPLETE_ORDER',
  POST_COMPLETE_BUY: 'mileage/POST_COMPLETE_BUY',
};

// GET /point/list/total/{pointType} 포인트 토탈 조회
export function getTotal(pointType) {
  // pointType: 0: 포인트, 1: 캐시
  const promise = axios.get(`/point/list/total/${pointType}`, {
    ...pointApi(),
  });
  return promise;
}

// GET /point/list/{pointType} 포인트 적립 및 사용 내역 조회
export function getList(param) {
  const { pointType } = param; // 0: 포인트, 1: 캐시

  delete param.pointType;
  const queryString = getQueryString(param);

  const promise = axios.get(`/point/list/${pointType}${queryString}`, {
    ...pointApi(),
  });
  return promise;
}

// POST /point/add/{pointCategorySeq} 포인트 요청
export function pointAdd(pointCategorySeq, addInfo, orderNo) {
  const pointRequest = {
    ...(addInfo && { addInfo }),
    ...(orderNo && { orderNo }),
  };

  const promise = axios.post(
    `/v1/point/add/${pointCategorySeq}`,
    pointRequest,
    { ...pointApi() },
  );
  return promise;
}

// 통신 안함 : UI 상으로 포인트 차감
export function setMyPointCalc(point) {
  return point;
}

// 통신 안함 : UI 상으로 이벤트 참여횟수 증감
export function setMyUseTryPointCalc(point) {
  return point;
}

// POST 택배 예약 완료에 대한 포인트 지급
export function postCompleteOrder(orderNo) {
  const promise = axios.post(
    `/v1/point/add/1005`,
    {
      addInfo: 0,
      orderNo,
    },
    { ...pointApi() },
  );
  return promise;
}

// POST 구매 확정 완료에 대한 포인트 지급
export function postCompleteBuy(orderNo) {
  const promise = axios.post(
    `/v1/point/add/1006`,
    {
      addInfo: 0,
      orderNo,
    },
    { ...pointApi() },
  );
  return promise;
}

export default mileage;
