import { createAction, handleActions } from 'redux-actions';
import moment from 'moment';
import { pender } from 'redux-pender';

import * as Delivery from '../../api/base/delivery';

const delivery = {
  // 택배신청 페이지 기본 데이터
  GET_DELIVERY_INFO: 'delivery/GET_DELIVERY_INFO',
  GET_DELIVERY_INFO_RETURN: 'delivery/GET_DELIVERY_INFO_RETURN',
  GET_DELIVERY_INFO_PRODUCT: 'delivery/GET_DELIVERY_INFO_PRODUCT',

  // 택배신청 물건 데이터
  GET_PRODUCT_INFO: 'delivery/GET_PRODUCT_INFO',

  // 편의점 배송등록 TEMP 데이터 및 RETURN URL
  SET_CVS_DELIVERY_TEMP: 'delivery/SET_CVS_DELIVERY_TEMP',
  SET_CVS_DELIVERY_TEMP_ADDR: 'delivery/SET_CVS_DELIVERY_TEMP_ADDR',
  SET_CVS_DELIVERY_RETURN_URL: 'delivery/SET_CVS_DELIVERY_RETURN_URL',

  // 편의점 배송 등록
  POST_CUPOST_MYPAGE: 'delivery/POST_CUPOST_MYPAGE',
  POST_CVSNET_MYPAGE: 'delivery/POST_CVSNET_MYPAGE',

  POST_CUPOST_ORDER: 'delivery/POST_CUPOST_ORDER',
  POST_CVSNET_ORDER: 'delivery/POST_CVSNET_ORDER',

  POST_CUPOST_RETURN: 'delivery/POST_CUPOST_RETURN',
  POST_CVSNET_RETURN: 'delivery/POST_CVSNET_RETURN',

  // 편의점 배송 접수 취소
  POST_CUPOST_CANCEL: 'delivery/POST_CUPOST_CANCEL',
  POST_CVSNET_CANCEL: 'delivery/POST_CVSNET_CANCEL',

  // 편의점 반품 배송예약 취소
  POST_CUPOST_RETURN_CANCEL: 'delivery/POST_CUPOST_RETURN_CANCEL',
  POST_CVSNET_RETURN_CANCEL: 'delivery/POST_CVSNET_RETURN_CANCEL',

  // 받는사람 확인
  GET_DELIVERY_CUPOST_CHECK_MYPAGE: 'delivery/GET_DELIVERY_CUPOST_CHECK_MYPAGE',
  GET_DELIVERY_CVSNET_CHECK_MYPAGE: 'delivery/GET_DELIVERY_CVSNET_CHECK_MYPAGE',

  GET_DELIVERY_CUPOST_CHECK_ORDER: 'delivery/GET_DELIVERY_CUPOST_CHECK_ORDER',
  GET_DELIVERY_CVSNET_CHECK_ORDER: 'delivery/GET_DELIVERY_CVSNET_CHECK_ORDER',

  // 유니크로 택배사 코드
  GET_DELIVERY_UNICRO_CORP: 'delivery/GET_DELIVERY_UNICRO_CORP',

  // 편의점 택배등록 완료화면 리턴URL 저장
  SET_CVS_COMPLETE_RETURN_URL: 'delivery/SET_CVS_COMPLETE_RETURN_URL',

  // 편의점택배 이용안내 이미지 조회
  GET_DELIVERY_GUIDE_IMG: 'delivery/GET_DELIVERY_GUIDE_IMG',
};

// 택배배송페이지 정보
export const getDeliveryInfo = createAction(
  delivery.GET_DELIVERY_INFO,
  Delivery.deliveryInfo,
);
export const getDeliveryInfoProduct = createAction(
  delivery.GET_DELIVERY_INFO_PRODUCT,
  Delivery.deliveryProductInfo,
);
export const getDeliveryInfoReturn = createAction(
  delivery.GET_DELIVERY_INFO_RETURN,
  Delivery.deliveryReturnInfo,
);
// ----------------------------------------------------

// 택배배송 페이지 TEMP저장
export const setCvsDeliveryTemp = createAction(
  delivery.SET_CVS_DELIVERY_TEMP,
  tempData => tempData,
);
export const setCvsDeliveryTempAddr = createAction(
  delivery.SET_CVS_DELIVERY_TEMP_ADDR,
  tempData => tempData,
);
export const setCvsDeliveryReturnURL = createAction(
  delivery.SET_CVS_DELIVERY_RETURN_URL,
  returnUrl => returnUrl,
);
// ----------------------------------------------------

// 배송 신청
// CU
export const postCupostMypage = createAction(
  delivery.POST_CUPOST_MYPAGE,
  Delivery.CUpostMypage,
);
// GS
export const postCvsnetMypage = createAction(
  delivery.POST_CVSNET_MYPAGE,
  Delivery.GSpostMypage,
);
// ----------------------------------------------------

// CU
export const postCupostOrder = createAction(
  delivery.POST_CUPOST_ORDER,
  Delivery.CUpostOrder,
);
// GS
export const postCvsnetOrder = createAction(
  delivery.POST_CVSNET_ORDER,
  Delivery.GSpostOrder,
);
// ----------------------------------------------------

// CU
export const postCupostReturn = createAction(
  delivery.POST_CUPOST_RETURN,
  Delivery.CUpostReturn,
);
// GS
export const postCvsnetReturn = createAction(
  delivery.POST_CVSNET_RETURN,
  Delivery.GSpostReturn,
);
// ----------------------------------------------------

// 배송 접수 취소
// CU
export const postCupostCancel = createAction(
  delivery.POST_CUPOST_CANCEL,
  Delivery.CUcancle,
);
// GS
export const postCvsnetCancel = createAction(
  delivery.POST_CVSNET_CANCEL,
  Delivery.GScancle,
);
// ----------------------------------------------------

// 편의점 반품 배송예약 취소
// CU
export const postCupostReturnCancel = createAction(
  delivery.POST_CUPOST_RETURN_CANCEL,
  Delivery.CUpostReturnCancle,
);
// GS
export const postCvsnetReturnCancel = createAction(
  delivery.POST_CVSNET_RETURN_CANCEL,
  Delivery.GSpostReturnCancle,
);
// ----------------------------------------------------

// 받는사람 확인
// CU
export const getDeliveryCupostCheckMypage = createAction(
  delivery.GET_DELIVERY_CUPOST_CHECK_MYPAGE,
  Delivery.CUcheckMypage,
);
// GS
export const getDeliveryCvsnetCheckMypage = createAction(
  delivery.GET_DELIVERY_CVSNET_CHECK_MYPAGE,
  Delivery.GScheckMypage,
);
// ----------------------------------------------------

// CU
export const getDeliveryCupostCheckOrder = createAction(
  delivery.GET_DELIVERY_CUPOST_CHECK_ORDER,
  Delivery.CUcheckOrder,
);
// GS
export const getDeliveryCvsnetCheckOrder = createAction(
  delivery.GET_DELIVERY_CVSNET_CHECK_ORDER,
  Delivery.GScheckOrder,
);
// ----------------------------------------------------

// 택배사코드
export const getDeliveryUnicroCorp = createAction(
  delivery.GET_DELIVERY_UNICRO_CORP,
  Delivery.deliveryUnicroList,
);

// 편의점 택배 완료 리턴 URL 저장
export const setCvsCompleteReturnURL = createAction(
  delivery.SET_CVS_COMPLETE_RETURN_URL,
  returnURL => returnURL,
);

// 편의점택배 이용안내 이미지 조회
export const getDeliveryGuideImg = createAction(
  delivery.GET_DELIVERY_GUIDE_IMG,
  Delivery.deliveryGuideImg,
);

const initialState = {
  delivery_info: {},

  // 편의점 택배 신청 TEMP 데이터
  // 화면이동이 일어날시 잠시 저장 사용후 null처리 (없을경우는 null로 처리바람. {} 와같이  빈배열로 넣을시 해당 필드가 null처리만 하는곳은 오류 작동)
  cvs_delivery_temp: null,
  cvs_delivery_return_url: '',

  // 받는사람 확인
  receive_user_check: {
    buyerStoreSeq: 0,
    isMember: 0,
    sendUserNickName: '',
    sendUserPhoneNo: '',
  },

  onSucessReceiveUser: moment(),
  onFailureReceiveUser: moment(),

  // 배송등록 정상처리 확인
  onSucessPostDelivery: moment(),
  onFailurePostDelivery: moment(),
  onFailureMessage: { code: 0, message: '' },

  // 배송등록 상품 가져오기 에러
  onFailureProduct: moment(),
  onFailureProductMessage: { code: 0, message: '' },

  // 배송등록 완료 예약번호
  delivery_no: 0,

  // 택배사코드
  delivery_unicro_corp: [],

  // 편의점 택배 완료 리턴 URL 저장
  cvs_complete_return_url: '',

  // 편의점 배송 가이드 이미지
  deliveryGuideImg: '',
  onSuccessDeliveryGuideImg: moment(),

  error: { code: 0, message: '', moment: moment() },
};

export default handleActions(
  {
    ...pender({
      type: delivery.GET_DELIVERY_INFO,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          delivery_info: data.data,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: { ...data.meta, moment: moment() },
        };
      },
    }),
    ...pender({
      type: delivery.GET_DELIVERY_INFO_PRODUCT,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          delivery_info: data.data,
        };
      },
      // onFailure: (state, action) => {
      //   const { data } = action.payload.response;
      //   return {
      //     ...state,
      //     onFailureProduct : moment(),
      //     onFailureProductMessage : (data && data.meta)?data.meta : {code : 500 , message:"SERVER ERROR"}
      //   };
      // },
    }),
    ...pender({
      type: delivery.GET_DELIVERY_INFO_RETURN,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          delivery_info: data.data,
        };
      },
      // onFailure: (state, action) => {
      //   const { data } = action.payload.response;
      //   return {
      //     ...state,
      //     onFailureProduct : moment(),
      //     onFailureProductMessage : (data && data.meta)?data.meta : {code : 500 , message:"SERVER ERROR"}
      //   };
      // },
    }),

    // 배송 신청(택배신청)
    // CU
    ...pender({
      type: delivery.POST_CUPOST_MYPAGE,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          delivery_no: data.data,
          onSucessPostDelivery: moment(),
        };
      },
      onFailure: (state, action) => {
        const data = action.payload.response
          ? action.payload.response.data
          : {};

        return {
          ...state,
          onFailurePostDelivery: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : {
                  code: 500,
                  message: '서버 오류로 잠시 후에 다시 시도해 주세요.',
                },
        };
      },
    }),
    // GS
    ...pender({
      type: delivery.POST_CVSNET_MYPAGE,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          delivery_no: data.data,
          onSucessPostDelivery: moment(),
        };
      },
      onFailure: (state, action) => {
        const data = action.payload.response
          ? action.payload.response.data
          : {};
        return {
          ...state,
          onFailurePostDelivery: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : {
                  code: 500,
                  message: '서버 오류로 잠시 후에 다시 시도해 주세요.',
                },
        };
      },
    }),

    // 배송 신청(배송등록)
    // CU
    ...pender({
      type: delivery.POST_CUPOST_ORDER,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          delivery_no: data.data,
          onSucessPostDelivery: moment(),
        };
      },
      onFailure: (state, action) => {
        const data = action.payload.response
          ? action.payload.response.data
          : {};
        return {
          ...state,
          onFailurePostDelivery: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : {
                  code: 500,
                  message: '서버 오류로 잠시 후에 다시 시도해 주세요.',
                },
        };
      },
    }),
    // GS
    ...pender({
      type: delivery.POST_CVSNET_ORDER,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          delivery_no: data.data,
          onSucessPostDelivery: moment(),
        };
      },
      onFailure: (state, action) => {
        const data = action.payload.response
          ? action.payload.response.data
          : {};
        return {
          ...state,
          onFailurePostDelivery: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : {
                  code: 500,
                  message: '서버 오류로 잠시 후에 다시 시도해 주세요.',
                },
        };
      },
    }),

    // 배송 신청(반품 배송등록)
    // CU
    ...pender({
      type: delivery.POST_CUPOST_RETURN,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          delivery_no: data.data,
          onSucessPostDelivery: moment(),
        };
      },
      onFailure: (state, action) => {
        const data = action.payload.response
          ? action.payload.response.data
          : {};
        return {
          ...state,
          onFailurePostDelivery: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : {
                  code: 500,
                  message: '서버 오류로 잠시 후에 다시 시도해 주세요.',
                },
        };
      },
    }),
    // GS
    ...pender({
      type: delivery.POST_CVSNET_RETURN,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          delivery_no: data.data,
          onSucessPostDelivery: moment(),
        };
      },
      onFailure: (state, action) => {
        const data = action.payload.response
          ? action.payload.response.data
          : {};
        return {
          ...state,
          onFailurePostDelivery: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : {
                  code: 500,
                  message: '서버 오류로 잠시 후에 다시 시도해 주세요.',
                },
        };
      },
    }),

    // 배송 신청 취소
    // CU
    ...pender({
      type: delivery.POST_CUPOST_CANCEL,
      onSuccess: state => {
        return {
          ...state,
          onSucessPostDelivery: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          onFailurePostDelivery: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : { code: 500, message: 'SERVER ERROR' },
        };
      },
    }),
    // GS
    ...pender({
      type: delivery.POST_CVSNET_CANCEL,
      onSuccess: state => {
        return {
          ...state,
          onSucessPostDelivery: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          onFailurePostDelivery: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : { code: 500, message: 'SERVER ERROR' },
        };
      },
    }),
    // 편의점 반품 배송예약 취소
    // CU
    ...pender({
      type: delivery.POST_CUPOST_RETURN_CANCEL,
      onSuccess: state => {
        return {
          ...state,
          onSucessPostDelivery: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          onFailurePostDelivery: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : { code: 500, message: 'SERVER ERROR' },
        };
      },
    }),
    // GS
    ...pender({
      type: delivery.POST_CVSNET_RETURN_CANCEL,
      onSuccess: state => {
        return {
          ...state,
          onSucessPostDelivery: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          onFailurePostDelivery: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : { code: 500, message: 'SERVER ERROR' },
        };
      },
    }),

    // 받는사람 확인 (Product)
    // CU
    ...pender({
      type: delivery.GET_DELIVERY_CUPOST_CHECK_MYPAGE,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          receive_user_check: data.data,
          onSucessReceiveUser: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          onFailureReceiveUser: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : { code: 500, message: 'SERVER ERROR' },
        };
      },
    }),

    // GS
    ...pender({
      type: delivery.GET_DELIVERY_CVSNET_CHECK_MYPAGE,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          receive_user_check: data.data,
          onSucessReceiveUser: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          onFailureReceiveUser: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : { code: 500, message: 'SERVER ERROR' },
        };
      },
    }),
    // 받는사람 확인 (order)
    // CU
    ...pender({
      type: delivery.GET_DELIVERY_CUPOST_CHECK_ORDER,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          receive_user_check: data.data,
          onSucessReceiveUser: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          onFailureReceiveUser: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : { code: 500, message: 'SERVER ERROR' },
        };
      },
    }),
    // GS
    ...pender({
      type: delivery.GET_DELIVERY_CVSNET_CHECK_ORDER,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          receive_user_check: data.data,
          onSucessReceiveUser: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          onFailureReceiveUser: moment(),
          onFailureMessage:
            data && data.meta
              ? data.meta
              : { code: 500, message: 'SERVER ERROR' },
        };
      },
    }),

    // 택배사 코드
    ...pender({
      type: delivery.GET_DELIVERY_UNICRO_CORP,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          delivery_unicro_corp: data.data,
        };
      },
    }),

    // 편의점택배 이용안내 이미지 조회
    ...pender({
      type: delivery.GET_DELIVERY_GUIDE_IMG,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        const deliveryGuideImgObj = data.data.find(d => d.type === 3);

        return {
          ...state,
          onSuccessDeliveryGuideImg: moment(),
          deliveryGuideImg: deliveryGuideImgObj ? deliveryGuideImgObj.url : '',
        };
      },
    }),

    // 편의점 배송등록
    [delivery.SET_CVS_DELIVERY_TEMP]: (state, action) => {
      return {
        ...state,
        cvs_delivery_temp: action.payload,
      };
    },
    [delivery.SET_CVS_DELIVERY_TEMP_ADDR]: (state, action) => {
      const cvs_delivery_temp = {
        ...state.cvs_delivery_temp,
        ...action.payload,
      };
      return {
        ...state,
        cvs_delivery_temp,
      };
    },
    [delivery.SET_CVS_DELIVERY_RETURN_URL]: (state, action) => {
      return {
        ...state,
        cvs_delivery_return_url: action.payload,
      };
    },
    // 편의점 배송완료 return URL저장
    [delivery.SET_CVS_COMPLETE_RETURN_URL]: (state, action) => {
      return {
        ...state,
        cvs_complete_return_url: action.payload,
      };
    },
  },
  initialState,
);
