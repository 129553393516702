import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';

export const Actions = {
  INIT_SEARCH: 'SEARCH/INIT_SEARCH',
  SET_SEARCH_TAB_LIST: 'SEARCH/SET_SEARCH_TAB_LIST',
  INIT_PRODUCT: 'SEARCH/INIT_PRODUCT',
  INIT_PRODUCT_CATEGORY: 'SEARCH/INIT_PRODUCT_CATEGORY',
  SET_PRODUCT_FILTER: 'SEARCH/SET_PRODUCT_FILTER',
  SET_PRODUCT_DATA: 'SEARCH/SET_PRODUCT_DATA',
  CLEAR_PRODUCT_DATA: 'SEARCH/CLEAR_PRODUCT_DATA',
  SET_HOMETOWN_INFORMATION: 'SEARCH/SET_HOMETOWN_INFORMATION',
  SET_STANDARD_PRICE_DATA: 'SEARCH/SET_STANDARD_PRICE_DATA',
};

const initialState = {
  searchTabList: [],
  productFilter: {
    fixed: false,
    search: null,
    categoryData: {
      categorySeq: 0,
      categoryDepth: 0,
      categoryText: '카테고리 전체',
      categoryFullText: '카테고리 전체',
    },
  },
  productData: null,
  hometownInformationNotice: {
    show: false,
    expirationMillis: 4000,
    notice: null,
    policyType: 'SEARCH',
  },
  standardPriceData: {
    keyword: '',
    isShow: false,
    transactionId: null,
    abTestType: null,
    result: null,
  },
  error: {
    code: 200,
    message: '',
  },
};

export const initSearch = createAction(Actions.INIT_SEARCH);
export const setSearchTabList = createAction(
  Actions.SET_SEARCH_TAB_LIST,
  obj => ({ obj }),
);
export const initProduct = createAction(Actions.INIT_PRODUCT);
export const initProductCategory = createAction(Actions.INIT_PRODUCT_CATEGORY);
export const setProductFilter = createAction(
  Actions.SET_PRODUCT_FILTER,
  obj => ({ obj }),
);
export const setProductData = createAction(
  Actions.SET_PRODUCT_DATA,
  obj => obj,
);
export const clearProductData = createAction(Actions.CLEAR_PRODUCT_DATA);
export const setHometownInformation = createAction(
  Actions.SET_HOMETOWN_INFORMATION,
  obj => ({ obj }),
);
export const setStandardPriceData = createAction(
  Actions.SET_STANDARD_PRICE_DATA,
  obj => ({ obj }),
);

export default handleActions(
  {
    [Actions.INIT_SEARCH]: () => {
      return { ...initialState };
    },
    [Actions.SET_SEARCH_TAB_LIST]: (state, action) => {
      const { obj } = action.payload;
      return produce(state, d => {
        const draft = d;

        draft.searchTabList = [...obj];
      });
    },
    [Actions.INIT_PRODUCT]: state => {
      return produce(state, d => {
        const draft = d;

        draft.productFilter = {
          ...initialState.productFilter,
        };
      });
    },
    [Actions.INIT_PRODUCT_CATEGORY]: state => {
      return produce(state, d => {
        const draft = d;

        draft.productFilter.categoryData = {
          ...initialState.productFilter.categoryData,
        };
      });
    },
    [Actions.SET_PRODUCT_FILTER]: (state, action) => {
      const { obj } = action.payload;
      return produce(state, d => {
        const draft = d;
        const data = { ...obj };

        draft.productFilter = { ...draft.productFilter, ...data };
      });
    },
    [Actions.SET_PRODUCT_DATA]: (state, action) => {
      return produce(state, d => {
        const draft = d;
        draft.productData = {
          ...state.productData,
          ...action.payload,
        };
      });
    },
    [Actions.CLEAR_PRODUCT_DATA]: state => {
      return produce(state, d => {
        const draft = d;

        draft.productData = initialState.productData;
      });
    },
    [Actions.SET_HOMETOWN_INFORMATION]: (state, action) => {
      const { obj } = action.payload;
      return {
        ...state,
        hometownInformationNotice: {
          ...state.hometownInformationNotice,
          ...obj,
        },
      };
    },
    [Actions.SET_STANDARD_PRICE_DATA]: (state, action) => {
      const { obj } = action.payload;
      return {
        ...state,
        standardPriceData: {
          ...state.standardPriceData,
          ...obj,
        },
      };
    },
  },
  initialState,
);
