import Scroll from 'react-scroll';

import { AlertReducer, AuthReducer } from '../reducers/actionCreators';

import { config } from './api';

import { getQueryStringObj, objToQueryString } from './util';

import { parseLinkDeepToWeb, parseLink } from './parser';

import { isApp } from './webView';

export const alert = ({
  className,
  text,
  subText,
  onClick,
  bottomText,
  onCancle,
}) => {
  AlertReducer.open({
    className,
    text,
    subText,
    onClick,
    bottomText,
    onCancle,
  });
};

export const authAlert = ({ has, close, redirectUrl, external }) => {
  AuthReducer.open({
    has,
    close,
    redirectUrl,
    external,
  });
};

export const htmlLinkParser = html => {
  if (isApp()) return html;

  const el = document.createElement('html');
  el.innerHTML = html;
  const a = el.getElementsByTagName('a');
  [...a].forEach(element => {
    // eslint-disable-next-line no-param-reassign
    element.href = parseLinkDeepToWeb(element.href);
  });

  return el.outerHTML;
};

export const htmlExternalLinkParser = html => {
  const el = document.createElement('html');
  el.innerHTML = html;
  const a = el.getElementsByTagName('a');

  [...a].forEach(element => {
    const parseLinks = parseLink(element.href);

    if (parseLinks.isHashLink) {
      // eslint-disable-next-line no-param-reassign
      element.href = parseLinks.mwebLink;
    } else if (isApp()) {
      // eslint-disable-next-line no-param-reassign
      element.href = parseLinks.deepLink;
    } else if (parseLinks.recommendToUseApp) {
      // eslint-disable-next-line no-param-reassign
      element.classList.add('authAlert');
    } else {
      // eslint-disable-next-line no-param-reassign
      element.href = parseLinks.mwebLink;
      if (!parseLinks.isExternal) {
        element.classList.add('needPush');
      }
    }
  });
  return el.outerHTML;
};

export const historyModify = (pathname, search) => {
  const queryStrings = getQueryStringObj(search);

  if (queryStrings.from) {
    const replaceUrl = queryStrings.replace
      ? queryStrings.replace
      : `${config.DEFAULT_URL}`;
    window.history.pushState({}, document.title, replaceUrl);

    delete queryStrings.from;
    delete queryStrings.replace;

    const queryString = objToQueryString(queryStrings);
    const nowUrl = `${config.DEFAULT_URL}${pathname}${queryString}`;
    window.history.pushState({ from: replaceUrl }, document.title, nowUrl);
  }
};

export const scrollTo = (position = 0, op = {}) => {
  const scroll = Scroll.animateScroll;

  scroll.scrollTo(position, {
    duration: 0,
    delay: 100,
    smooth: true,
    ...op,
  });
};
