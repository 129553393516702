import axios from 'axios';
import config from '../config';
import { getHeaders } from '../apiUtil';

const baseURL = `${config.API_BASE_URL}`;

/**
 * cu post 배송예약 취소
 * @param {sting} orderNo
 */
export function CUcancle(orderNo) {
  return axios({
    method: 'post',
    url: `/delivery/cupost/cancel/${orderNo}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * cu post 배송정보 입력 완료 (거래번호 없는 편의점 택배관리)
 * @param {string} name
 * @param {string} phoneNo
 */
export function CUcheckMypage(name, phoneNo) {
  const uriName = encodeURIComponent(name);
  const uriPhoneNo = encodeURIComponent(phoneNo);
  return axios({
    method: 'get',
    url: `/delivery/cupost/check/mypage?name=${uriName}&phoneNo=${uriPhoneNo}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * cu post 배송정보 입력 완료(판구매내역, 거래번호 있는 편의점 택배관리)
 * @param {string} orderNo
 * @param {number} returnYn
 */
export function CUcheckOrder(orderNo, returnYn = 0) {
  return axios({
    method: 'get',
    url: `/delivery/cupost/check/order?orderNo=${orderNo}&returnYn=${returnYn}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * cu post 배송등록 (편의점 택배관리)
 * @param {
 *  buyerStoreSeq: number
 *  deliveryPayType: number
 *  orderNo: string
 *  productPrice: number
 *  productSeq: number
 *  productTitle: string
 *  receiveUserAddress: string
 *  receiveUserAddressDetail: string
 *  receiveUserName: string
 *  receiveUserPhoneNo: string
 *  receiveUserPostNo: string
 *  sendUserDeliverySeq: number
 * } param
 */

export function CUpostMypage({
  buyerStoreSeq,
  deliveryPayType,
  orderNo,
  productPrice,
  productSeq,
  productTitle,
  receiveUserAddress,
  receiveUserAddressDetail,
  receiveUserName,
  receiveUserPhoneNo,
  receiveUserPostNo,
  sendUserDeliverySeq,
}) {
  const param = {
    buyerStoreSeq,
    deliveryPayType,
    orderNo,
    productPrice,
    productSeq,
    productTitle,
    receiveUserAddress,
    receiveUserAddressDetail,
    receiveUserName,
    receiveUserPhoneNo,
    receiveUserPostNo,
    sendUserDeliverySeq,
  };
  return axios({
    method: 'post',
    url: `/delivery/cupost/mypage`,
    data: param,
    baseURL,
    headers: getHeaders(),
    timeout: 5 * 1000,
  });
}

/**
 * cu post 배송등록 (판구매내역)
 * @param {
 *  deliveryPayType: number
 *  orderNo: string
 *  sellerAccountSeq: number
 *  sendUserDeliverySeq: number
 * } param
 */
export function CUpostOrder({
  deliveryPayType,
  orderNo,
  sellerAccountSeq,
  sendUserDeliverySeq,
}) {
  const param = {
    deliveryPayType,
    orderNo,
    sellerAccountSeq,
    sendUserDeliverySeq,
  };
  return axios({
    method: 'post',
    url: `/delivery/cupost/order`,
    data: param,
    baseURL,
    headers: getHeaders(),
    timeout: 5 * 1000,
  });
}

/**
 * cu post 반품 배송등록(판구매내역)
 * @param {
 *  deliveryPayType: number
 *  orderNo: string
 *  sendUserDeliverySeq: number
 * } param
 */
export function CUpostReturn({
  deliveryPayType,
  orderNo,
  sendUserDeliverySeq,
}) {
  const param = {
    deliveryPayType,
    orderNo,
    sendUserDeliverySeq,
  };
  return axios({
    method: 'post',
    url: `/delivery/cupost/return`,
    data: param,
    baseURL,
    headers: getHeaders(),
    timeout: 5 * 1000,
  });
}

/**
 * cu post 반품 배송 예약 취소
 * @param {string} orderNo
 */
export function CUpostReturnCancle(orderNo) {
  return axios({
    method: 'post',
    url: `/delivery/cupost/return/cancel/${orderNo}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 택배 배송 조회 (cvs 적용 후)
 * @param {number} deliveryCorpCode
 * @param {string} invoiceNo
 */
export function deliveryTracking(deliveryCorpCode, invoiceNo) {
  const invoiceNoFilter = invoiceNo.replace(/[^0-9]/g, '');
  return axios({
    method: 'get',
    url: `/delivery/${deliveryCorpCode}/${invoiceNoFilter}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 편의점 택배 이용안내 이미지 조회
 * @param {number / 0: All, 1: App, 2: Web} deviceType
 * @param {number / 0: All, 1: Android, 2: Ios} osType
 */
export function deliveryGuideImg(deviceType, osType) {
  return axios({
    method: 'get',
    url: `/delivery/content/image/${deviceType}/${osType}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 택배신청 (안전결제배송등록, 편의점택배등록)시 정보
 * @param {string} orderNo
 * @param {boolean} isJnDelivery
 */
export function deliveryInfo(orderNo, isJnDelivery) {
  return axios({
    method: 'get',
    url: `/delivery/info/${orderNo}?isJnDelivery=${isJnDelivery}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 택배(반품) 신청(안전결제배송등록, 편의점택배등록)시 정보
 * @param {string} orderNo
 */
export function deliveryReturnInfo(orderNo) {
  return axios({
    method: 'get',
    url: `/delivery/info/return/${orderNo}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 편의점 택배 신청 선택 상품 정보
 * @param {number} productSeq
 */
export function deliveryProductInfo(productSeq) {
  return axios({
    method: 'get',
    url: `/delivery/product/${productSeq}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 편의점 택배 신청 상품 목록
 */
export function deliveryProductList() {
  return axios({
    method: 'get',
    url: `/delivery/product/list`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 유니크로 택배신청 시 가능한 택배사 목록
 */
export function deliveryUnicroList() {
  return axios({
    method: 'get',
    url: `/delivery/unicro/corp`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * gs post 배송예약 취소
 * @param {sting} orderNo
 */
export function GScancle(orderNo) {
  return axios({
    method: 'post',
    url: `/delivery/cvsnet/cancel/${orderNo}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * gs post 배송정보 입력 완료 (거래번호 없는 편의점 택배관리)
 * @param {string} name
 * @param {string} phoneNo
 */
export function GScheckMypage(name, phoneNo) {
  const uriName = encodeURIComponent(name);
  const uriPhoneNo = encodeURIComponent(phoneNo);
  return axios({
    method: 'get',
    url: `/delivery/cvsnet/check/mypage?name=${uriName}&phoneNo=${uriPhoneNo}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * gs post 배송정보 입력 완료(판구매내역, 거래번호 있는 편의점 택배관리)
 * @param {string} orderNo
 * @param {number} returnYn
 */
export function GScheckOrder(orderNo, returnYn = 0) {
  return axios({
    method: 'get',
    url: `/delivery/cvsnet/check/order?orderNo=${orderNo}&returnYn=${returnYn}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * gs post 배송등록 (편의점 택배관리)
 * @param {
 *  buyerStoreSeq: number
 *  deliveryPayType: number
 *  orderNo: string
 *  productPrice: number
 *  productSeq: number
 *  productTitle: string
 *  receiveUserAddress: string
 *  receiveUserAddressDetail: string
 *  receiveUserName: string
 *  receiveUserPhoneNo: string
 *  receiveUserPostNo: string
 *  sendUserDeliverySeq: number
 * } param
 */
export function GSpostMypage({
  buyerStoreSeq,
  deliveryPayType,
  orderNo,
  productPrice,
  productSeq,
  productTitle,
  receiveUserAddress,
  receiveUserAddressDetail,
  receiveUserName,
  receiveUserPhoneNo,
  receiveUserPostNo,
  sendUserDeliverySeq,
}) {
  const param = {
    buyerStoreSeq,
    deliveryPayType,
    orderNo,
    productPrice,
    productSeq,
    productTitle,
    receiveUserAddress,
    receiveUserAddressDetail,
    receiveUserName,
    receiveUserPhoneNo,
    receiveUserPostNo,
    sendUserDeliverySeq,
  };
  return axios({
    method: 'post',
    url: `/delivery/cvsnet/mypage`,
    data: param,
    baseURL,
    headers: getHeaders(),
    timeout: 5 * 1000,
  });
}

/**
 * gs post 배송등록 (판구매내역)
 * @param {
 *  deliveryPayType: number
 *  orderNo: string
 *  sellerAccountSeq: number
 *  sendUserDeliverySeq: number
 * } param
 */
export function GSpostOrder({
  deliveryPayType,
  orderNo,
  sellerAccountSeq,
  sendUserDeliverySeq,
}) {
  const param = {
    deliveryPayType,
    orderNo,
    sellerAccountSeq,
    sendUserDeliverySeq,
  };
  return axios({
    method: 'post',
    url: `/delivery/cvsnet/order`,
    data: param,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * gs post 반품 배송등록(판구매내역)
 * @param {
 *  deliveryPayType: number
 *  orderNo: string
 *  sendUserDeliverySeq: number
 * } param
 */
export function GSpostReturn({
  deliveryPayType,
  orderNo,
  sendUserDeliverySeq,
}) {
  const param = {
    deliveryPayType,
    orderNo,
    sendUserDeliverySeq,
  };
  return axios({
    method: 'post',
    url: `/delivery/cvsnet/return`,
    data: param,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * gs post 반품 배송 예약 취소
 * @param {string} orderNo
 */
export function GSpostReturnCancle(orderNo) {
  return axios({
    method: 'post',
    url: `/delivery/cvsnet/return/cancel/${orderNo}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 *
 * @param {string} postNo
 * @return Boolean
 *
 */
export function checkArea(postNo) {
  const urlPostNo = encodeURIComponent(postNo);
  return axios({
    method: 'get',
    url: `/delivery/home-pick/area?postNo=${urlPostNo}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 *
 * @param {string} orderNo
 */
export function homePickCancel(orderNo) {
  const urlOrderNo = encodeURIComponent(orderNo);
  return axios({
    method: 'post',
    url: `/delivery/home-pick/cancel/${urlOrderNo}`,
    baseURL,
    headers: getHeaders(),
    timeout: 5 * 1000,
  });
}

/**
 *
 * @param {string} name
 * @param {string} phoneNo
 */
//  HomePickcheckMypage
export function HomePickcheckMypage(name, phoneNo) {
  const urlName = encodeURIComponent(name);
  const urlPhoneNo = encodeURIComponent(phoneNo);
  return axios({
    method: 'get',
    url: `/delivery/home-pick/check/mypage?name=${urlName}&phoneNo=${urlPhoneNo}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 *
 * @param {string} orderNo
 * @param {number} returnYn
 */

export function HomePickcheckOrder(orderNo, returnYn) {
  const urlOrderNo = encodeURIComponent(orderNo);
  const urlReturnYn = encodeURIComponent(returnYn);
  return axios({
    method: 'get',
    url: `/delivery/home-pick/check/order?orderNo=${urlOrderNo}&returnYn=${urlReturnYn}`,
    baseURL,
    headers: getHeaders(),
  });
}

export function getHomePickDate() {
  return axios({
    method: 'get',
    url: `/delivery/home-pick/datetime`,
    baseURL,
    headers: getHeaders(),
  });
}

export function homePickPostMyPage({
  buyerStoreSeq,
  homePickParameter,
  orderNo,
  productPrice,
  productSeq,
  productTitle,
  receiveUserAddress,
  receiveUserAddressDetail,
  receiveUserName,
  receiveUserPhoneNo,
  receiveUserPostNo,
  sendUserDeliverySeq,
}) {
  const param = {
    buyerStoreSeq,
    deliveryPayType: 1, // 홈픽은 무조건 선불
    homePickParameter,
    orderNo,
    productPrice,
    productSeq,
    productTitle,
    receiveUserAddress,
    receiveUserAddressDetail,
    receiveUserName,
    receiveUserPhoneNo,
    receiveUserPostNo,
    sendUserDeliverySeq,
  };
  return axios({
    method: 'post',
    url: `/delivery/home-pick/mypage`,
    data: param,
    baseURL,
    headers: getHeaders(),
    timeout: 5 * 1000,
  });
}

export function homePickPostOrder({
  homePickParameter,
  orderNo,
  sellerAccountSeq,
  sendUserDeliverySeq,
}) {
  const param = {
    deliveryPayType: 1, // 홈픽은 무조건 선불
    homePickParameter,
    orderNo,
    sellerAccountSeq,
    sendUserDeliverySeq,
  };
  return axios({
    method: 'post',
    url: `/delivery/home-pick/order`,
    data: param,
    baseURL,
    headers: getHeaders(),
  });
}

export function getHomePickPriceInfo() {
  return axios({
    method: 'get',
    url: `/delivery/home-pick/price`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * HomePick post 반품 배송등록(판구매내역)
 * @param {
 *  deliveryPayType: number
 *  homePickParameter: {
 *   date: string,
 *   time: string,
 *   weight: string
 *  }
 *  orderNo: string
 *  sendUserDeliverySeq: number
 * } param
 */
export function HomePickpostReturn({
  homePickParameter,
  orderNo,
  sendUserDeliverySeq,
}) {
  const param = {
    deliveryPayType: 1, // 홈픽은 무조건 선불
    homePickParameter,
    orderNo,
    sendUserDeliverySeq,
  };
  return axios({
    method: 'post',
    url: `/delivery/home-pick/return`,
    data: param,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * gs post 반품 배송 예약 취소
 * @param {string} orderNo
 */
export function HomePickpostReturnCancle(orderNo) {
  return axios({
    method: 'post',
    url: `/delivery/home-pick/return/cancel/${orderNo}`,
    baseURL,
    headers: getHeaders(),
  });
}
