import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';

import { getInitInfo } from '../../api/boot/api';

export const Actions = {
  APP_INIT: 'INIT/APP_INIT',
};

const initialState = {
  auto: {
    garage: {
      accidentStandard:
        '자동차 관리법에 따라 차량의 성능에 문제가 되는 부분이 있거나 성능점검기록부 상에서 프레임의 수리/교환은 사고, 외판의 수리/교환은 무사고로 분류함',
      loanInquiryLinkUrl: '{front}/auto/instalment-check',
      loanLinkUrl: '{front}/auto/autoloan',
      mileageValidDescription: '숫자만 입력해주세요.',
    },
    inspect: {
      agreementPopupAosHeight: 373,
      agreementPopupAosWidth: 300,
      agreementPopupIosHeight: 313,
      agreementPopupIosWidth: 315,
      agreementProvideUrl: '/auto-thirdparties-terms',
      inspectFirstPopupContent: '차량진단을 받으시면 판매기간이 단축됩니다.',
      inspectFirstPopupTitle:
        '직거래 판매 등록이 완료되었습니다. 차량진단을 신청하시겠습니까?',
      inspectSecondPopupImageUrl: '{image}/common/home/v2/splash/normal.png',
      isInspectSecondPopup: true,
      reservationCancelFailPopupContent:
        '예약시간이 지났거나 진단이 시작된 경우 진단신청을 취소할 수 없습니다.',
      reservationCancelPopupContent:
        '빠른 판매를 원하시면 차량진단을 받으세요. 차량진단을 받지 않은 차량은 할부대출을 받을 수 업습니다. ↵* 진단 취소 시 내 차고에서 진단신청 가능',
      reservationCancelPopupTitle: '진단신청을 취소하시겠습니까?',
      reservationChangeApiFailPopupContent:
        '선택한 날짜와 시간에는 예약이 불가능합니다. 다른 날짜와 시간을 선택해주세요.',
      reservationChangeCancelPopupContent: '진단예약 변경을취소 하시겠습니까?',
      reservationChangeFailPopupContent:
        '예약시간이 지났거나 진단이 시작된 경우 예약을 변경할 수 없습니다.',
      reservationChangePopupContent:
        '예약일시만 변경 가능합니다. 정비소 변경은 진단취소 후에 다시 진단신청을 해주세요.',
      reservationChangePopupTitle: '진단예약을 변경 하시겠습니까?',
      reservationCompleteApiFailPopupContent:
        '정비소 사정으로 진단신청이 취소되었습니다. 진단신청을 다시 시도해주세요.',
      reservationCompletePopupContent:
        '차량진단 후에 직거래 등록이 완료됩니다.',
      reservationCompletePopupTitle:
        '실매물 확인을 위해직거래 등록 시 차량진단 필수',
      reservationIncompletePopupContent:
        '찜하시면 차량진단이 완료된 후에푸시 알림을 받을 수 있습니다.',
      reservationIncompletePopupTitle: '차량진단 후 차량 문의가 가능합니다.',
    },
  },
  domain: {
    main: 'https://stage.api.joongna.com',
    auto: 'https://stage.api.auto.joongna.com',
    point: 'https://stage.api.point.joongna.com',
    pickPoint: 'https://edge-stg.joongna.com/api/pickpoint',
    ticket: 'https://ticket-api-stg.joongna.com',
    marketing: 'https://stage.api.marketing.joongna.com',
    search: 'https://stage.api.search.joongna.com',
    user: 'https://edge-stg.joongna.com/api/user',
    init: 'https://edge-stg.joongna.com/api/init',
    message: 'https://edge-stg.joongna.com/api/message',
    product: 'https://edge-stg.joongna.com/api/product',
    image: 'https://img2.joongna.com',
  },
  configuration: {
    abTest: [
      {
        key: 'recommand',
        value: 'A',
        index: 1,
      },
      {
        key: 'review',
        value: 'B',
        index: 18,
      },
    ],
    footer: {
      compnayName: '(주)중고나라',
      terms: [
        {
          name: '이용약관',
          type: 'terms',
          url: '{mw}/terms',
        },
        {
          name: '개인정보취급방침',
          type: 'privacy-policy',
          url: '{mw}/privacy-policy',
        },
      ],
      responsibility:
        '(주)중고나라는 통신판매중개자이며, 통신판매의 당사자가 아닙니다. 판매 회원과 구매 회원 간의 상품거래정보 및 거래에 관여하지 않으며, 어떠한 의무와 책임도 부담하지 않습니다.',
      companyAdditionalTitle: '(주)중고나라 사업자 정보',
      companyAdditional:
        '대표자: 이승우 | 사업자 등록번호: 215-87-87482\n통신판매신고번호: 제2019-서울서초-0097호\n주소: 서울특별시 서초구 서초대로 301 동익성봉빌딩 7층\n메일: joongna@qdillion.com\n',
    },
    toggle: {
      partnerCenter: {
        isActive: false,
        message: '서비스 점검중입니다.\n이용에 불편을 드려 죄송합니다.',
        url: 'http://m.qdillion20181.godomall.com/inspect/inspect.php',
      },
      sso: {
        isActive: false,
        message: 'SSO 비활성화',
      },
      home: {
        isActive: false,
      },
      homeV2: {
        isActive: true,
      },
    },
    placeholder: {
      home: {
        search: '거래할 물건을 검색해주세요.',
      },
    },
    redirect: {
      root: {
        url: 'main',
      },
    },
    popup: {
      legalRepresentative: {
        buttonText: '법정 대리인 휴대폰 인증하기',
        checkDesc:
          '개인정보보호법 제 22조에 따라, 보호자(법정대리인) 인증이 필수로 진행되며, 이를 동의하지 않을 경우 서비스 이용이 불가합니다.',
        checkText: '법정대리인의 개인정보 수집에 동의합니다.',
        popupDesc:
          '고객님은 만 14세 미만 가입자로\n보호자(법정 대리인) 인증이 필요합니다.',
        popupTitle: '법정 대리인 인증',
        termsDesc: [
          '• 수집항목 : 이름, 생년월일, 성별, 휴대폰번호, 본인인증정보(ci/di)',
          '• 수집목적 : 법정대리인 본인확인 및 미성년자 회원가입/SNS 연동 시 법정대리인의 동의',
          '• 보유기간 : 회원탈퇴 시 파기',
        ],
      },
    },
  },
  quick: {
    withSize: 4,
    menu: [
      {
        title: '우리동네',
        icon: {
          width: 100,
          height: 100,
          url: '{image}/common/home/town.png',
        },
        linkUrl: '/maintab/town',
        requireLogin: true,
        isFeedBackAction: true,
      },
    ],
  },
  shortcuts: [
    {
      title: '내가게',
      titleColor: '#979797',
      image: {
        url: '{image}/common/home/panelStore.png',
        width: 0,
        height: 0,
      },
      backgroundImage: {
        url: '{image}/common/home/panelStore.png',
        width: 0,
        height: 0,
      },
      linkUrl: '/???',
      linkText: '가게 보러가기',
      isFeedBackAction: true,
    },
    {
      title: '내차고',
      titleColor: '#979797',
      image: {
        url: '{image}/common/home/panelStore.png',
        width: 0,
        height: 0,
      },
      backgroundImage: {
        url: '{image}/common/home/panelStore.png',
        width: 0,
        height: 0,
      },
      linkUrl: '/???',
      linkText: '차고 관리하기',
      isFeedBackAction: true,
    },
  ],
  gnb: {
    refreshTime: '2020-03-11 10:00:00',
    list: [
      {
        name: '우리동네',
        url: '/maintab/town',
      },
      {
        name: '티켓/쿠폰',
        url: '/maintab/ticket',
      },
    ],
  },
  api: {
    main: {
      personalPopup: {
        url: '{main}/popup/init/personal/1',
      },
      seller: {
        url: '{main}/mainpage',
      },
    },
  },
  notification: {
    chat: {
      adminStoreSeq: 4243078,
    },
  },
  paging: {
    configs: {
      searchMain: {
        firstQuantity: 10,
        page: 0,
        quantity: 10,
      },
    },
  },
  service: [
    {
      id: 'sellingMyCar',
      name: '내 차 팔기 (카옥션)',
      url: 'https://m.joongna.com/auto/sales',
    },
  ],
  secureConfig: {
    deepLinkSecure: {
      allowedUrl: [
        'stage.m.joongna.com',
        'www.joongna.com',
        'www.joonggonara.co.kr',
        'qdillion20181.godomall.com',
        'sell.joongnauto.com',
        'sell.joongnacar.com:9090',
      ],
    },
  },
  payment: {
    safePayGuideUrl: '/cs/info-detail/8',
  },
  settlement: {
    settlementUrl: 'http://stage.m.joongna.com/mysettlement',
  },
  user: {
    join: {
      title: '회원가입',
      content:
        '중고나라 이용약관, 위치정보\n이용약관과 개인정보 수집 및 이용에\n동의하셔야 회원 가입이 가능합니다.',
      termGuideMessage:
        '전체 이용약관(필수), 개인정보 수집 및 이용(필수), 제 3자 정보 제공(필수) 및 마케팅 수신(선택)에 동의합니다.',
      terms: [
        {
          name: '중고나라 이용약관',
          isRequired: true,
          url: 'http://common.joongna.com/static/terms/TermsOfService.html',
        },
        {
          name: '위치정보 이용약관',
          isRequired: true,
          url: 'http://common.joongna.com/static/terms/LocationBase.html',
        },
        {
          name: '개인정보 수집 및 이용',
          isRequired: true,
          url: 'http://common.joongna.com/static/terms/PrivacyCollection.html',
        },
        {
          name: '제 3자 정보 제공',
          isRequired: true,
          url: 'http://common.joongna.com/static/terms/ThirdParties.html',
        },
        {
          name: 'SMS, E-mail, PUSH 마케팅 수신',
          isRequired: false,
        },
      ],
      termAdditionalMessage:
        '* 선택 동의항목에 동의하지 않아도 서비스를 이용할 수 있습니다.',
      responsibility:
        '(주)중고나라는 통신판매중개자이며, 통신판매의 당사자가 아닙니다. 판매 회원과 구매 회원 간의 상품거래 정보 및 거래에 관여하지 않으며, 어떠한 의무와 책임도 부담하지 않습니다.',
    },
  },
  splash: {
    isActive: true,
    image: {
      url: '{image}/media/original/2019/12/10/1575943812026.png',
      foldUrl: '{image}/media/original/2019/12/10/1575943812026.png',
    },
  },
  logo: {
    home: {
      icon: {
        front: {
          height: 120,
          url: '{image}/common/home/v2/logo/home/front_icon.png',
          width: 1,
        },
      },
      full: {
        front: {
          url: '{image}/common/home/v2/logo/home/front_full.png',
          width: 816,
          height: 176,
        },
      },
    },
  },
  navigation: {
    bottom: {
      buttons: {
        regist: {
          title: 'string',
          shortcuts: [
            {
              title: 'string',
              image: {
                url: 'string',
              },
            },
          ],
        },
        cancel: {
          title: 'string',
        },
        menus: [
          {
            title: 'string',
            url: 'string',
            image: {
              selectUrl: 'string',
              unSelectUrl: 'string',
              spriteUrl: 'string',
            },
          },
        ],
      },
    },
  },
  myStore: {
    adminReportMessage: '관리자에 의해 숨김 처리 되었습니다.',
    inaccessibleMessage: '접근 불가 상품입니다.\n다시 확인해 주세요.',
    isReviewCountView: true,
    userReportMessage: '다수의 사용자신고로 숨김 처리되었습니다.',
  },
  inquire: {
    information:
      '<strong>카페 관련 문의는</strong> <a href="https://cafe-help-stg.joongna.com"><font color="#11c482">https://cafe-help-stg.joongna.com</font></a>를 통해서 남겨주시면 확인 후 답변드리고 있습니다.',
    url: 'string',
    waitMessage:
      '문의하신 내용은 순차적으로 답변드릴 예정입니다. 문의내용 및 유형에 따라 답변기간은 최대 7~10일 정도 소요될 수 있으니 조금만 시간양해 부탁드리겠습니다. 감사합니다.',
    cafeTypePopupText:
      '<strong><font color="#333333">중고나라 카페 관련 문의는 중고나라 카페전용 고객센터에서 상담이 가능합니다.</font></strong><br><br><font color="#979797">중고나라 카페전용 고객센터로이동하시겠습니까?</font>',
  },
  productDetail: {
    isReviewAverageView: false,
  },
  search: {
    valid: {
      minSearchLength: 1,
      minSearchToast: '검색어를 입력해주세요.',
    },
  },

  // V1 대응
  panel: [
    {
      title: '내가게',
      image: {
        url: 'https://img2.joongna.com/common/home/titleGarage.png',
        width: 61,
        height: 19,
      },
      linkUrl: '/mystore',
      linkText: '가게 보러가기',
    },
    {
      title: '내차고',
      image: {
        url: 'https://img2.joongna.com/common/home/titleStore.png',
        width: 62,
        height: 19,
      },
      linkUrl: '/auto/garage',
      linkText: '차고 관리하기,',
    },
  ],

  error: {
    code: 200,
    message: '',
  },
};

export const appInit = createAction(Actions.APP_INIT, () => getInitInfo());

export default handleActions(
  {
    // init 정보 받아오기
    ...pender({
      type: Actions.APP_INIT,
      onSuccess: (state, action) => {
        const {
          data: { data },
        } = action.payload;
        const domain = data.domain.reduce((acc, cur) => {
          acc[cur.name] = cur.url;
          return acc;
        }, {});
        const payment = {
          safePayGuideUrl:
            data.payment && data.payment.safePayGuideUrl
              ? data.payment.safePayGuideUrl.replace('{front}', '')
              : state.payment.safePayGuideUrl,
        };
        return {
          ...state,
          ...data,
          domain,
          payment,
        };
      },

      onFailure: (state, action) => {
        const { response } = action.payload;

        if (response) {
          const error = response.data.meta
            ? {
                code: response.data.meta.code,
                message: response.data.meta.message,
              }
            : {
                code: response.data.error.status,
                message: response.data.error.code,
              };

          return {
            ...state,
            error,
          };
        }

        return {
          ...state,
          error: {
            code: 999,
            message: 'Network Error',
          },
        };
      },
    }),
  },
  initialState,
);
