import React from 'react';
import { bool } from 'prop-types';
import BarLoader from 'react-spinners/BarLoader';
import { css } from '@emotion/core';

const postLoadingCss = css`
  position: absolute !important;
  top: 50%;
  left: calc(50% - 50px);
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const BarLoading = ({ isPage }) => {
  if (isPage) {
    return (
      <div className="page">
        <BarLoader
          css={postLoadingCss}
          sizeUnit="px"
          size={50}
          color="#ff6900"
          loading
        />
      </div>
    );
  }

  return (
    <BarLoader
      css={postLoadingCss}
      sizeUnit="px"
      size={50}
      color="#ff6900"
      loading
    />
  );
};

BarLoading.propTypes = {
  isPage: bool,
};

BarLoading.defaultProps = {
  isPage: false,
};

export default BarLoading;
