import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import produce from 'immer';

import { getFranchiseList as getFranchiseListApi } from '../../api/base/acl';

export const Actions = {
  GET_FRANCHISE_LIST: 'MOBILE_FRANCHISE/GET_FRANCHISE_LIST',
  CLEAR: 'MOBILE_FRANCHISE/CLEAR',
};

export const getFranchiseList = createAction(
  Actions.GET_FRANCHISE_LIST,
  getFranchiseListApi,
);

export const clear = createAction(Actions.CLEAR);

const initialState = {
  isFetch: false,
  placeList: [],
  // 에러
  error: {
    code: 200,
    message: '',
  },
};

export default handleActions(
  {
    ...pender({
      type: Actions.GET_FRANCHISE_LIST,
      onSuccess: (state, action) => {
        const {
          data: { data },
        } = action.payload;

        const placeList = data.map(d => ({
          ...d,
          seq: d.franchiseSeq,
          name: d.franchiseName,
          fullAddress: `${d.address} ${d.addressDetail}`,
          lat: d.latitude,
          lng: d.longitude,
        }));

        return produce(state, d => {
          const draft = d;
          draft.placeList = placeList;
          draft.isFetch = true;
        });
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;

        return produce(state, d => {
          const draft = d;
          draft.error.code = data.meta.code;
          draft.error.message = data.meta.message;
        });
      },
    }),

    [Actions.CLEAR]: () => {
      return {
        ...initialState,
      };
    },
  },
  initialState,
);
