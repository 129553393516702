import React, { Component } from 'react';
import { number, string } from 'prop-types';
import classNames from 'classnames';

// 라인 화살표 컴포넌트입니다.
// 기본 형태는 아래로 향해있으며 rotate값으로 화살표 방향을 결정할 수 있습니다.
// className으로 회살표 위치를 정할 수 있습니다.
// 주로 화살표의 위치가 보통 오른쪽 끝, 위아래 중앙에 있기 때문에 공통 클래스로 조절할 수 있게 만들었습니다.
// ex) abs_v_r0 : 위아래 중앙 오른쪽 가장 끝 / abs_v_r5 : 위아래 중앙 오른쪽 끝에서 5px 안쪽에 위치
// 5단위로 0부터 최대 20까지 있습니다.

class IcArrowLine extends Component {
  render() {
    const { width, height, stroke, color, className, rotate } = this.props;
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 11 6"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames(className, `rotate${rotate}`)}
      >
        <g
          id="Symbols"
          stroke="none"
          strokeWidth={stroke}
          fill="none"
          fillRule="evenodd"
          strokeLinejoin="round"
        >
          <g
            id="icon/arrow/nextpage-copy-3"
            transform="translate(-3.000000, -5.000000)"
            fill={color}
            stroke={color}
          >
            <path
              d="M10.3750737,3.22728734 L6.51256972,7.44169561 C6.4489945,7.51106304 6.3853214,7.5803407 6.32155061,7.64952839 L6.20860342,7.7720697 C6.09059344,7.90010384 6.09010792,8.09712665 6.20748544,8.22574085 L6.41720684,8.45553912 L10.3750416,12.772742 C10.4805122,12.8877892 10.6592771,12.8955526 10.7743243,12.7900821 C10.7803622,12.7845467 10.7861567,12.7787514 10.7916912,12.7727127 C10.9097608,12.643886 10.9104106,12.4463729 10.7931913,12.3167722 L6.88406278,7.99473572 L10.792928,3.68298934 C10.9102611,3.55356282 10.9097251,3.35607508 10.7916912,3.22728734 C10.6862518,3.11224158 10.5075133,3.10445409 10.3924675,3.2098935 C10.3864198,3.2154362 10.3806164,3.22123965 10.3750737,3.22728734 Z"
              id="ic_arrow"
              transform="translate(8.500000, 8.000000) rotate(-90.000000) translate(-8.500000, -8.000000) "
            />
          </g>
        </g>
      </svg>
    );
  }
}

IcArrowLine.defaultProps = {
  width: 11,
  height: 6,
  stroke: 1,
  color: `#333333`,
  className: '',
  rotate: 0,
};

IcArrowLine.propTypes = {
  width: number,
  height: number,
  stroke: number,
  color: string,
  className: string,
  rotate: number,
};

export default IcArrowLine;
