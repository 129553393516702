import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import * as Mileage from '../../actions/service/MileageService';

const initialState = {
  myPoint: 0,
  myPointLog: [],
  resultLog: null,
  pointTry: {
    maxCount: 3,
    tryCount: 0,
  },
};

export const getTotal = createAction(
  Mileage.default.GET_TOTAL,
  Mileage.getTotal,
);
export const pointAdd = createAction(
  Mileage.default.POINT_ADD,
  Mileage.pointAdd,
);

export const setMyPointCalc = createAction(
  Mileage.default.SET_MY_POINT_CALC,
  Mileage.setMyPointCalc,
);

export const setMyUseTryPointCalc = createAction(
  Mileage.default.SET_MY_USE_TRY_POINT_CALC,
  Mileage.setMyUseTryPointCalc,
);
export const postCompleteOrder = createAction(
  Mileage.default.POST_COMPLETE_ORDER,
  Mileage.postCompleteOrder,
);
export const postCompleteBuy = createAction(
  Mileage.default.POST_COMPLETE_BUY,
  Mileage.postCompleteBuy,
);

export default handleActions(
  {
    ...pender({
      type: Mileage.default.GET_TOTAL,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          myPoint: data.data.point,
        };
      },
    }),
    ...pender({
      type: Mileage.default.POINT_ADD,
      onSuccess: (state, action) => ({ ...state }),
    }),
    [Mileage.default.SET_MY_POINT_CALC]: (state, action) => {
      return {
        ...state,
        myPoint: state.myPoint + action.payload,
      };
    },

    [Mileage.default.SET_MY_USE_TRY_POINT_CALC]: (state, action) => {
      return {
        ...state,
        pointTry: {
          ...state.pointTry,
          tryCount: state.pointTry.tryCount + action.payload,
        },
      };
    },
    ...pender({
      type: Mileage.default.POST_COMPLETE_ORDER,
      onSuccess: (state, action) => ({ ...state }),
    }),
    ...pender({
      type: Mileage.default.POST_COMPLETE_BUY,
      onSuccess: (state, action) => ({ ...state }),
    }),
  },
  initialState,
);
