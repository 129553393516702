import { createAction, handleActions } from 'redux-actions';
import moment from 'moment';
import { pender } from 'redux-pender';

import * as MyLocation from '../../actions/service/MyLocationService';

// 우리동네 리스트
export const getMyLocationList = createAction(
  MyLocation.default.GET_MY_LOCATION_LIST,
  MyLocation.getMyLocationList,
);
// 우리동네 리스트
export const setMyLocationList = createAction(
  MyLocation.default.SET_MY_LOCATION_LIST,
);
// 우리동네 추가
export const postMyLocationList = createAction(
  MyLocation.default.POST_MY_LOCATION_LIST,
  MyLocation.postMyLocationList,
);
// 우리동네 삭제
export const deleteMyLocationList = createAction(
  MyLocation.default.DELETE_MY_LOCATION_LIST,
  MyLocation.deleteMyLocationList,
);
// 최근검색한동네
export const getMyLocationHistory = createAction(
  MyLocation.default.GET_MY_LOCATION_HISTORY,
  MyLocation.getMyLocationHistory,
);
// 즐겨찾기 추가
export const postFavorite = createAction(
  MyLocation.default.POST_FAVORITE,
  MyLocation.postFavorite,
);
// 즐겨찾기 삭제
export const deleteFavorite = createAction(
  MyLocation.default.DELETE_FAVORITE,
  MyLocation.deleteFavorite,
);
// 시구동 검색
export const getMyLocationDong = createAction(
  MyLocation.default.GET_MY_LOCATION_DONG,
  MyLocation.getMyLocationDong,
);
export const getMyLocationDongBcode = createAction(
  MyLocation.default.GET_MY_LOCATION_DONG_BCODE,
  MyLocation.getMyLocationDongBcode,
);
// 랜드마크신청
export const postMyLocationLandmark = createAction(
  MyLocation.default.POST_MY_LOCATION_LANDMARK,
  MyLocation.postMyLocationLandmark,
);
// 우리동네 중복체크
export const getMylocationCheck = createAction(
  MyLocation.default.GET_MY_LOCATION_CHECK,
  MyLocation.getMylocationCheck,
);
// latlon 으로 locationVo가져오기
export const getLocationVo = createAction(
  MyLocation.default.GET_LOCATION_VO,
  MyLocation.getLocationVo,
);

const initialState = {
  myList: [],
  myHistory: [],
  updateMyLocation: moment(),
  doList: [],
  siguList: [],
  dongList: [],
  postLandmarkSuccess: moment(),

  // 우리동네 중복체크
  myLocationCheck: 0,
  myLocationCheckUpdate: moment(),

  getMyLocationDongBcodeTime: moment(),
  locationVO: {},
};

export default handleActions(
  {
    ...pender({
      type: MyLocation.default.GET_MY_LOCATION_LIST,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          myList: data.data,
        };
      },
    }),
    [MyLocation.default.SET_MY_LOCATION_LIST]: (state, action) => {
      return {
        ...state,
        myList: action.payload,
      };
    },
    ...pender({
      type: MyLocation.default.GET_MY_LOCATION_HISTORY,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          myHistory: data.data,
        };
      },
    }),
    ...pender({
      type: MyLocation.default.POST_MY_LOCATION_LIST,
      onSuccess: (state, action) => {
        return {
          ...state,
          updateMyLocation: moment(),
        };
      },
    }),
    ...pender({
      type: MyLocation.default.DELETE_MY_LOCATION_LIST,
      onSuccess: (state, action) => {
        return {
          ...state,
          updateMyLocation: moment(),
        };
      },
    }),
    ...pender({
      type: MyLocation.default.POST_FAVORITE,
      onSuccess: (state, action) => {
        return {
          ...state,
          updateMyLocation: moment(),
        };
      },
      onFailure: (state, action) => {
        alert(action.payload.response.data.meta.message);
        return {
          ...state,
          updateMyLocation: moment(),
        };
      },
    }),
    ...pender({
      type: MyLocation.default.DELETE_FAVORITE,
      onSuccess: (state, action) => {
        return {
          ...state,
          updateMyLocation: moment(),
        };
      },
      onFailure: (state, action) => {
        alert(action.payload.response.data.meta.message);
        return {
          ...state,
          updateMyLocation: moment(),
        };
      },
    }),
    ...pender({
      type: MyLocation.default.GET_MY_LOCATION_DONG,
      onSuccess: (state, action) => {
        const { data, config } = action.payload;
        const type = parseInt(config.url.slice(config.url.indexOf('=') + 1));

        if (type == 1) {
          // 시
          state.doList = data.data;
          state.siguList = [];
          state.dongList = [];
        } else if (type == 2) {
          // 구
          state.siguList = data.data;
          state.dongList = [];
        } else if (type == 3) {
          // 동
          state.dongList = data.data;
        }
        return {
          ...state,
        };
      },
    }),
    ...pender({
      type: MyLocation.default.POST_MY_LOCATION_LANDMARK,
      onSuccess: (state, action) => {
        return {
          ...state,
          postLandmarkSuccess: moment(),
          siguList: [],
          dongList: [],
        };
      },
    }),
    ...pender({
      type: MyLocation.default.GET_MY_LOCATION_CHECK,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          myLocationCheck: data.data,
          myLocationCheckUpdate: moment(),
        };
      },
    }),
    ...pender({
      type: MyLocation.default.GET_MY_LOCATION_DONG_BCODE,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        if (data.data) {
          state.doList = data.data.siList ? data.data.siList : [];
          state.siguList = data.data.guList ? data.data.guList : [];
          state.dongList = data.data.dongList ? data.data.dongList : [];

          state.doList.map(item => {
            item.select = item.doCode == state.locationVO.doCode;
          });
          state.siguList.map(item => {
            item.select = item.siguCode == state.locationVO.siguCode;
          });
          state.dongList.map(item => {
            item.select = item.dongCode == state.locationVO.dongCode;
          });
        }
        return {
          ...state,
          getMyLocationDongBcodeTime: moment(),
        };
      },
    }),
    ...pender({
      type: MyLocation.default.GET_LOCATION_VO,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        let vo = {};
        if (data.data && data.data.length > 0) {
          data.data.map(item => {
            if (item.locationType == 0) vo = item;
          });
        }
        return {
          ...state,
          locationVO: vo,
        };
      },
    }),
  },
  initialState,
);
