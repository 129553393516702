import { getOsType } from './webView';

export const API = 'api';
export const apiType = {
  INIT: 'init',
  EDGE: 'edge',
  BASE: 'base',
  POINT: 'point',
  AUTO: 'auto',
  SEARCH: 'search',
  STANDARDPRICE: 'standardPrice',
  MARKETING: 'marketing',
  TICKETS: 'tickets',
};

export const config = {
  BOOT_API_URL: process.env.REACT_APP_BOOT_API_URL,
  EDGE_API_URL: process.env.REACT_APP_EDGE_API_URL,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  API_POINT_URL: process.env.REACT_APP_API_POINT_URL,
  AUTO_API_BASE_URL: process.env.REACT_APP_AUTO_API_BASE_URL,
  SEARCH_API_BASE_URL: process.env.REACT_APP_SEARCH_API_BASE_URL,
  STANDARD_PRICE_API_BASE_URL:
    process.env.REACT_APP_STANDARD_PRICE_API_BASE_URL,
  MARKETING_API_BASE_URL: process.env.REACT_APP_MARKETING_API_BASE_URL,
  TICKETS_API_BASE_URL: process.env.REACT_APP_TICKETS_API_BASE_URL,
  DEFAULT_URL: process.env.REACT_APP_DEFAULT_URL,
  SHARE_URL: process.env.REACT_APP_SHARE_URL,
  WEBVIEW_GA_URL: process.env.REACT_APP_WEBVIEW_GA_URL,
  PARTNER_CENTER_URL: process.env.REACT_APP_PARTNER_CENTER_URL,

  PARTERCENTER: process.env.REACT_APP_PARTERCENTER,
  PARTERCENTER_SSL: process.env.REACT_APP_PARTERCENTER_SSL,
  PARTERCENTER_MAIN: process.env.REACT_APP_PARTERCENTER_MAIN,
  PARTERCENTER_EARN: process.env.REACT_APP_PARTERCENTER_EARN,
  PARTERCENTER_SELL_LIST: process.env.REACT_APP_PARTERCENTER_SELL_LIST,
  PARTERCENTER_SSO: process.env.REACT_APP_PARTERCENTER_SSO,

  APP_UNICRO_URL: process.env.REACT_APP_UNICRO_URL,

  CDN_URL: 'https://img2.joongna.com',
  NAVER_API_KEY: process.env.REACT_APP_NAVER_API,
  KAKAO_API_KEY: process.env.REACT_APP_KAKAO_API,
  PAYCO_API: process.env.REACT_APP_PAYCO_API,
  UNICRO_URL: process.env.REACT_APP_UNICRO_URL,

  JSON_TYPE: 'application/json',
  FORM_TYPE: 'application/x-www-form-urlencoded',
  MULTIPART_TYPE: 'multipart/form-data',
};

/**
 * @param {string} URLtype
 * @param {string} type
 * axios 로그인 상태이면 헤더에 토큰 넣어서 보내고,
 * 아니면 토큰 없이 보내는 axios create 함수
 * @return {axios Object}
 */
export function joongnaAxios(URLtype = apiType.BASE, type = 'JSON') {
  const check = localStorage.getItem('jn_access_token');
  let contentType = config.JSON_TYPE;
  if (type === 'FORM') contentType = config.FORM_TYPE;
  if (type === 'MULTIPART') contentType = config.MULTIPART_TYPE;
  const headers = check
    ? {
        Authorization: `Bearer ${localStorage.getItem('jn_access_token')}`,
        'Content-Type': contentType,
        'Os-Type': getOsType(),
      }
    : {
        'Content-Type': contentType,
        'Os-Type': getOsType(),
      };

  let baseURL = config.API_BASE_URL;
  if (URLtype === apiType.POINT) baseURL = config.API_POINT_URL;
  else if (URLtype === apiType.EDGE) baseURL = config.EDGE_API_URL;
  else if (URLtype === apiType.AUTO) baseURL = config.AUTO_API_BASE_URL;
  else if (URLtype === apiType.SEARCH) baseURL = config.SEARCH_API_BASE_URL;
  else if (URLtype === apiType.STANDARDPRICE)
    baseURL = config.STANDARD_PRICE_API_BASE_URL;
  else if (URLtype === apiType.MARKETING)
    baseURL = config.MARKETING_API_BASE_URL;
  else if (URLtype === apiType.TICKETS) baseURL = config.TICKETS_API_BASE_URL;

  return {
    baseURL,
    headers,
  };
}

// 리팩토링 필요
export const baseApi = type => joongnaAxios(apiType.BASE, type);
export const edgeApi = type => joongnaAxios(apiType.EDGE, type);
export const pointApi = type => joongnaAxios(apiType.POINT, type);
export const autoApi = type => joongnaAxios(apiType.AUTO, type);
export const searchApi = type => joongnaAxios(apiType.SEARCH, type);
export const standardPriceApi = type =>
  joongnaAxios(apiType.STANDARDPRICE, type);
export const marketingApi = type => joongnaAxios(apiType.MARKETING, type);
export const ticketsApi = type => joongnaAxios(apiType.TICKETS, type);

export function joongnaAxiosNoHeader(URLtype = apiType.BASE, type = 'JSON') {
  let contentType = config.JSON_TYPE;
  if (type === 'FORM') contentType = config.FORM_TYPE;
  if (type === 'MULTIPART') contentType = config.MULTIPART_TYPE;
  const headers = {
    'Content-Type': contentType,
    'Os-Type': getOsType(),
  };

  let baseURL = config.API_BASE_URL;
  if (URLtype === apiType.EDGE) baseURL = config.EDGE_API_URL;
  else if (URLtype === apiType.POINT) baseURL = config.API_POINT_URL;
  else if (URLtype === apiType.AUTO) baseURL = config.AUTO_API_BASE_URL;
  else if (URLtype === apiType.SEARCH) baseURL = config.SEARCH_API_BASE_URL;
  else if (URLtype === apiType.STANDARDPRICE)
    baseURL = config.STANDARD_PRICE_API_BASE_URL;
  else if (URLtype === apiType.MARKETING)
    baseURL = config.MARKETING_API_BASE_URL;
  else if (URLtype === apiType.TICKETS) baseURL = config.TICKETS_API_BASE_URL;

  return {
    baseURL,
    headers,
  };
}

// 리팩토링 필요
export const baseApiNoHeader = type => joongnaAxiosNoHeader(apiType.BASE, type);
export const productApiNoHeader = type =>
  joongnaAxiosNoHeader(apiType.EDGE, type);
export const pointApiNoHeader = type =>
  joongnaAxiosNoHeader(apiType.POINT, type);
export const autoApiNoHeader = type => joongnaAxiosNoHeader(apiType.AUTO, type);
export const searchApiNoHeader = type =>
  joongnaAxiosNoHeader(apiType.SEARCH, type);
export const standardPriceApiNoHeader = type =>
  joongnaAxiosNoHeader(apiType.STANDARDPRICE, type);
export const marketingApiNoHeader = type =>
  joongnaAxiosNoHeader(apiType.MARKETING, type);
export const ticketsApiNoHeader = type =>
  joongnaAxiosNoHeader(apiType.TICKETS, type);
