import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import { ToastReducer } from '../../reducers/actionCreators';
import { getQueryString } from '../../common/util';
import {
  isApp,
  interfaceCloseWebView,
  interfaceSetDelivery,
} from '../../common/webView';

import Header from '../common/Header/Header';

class AddressSearchComponent extends Component {
  componentDidMount() {
    this.add_daum_script();
  }

  add_daum_script = () => {
    const id = 'daumScript';
    let script = document.getElementById(id);

    if (script) {
      this.initial();
      return false;
    }

    script = window.document.createElement('script');
    script.id = id;
    script.src =
      'https://ssl.daumcdn.net/dmaps/map_js_init/postcode.v2.js?autoload=false';
    script.onload = this.initial;

    window.document.body.appendChild(script);
  };

  shippingAddrAction = async data => {
    const addressObj = {
      deliveryAddress: data.address,
      deliveryAddressDetail: null,
      deliveryUserName: null,
      deliveryUserPhoneNo: null,
      deliveryPostNo: data.zonecode,
    };

    if (isApp()) {
      await interfaceSetDelivery(addressObj);
      interfaceCloseWebView();
    } else {
      ToastReducer.open('잠시 후 다시 시도해주세요.');
    }
  };

  initial = () => {
    const self = this;

    const element_wrap = document.getElementById('daumSection');
    if (Boolean(element_wrap) == false) {
      return false;
    }

    // load함수를 이용하여 core스크립트의 로딩이 완료된 후, 우편번호 서비스를 실행합니다.
    window.daum.postcode.load(() => {
      new window.daum.Postcode({
        oncomplete(data) {
          const shippingAddr = getQueryString('shippingAddr');
          if (shippingAddr.length > 0 && shippingAddr === 'false') {
            self.shippingAddrAction(data);
          } else {
            self.props.onSearchDeliveryValue(data);
          }
        },
        // 우편번호 찾기 화면 크기가 조정되었을때 실행할 코드를 작성하는 부분. iframe을 넣은 element의 높이값을 조정한다.
        onresize(size) {
          element_wrap.style.height = `${size.height}px`;
        },
        width: '100%',
        height: '100%',
      }).embed(element_wrap, { autoClose: false });
    });
  };

  render() {
    const { className, hiddenHeader, goBack } = this.props;
    return (
      <>
        <Header
          title={hiddenHeader ? '접수 가능 지역' : '주소검색'}
          leftBtnOption={{
            onClick: goBack,
          }}
        />

        {/* content all START */}
        <section id="daumSection" className={className} />
      </>
    );
  }
}

AddressSearchComponent.propTypes = {
  className: string,
  hiddenHeader: bool,
  onSearchDeliveryValue: func.isRequired,
  goBack: func,
};

AddressSearchComponent.defaultProps = {
  className: '',
  hiddenHeader: false,
  goBack: () => {},
};

export default AddressSearchComponent;
