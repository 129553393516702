import axios from 'axios';
import config from '../config';
import { getHeaders } from '../apiUtil';
import { getAppDatabyStorage } from '../../common/webView';

const baseURL = `${config.API_BASE_URL}`;
const baseOrderURL = `${config.ORDER_API_BASE_URL}`;

// v2 버전은 상품수, 단골 수 나오지 않음.
export function getFollow(type, storeSeq, startIndex = 0, searchQuantity = 20) {
  if (storeSeq === -1) {
    return axios({
      method: 'get',
      url: `/v2/mypage/follow/${type}?startIndex=${startIndex}&searchQuantity=${searchQuantity}`,
      baseURL,
      headers: getHeaders(),
    });
  }

  return axios({
    method: 'get',
    url: `/v2/mypage/follow/${type}/${storeSeq}?startIndex=${startIndex}&searchQuantity=${searchQuantity}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 거래내역 버튼 : 거래 취소
 * cancelData
 * @param {String} orderNo - 주문번호
 * @param {Number} returnCd - 취소사유 코드
 * @param {String} returnMemo - 취소사유 메모
 * type - 판매취소: sell, 구매취소 : buy
 */

export const putSellerCancel = (cancelData, type) => {
  const typeStr = `${type}er`;

  return axios({
    method: 'put',
    url: `/order/unicro/${typeStr}/cancel`,
    data: cancelData,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 거래내역 버튼 : 거래 취소
 * cancelData
 * @param {String} orderNo - 주문번호
 * @param {Number} returnCd - 취소사유 코드
 * @param {String} returnMemo - 취소사유 메모
 * type - 판매취소: sell, 구매취소 : buy
 */

export const putSellerCancelBySettle = (cancelData, type) => {
  const typeStr = `${type}er`;

  return axios({
    method: 'put',
    url: `/order/cancel/${typeStr}`,
    data: cancelData,
    baseURL,
    headers: getHeaders(),
  });
};
/**
 * 거래내역 버튼 : 주문확인 팝업
 * (판매수량 체크, 구매량 체크하여 팝업/팝업의 경고 문구 노출 여부)
 * @param {string | number} orderNo - 주문번호
 */
export const getOrderAcceptPopup = (orderNo, getPath = false) => {
  if (getPath) return `${baseURL}/order/unicro/seller/accept/popup/${orderNo}`;

  return axios({
    method: 'get',
    url: `/order/unicro/seller/accept/popup/${orderNo}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 거래내역 버튼 : 주문확인
 * @param {string | number} orderNo - 주문번호
 */
export const putOrderAccept = orderNo => {
  return axios({
    method: 'put',
    url: `/order/unicro/seller/accept/${orderNo}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 거래내역 버튼 : 거래취소요청
 * @param {String} orderNo - 주문번호
 * @param {Number} returnCd - 취소사유 코드
 * @param {String} returnMemo - 취소사유 메모
 */
export function putOrderCancelRequest({
  orderNo,
  returnCd = '',
  returnMemo = '',
}) {
  const data = {
    orderNo,
    returnCd,
    returnMemo,
  };

  return axios({
    method: 'put',
    url: `/order/unicro/buyer/cancel/request`,
    baseURL,
    data,
    headers: getHeaders(),
  });
}

/**
 * 거래내역 버튼 : 구매취소요청 거절
 * @param {String} orderNo - 주문번호
 */
// 유니크로 구매자의 구매취소요청 거절
export function putOrderCancelReject(orderNo) {
  return axios({
    method: 'put',
    url: `/order/unicro/seller/cancel/reject/${orderNo}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 거래내역 버튼 : 구매취소요청 수락
 * @param {String} orderNo - 주문번호
 */
// 유니크로 구매자의 구매취소요청 수락
export function putOrderCancelAccept(orderNo) {
  return axios({
    method: 'put',
    url: `/order/unicro/seller/cancel/accept/${orderNo}`,
    baseURL,
    headers: getHeaders(),
  });
}

// 세틀 구매자의 구매취소요청 수락
export function putOrderCancelAcceptBySettle(orderNo) {
  return axios({
    method: 'put',
    url: `/order/cancel/buyer/request/${orderNo}`,
    baseURL,
    headers: getHeaders(),
  });
}
/**
 * 거래내역 버튼 : 구매확정
 * @param {string | number} orderNo - 주문번호
 */
export function putOrderPurchaseConfirm(orderNo) {
  return axios({
    method: 'put',
    url: `/order/unicro/buyer/completed/${orderNo}`,
    baseURL,
    headers: getHeaders(),
  });
}
/**
 * 거래내역 버튼 : 구매확정
 * @param {string | number} orderNo - 주문번호
 */
export function putOrderPurchaseConfirmBySettle(orderNo) {
  return axios({
    method: 'put',
    url: `/order/completed/${orderNo}`,
    baseURL,
    headers: getHeaders(),
  });
}
/**
 * 거래내역 버튼 : 반품신청
 * @param {string | number} orderNo - 주문번호
 */
export const putOrderReturnRequest = ({
  orderNo,
  returnCd = '',
  returnMemo = '',
}) => {
  const data = {
    orderNo,
    returnCd,
    returnMemo,
  };
  return axios({
    method: 'put',
    url: `/order/unicro/buyer/return/request`,
    baseURL,
    data,
    headers: getHeaders(),
  });
};

/**
 * 거래내역 버튼 : 반품확인
 * @param {string | number} orderNo - 주문번호
 */
export const putOrderReturnConfirm = orderNo => {
  return axios({
    method: 'put',
    url: `/order/unicro/seller/delivery/${orderNo}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 거래내역 버튼 : 반품확인
 * @param {string | number} orderNo - 주문번호
 */
export const putOrderReturnConfirmBySettle = orderNo => {
  return axios({
    method: 'put',
    url: `/order/return/completed/${orderNo}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 거래내역 버튼 : 반품확인
 * @param {string | number} orderNo - 주문번호
 */
export const checkValidDeliveryInfo = data => {
  return axios({
    method: 'post',
    url: `/order/unicro/seller/delivery/valid`,
    baseURL,
    data,
    headers: getHeaders(),
  });
};

export async function getPeaceToken(productSeq) {
  const result = axios({
    method: 'get',
    url: `/order/peace/${productSeq}`,
    baseURL,
    headers: getHeaders(),
  });

  if (result.status === 200) return result.data.data;
  return '';
}

/**
 * 상품 결제 정보
 */
export const getOrderInfo = (productSeq, getPath = false) => {
  if (getPath) return `${baseURL}/v2/order/${productSeq}`;

  return axios({
    method: 'get',
    url: `/v2/order/${productSeq}`,
    baseURL,
    headers: getHeaders(),
  });
};

export const getOrderCheck = (productSeq, getPath = false) => {
  if (getPath) return `${baseURL}/v2/order/check/${productSeq}`;

  return axios({
    method: 'get',
    url: `/v2/order/check/${productSeq}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 거래내역리스트
 */
export const getOrderHistoryList = (
  type = 'buy',
  {
    title = '',
    status = '',
    period = '',
    dealType = '',
    isPaymentPending = '',
  },
  startIndex = 0,
  searchQuantity = 20,
  getPath = false,
) => {
  if (getPath) {
    return `${baseURL}/v2/order/history/${type}?title=${title}&status=${status}&period=${period}&dealType=${dealType}&isPaymentPending=${isPaymentPending}&startIndex=${startIndex}&searchQuantity=${searchQuantity}`;
  }

  return axios({
    method: 'get',
    url: `/v2/order/history/${type}?title=${title}&status=${status}&period=${period}&dealType=${dealType}&isPaymentPending=${isPaymentPending}&startIndex=${startIndex}&searchQuantity=${searchQuantity}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 거래상세리스트
 */
export const getOrderHistoryDetail = (
  type = 'buy',
  orderNo,
  getPath = false,
) => {
  if (getPath) {
    return `${baseURL}/v2/order/history/${type}/detail?orderNo=${orderNo}`;
  }

  return axios({
    method: 'get',
    url: `/v2/order/history/${type}/detail?orderNo=${orderNo}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 주문최종상태
 */
export const getCurrentOrderState = orderNo => {
  return axios({
    method: 'get',
    url: `/v2/order/history/current/?orderNo=${orderNo}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 주문삭제처리
 */
export const deleteOrderHistory = orderNo => {
  return axios({
    method: 'delete',
    url: `/v2/order/history?orderNo=${orderNo}`,
    baseURL,
    headers: getHeaders(),
  });
};

export const postOrderUnicro = data => {
  return axios({
    method: 'post',
    url: `/v2/order/unicro`,
    data,
    baseURL,
    headers: getHeaders(),
  });
};

export const getUnicroCompleted = (aspTradeNo, result, getPath = false) => {
  if (getPath) {
    return `${baseURL}/v2/order/unicro/completed?aspTradeNo=${aspTradeNo}&result=${result}`;
  }

  return axios({
    method: 'get',
    url: `/v2/order/unicro/completed?aspTradeNo=${aspTradeNo}&result=${result}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 주문서 정보 조회
 * @param {Number} productSeq
 */
export const postOrdersPrePared = productSeq => {
  const userAgentData = getAppDatabyStorage() || {};

  return axios({
    method: 'post',
    url: `/api/orders/prepared`,
    baseURL: baseOrderURL,
    headers: {
      ...getHeaders(),
      'Os-Version': userAgentData['Os-Version'] || null,
    },
    data: {
      productSeq,
    },
  });
};

/**
 * 중고나라 페이-세틀뱅크 팝업 구성을 위한 결제정보 조회
 * @param {String} url
 * @param {String} orderId
 * @param {Object} dataJson
 */
export const putPayPrePared = (url, orderId, dataJson) => {
  return axios({
    method: 'put',
    url: `${url}${orderId}`,
    headers: getHeaders(),
    data: dataJson,
  });
};

export const postJoongnaPayAgree = () => {
  return axios({
    method: 'post',
    url: `/order/joongnapay/terms`,
    baseURL,
    headers: getHeaders(),
  });
};

export const postJoongnaPayRefund = orderNo => {
  return axios({
    method: 'post',
    url: `/order/buyer/refund/account/${orderNo}`,
    baseURL,
    headers: getHeaders(),
  });
};
