import { createAction, handleActions } from 'redux-actions';

const OPEN = 'alert/OPEN';
const CLOSE = 'alert/CLOSE';

const initialState = {
  show: false,
  className: '',
  text: '잠시 후 다시 시도해주세요.',
  title: '',
  subText: '',
  bottomText: '확인',
  bottomBackground: 'white',
  onClick: () => {},
  onCancle: () => {},
};

export const open = createAction(OPEN);
export const close = createAction(CLOSE);

export default handleActions(
  {
    [OPEN]: (state, { payload }) => {
      return payload
        ? {
            ...state,
            show: true,
            className: payload.className || initialState.className,
            text: payload.text || initialState.text,
            title: payload.title || initialState.title,
            subText: payload.subText || initialState.subText,
            bottomText: payload.bottomText || initialState.bottomText,
            bottomBackground:
              payload.bottomBackground || initialState.bottomBackground,
            onClick: payload.onClick || initialState.onClick,
            onCancle: payload.onCancle || initialState.onCancle,
          }
        : { ...initialState, show: true };
    },
    [CLOSE]: state => {
      return { ...state, show: false };
    },
  },
  initialState,
);
