export default {
  BOOT_API_URL: process.env.REACT_APP_BOOT_API_URL,
  EDGE_API_URL: process.env.REACT_APP_EDGE_API_URL,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  API_POINT_URL: process.env.REACT_APP_API_POINT_URL,
  AUTO_API_BASE_URL: process.env.REACT_APP_AUTO_API_BASE_URL,
  SEARCH_API_BASE_URL: process.env.REACT_APP_SEARCH_API_BASE_URL,
  STANDARD_PRICE_API_BASE_URL:
    process.env.REACT_APP_STANDARD_PRICE_API_BASE_URL,
  MARKETING_API_BASE_URL: process.env.REACT_APP_MARKETING_API_BASE_URL,
  TICKETS_API_BASE_URL: process.env.REACT_APP_TICKETS_API_BASE_URL,
  ORDER_API_BASE_URL: process.env.REACT_APP_ORDER_API_BASE_URL,

  JSON_TYPE: 'application/json',
  FORM_TYPE: 'application/x-www-form-urlencoded',
  MULTIPART_TYPE: 'multipart/form-data',
};
