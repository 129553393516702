import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import classNames from 'classnames';
import { bool, func, string } from 'prop-types';

import useHistory from '../../../common/Hook/useHistory';
import { AuthReducer } from '../../../reducers/actionCreators';
import PopUp from '../popup/PopUp';
import Image from '../Image/Image';

import { interfaceAppLoginPopup } from '../../../common/webView';
import { isAndroid, isIOS } from '../../../common/device';
import { parseDeepLink } from '../../../common/parser';
import { getToken } from '../../../common/auth';
import {
  goApp,
  getQueryString,
  getQueryStringObj,
  objToQueryString,
} from '../../../common/util';

import JoongNaCI from '../../../asset/img/ci-squircle.png';
import styles from './AuthAlert.module.scss';

const AuthAlert = ({ show, has, not, close, redirectUrl, external }) => {
  const {
    location: { pathname, search },
  } = useHistory();
  const { isApp } = useSelector(({ userLoginInfo }) => userLoginInfo);
  const sendFrom = getQueryString('sendfrom');

  const [isShow, setIsShow] = useState(false);
  const [parseLink, setParseLink] = useState({});

  const onClickGoApp = () => {
    if (parseLink.deepLink) {
      goApp(parseLink.link);
    } else {
      goApp();
    }
  };

  const clickClose = () => {
    close();
    AuthReducer.close();
  };

  useEffect(() => {
    // auth 확인
    if (show) {
      // 토큰 있는지만 확인 ( vaild 는 안함 )
      const token = getToken();
      if (token && token !== '') {
        has();
        AuthReducer.close();
      } else if (isApp) {
        interfaceAppLoginPopup();
        AuthReducer.close();
      } else {
        // 토큰 없으면 로그인 얼럿 띄움
        setIsShow(true);
      }
    } else {
      setIsShow(false);
    }
  }, [show]);

  useEffect(() => {
    if (sendFrom.length > 0) {
      const obj = getQueryStringObj();
      delete obj.sendfrom;
      const newSearch = objToQueryString(obj);

      const parse = parseDeepLink(pathname + newSearch);

      if (parse.deepLink && (isAndroid() || isIOS())) {
        goApp(parse.link);
      }
      setParseLink(parse);
    } else {
      const parse = parseDeepLink(redirectUrl || pathname + search);
      setParseLink(parse);
    }
  }, [sendFrom, show]);

  return (
    <PopUp show={isShow}>
      <div className={classNames(styles.alert)}>
        <div className={styles.body}>
          <div className={styles.icon}>
            <Image src={JoongNaCI} alt="중고나라 CI" noExpand />
          </div>
          <p className="f16 mt8">
            <strong>중고나라</strong>
          </p>
          <p className="f16 mt4">2,400만 회원 국내 최대 중고장터</p>
          <div className={styles.border} />
          <p className="f16">해당 기능은 앱으로 이용 가능합니다.</p>
          <button type="button" className={styles.btn} onClick={onClickGoApp}>
            중고나라 앱 사용하기
          </button>
        </div>
      </div>
      <div className={styles.outerNotify}>
        <button type="button" onClick={clickClose}>
          괜찮습니다.
        </button>
      </div>
    </PopUp>
  );
};

AuthAlert.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  show: bool.isRequired,
  has: func.isRequired,
  not: func.isRequired,
  close: func.isRequired,
  redirectUrl: string,
  external: string.isRequired,
};

AuthAlert.defaultProps = {
  redirectUrl: null,
};

export default connect(state => ({
  show: state.auth.show,
  has: state.auth.has,
  not: state.auth.not,
  close: state.auth.close,
  redirectUrl: state.auth.redirectUrl,
  external: state.auth.external,
}))(AuthAlert);
