import axios from 'axios';
import config from '../config';
import { getHeaders } from '../apiUtil';

const baseURL = `${config.API_BASE_URL}`;

/**
 * 이벤트 리스트 호출
 * @param {Number} eventType 0: 이벤트, 1: 당첨자 발표
 * @param {Number} startIndex 시작 인덱스, 0 이상, default 0
 * @param {Number} searchQuantity 검색 수량 default 20
 * @returns
 */
export const getEventList = ({
  eventType = 0,
  startIndex = 0,
  searchQuantity = 20,
}) => {
  // osType : OS 타입 1:안드로이드, 2:iOS, 3:모바일웹
  // deviceType : 기기 타입 1:APP, 2:WEB
  const path = `/mypage/event?osType=3&deviceType=2&eventType=${eventType}&startIndex=${startIndex}&searchQuantity=${searchQuantity}`;

  return axios({
    method: 'get',
    url: path,
    baseURL,
    headers: getHeaders(),
  });
};

export function postFollow(storeSeq) {
  return axios({
    method: 'post',
    url: `/mypage/follow/${storeSeq}`,
    baseURL,
    headers: getHeaders(),
  });
}

export function deleteFollow(storeSeq) {
  return axios({
    method: 'delete',
    url: `/mypage/follow/${storeSeq}`,
    baseURL,
    headers: getHeaders(),
  });
}

// v2 버전은 상품수, 단골 수 나오지 않음.
export function getFollow(type, storeSeq, startIndex = 0, searchQuantity = 20) {
  if (storeSeq === -1) {
    return axios({
      method: 'get',
      url: `/v2/mypage/follow/${type}?startIndex=${startIndex}&searchQuantity=${searchQuantity}`,
      baseURL,
      headers: getHeaders(),
    });
  }

  return axios({
    method: 'get',
    url: `/v2/mypage/follow/${type}/${storeSeq}?startIndex=${startIndex}&searchQuantity=${searchQuantity}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 이용안내 조회
 */

export const getCsInfo = () => {
  return axios({
    method: 'get',
    url: `/mypage/user-guide`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 이용안내 상세 조회
 * @param {Number} useInfoSeq - 이용안내 seq
 */

export const getCsInfoDetail = useInfoSeq => {
  return axios({
    method: 'get',
    url: `/mypage/user-guide/${useInfoSeq}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 1:1문의 - 문의유형조회 API
 * @param {string} cateType - 문의하려는 서비스 분야 타입 // 0: 앱(구버전), 1: 카페, 2: 앱(신버전), newAppVerAndCafeType: 앱(FE-492)
 */

export const getQnaCategory = (cateType, getPath = false) => {
  if (getPath) return `${baseURL}/mypage/qna/category/${cateType}`;

  return axios({
    method: 'get',
    url: `/mypage/qna/category/${cateType}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 1:1문의 - 문의 조회 API
 * @param {Number} startIndex - 문의내역 목록 시작 카운트
 * @param {Number} searchQuantity - 문의내역 목록 개수
 */

export const getQnaList = (
  startIndex = 0,
  searchQuantity = 30,
  getPath = false,
) => {
  if (getPath)
    return `${baseURL}/mypage/qna?startIndex=${startIndex}&searchQuantity=${searchQuantity}`;
  return axios({
    method: 'get',
    url: `/mypage/qna?startIndex=${startIndex}&searchQuantity=${searchQuantity}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 1:1문의 - 문의 상세 조회 API
 * @param {Number} qnaSeq - 문의 번호
 */

export const getQnaDetail = (qnaSeq, getPath = false) => {
  if (getPath) return `${baseURL}/mypage/qna/${qnaSeq}`;

  return axios({
    method: 'get',
    url: `/mypage/qna/${qnaSeq}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 1:1문의 - 문의 등록
 * @param {String} appVersion - 문의
 * @param {Number} category - 문의유형 Seq
 * @param {String} content - 문의 내용
 * @param {String} deviceName
 * @param {Number} groupSeq
 * @param {String} orderNo
 * @param {Number} osType - OS 타입 (1: Android, 2: iOS, 3: MobileWeb)
 * @param {String} osVersion
 * @param {Number} productSeq
 * @param {Array} qnaImage - 첨부 사진
 * @param {String} title - 문의 제목
 */

export const postQnaAdd = (data, getPath = false) => {
  if (getPath) return `${baseURL}/mypage/qna/add`;

  return axios({
    method: 'post',
    url: `/mypage/qna/add`,
    baseURL,
    data,
    headers: getHeaders(),
  });
};

/**
 * 1:1문의 - 문의 재등록
 */

export const postQnaReadd = (data, getPath = false) => {
  if (getPath) return `${baseURL}/mypage/qna/readd`;

  return axios({
    method: 'post',
    url: `/mypage/qna/readd`,
    baseURL,
    data,
    headers: getHeaders(),
  });
};

/**
 * 1:1문의 - 제안하기
 */

export const postFaqSuggest = (data, getPath = false) => {
  if (getPath) return `${baseURL}/mypage/qna/opinion`;

  return axios({
    method: 'post',
    url: `/mypage/qna/opinion`,
    baseURL,
    data,
    headers: getHeaders(),
  });
};

// 고객센터 - FAQ 카테고리 조회 API
export function getFaqCategory() {
  return axios({
    method: 'get',
    url: `/mypage/faq/category`,
    baseURL,
    headers: getHeaders(),
  });
}

// 고객센터 - FAQ 카테고리별 리스트 조회 API
export function getFaqList(cateType, startIndex, searchQuantity) {
  return axios({
    method: 'get',
    url: `/mypage/faq?cateType=${cateType}&startIndex=${startIndex}&searchQuantity=${searchQuantity}`,
    baseURL,
    headers: getHeaders(),
  });
}

// 고객센터 - FAQ 검색 API
export function getFaqSearch(word, startIndex, searchQuantity) {
  const searchWord = encodeURIComponent(word);
  return axios({
    method: 'get',
    url: `/mypage/faq/search?word=${searchWord}&startIndex=${startIndex}&searchQuantity=${searchQuantity}`,
    baseURL,
    headers: getHeaders(),
  });
}

// 고객센터 - FAQ 조회 API
export function putFaqHit(faqSeq) {
  return axios({
    method: 'put',
    url: `/mypage/faq/hit/${faqSeq}`,
    baseURL,
    headers: getHeaders(),
  });
}
