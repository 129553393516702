import { useSelector } from 'react-redux';
import { getCookie } from './util';
import { config } from './api';
import { getToken } from './auth';
import { interfaceSendAppsflyer } from './webView';

import { postSearchUserGALog } from '../api/search/search';

const loginTypeList = [
  '이메일',
  '네이버',
  '카카오',
  '페이스북',
  '',
  '페이코',
  'Auto',
];

export const getGA = () => {
  const gaCookie = getCookie('_ga');
  if (!gaCookie) return '';
  return `-${gaCookie
    .split('.')
    .slice(2)
    .join('.')}`;
};

export const getLoginType = (isLogin, loginType) => {
  if (!isLogin) return null;
  return loginType < 0 ? 'Auto' : loginTypeList[loginType];
};

const getYN = (isLogin, data) => {
  if (!isLogin) return null;
  return data.map(d => (d === 0 ? 'N' : 'Y')).join('_');
};

export const GA = (addInfo, userLoginInfo) => {
  // ga태깅
  if (window.GA_PUSH) {
    const {
      isApp,
      loginType,
      marketing: { pushYn, smsYn, emailYn },
      gaUserInfo,
    } = userLoginInfo;

    const isLogin = !!getToken();

    const { search } = window.location;

    const queryString = addInfo.query || '';

    const GADATA = {
      // 태깅 종류. 빈값이면 PV, ga_event 이면 이벤트 태깅
      event: addInfo.event || 'ga_virtual',

      // 아래 3개는 모든 태깅에서 필수값.
      location: addInfo.location
        ? `${isApp ? config.WEBVIEW_GA_URL : config.DEFAULT_URL}${
            addInfo.location
          }${queryString}${queryString ? search.replace('?', '&') : search}`
        : null,
      title: addInfo.title ? `중나_${addInfo.title}` : '중고나라',
      userid: gaUserInfo && gaUserInfo.gaUserId,

      // 아래 3개는 이벤트 태깅시 필수값
      category: addInfo.category || null,
      action: addInfo.action || null,
      label: addInfo.label || null,
      value: addInfo.value || null,

      layerCategory: addInfo.layerCategory || null,
      layerAction: addInfo.layerAction || null,
      layerLabel: addInfo.layerLabel || null,
      ecommerce: addInfo.ecommerce || null,
      nonInteraction: addInfo.nonInteraction || false,

      // dimension 무료버전
      // 채널_중나파센_채널유형
      dimension2: isApp ? 'APP' : 'MWeb',
      // 고객_중나_USER_ID
      dimension3: (gaUserInfo && gaUserInfo.jnUserId) || null,
      // 검색_중나_검색어
      dimension4: addInfo.dimension4 || null, // dimension24 // 검색결과
      // 검색_중나_검색어유형
      dimension5: addInfo.dimension5 || null, // dimension25 // 검색결과
      // 검색_중나_검색분류
      dimension6: addInfo.dimension6 || null, // dimension26 // 검색결과
      // 고객_중나파센_로그인유형
      dimension7: getLoginType(isLogin, loginType),
      // 미정의
      dimension8: null,
      // 고객_중나_회원등급
      dimension9: (gaUserInfo && gaUserInfo.membershipDesc) || null,
      // 숏컷_광고주
      dimension10: null,
      // 숏컷_타이틀
      dimension11: null,
      // 고객_중나파센_성별
      dimension12: (gaUserInfo && gaUserInfo.gender) || null,
      // 고객_중나파센_연령대
      dimension13: (gaUserInfo && gaUserInfo.ageRange) || null,
      // 고객_중나_마케팅동의_PUSH_SMS_E-MAIL
      dimension14: getYN(isLogin, [pushYn, smsYn, emailYn]),
      // 상품등록_중나_판매자정보
      dimension15: addInfo.dimension15 || null, // 상품등록 완료 or 대기추가
      // 상품등록_중나_거래희망위치
      dimension16: addInfo.dimension16 || null, // 상품등록 완료 or 대기추가
      // 고객_중나_포인트
      dimension17: (gaUserInfo && gaUserInfo.pointRange) || null,
      // 구매_중나_판매자정보
      dimension18: addInfo.dimension18 || null, // 안전결제 구매완료
      // 구매_중나_거래희망위치
      dimension19: addInfo.dimension19 || null, // 안전결제 구매완료
      // 미정의
      dimension20: null,
    };

    window.GA_PUSH(GADATA);
    postSearchUserGALog(GADATA);
  }
};

const useGA = () => {
  const userLoginInfoData = useSelector(({ userLoginInfo }) => userLoginInfo);

  const sendGA = addInfo => {
    GA(addInfo, userLoginInfoData);
  };

  const sendEventGA = e => {
    const addInfo = {
      event: 'ga_event',
      ...e.currentTarget.dataset,
    };
    GA(addInfo, userLoginInfoData);
  };

  return {
    sendGA,
    sendEventGA,
    userLoginInfo: userLoginInfoData,
  };
};

export default useGA;
