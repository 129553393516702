import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { oneOfType, node, element, bool, func, string } from 'prop-types';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { isAndroid } from '../../../common/device';

import styles from './PopUp.module.scss';

// 나중에 에니메이션 넣어보기 -> 완성되면 bootstrap Modal 제거

const PopUp = ({
  show,
  warpClassName,
  contentClassName,
  onClickBack,
  children,
  type,
}) => {
  const [isShow, setIsShow] = useState(show);
  const contents = useRef();

  const addScrollLock = () => {
    setTimeout(() => {
      const possibleScrolls = document.getElementsByClassName('possibleScroll');

      for (let i = 0; i < possibleScrolls.length; i += 1) {
        disableBodyScroll(possibleScrolls[i]);
      }
      disableBodyScroll(contents.current);
      document.body.classList.add('scrollLock');
    }, 0);
  };

  const removeScrollLock = () => {
    const portal = document.getElementById('portal');

    // 이벤트 루프로 렌더링 이후 실행되도록
    setTimeout(() => {
      if (portal.children.length === 0) {
        document.body.classList.remove('scrollLock');
        clearAllBodyScrollLocks();
      }
    }, 0);
  };

  /**
   * transitionend 안먹는 현상이 가끔 발생.
   * 따라서 강제적으로 show와 isShow의 sync를 맞춰주는 작업이 필요함.
   * -> 더 좋은 로직 발견시 변경 요망
   */

  useEffect(() => {
    if (show) addScrollLock();
    else removeScrollLock();
    return () => {
      removeScrollLock();
    };
  }, []);

  useEffect(() => {
    const removeScrollLockF = () => {
      setIsShow(show);
      removeScrollLock();
    };

    if (show !== isShow) {
      if (show) {
        addScrollLock();
        setIsShow(show);
      } else if (contents.current) {
        contents.current.addEventListener('transitionend', removeScrollLockF);
      } else {
        setIsShow(show);
        removeScrollLock();
      }
    }

    return () => {
      if (contents.current) {
        contents.current.removeEventListener(
          'transitionend',
          removeScrollLockF,
        );
      }
    };
  }, [show]);

  if (show || isShow) {
    const el = document.getElementById('portal');

    return ReactDOM.createPortal(
      <div
        className={classNames(
          styles.popUpsWarp,
          isShow && styles.isShow,
          !show && isShow && styles.willHide,
          isAndroid() && 'mh100v',
          warpClassName,
        )}
      >
        <div
          className={styles.backGround}
          role="button"
          tabIndex="-1"
          onClick={onClickBack}
          onKeyPress={onClickBack}
        />
        <div
          className={classNames(
            styles.content,
            contentClassName,
            type === 'slideToRight' && styles.clip,
            type === 'slideToTop' && styles.letDown,
          )}
        >
          <div
            ref={contents}
            className={classNames(styles.transitionCont, styles[type])}
          >
            {children}
          </div>
        </div>
      </div>,
      el,
    );
  }
  return null;
};

PopUp.propTypes = {
  show: bool,
  type: string,
  onClickBack: func,
  warpClassName: string,
  contentClassName: string,
  children: oneOfType([node, element]),
};

PopUp.defaultProps = {
  show: false,
  type: 'fadeIn',
  onClickBack: () => {},
  warpClassName: '',
  contentClassName: '',
  children: null,
};

export default PopUp;
