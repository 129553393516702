import React from 'react';
import classNames from 'classnames';
import { string, bool } from 'prop-types';

import styles from './BorderLine.module.scss';

const BorderLine = ({ className, double, thin }) => {
  return (
    <div
      className={classNames(
        styles.borderLine,
        double && styles.double,
        thin && styles.thin,
        className,
      )}
    />
  );
};

BorderLine.propTypes = {
  className: string,
  double: bool,
  thin: bool,
};

BorderLine.defaultProps = {
  className: '',
  double: false,
  thin: false,
};

export default BorderLine;
