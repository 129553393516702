import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import produce from 'immer';

import { getUserMainAccount } from '../../api/base/user';

export const Actions = {
  CLEAR: 'ACCOUNT/CLEAR',
  GET_MAIN_ACCOUNT: 'ACCOUNT/GET_MAIN_ACCOUNT',
  SET_MAIN_ACCOUNT: 'ACCOUNT/SET_MAIN_ACCOUNT',
  CLEAR_MAIN_ACCOUNT: 'ACCOUNT/CLEAR_MAIN_ACCOUNT',
};

const getMainAccountFunc = async mainAccount => {
  if (mainAccount.accountSeq && mainAccount.accountSeq > 0) {
    return {
      info: mainAccount,
    };
  }
  try {
    const {
      data: { data },
    } = await getUserMainAccount();
    return {
      info: data,
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

export const clear = createAction(Actions.CLEAR);

export const getMainAccount = createAction(
  Actions.GET_MAIN_ACCOUNT,
  getMainAccountFunc,
);

export const setMainAccount = createAction(Actions.SET_MAIN_ACCOUNT, info => ({
  info,
}));

export const clearSelectAccount = createAction(Actions.CLEAR_MAIN_ACCOUNT);

const initialState = {
  // 유저가 선택한 계좌 ( 대표가 아닐수도 있음. )
  mainAccount: {
    accountHolder: '',
    accountNo: '',
    accountSeq: 0,
    bankCode: '',
    bankName: '',
    mainAccountYn: 0,
    userSeq: -1,
    isAccountsConfig: false,
  },

  // 에러
  error: {
    code: 200,
    message: '',
  },
};

export default handleActions(
  {
    [Actions.CLEAR]: () => {
      return {
        ...initialState,
      };
    },
    ...pender({
      type: Actions.GET_MAIN_ACCOUNT,
      onSuccess: (state, action) => {
        const { info } = action.payload;

        return produce(state, d => {
          const draft = d;
          draft.mainAccount = info || initialState.mainAccount;
        });
      },

      onFailure: (state, action) => {
        // const { data } = action.payload.response;
        return {
          ...state,
          // error: {
          //   code: data.meta.code,
          //   message: data.meta.message,
          // },
        };
      },
    }),

    [Actions.SET_MAIN_ACCOUNT]: (state, action) => {
      const { info } = action.payload;

      return produce(state, d => {
        const draft = d;
        draft.mainAccount = info || initialState.mainAccount;
      });
    },
    [Actions.CLEAR_MAIN_ACCOUNT]: state => {
      return produce(state, d => {
        const draft = d;
        draft.mainAccount = initialState.mainAccount;
      });
    },
  },
  initialState,
);
