import React, {
  forwardRef,
  useRef,
  useLayoutEffect,
  useImperativeHandle,
} from 'react';
import { oneOfType, node, element, string, bool } from 'prop-types';

import { isApp } from '../../common/webView';

const Page = forwardRef(({ children, className, updateHeight }, ref) => {
  const page = useRef(null);

  // 부모가 ref를 필요로 할 경우 부모 ref에 page 할당
  useImperativeHandle(ref, () => page.current);

  useLayoutEffect(() => {
    function updateSize() {
      if (page.current) {
        page.current.style.height = `${window.innerHeight}px`;
        page.current.style.minHeight = 'auto';
      }
    }
    if (!isApp() || updateHeight) {
      window.addEventListener('resize', updateSize);
      updateSize();
    }

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div className={className} ref={page}>
      {children}
    </div>
  );
});

Page.propTypes = {
  children: oneOfType([node, element]),
  className: string,
  updateHeight: bool,
};

Page.defaultProps = {
  children: null,
  className: '',
  updateHeight: false,
};

export default Page;
