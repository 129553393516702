import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';

import {
  getCheckMyLocation as getCheckMyLocationApi,
  getMyLocationList as getMyLocationListApi,
  postMyLocationList as postMyLocationListApi,
  deleteMyLocationList as deleteMyLocationListApi,
} from '../../api/base/mylocation';

export const Actions = {
  GET_MYLOCATION_LIST: 'MYLOCATION/GET_MYLOCATION_LIST',
  POST_MYLOCATION_LIST: 'MYLOCATION/POST_MYLOCATION_LIST',
  DELETE_MYLOCATION_LIST: 'MYLOCATION/DELETE_MYLOCATION_LIST',

  GET_CHECK_MYLOCATION: 'MYLOCATION/GET_CHECK_MYLOCATION',

  CLEAR_MYLOCATION_LIST: 'MYLOCATION/CLEAR_MYLOCATION_LIST',
};

const initialState = {
  myLocationList: [],

  error: {
    code: 200,
    message: '',
  },
};

const addMyLocation = async (locationType, locationCode) => {
  try {
    await postMyLocationListApi(locationType, locationCode);
    const result = await getMyLocationListApi();
    return result;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getMyLocationList = createAction(
  Actions.GET_MYLOCATION_LIST,
  getMyLocationListApi,
);

export const postMyLocationList = createAction(
  Actions.POST_MYLOCATION_LIST,
  addMyLocation,
);

export const deleteMyLocationList = createAction(
  Actions.DELETE_MYLOCATION_LIST,
  deleteMyLocationListApi,
);

export const getCheckMyLocation = createAction(
  Actions.GET_CHECK_MYLOCATION,
  getCheckMyLocationApi,
);

export const clearMyLocationList = createAction(Actions.CLEAR_MYLOCATION_LIST);

export default handleActions(
  {
    ...pender({
      type: Actions.GET_MYLOCATION_LIST,
      onSuccess: (state, action) => {
        const {
          data: { data },
        } = action.payload;

        return {
          ...state,
          myLocationList: data,
          error: initialState.error,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),

    ...pender({
      type: Actions.POST_MYLOCATION_LIST,
      onSuccess: (state, action) => {
        const {
          data: { data },
        } = action.payload;

        return {
          ...state,
          myLocationList: data,
          error: initialState.error,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),

    ...pender({
      type: Actions.DELETE_MYLOCATION_LIST,
      onSuccess: state => {
        return {
          ...state,
          error: initialState.error,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),

    ...pender({
      type: Actions.GET_CHECK_MYLOCATION,
      onSuccess: state => {
        return {
          ...state,
          error: initialState.error,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),

    [Actions.CLEAR_MYLOCATION_LIST]: () => {
      return { ...initialState };
    },
  },
  initialState,
);
