/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import config from './config';
import { getOsType, getAppDatabyStorage, isApp } from '../common/webView';
import { getToken } from '../common/auth';

export function getHeaders(type = 'JSON') {
  let contentType = config.JSON_TYPE;
  if (type === 'FORM') contentType = config.FORM_TYPE;
  if (type === 'MULTIPART') contentType = config.MULTIPART_TYPE;

  const token = getToken();
  const userAgentData = getAppDatabyStorage(true) || {};
  // 웹뷰일 경우 uuid 값 세팅. userAgentData.uuid = undifined || '' || string
  const uuid =
    userAgentData.uuid && isApp() ? { device_uuid: userAgentData.uuid } : {};

  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
        'Content-Type': contentType,
        'Os-Type': getOsType(),
        'App-Version': userAgentData['App-Version'] || null,
        ...uuid,
      }
    : {
        'Content-Type': contentType,
        'Os-Type': getOsType(),
        'App-Version': userAgentData['App-Version'] || null,
        ...uuid,
      };
  return headers;
}

export function getNoHeaders(type = 'JSON') {
  let contentType = config.JSON_TYPE;
  if (type === 'FORM') contentType = config.FORM_TYPE;
  if (type === 'MULTIPART') contentType = config.MULTIPART_TYPE;

  const userAgentData = getAppDatabyStorage(true) || {};
  // 웹뷰일 경우 uuid 값 세팅. userAgentData.uuid = undifined || '' || string
  const uuid =
    userAgentData.uuid && isApp() ? { device_uuid: userAgentData.uuid } : {};

  const headers = {
    'Content-Type': contentType,
    'Os-Type': getOsType(),
    ...uuid,
  };

  return headers;
}

export default {
  getHeaders,
  getNoHeaders,
};
