import axios from 'axios';
import config from '../config';
import { getHeaders } from '../apiUtil';

const baseURL = `${config.BOOT_API_URL}`;

/**
 * 앱 초기 데이터 조회
 */

export const getInitInfo = (isV1 = false, getPath = false) => {
  const url = isV1
    ? '/api/init/v1/initFrontInfo'
    : '/api/init/v2/initFrontInfo';
  if (getPath) return `${baseURL}${url}`;

  return axios({
    method: 'get',
    url,
    baseURL,
    headers: getHeaders(),
  });
};

export default {
  getInitInfo,
};
