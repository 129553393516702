import { createAction, handleActions } from 'redux-actions';

const AutoSales = {
  AGREE_MOBILE_CERT: 'autoSales/AGREE_MOBILE_CERT',
  SET_FORM_DATA: 'autoSales/SET_FORM_DATA',
  SET_DATA: 'autoSales/SET_DATA',
};

const initialState = {
  passMobileCert: false,

  formData: {
    // 회원정보
    username: '',
    phone_no: '',
    cert_code: '',

    // 차량기본정보
    carnum: '',
    company_idx: '',
    model_idx: '',
    submodel_idx: '',
    grade_idx: '',
    subgrade_idx: '',
    myear: '', // 2021 ~ 1990
    mmonth: '', // 1 ~ 12
    ext_color: '',
    kms: '',

    // 차량세부정보
    fuel_type: '',
    trans_type: '',
    accident: '',

    // 옵션정보
    options: '',
    sigu: '',
    location: '', // 서울시 강남구/경기도 성남시

    // 차량설명
    desc: '',

    // 사진등록
    imgs: '',

    // 기본값으로 현행유지
    event_code: '', // 이벤트코드 입력칸 없음.
    apply_type: 'A', // A: 비교할게요, D: 바로팔게요 // 선택 영역 없음.
  },

  requiredList: [],
};

// 대출 진행 문자 받기
export const agreeMobileCert = createAction(AutoSales.AGREE_MOBILE_CERT);
export const setFormData = createAction(AutoSales.SET_FORM_DATA, obj => ({
  obj,
}));
export const setData = createAction(AutoSales.SET_DATA, obj => ({
  obj,
}));

export default handleActions(
  {
    [AutoSales.AGREE_MOBILE_CERT]: (state, action) => {
      return {
        ...state,
        passMobileCert: true,
      };
    },
    [AutoSales.SET_FORM_DATA]: (state, action) => {
      const { obj } = action.payload;
      return {
        ...state,
        formData: {
          ...state.formData,
          ...obj,
        },
      };
    },
    [AutoSales.SET_DATA]: (state, action) => {
      const { obj } = action.payload;
      return {
        ...state,
        ...obj,
      };
    },
  },
  initialState,
);
