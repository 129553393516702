import { createAction, handleActions } from 'redux-actions';
import moment from 'moment';
import { pender } from 'redux-pender';

import * as UserService from '../../actions/service/UserService';

import { saveToken, removeToken } from '../../common/auth';

// 회원가입/로그인 API
export const postUserOverlap = createAction(
  UserService.default.POST_USER_OVERLAP,
  UserService.postUserOverlap,
);
// SNS 로그인
export const postUserSnsLogin = createAction(
  UserService.default.POST_USER_SNS_LOGIN,
  UserService.postUserSnsLogin,
);
// SNS 회원가입
export const postUserSnsJoin = createAction(
  UserService.default.POST_USER_SNS_JOIN,
  UserService.postUserSnsJoin,
);
// EMAIL 회원가입
export const postUserEmailJoin = createAction(
  UserService.default.POST_USER_EMAIL_JOIN,
  UserService.postUserEmailJoin,
);
// EMAIL 로그인
export const postUserEmailLogin = createAction(
  UserService.default.POST_USER_EMAIL_LOGIN,
  UserService.postUserEmailLogin,
);
// 비밀번호 변경
export const postPasswordChange = createAction(
  UserService.default.POST_PASSWORD_CHANGE,
  UserService.postPasswordChange,
);
// 로그아웃
export const postUserLogout = createAction(
  UserService.default.POST_USER_LOGOUT,
  UserService.postUserLogout,
);

// 데이터 셋팅
export const setPageType = createAction(
  UserService.default.SET_PAGE_TYPE,
  UserService.setPageType,
);
export const setLoginType = createAction(
  UserService.default.SET_LOGIN_TYPE,
  UserService.setLoginType,
);
export const setNaverData = createAction(
  UserService.default.SET_NAVER_DATA,
  UserService.setNaverData,
);
export const setKakaoData = createAction(
  UserService.default.SET_KAKAO_DATA,
  UserService.setKakaoData,
);
export const setKakaoUser = createAction(
  UserService.default.SET_KAKAO_USER,
  UserService.setKakaoUser,
);
export const setFacebookData = createAction(
  UserService.default.SET_FACEBOOK_DATA,
  UserService.setFacebookData,
);
export const setPaycoData = createAction(
  UserService.default.SET_PAYCO_DATA,
  UserService.setPaycoData,
);
export const setMarketingYn = createAction(
  UserService.default.SET_MARKETING_YN,
  UserService.setMarketingYn,
);
export const setFindId = createAction(
  UserService.default.SET_FIND_ID,
  UserService.setFindId,
);

// 파트너 URL 셋팅
export const setRedirectUrl = createAction(
  UserService.default.SET_REDIRECT_URL,
  UserService.setRedirectUrl,
);
export const setAfterLoginUrl = createAction(
  UserService.default.SET_AFTER_LOGIN_URL,
  afterLoginUrl => afterLoginUrl,
);

// 친구초대 추천친 코드 조회
export const getRecommCode = createAction(
  UserService.default.GET_RECOMM_CODE,
  UserService.getRecommCode,
);

// 회원정보설정 - 닉네임 변경
export const putChangeNickname = createAction(
  UserService.default.PUT_CHANGE_NICKNAME,
  UserService.putChangeNickname,
);
// 회원정보설정 - 비밀번호 변경
export const putChangePassword = createAction(
  UserService.default.PUT_CHANGE_PASSWORD,
  UserService.putChangePassword,
);
// 회원정보설정 - 안심번호 설정
export const putStateSafety = createAction(
  UserService.default.PUT_STATE_SAFETY,
  UserService.putStateSafety,
);
// 회원정보설정 - 전화번호 비공개 설정
export const putStatePhoneShow = createAction(
  UserService.default.PUT_STATE_PHONE_SHOW,
  UserService.putStatePhoneShow,
);
// 회원정보설정 - 마케팅수신동의 설정
export const putStateMarketing = createAction(
  UserService.default.PUT_STATE_MARKETING,
  UserService.putStateMarketing,
);
export const getStateMarketing = createAction(
  UserService.default.GET_STATE_MARKETING,
  UserService.getStateMarketing,
);
// 회원정보설정 - 회원탈퇴
export const deleteUserState = createAction(
  UserService.default.DELETE_USER_STATE,
  UserService.deleteUserState,
);
// 회원정보설정 - 이메일 연동
export const putLinkEmail = createAction(
  UserService.default.PUT_LINK_EMAIL,
  UserService.putLinkEmail,
);
// 회원정보설정 - 이메일 연동해제
export const deleteUnlinkEmail = createAction(
  UserService.default.DELETE_UNLINK_EMAIL,
  UserService.deleteUnlinkEmail,
);
// 회원정보설정 - sns 연동해제
export const deleteUnlinkSns = createAction(
  UserService.default.DELETE_UNLINK_SNS,
  UserService.deleteUnlinkSns,
);
// 회원정보설정 - CAFE 연동
export const putLinkCafe = createAction(
  UserService.default.PUT_LINK_CAFE,
  UserService.putLinkCafe,
);
// 회원정보설정 - CAFE 연동해제
export const deleteUnlinkCafe = createAction(
  UserService.default.DELETE_UNLINK_CAFE,
  UserService.deleteUnlinkCafe,
);

// SNS 연동
export const sns_link = createAction(
  UserService.default.SNS_LINK,
  UserService.sns_link,
);

// 초기화
export const clearUser = createAction(UserService.default.CLEAR_USER);
export const clearUserNotRedirect = createAction(
  UserService.default.CLEAR_USER_NOT_REDIRECT,
);
export const clearSnsError = createAction(UserService.default.CLEAR_SNS_ERROR);

const initialState = {
  // 접근 페이지
  page_type: '', // web, cafe,

  // 이메일, 닉네임, 추천코드, 중복 확인 및 이메일 연동 여부 확인
  user_overlap: '',
  user_overlap_type: 0,
  onSuccessUserOverlap: moment(),

  // 마케팅 동의 여부
  marketing_yn: 0, // 0:미동의, 1:동의

  // 찾은 아이디
  find_id: '',

  // 로그인 타입
  user_login_type: 0, // 0:이메일, 1:네이버, 2:카카오, 3:페이스북, 5:페이코

  // 네이버 로그인 데이터
  naver_data: {
    accessToken: '',
    email: '',
    expiresIn: 0,
    id: '',
    refreshToken: '',
    refreshTokenExpiresIn: null,
    scope: null,
    tokenType: '',
  },
  // 카카오톡
  kakao_data: {
    access_token: '',
    token_type: '',
    refresh_token: '',
    expires_in: 0,
    scope: '',
    refresh_token_expires_in: 0,
    stateToken: '',
  },
  kakao_user: {
    id: 0,
    kaccount_email: '',
    kaccount_email_verified: true,
    properties: { profile_image: null, nickname: '', thumbnail_image: null },
  },
  // 페이스북 로그인 데이터
  facebook_data: {
    accessToken: undefined,
    expiresIn: undefined,
    reauthorize_required_in: undefined,
    signedRequest: undefined,
    userID: undefined,
  },
  // 페이코 로그인 데이터
  payco_data: {
    login: {
      access_token: undefined,
      access_token_secret: undefined,
      expires_in: undefined,
      refresh_token: undefined,
      state: undefined,
      token_type: undefined,
    },
    profile: {
      ageGroup: undefined,
      birthdayMMDD: undefined,
      id: undefined,
      idNO: undefined,
      maskingId: undefined,
      maskingMobileId: undefined,
      mobileId: undefined,
      name: undefined,
      sexCode: undefined,
    },
  },
  // SNS API 사용 DATA 셋팅 (네이버, 카카오톡 데이터 셋팅시 함께 셋팅 )
  accessToken: '',
  refreshToken: '',
  uniqueId: '',
  email: '',
  profileImgUrl: '',

  // SNS 로그인
  sns_login: '',
  sns_login_error: { code: 0, message: '' },
  sns_login_error_data: {},
  onSuccessSnsLogin: moment(),
  onFailureSnsLogin: moment(),

  // SNS 회원가입
  sns_join_error: { code: 0, message: '' },
  onSuccessSnsJoin: moment(),
  onFailureSnsJoin: moment(),

  // Email 회원가입
  email_join_error: { code: 0, message: '' },
  onSuccessEmailJoin: moment(),
  onFailureEmailJoin: moment(),

  joinMarketingYn: false,
  onJoinMarketing: '',

  // Email 로그인
  email_login_error: { code: 0, message: '' },
  email_login_error_data: {},
  onSuccessEmailLogin: moment(),
  onFailureEmailLogin: moment(),

  // 비밀번호 변경
  password_change_error: { code: 0, message: '' },
  onSuccessPasswordChange: moment(),
  onFailurePasswordChange: moment(),

  // 카페 등업 신청 구분
  cafe_levelup: '',
  cafe_levelup_error: '',
  onSuccessCafeLevelup: moment(),
  onFailureCafeLevelup: moment(),

  // 회원정보설정 - 닉네임변경
  onSuccessChangeNickname: moment(),
  // 회원정보설정 - 비밀번호변경
  onSuccessChangePassword: moment(),
  onFailureChangePassword: moment(),
  error_password_change: '',
  // 회원정보설정 - 안심번호 설정
  onSuccessStateSafety: moment(),
  // 회원정보설정 - 전화번호 비공개 설정
  onSuccessStatePhoneShow: moment(),
  // 회원정보설정 - 마케팅 수신동의 설정
  onSuccessStateMarketing: moment(),
  // 회원정보설정 - 이메일 연동해제
  onSuccessUnlinkEmail: moment(),
  // 회원정보설정 - 이메일 연동
  onSuccessLinkEmail: moment(),
  // 회원정보설정 - 이메일 연동해제
  onSuccessUnlinkCafe: moment(),
  // 회원정보설정 - 이메일 연동
  onSuccessLinkCafe: moment(),
  error_link_cafe: '',
  onFailureLinkCafe: moment(),

  // 로그아웃
  onSuccessLogout: moment(),

  // 회원탈퇴
  error_delete_user: '',
  onSuccessDeleteUser: moment(),
  onFailureDeleteUser: moment(),

  // 회원정보설정 - sns 연동
  onSuccessUnlinkSns: moment(),
  onSuccessSnsLink: moment(),
  error_sns_link_message: '',
  error_sns_link_code: '',
  onFailureSnsLink: moment(),

  // 로그인 완료되면 갈 페이지
  redirectUrl: {
    path: '',
    ExternalPath: '',
  },
  afterLoginUrl: '',
};

export default handleActions(
  {
    ...pender({
      type: UserService.default.POST_USER_OVERLAP,
      onSuccess: (state, action) => {
        const { data, config } = action.payload;
        const param = JSON.parse(config.data);
        return {
          ...state,
          user_overlap: data.data,
          user_overlap_type: param.type,
          onSuccessUserOverlap: moment(),
        };
      },
    }),
    // SNS 로그인
    ...pender({
      type: UserService.default.POST_USER_SNS_LOGIN,
      onSuccess: (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          sns_login: data.data,
          onSuccessSnsLogin: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        const isPhoneNumberExpired = data.meta.code == 400115;

        return {
          ...state,
          sns_login_error: data.meta,
          sns_login_error_data: isPhoneNumberExpired ? data.data : {},
          onFailureSnsLogin: moment(),
        };
      },
    }),

    // SNS 회원가입
    ...pender({
      type: UserService.default.POST_USER_SNS_JOIN,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        // 토큰값 셋팅
        saveToken(data.data);
        return {
          ...state,
          onSuccessSnsJoin: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          sns_join_error: data.meta,
          onFailureSnsJoin: moment(),
        };
      },
    }),
    // Email 회원가입
    ...pender({
      type: UserService.default.POST_USER_EMAIL_JOIN,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        // 토큰값 셋팅
        saveToken(data.data);
        return {
          ...state,
          onSuccessEmailJoin: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          email_join_error: data.meta,
          onFailureEmailJoin: moment(),
        };
      },
    }),
    // EMAIL 로그인
    ...pender({
      type: UserService.default.POST_USER_EMAIL_LOGIN,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          sns_login: data.data,
          onSuccessEmailLogin: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        const isPhoneNumberExpired = data.meta.code == 400115;

        return {
          ...state,
          email_login_error: data.meta,
          email_login_error_data: isPhoneNumberExpired ? data.data : {},
          onFailureEmailLogin: moment(),
        };
      },
    }),

    // 패스워드변경
    ...pender({
      type: UserService.default.POST_PASSWORD_CHANGE,
      onSuccess: (state, action) => {
        // const { data } = action.payload;
        return {
          ...state,
          onSuccessPasswordChange: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          password_change_error: data.meta,
          onFailurePasswordChange: moment(),
        };
      },
    }),

    // 친구초대 추천인 코드 조회
    ...pender({
      type: UserService.default.GET_RECOMM_CODE,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          recomm_code: data.data,
        };
      },
    }),

    // 회원정보설정 - 닉네임 변경
    ...pender({
      type: UserService.default.PUT_CHANGE_NICKNAME,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        // 토큰값 셋팅
        saveToken(data.data);

        return {
          ...state,
          onSuccessChangeNickname: moment(),
        };
      },
    }),
    // 회원정보설정 - 비밀번호 변경
    ...pender({
      type: UserService.default.PUT_CHANGE_PASSWORD,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        // 토큰값 셋팅
        saveToken(data.data);

        return {
          ...state,
          onSuccessChangePassword: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error_password_change: data.meta.message,
          onFailureChangePassword: moment(),
        };
      },
    }),
    // 회원정보설정 - 로그아웃
    ...pender({
      type: UserService.default.POST_USER_LOGOUT,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        // 토큰값 셋팅
        removeToken();

        return {
          ...state,
          onSuccessLogout: moment(),
        };
      },
    }),
    // 회원정보설정 - 안심번호 설정
    ...pender({
      type: UserService.default.PUT_STATE_SAFETY,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        // 토큰값 셋팅
        saveToken(data.data);

        return {
          ...state,
          onSuccessStateSafety: moment(),
        };
      },
    }),
    // 회원정보설정 - 전화번호 비공개 설정
    ...pender({
      type: UserService.default.PUT_STATE_PHONE_SHOW,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        // 토큰값 셋팅
        saveToken(data.data);

        return {
          ...state,
          onSuccessStatePhoneShow: moment(),
        };
      },
    }),
    // 가입완료 - 마케팅 수신 동의 조회
    ...pender({
      type: UserService.default.GET_STATE_MARKETING,
      onSuccess: (state, action) => {
        const {
          data: { data },
        } = action.payload;

        return {
          ...state,
          joinMarketingYn: data.isAccept,
          onJoinMarketing: data.acceptDate,
        };
      },
    }),
    // 회원정보설정 - 마케팅 수신 동의 설정
    ...pender({
      type: UserService.default.PUT_STATE_MARKETING,
      onSuccess: (state, action) => {
        const {
          data: { data },
        } = action.payload;
        // 토큰값 셋팅
        saveToken(data.token);

        return {
          ...state,
          onSuccessStateMarketing: data.acceptDate,
        };
      },
    }),
    // 회원정보설정 - 회원탈퇴
    ...pender({
      type: UserService.default.DELETE_USER_STATE,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        // 토큰값 셋팅
        removeToken();

        return {
          ...state,
          onSuccessDeleteUser: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error_delete_user: data.code,
          onFailureDeleteUser: moment(),
        };
      },
    }),
    // 회원정보설정 - 이메일 연동
    ...pender({
      type: UserService.default.PUT_LINK_EMAIL,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        // 토큰값 셋팅
        saveToken(data.data);

        return {
          ...state,
          onSuccessLinkEmail: moment(),
        };
      },
    }),
    // 회원정보설정 - 이메일 연동 해제
    ...pender({
      type: UserService.default.DELETE_UNLINK_EMAIL,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        // 토큰값 셋팅
        saveToken(data.data);

        return {
          ...state,
          onSuccessUnlinkEmail: moment(),
        };
      },
    }),

    // SNS 연동
    ...pender({
      type: UserService.default.SNS_LINK,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        // 토큰값 셋팅
        saveToken(data.data);

        return {
          ...state,
          onSuccessSnsLink: moment(),
          sns_link: data,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error_sns_link_message: data.meta.message,
          error_sns_link_code: data.meta.code,
          onFailureSnsLink: moment(),
        };
      },
    }),

    // 회원정보설정 - sns 연동 해제
    ...pender({
      type: UserService.default.DELETE_UNLINK_SNS,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        // 토큰값 셋팅
        saveToken(data.data);

        return {
          ...state,
          onSuccessUnlinkSns: moment(),
        };
      },
    }),

    // 회원정보설정 - cafe 연동
    ...pender({
      type: UserService.default.PUT_LINK_CAFE,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        // 토큰값 셋팅
        saveToken(data.data);

        return {
          ...state,
          onSuccessLinkCafe: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error_link_cafe: data.meta,
          onFailureLinkCafe: moment(),
        };
      },
    }),
    // 회원정보설정 - cafe 연동 해제
    ...pender({
      type: UserService.default.DELETE_UNLINK_CAFE,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        // 토큰값 셋팅
        saveToken(data.data);

        return {
          ...state,
          onSuccessUnlinkCafe: moment(),
        };
      },
    }),

    // 페이지 타입
    [UserService.default.SET_PAGE_TYPE]: (state, action) => {
      return {
        ...state,
        page_type: action.payload,
      };
    },
    // 로그인 타입
    [UserService.default.SET_LOGIN_TYPE]: (state, action) => {
      return {
        ...state,
        user_login_type: action.payload,
      };
    },
    // 네이버 데이터
    [UserService.default.SET_NAVER_DATA]: (state, action) => {
      return {
        ...state,
        user_login_type: 1,
        naver_data: action.payload,
        // 공통값 셋팅
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        uniqueId: action.payload.id,
        email: action.payload.email,
      };
    },
    // 카카오 데이터
    [UserService.default.SET_KAKAO_DATA]: (state, action) => {
      return {
        ...state,
        user_login_type: 2,
        kakao_data: action.payload,
        // 공통값 셋팅
        accessToken: action.payload.access_token,
        refreshToken: action.payload.refresh_token,
      };
    },
    // 카카오 회원 정보
    [UserService.default.SET_KAKAO_USER]: (state, action) => {
      return {
        ...state,
        kakao_user: action.payload,
        // 공통값 셋팅
        uniqueId: action.payload.id,
        email: action.payload.kaccount_email,
        profileImgUrl: action.payload.properties.profile_image,
      };
    },
    // 페이스북 데이터
    [UserService.default.SET_FACEBOOK_DATA]: (state, action) => {
      return {
        ...state,
        user_login_type: 3,
        facebook_data: action.payload,
        // 공통값 셋팅
        accessToken: action.payload.authResponse.accessToken,
        uniqueId: action.payload.authResponse.userID,
      };
    },
    // 페이코 데이터
    [UserService.default.SET_PAYCO_DATA]: (state, action) => {
      return {
        ...state,
        user_login_type: 5,
        payco_data: action.payload,
        // 공통값 셋팅
        accessToken: action.payload.login.access_token,
        refreshToken: action.payload.login.refresh_token,
        uniqueId: action.payload.profile.id,
      };
    },

    // 마케팅 동의 여부
    [UserService.default.SET_MARKETING_YN]: (state, action) => {
      return {
        ...state,
        marketing_yn: action.payload,
      };
    },
    // 찾은 아이디 저장
    [UserService.default.SET_FIND_ID]: (state, action) => {
      return {
        ...state,
        find_id: action.payload,
      };
    },

    [UserService.default.SET_REDIRECT_URL]: (state, action) => {
      return {
        ...state,
        redirectUrl: action.payload,
      };
    },

    [UserService.default.SET_AFTER_LOGIN_URL]: (state, action) => {
      return {
        ...state,
        afterLoginUrl: action.payload,
      };
    },
    [UserService.default.CLEAR_USER]: () => {
      return {
        ...initialState,
      };
    },
    [UserService.default.CLEAR_USER_NOT_REDIRECT]: state => {
      return {
        ...initialState,
        redirectUrl: state.redirectUrl,
        afterLoginUrl: state.afterLoginUrl,
      };
    },

    [UserService.default.CLEAR_SNS_ERROR]: state => {
      return {
        ...state,
        sns_login_error: { code: 0, message: '' },
        sns_login_error_data: {},
      };
    },
  },
  initialState,
);
