import { createAction, handleActions } from 'redux-actions';

const initialState = {};

const action = {
  SET_FLOATING_QUICK_DISPLAY: 'subAction/SET_FLOATING_QUICK_DISPLAY',
};

export const setFloatingQuickDisplay = createAction(action.SET_FLOATING_QUICK_DISPLAY, (selectTab, mode) => ({ selectTab, mode }));

export default handleActions({
  [action.SET_FLOATING_QUICK_DISPLAY]: (state, action) => {
    const { selectTab, mode } = action.payload;
    
    if (selectTab == 1) {
      if (mode == 'edit') {
        return { ...state, isFloatingQuickDisplay: true };

      } else if (mode == 'list') {
        return { ...state, isFloatingQuickDisplay: false };

      } else {
        return state; // DO NOTHING
      }

    } else {
      return { ...state, isFloatingQuickDisplay: true };
    }
  },
}, initialState);