import React from 'react';
import ReactDOM from 'react-dom';

import moment from 'moment';
import axios from 'axios';
import crypto from 'crypto';
import * as Device from 'react-device-detect';
import * as Config from '../actions/config';

export const Common = {
  // 파라미터 추출
  setGetParam(param, query) {
    // const query = this.props.location.search;
    const obj = query.substring(1).split('&');

    for (const item in obj) {
      const key_value = obj[item].split('=');
      const key = key_value[0];
      const value = key_value[1];
      param[`${key}`] = value;
    }
  },
};

// 토큰 파싱
export const convertToken = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export const getTokenValue = key => {
  if (isLogin() == false) {
    return undefined;
  }

  const token = convertToken(localStorage.jn_access_token);
  const value = token[key];
  return value;
};

// 파라미터 파싱
export const convertParam = () => {
  const returnParam = {};
  const query = window.location.search;
  if (query) {
    const obj = query.substring(1).split('&');
    for (const item in obj) {
      const key_value = obj[item].split('=');
      const key = key_value[0];
      const value = key_value[1];
      returnParam[`${key}`] = value;
    }
  }
  return returnParam;
};

// parameter 확인하여 token 있을시 로그인값 저장 후 파람 파싱결과 리턴
export const setParamLoginInfo = () => {
  if (window.location.pathname.indexOf('time-deal') !== -1) return;
  if (window.location.pathname.indexOf('point-event') !== -1) return;
  // 파라미터 추출
  const param = convertParam();

  /**
   * 임시
   */
  if (param.token) param.token = param.token.replace('?token', '');

  const isToken = param.token;
  const isKakao = param.type === 'kakao';
  const isPayco = param.state === 'payco';

  if (isToken && (!isKakao && !isPayco)) {
    localStorage.setItem('jn_access_token', param.token);
    localStorage.setItem(
      'jn_login_info',
      JSON.stringify(convertToken(param.token)),
    );
  }
};

// 로그인값 저장
export const setToken = token => {
  localStorage.setItem('jn_access_token', token);
  localStorage.setItem(
    'jn_login_info',
    token !== null && token !== '' ? JSON.stringify(convertToken(token)) : '',
  );
};

// 로그인 여부 확인
export const isLogin = () => Boolean(localStorage.jn_access_token);

// 토큰 유효성 확인
export const checkTokenValid = async (ignore = false) => {
  if (localStorage.jn_access_token) {
    const isStageLive = Config.default.API_BASE_URL.indexOf('stage-live') >= 0;
    if (isStageLive) return false;

    await axios
      .get(
        `${Config.default.API_BASE_URL}/common/token/valid?token=${
          localStorage.jn_access_token
        }${ignore ? 'ignore=Y' : ''}`,
        Config.default.JSON_HEADER,
      )
      .then(function(response) {
        if (response.data.data === false) {
          // 만료된 토큰
          setToken('');
        }
      })
      .catch(function(error) {
        // 정상적인 토큰이 아님
        setToken('');
      });
  } else {
    setToken('');
  }
};

// 날짜 계산 후 한글로 표기.
export const setDateFormat = dateData => {
  let dateKor = dateData;
  if (dateData && dateData.length >= 10) {
    const diffSec = moment().diff(
      moment(dateData, 'YYYY-MM-DD HH:mm:ss'),
      'second',
    );
    if (diffSec < 10) {
      // 10초전
      dateKor = '방금전';
    } else if (diffSec < 60) {
      // 10초~60초미만
      dateKor = `${diffSec}초 전`;
    } else if (diffSec < 60 * 60) {
      // 1분~60분 미만
      dateKor = `${parseInt(diffSec / 60)}분 전`;
    } else if (diffSec < 60 * 60 * 24) {
      // 1시간~24시간 미만
      dateKor = `${parseInt(diffSec / 60 / 60)}시간 전`;
    } else if (diffSec < 60 * 60 * 24 * 30) {
      // 1일 30일 미만
      dateKor = `${parseInt(diffSec / 60 / 60 / 24)}일 전`;
    } else {
      dateKor = moment(dateKor).format('YYYY-M-D');
    }
  }
  return dateKor;
};

// GA 태깅
export const tracking = eventName => {
  // const isIOSChrome = window.navigator.userAgent.match('CriOS');
  // // os type 확인
  // let osType = 'web';
  // if (window.qdillion) {
  //   // android
  //   osType = 'aos';
  // } else if (window.webkit && window.webkit.messageHandlers && !isIOSChrome) {
  //   // ios
  //   osType = 'ios';
  // }
  // // storeSeq 확인
  // const login_info = localStorage.getItem('jn_login_info')
  //   ? JSON.parse(localStorage.getItem('jn_login_info'))
  //   : null;
  // // ga태깅
  // if (window.gtag) {
  //   window.gtag('event', `${eventName}_${osType}`, {
  //     event_category: `${eventName}_${osType}`,
  //     storeSeq:
  //       login_info != null && login_info.storeSeq != null
  //         ? login_info.storeSeq
  //         : '',
  //   });
  // }
};

// 카카오 공유
export const sendKakaoLink = data => {
  const content = {
    title: data.title,
    description: data.description,
    imageUrl: data.imageUrl,
    link: {
      mobileWebUrl: data.link,
      webUrl: data.link,
    },
  };
  if (data.imageWidth) {
    content.imageWidth = data.imageWidth;
  }
  if (data.imageHeight) {
    content.imageHeight = data.imageHeight;
  }
  if (window.Kakao) {
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content,
      buttons: [
        {
          title: data.btn_title,
          link: {
            mobileWebUrl: data.link,
            webUrl: data.link,
          },
        },
      ],
    });
  }
};

// 페이스북 공유
export const sendFacebooklink = link => {
  const shareLink = `https://www.facebook.com/sharer/sharer.php?u=${link}`;

  if (isApp()) {
    window.location.href = `jnapps3://?openweb=${shareLink}`;
  } else {
    window.open(shareLink);
  }
};

// 숫자 자릿수 포메팅
export const formatNumber = n => {
  // validation
  if (isNaN(parseInt(n))) return '';

  // ready
  n = `${parseInt(n)}`;
  while (n.length % 3 != 0) n = `0${n}`;

  // formatting
  let buffer = '';
  for (let i = 0; i < n.length - 1; i += 3)
    buffer += `,${i == 0 ? parseInt(n.substr(i, 3)) : n.substr(i, 3)}`;

  return buffer.substr(1);
};

// 금액 자릿수 포메팅
export const formatPrice = n => (n == 0 ? '무료나눔' : `${formatNumber(n)}원`);

// rankChange 포메팅
export const formatRankChange = rankChange =>
  rankChange == 0 ? '' : rankChange == 999999 ? 'NEW' : Math.abs(rankChange);

// rankChange 클래스
export const classRankChange = rankChange =>
  rankChange == 0
    ? ''
    : rankChange == 999999
    ? 'up'
    : rankChange > 0
    ? 'up'
    : rankChange < 0
    ? 'down'
    : '';

// 검색엔진 Request 객체
export const searchWordVODefault = () => ({
  // deep copy
  filter: {
    categoryDepth: 0,
    categorySeq: 0,
    color: ['ZZZZZZ'],
    condition: {
      options: {
        flawedYn: 0,
        fullPackageYn: 0,
        limitedEditionYn: 0,
      },
      productCondition: -1,
    },
    locations: [],
    maxPrice: 0,
    minPrice: 0,
    platformType: 1, // 사업별 구분, 1: 중고나라, 2: 오토, 3: 평화시장
    preferredTrade: 0,
    sortEndDate: '',
    sortStartDate: '',
    state: -1,
    categorySeqList: [],
    productSectionType: 0, // 0 고정, 0: 해당없음, 10: 새상품, 20: 구제상품: 30: 중고폰
  },
  isSearchSaved: 0,
  searchQuantity: 20,
  searchWord: undefined,
  sort: {
    date: 0,
    order: 0,
    price: 0,
  },
  startIndex: 0,
});

export const fncValidPW = password => {
  let ok_count = 0;
  const reg_password_upper = /(?=.*?[AZ])/;
  const reg_password_number = /(?=.*?[0-9])/;
  const reg_password_special = /(?=.*?[#?!@$%^&*-])/;
  const reg_length = /(?=^.{6,16}$)/;

  ok_count += reg_password_upper.test(password) ? 1 : 0;
  ok_count += reg_password_number.test(password) ? 1 : 0;
  ok_count += reg_password_special.test(password) ? 1 : 0;

  // true면 사용가능, false면 사용 불가능
  return reg_length.test(password) && ok_count >= 2;
};

export const overlayOpen = (name, gtag) => {
  if (gtag) {
    // tracking(gtag);
  }
  let count = 0;

  (function recursive() {
    const popup = document.querySelector(`[data-popup="${name}"]`);

    if (
      window.overlayOpen &&
      popup &&
      popup.innerHTML &&
      popup.innerHTML.length &&
      isOuterWrap()
    ) {
      window.outerWrap = document.getElementById('outerWrap');
      window.overlayOpen(popup);
    } else if (count < 10) {
      count++;
      window.setTimeout(recursive, 333);
    }
  })();
};
export const overlayClose = name => {
  let count = 0;

  (function recursive() {
    const popup = document.querySelector(`[data-popup="${name}"]`);

    if (
      window.overlayOpen &&
      popup &&
      popup.innerHTML &&
      popup.innerHTML.length &&
      isOuterWrap()
    ) {
      window.outerWrap = document.getElementById('outerWrap');
      window.overlayClose(popup);
    } else if (count < 10) {
      count++;
      window.setTimeout(recursive, 333);
    }
  })();
};

export const addSeenProduct = productSeq => {
  if (isNaN(Number(productSeq))) return false;
  if (localStorage.jn_access_token > '' == false) return false;

  const { userSeq } = convertToken(localStorage.jn_access_token);
  const name = `seenProduct${userSeq}`;

  const list = (typeof localStorage[name] === 'string'
    ? localStorage[name]
    : ''
  ).split(',');
  localStorage[name] = [productSeq, ...list.filter(seq => seq != productSeq)]
    .slice(0, 10)
    .join(',');
};

export const getSeenProduct = () => {
  if (localStorage.jn_access_token > '' == false) return '';

  const { userSeq } = convertToken(localStorage.jn_access_token);
  const name = `seenProduct${userSeq}`;

  return typeof localStorage[name] === 'string' ? localStorage[name] : '';
};

export const encPassword = password => {
  const key = process.env.REACT_APP_PUB_KEY;
  const encPassword = crypto
    .publicEncrypt(
      { key, padding: crypto.constants.RSA_PKCS1_PADDING },
      Buffer.from(password, 'utf8'),
    )
    .toString('base64');
  return encPassword;
};

export const isMobile = () => {
  return Device.isMobile;
};

export const isDesktop = () => {
  return Device.isMobile == false;
};

export const isAndroid = () => {
  return Device.isAndroid;
};

export const isAndroidApp = () => {
  const isAndroidApp = Boolean(window.qdillion);
  return isAndroidApp;
};

export const isIOS = () => {
  return Device.isIOS;
};

export const isIOSApp = () => {
  const isIOSChrome = window.navigator.userAgent.match('CriOS');
  const isIOSApp = Boolean(
    window.webkit && window.webkit.messageHandlers && !isIOSChrome,
  );
  return isIOSApp;
};

export const isApp = () => {
  return isAndroidApp() || isIOSApp();
};

export const isMobileWeb = () => {
  return isApp() == false;
};

// jnapps3://?openweb의 웹뷰를 닫습니다.
export const closeWebView = () => {
  if (isAndroidApp()) {
    window.qdillion.close();
  } else if (isIOSApp()) {
    window.webkit.messageHandlers.closeEnd.postMessage('');
  }
};

export const setCookie = (cookie_name, value, days) => {
  const exdate = new Date();
  exdate.setDate(exdate.getDate() + days);
  // 설정 일수만큼 현재시간에 만료값으로 지정

  const cookie_value =
    escape(value) +
    (days == null ? '' : `;    expires=${exdate.toUTCString()}`);
  window.document.cookie = `${cookie_name}=${cookie_value}`;
};

export const getCookie = cookie_name => {
  let x;
  let y;
  const val = window.document.cookie.split(';');
  for (let i = 0; i < val.length; i++) {
    x = val[i].substr(0, val[i].indexOf('='));
    y = val[i].substr(val[i].indexOf('=') + 1);
    x = x.replace(/^\s+|\s+$/g, ''); // 앞과 뒤의 공백 제거하기
    if (x == cookie_name) {
      return unescape(y); // unescape로 디코딩 후 값 리턴
    }
  }
};

export const setHeight = () => {
  let count = 0;

  (function recursive() {
    if (window.setHeight && isOuterWrap()) {
      window.outerWrap = document.getElementById('outerWrap');
      window.setHeight();
    } else if (count++ < 10) {
      window.setTimeout(recursive, 333);
    }
  })();
};

function isOuterWrap() {
  const outerWrap = document.getElementById('outerWrap');
  return outerWrap && outerWrap.innerHTML && outerWrap.innerHTML.length;
}

export function redirectLogin() {
  window.location.href = '/login';
}

export function popupConfirm(
  intro,
  confirmName,
  confirmFunc,
  cancelName,
  cancelFunc,
  divId,
) {
  const layerName = 'divError';

  const div = document.getElementById(divId || 'div-portal');
  // ReactDOM.render(
  //   <Popup
  //     layerName={layerName}
  //     intro={replaceCRLF(intro)}
  //     confirmName={confirmName}
  //     confirmFunc={() => {
  //       unmount();
  //       if (typeof confirmFunc === 'function') {
  //         confirmFunc();
  //       }
  //     }}
  //     cancelName={cancelName}
  //     cancelFunc={() => {
  //       unmount();
  //       if (typeof cancelFunc === 'function') {
  //         cancelFunc();
  //       }
  //     }}
  //   />,
  //   div,
  // );
  overlayOpen('divError');

  function unmount() {
    overlayClose(layerName);
    window.setTimeout(() => ReactDOM.unmountComponentAtNode(div));
  }
}

export function getCvsStoreMapUrl(deliveryCorpName) {
  deliveryCorpName = deliveryCorpName.toUpperCase();
  const isGS = deliveryCorpName == 'GS';
  // const isCU = (deliveryCorpName == 'CU');

  const src = isGS
    ? 'https://www.cvsnet.co.kr/service/search-store/index.do'
    : isDesktop()
    ? 'https://www.cupost.co.kr/GIS/chzero/cvsnet_shop_home_terminal_size.jsp'
    : 'https://www.cupost.co.kr/GIS/chzero/mobile/index.html';

  return src;
}

export function pushCvsFind(DCN) {
  // 로직 파악되면 수정 필
  const deliveryCorpName = DCN.toUpperCase();

  if (isDesktop()) {
    const url = getCvsStoreMapUrl(deliveryCorpName);
    window.open(url, '_blank');
  } else {
    const isGS = deliveryCorpName === 'GS';
    const isCU = deliveryCorpName === 'CU';
    let url = '/home';
    if (isGS) url = '/cvs-find/gs';
    if (isCU) url = '/cvs-find/cu';

    const history = window.WILD.history[0];
    history.push(url, {
      from: `${history.location.pathname}${history.location.search}`,
    });
  }
}

export function initFacebookAPI() {
  window.fbAsyncInit = function() {
    window.FB.init({
      appId: process.env.REACT_APP_FACEBOOK_API,
      cookie: true,
      xfbml: true,
      version: 'v3.2',
    });
  };

  (function(d, s, id) {
    let js;
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
}

export function getPaycoAuthCode(redirect_uri) {
  const uri = `https://id.payco.com/oauth2.0/authorize?response_type=code&client_id=${
    process.env.REACT_APP_PAYCO_API
  }&redirect_uri=${encodeURIComponent(
    redirect_uri,
  )}&serviceProviderCode=FRIENDS&viewType=Y&userLocale=ko_KR&state=payco`;

  // window.location.href = uri;
  window.payco_window = window.open(uri, '_blank', 'height=700,width=450');
}

export function getQueryString(obj) {
  return `?${Object.keys(obj)
    .filter(name => isValid(obj[name]))
    .reduce(
      (string, name) => `${string}&${name}=${encodeURIComponent(obj[name])}`,
      '',
    )
    .substr(1)}`;

  function isValid(item) {
    return Boolean(
      typeof item === 'string' ||
        (typeof item === 'number' &&
          isNaN(item) == false &&
          isInfinity(item) == false),
    );

    function isInfinity(item) {
      return item == Infinity;
    }
  }
}

export function historyPush(history) {
  if (Boolean(window.WILD) == false) {
    return false; // validation
  }

  window.WILD.history = [{ ...history }, ...window.WILD.history];
}

export function getHistory() {
  if (Boolean(window.WILD) == false) {
    return false; // validation
  }

  return window.WILD.history.length && window.WILD.history[0];
}

export function enumToValue(enumType, key) {
  const map =
    {
      deliveryCorpName: {
        CJ대한통운: 'CJ대한통운',
        우체국택배: '우체국택배',
        우편등기: '우편등기',
        GS: '편의점택배(GS25)',
        롯데택배: '롯데택배',
        로젠택배: '로젠택배',
        한진택배: '한진택배',
        KCB택배: 'KGB택배',
        대신택배: '대신택배',
        일양로지스: '일양로지스',
        경동택배: '경동택배',
        천일택배: '천일택배',
        CU: '편의점택배(CUpost)',
        합동택배: '합동택배',
        GSMNtoN: 'GSM NtoN',
        NONE: '',
      },
      productColor: {
        '000000': 'black',
        FFFFFF: 'white',
        E2E2E2: 'gray',
        // 'E2E2E2': '',
        DAB87B: 'beige',
        '0A88C0': 'blue',
        F13239: 'red',
        '9F6F36': 'brown',
        FEB5B5: 'pink',
        F37D41: 'orange',
        FBD13B: 'yellow',
        '52C161': 'green',
        '8572EE': 'purple',
        // '8572EE': '',
        XXXXXX: 'mix',
      },
      accidentType: {
        1: '무사고',
        2: '사고',
        3: '모름',
      },
    }[enumType] || {};

  const value = map[key] || key;

  return value;
}

export function onClickBackBtn() {
  if (isApp()) {
    closeWebView();
  } else {
    // isMobileWeb()
    const history = window.WILD.history[0];
    history.goBack();
  }
}

export function replaceCRLF(message) {
  return message
    .replace(/\r\n/gi, '<br>')
    .replace(/\r/gi, '<br>')
    .replace(/\n/gi, '<br>');
}

export function getListCRLF(message) {
  return replaceCRLF(message).split('<br>');
}

export function onClickPageMove(gtag, linkUrl) {
  // tracking(gtag);
  window.location.href = linkUrl;
}
export default Common;
