import axios from 'axios';
import config from '../config';
import { getHeaders } from '../apiUtil';

const baseURL = `${config.AUTO_API_BASE_URL}`;

/**
 * 차량 정보 보기
 * @param {Number} productSeq
 * @param {boolean} getPath
 */
export function getAutoProductDetail(productSeq, getPath = false) {
  if (getPath) return `${baseURL}/product/${productSeq}`;
  return axios({
    method: 'get',
    url: `/product/${productSeq}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 차량 상태변경 처리
 * @param {*} params
 * {
      "productSeq": 0,
      "status": 0,
    }
 */
export function putAutoProductModifyStatus(params, getPath = false) {
  if (getPath) {
    return `${baseURL}/product/modify/${params.productSeq}/status/${
      params.status
    }`;
  }

  return axios({
    method: 'put',
    url: `/product/modify/${params.productSeq}/status/${params.status}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 차량 상태변경 처리
 * @param {*} params
 * {
      "productSeq": 0,
      "storeSeq": 0,
      "type": 0,
    }
 */
export function putAutoProductSale(params, getPath = false) {
  if (getPath) {
    return `${baseURL}/product/sale/${params.productSeq}/${params.storeSeq}/${
      params.type
    }`;
  }

  return axios({
    method: 'put',
    url: `/product/sale/${params.productSeq}/${params.storeSeq}/${params.type}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 할부 한도정보
 * @param {Number} productSeq
 * @param {boolean} getPath
 */
export function getInstalmentInfo(productSeq, getPath = false) {
  if (getPath)
    return `${baseURL}/product/autoLoan/installment/info/${productSeq}`;
  return axios({
    method: 'get',
    url: `/product/autoLoan/installment/info/${productSeq}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * postInstalmentCheck() 함수와 spec이 동일하지만, 서버 내부에서의 로직이 다름.
 * 계속 유지해야 한다.
 * @param {*} param0
 */
export function postInstallment({
  productSeq,
  osType,
  idNo,
  loanAmount,
  name,
  commId,
  mobilid,
  no,
  ci,
  privacyPolicy,
  OptionalcreditInfo,
}) {
  const url = `/product/autoLoan/installment?productSeq=${productSeq}&osType=${osType}&idNo=${idNo}&loanAmount=${loanAmount}&name=${name}&commId=${commId}&mobilid=${mobilid}&no=${no}&ci=${ci}&usePrivacyYn=${privacyPolicy}&useCreditYn=${OptionalcreditInfo}`;
  return axios({
    method: 'post',
    url,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 할부 한도조회
 * @param {Number} productSeq
 * @param {Number} osType
 * @param {string} idNo
 * @param {string} loanAmount
 * @param {string} ci
 * @param {string} commId
 * @param {string} mobilid
 * @param {string} name
 * @param {string} no
 * @param {Number} usePrivacyYn
 * @param {Number} useCreditYn
 */
export function postInstalmentCheck({
  productSeq,
  osType,
  idNo,
  loanAmount,
  ci,
  commId,
  mobilid,
  name,
  no,
  usePrivacyYn,
  useCreditYn,
}) {
  const url = `/autoLoan/installment/c2c?productSeq=${productSeq}&osType=${osType}&idNo=${idNo}&loanAmount=${loanAmount}&name=${name}&commId=${commId}&mobilid=${mobilid}&no=${no}&ci=${ci}&usePrivacyYn=${usePrivacyYn}&useCreditYn=${useCreditYn}`;
  return axios({
    method: 'post',
    url,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 *할부 한도조회 진행 문자 받기 또는 신청하기
 * @param {string} resultUuid
 * @param {string} smsType : 1:진행문자받기(대출승인인 경우), 2:신청하기(심사필요인경우)
 */
export function postInstalmentProceed(resultUuid, smsType) {
  const url = `/autoLoan/reqSms/c2c?resultUuid=${resultUuid}&smsType=${smsType}`;
  return axios({
    method: 'post',
    url,
    baseURL,
    headers: getHeaders(),
  });
}
