import axios from 'axios';
import * as Config from '../config';

const category = {
  REQUEST_CATEGORY_LIST: 'REQUEST_CATEGORY_LIST',
  GET_CATEGORY: 'GET_CATEGORY',
  GET_CATEGORY_DEPTH: 'GET_CATEGORY_DEPTH',
  STORE_CATEGORY: 'STORE_CATEGORY',
  TOGGLE_CATEGORY: 'TOGGLE_CATEGORY',
  RESET_CATEGORY: 'RESET_CATEGORY',
};

// 카테고리 리스트
export function getCategory(depth, parentSeq) {
  return axios
    .get(
      `${
        Config.default.API_BASE_URL
      }/common/category/list?parentSeq=${parentSeq}`,
      Config.default.JSON_HEADER,
    )
    .then(result => {
      return { ...result, depth, parentSeq };
    });
}

// 카테고리 depth 별 seq
export function getCategoryDepth(seq) {
  return axios
    .get(
      `${Config.default.API_BASE_URL}/common/category/parent/${seq}`,
      Config.default.JSON_HEADER,
    )
    .then(result => {
      return { ...result, seq };
    });
}

export function toggleCategory(depth, parentSeq, parentUlSeq = 0) {
  return { depth, parentSeq, parentUlSeq };
}

export default category;
