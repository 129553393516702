import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import {
  getInstalmentInfo,
  postInstallment,
  postInstalmentProceed,
} from '../../api/auto/product';

export const Actions = {
  GET_INFO: 'AUTO-LOAN-INSTALLMENT/GET-INFO',
  POST_LOAN: 'AUTO-LOAN-INSTALLMENT/POST-LOAN',
  POST_SMS: 'AUTO-LOAN-INSTALLMENT/POST-SMS',
};

const initialState = {
  // 할부 정보 조회
  autoLoanInfo: {
    defaultPrice: 0,
    lateInterest: 0,
    maxInterest: 0,
    maxMonth: 0,
    maxPrepayFee: 0,
    maxPrice: 0,
    minInterest: 0,
    minMonth: 0,
    minPrepayFee: 0,
    minPrice: 0,
    mortgageRate: 0,
    productPrice: 0,
  },

  // 할부 신청 조회
  autoLoanResult: {
    appLinkAndroid: '',
    appLinkIos: '',
    appStoreLinkAndroid: '',
    appStoreLinkIos: '',
    availableYN: '',
    consentCode: '',
    loanAmount: 0,
    loanMaxMonth: 0,
    loanMonth: 0,
    loanRate: 0,
    loanSetupCount: 0,
    productSeq: 0,
    resultCode: '',
    resultErrorMsg: '',
    resultUuid: '',
    seizeCount: 0,
  },

  // 할부 진행 문자 받기
  autoLoanSmsResult: '',

  // 에러
  error: {
    code: 200,
    message: '',
  },
};

// 할부 정보 조회
export const getAutoLoanInstallment = createAction(
  Actions.GET_INFO,
  getInstalmentInfo,
);

// 할부 신청 조회
export const postAutoLoanInstallment = createAction(
  Actions.POST_LOAN,
  postInstallment,
);

// 할부 진행 문자 받기
export const postAutoLoanInstallmentReqsms = createAction(
  Actions.POST_SMS,
  postInstalmentProceed,
);

export default handleActions(
  {
    // 할부 정보 조회
    ...pender({
      type: Actions.GET_INFO,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          autoLoanInfo: { ...data.data },
          error: initialState.error,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          autoLoanInfo: initialState.autoLoanInfo,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),

    // 할부 신청 조회
    ...pender({
      type: Actions.POST_LOAN,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          autoLoanResult: { ...data.data },
          error: initialState.error,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          autoLoanResult: initialState.autoLoanResult,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),

    // 할부 진행 문자 받기
    ...pender({
      type: Actions.POST_SMS,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          autoLoanSmsResult: data.data,
          error: initialState.error,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          autoLoanSmsResult: initialState.autoLoanSmsResult,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),
  },
  initialState,
);
