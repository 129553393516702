import axios from 'axios';
import * as Config from '../config';
import { getHeaders } from '../../api/apiUtil';

const baseURL = Config.default.AUTO_API_BASE_URL;

const auto = {
  GET_GARAGE_PRODUCTSEQ: 'auto/GET_GARAGE_PRODUCTSEQ',
  POST_AUTOLOAN_INQUIRY: 'auto/POST_AUTOLOAN_INQUIRY',
  POST_AUTOLOAN_REQSMS: 'auto/POST_AUTOLOAN_REQSMS',
  INIT_OPTION_LIST: 'auto/INIT_OPTION_LIST',
  GET_GARAGE_LIST: 'auto/GET_GARAGE_LIST',
  DELETE_GARAGE: 'auto/DELETE_GARAGE',
  GET_CLOSE_LOANBANNER: 'auto/GET_CLOSE_LOANBANNER',
  POST_AUTOLOAN_INSTALLMENT: 'auto/POST_AUTOLOAN_INSTALLMENT',
  POST_AUTOLOAN_INSTALLMENT_REQSMS: 'auto/POST_AUTOLOAN_INSTALLMENT_REQSMS',
};

// GET /carinfo/searchCarinfoByCarno/{carNo} 차량번호로 차량정보 조회
export function searchCarinfoByCarno(carNo) {
  const promise = axios.get(
    `${Config.default.AUTO_API_BASE_URL}/carinfo/searchCarinfoByCarno/${carNo}`,
    Config.getJSONHeader(),
  );
  return promise;
}

// POST /garage/add 내차 등록
export function garageAdd(garageAddVO) {
  const promise = axios.post(
    `${Config.default.AUTO_API_BASE_URL}/garage/add`,
    garageAddVO,
    Config.getJSONHeader(),
  );

  return promise;
}

// GET /garage/{productSeq} 차량 정보 조회
export function garageGet(productSeq, getPath = false) {
  if (getPath) return `${baseURL}/garage/${productSeq}`;

  return axios({
    method: 'get',
    url: `/garage/${productSeq}`,
    baseURL,
    headers: getHeaders(),
  });
}

// PUT /garage/update 내차 정보 수정
export function garageUpdate(garageUpdateVO) {
  const promise = axios.put(
    `${Config.default.AUTO_API_BASE_URL}/garage/update`,
    garageUpdateVO,
    Config.getJSONHeader(),
  );

  return promise;
}

// GET /carinfo/optionList 차량 옵션 리스트 전체 (CD011 : 색상, CD007 : 차량 옵션, CD012:변속기, CD013:연료)
export function carinfoOptionList() {
  const promise = axios.get(
    `${Config.default.AUTO_API_BASE_URL}/carinfo/optionList`,
    Config.getJSONHeader(),
  );

  return promise;
}

// GET /garage/{productSeq} productSeq로 차량정보 조회
export function searchCarinfoByProductSeq(productSeq) {
  const promise = axios.get(
    `${Config.default.AUTO_API_BASE_URL}/garage/${productSeq}`,
    Config.getJSONHeader(),
  );

  return promise;
}

// POST /garage/autoLoan/reqSms 진행 문자 받기 / 신청하기
export function postAutoLoanReqsms(resultUuid) {
  const promise = axios.post(
    `${
      Config.default.AUTO_API_BASE_URL
    }/garage/autoLoan/reqSms?resultUuid=${resultUuid}`,
    '',
    Config.getJSONHeader(),
  );

  return promise;
}

// GET /garage/list 내차고 차량 목록 조회
export function getGarageList(getPath = false) {
  if (getPath) return `${baseURL}/garage/list`;

  return axios({
    method: 'get',
    url: `/garage/list`,
    baseURL,
    headers: getHeaders(),
  });
}

// DELETE /garage/{productSeq} 차고차량 삭제
export function deleteGarage(productSeq) {
  const promise = axios.delete(
    `${Config.default.AUTO_API_BASE_URL}/garage/${productSeq}`,
    Config.getJSONHeader(),
  );
  return promise;
}

// GET /carinfo/makerList 제조사 리스트
export function getMakerList() {
  const promise = axios.get(
    `${Config.default.AUTO_API_BASE_URL}/carinfo/makerList`,
    Config.getJSONHeader(),
  );

  return promise;
}

// GET /carinfo/classModelList/{makerNo} 대표 모델 리스트
export function getClassModelList(makerNo) {
  const promise = axios.get(
    `${Config.default.AUTO_API_BASE_URL}/carinfo/classModelList/${makerNo}`,
    Config.getJSONHeader(),
  );

  return promise;
}

// GET /carinfo/modelList/{classModelNo} 모델 리스트
export function getModelList(classModelNo) {
  const promise = axios.get(
    `${Config.default.AUTO_API_BASE_URL}/carinfo/modelList/${classModelNo}`,
    Config.getJSONHeader(),
  );

  return promise;
}

// GET /carinfo/gradeList/{modelNo} 등급 리스트
export function getGradeList(modelNo) {
  const promise = axios.get(
    `${Config.default.AUTO_API_BASE_URL}/carinfo/gradeList/${modelNo}`,
    Config.getJSONHeader(),
  );

  return promise;
}

// GET /carinfo/subGradeList/{modelNo}/{bsNo} 세부 등급 리스트
export function getSubGradeList(modelNo, bsNo) {
  const promise = axios.get(
    `${
      Config.default.AUTO_API_BASE_URL
    }/carinfo/subGradeList/${modelNo}/${bsNo}`,
    Config.getJSONHeader(),
  );

  return promise;
}

// GET /garage/closeLoanBanner 7일간 대출 안내 문구 표시 보지 않음
export function getCloseLoanBanner() {
  const promise = axios.get(
    `${Config.default.AUTO_API_BASE_URL}/garage/closeLoanBanner`,
    Config.getJSONHeader(),
  );

  return promise;
}

/*
  Auto 상품 API
  Auto 상품 등록, 재판매, 상세, 수정, 상태수정, 판매완료, 재등록
*/

// GET /product/{productSeq} 상품 상세 정보 가져오기
export function getProduct(productSeq) {
  const promise = axios.get(
    `${Config.default.AUTO_API_BASE_URL}/product/${productSeq}`,
    Config.getJSONHeader2(),
  );
  return promise;
}

// POST /product/autoLoan/installment 할부 조회
export function postAutoLoanInstallment({
  productSeq,
  osType,
  idNo,
  loanAmount,
  name,
  commId,
  mobilid,
  no,
  ci,
  privacyPolicy,
  OptionalcreditInfo,
}) {
  const promise = axios.post(
    `${
      Config.default.AUTO_API_BASE_URL
    }/product/autoLoan/installment?productSeq=${productSeq}&osType=${osType}&idNo=${idNo}&loanAmount=${loanAmount}&name=${name}&commId=${commId}&mobilid=${mobilid}&no=${no}&ci=${ci}&usePrivacyYn=${privacyPolicy}&useCreditYn=${OptionalcreditInfo}`,
    '',
    Config.getJSONHeader(),
  );

  return promise;
}

// POST /product/autoLoan/reqSms 진행 문자 받기 / 신청하기
export function postAutoLoanInstallmentReqsms(resultUuid, smsType) {
  const promise = axios.post(
    `${
      Config.default.AUTO_API_BASE_URL
    }/product/autoLoan/reqSms?resultUuid=${resultUuid}&smsType=${smsType}`,
    '',
    Config.getJSONHeader(),
  );

  return promise;
}

// GET /carinfo/searchUsedPriceInfo/{productSeq} 시세정보 조회
export function getSearchUsedPriceInfo(productSeq, getPath = false) {
  if (getPath) return `${baseURL}/carinfo/searchUsedPriceInfo/${productSeq}`;

  return axios({
    method: 'get',
    url: `/carinfo/searchUsedPriceInfo/${productSeq}`,
    baseURL,
    headers: getHeaders(),
  });
}

// PUT /product/modify/{productSeq}/status/{status} 상품 상태값 수정
export function putModifyStatus(productSeq, status) {
  // status: 0:판매중, 1:예약중, 2:판매보류, 3:판매완료
  const promise = axios.put(
    `${
      Config.default.AUTO_API_BASE_URL
    }/product/modify/${productSeq}/status/${status}`,
    null,
    Config.getJSONHeader(),
  );

  return promise;
}

// PUT /product/sale/{productSeq}/{storeSeq}/{type} 상품 판매완료 선택, 거래방식 선택
export function putProductSale(productSeq, storeSeq, type) {
  const promise = axios.put(
    `${
      Config.default.AUTO_API_BASE_URL
    }/product/sale/${productSeq}/${storeSeq}/${type}`,
    null,
    Config.getJSONHeader(),
  );

  return promise;
}

// DELETE /product/{productSeq} 상품 삭제
export function deleteProduct(productSeq) {
  const promise = axios.delete(
    `${Config.default.AUTO_API_BASE_URL}/product/${productSeq}`,
    Config.getJSONHeader(),
  );

  return promise;
}

export default auto;
