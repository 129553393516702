import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import moment from 'moment';
import * as Payment from '../../actions/service/PaymentService';

const initial_order_VO = {
  contentsPayVO: [
    {
      content: '',
      payMethod: '',
      payTool: '',
    },
  ],
  orderNo: '',
  product: {
    categoryName: '',
    categorySeq: '',
    condition: {
      options: {
        flawedYn: 0,
        fullPackageYn: 0,
        limitedEditionYn: 0,
      },
      productCondition: 0,
    },
    keywords: [],
    locations: [
      {
        dongName: '',
        lat: 0,
        locationCode: 0,
        lon: 0,
      },
    ],
    media: [
      {
        mediaSort: 0,
        mediaType: 0,
        mediaUrl: '',
        thumbnailUrl: '',
        waterMarkUrl: '',
        watermarkYn: 0,
      },
    ],
    parcelFeeYn: 0,
    payment: {
      accountDetail: {
        accountBank: '',
        accountHolder: '',
        accountNo: '',
      },
      bankTransferYn: 0,
      cardYn: 0,
      depositlessYn: 0,
      userAccountSeq: 0,
    },
    productCategory: 0,
    productColor: '',
    productDescription: '',
    productPrice: 0,
    productSeq: 0,
    productStatus: 0,
    productTitle: '',
    productTradeType: 0,
    qty: 0,
    sortDate: '',
    storeSeq: 0,
  },
  termsBoardVO: [],
};

const initial_unicro_VO = {
  bankCode: '',
  orderNo: '',
  paymentType: 0,
  receiptNo: '',
  receiptType: 2, // 0:개인, 1:사업자, 2:선택X - 중나 요청으로 2를 선택되지 않은 상태코드로 사용 2019.01.07
  userAccountSeq: 0,
  userDeliverySeq: 0,

  // 실시간계좌이체 현금영수증 값저장
  transfer_mobile1: '',
  transfer_mobile2: '',
  transfer_mobile3: '',
  transfer_business1: '',
  transfer_business2: '',
  transfer_business3: '',

  // 무통장입금 현금영수증
  nobook_mobile1: '',
  nobook_mobile2: '',
  nobook_mobile3: '',
  nobook_business1: '',
  nobook_business2: '',
  nobook_business3: '',
};

const initial_unicro_payment_VO = {
  unicroToken: '',
  userId: '',
  itemNo: '',
  aspItemNo: '',
  aspTradeNo: '',
  aspCateNo: '',
  itemName: '',
  itemPrice: '',
  payway: 1,
  returnUrl: '',
  deliveryPayType: 0,
  bankCd: '0',
  trCodeOpt: '',
  cashHhpNo: '',
  cashTaxNo: '',
  buyrName: '',
  buyrMail: '',
  buyrHhpNo: '',
  deliveryZip: '',
  deliveryAddress1: '',
  deliveryAddress2: '',
  accountName: '',
  accountBankNo: '',
  accountNo: '',
};

const initial_payco_result = {
  demandBundleSeq: '',
  paycoAppUrlScheme: '',
  sellerReferenceKey: '',
  sendMoneyAppReserveNo: '',
};

const initial_payco_comlete = {
  productSeq: 0,
  pgSerialNo: '',
  orderQty: 0,
  updateDate: '',
  productName: '',
  mediaUrl: '',
  condition: {
    productCondition: 0,
    options: {
      fullPackageYn: 0,
      limitedEditionYn: 0,
      flawedYn: 0,
    },
  },
  productPrice: 0,
  completeYmdt: '',
  orderNo: '',
  paymentType: '',
  orderAmount: 0,
  receiveUserName: null,
  receiveUserPhoneNo: null,
  receiveUserPostNo: null,
  receiveUserAddress: null,
  receiveUserAddressDetail: null,
  receiptNo: null,
  bankCode: null,
  accountNo: null,
  accountName: null,
  expireYmdt: null,
  returnBankCode: null,
  returnAccountNo: null,
  returnAccountName: null,
};

const initial_kakao_result = {
  created_at: '', // 송금요청 생성일시
  expired_at: '', // 송금요청 만료일시
  next_send_url: '', // 송금진입 url
  status: 0, // 응답상태값
  tid: '', // 송금 거래번호
};

const initialState = {
  order_info: {
    meta: { message: '' },
    data: { ...initial_order_VO },
    moment: moment(),
  },
  unicro_VO: {
    ...initial_unicro_VO,
  },
  unicro_payment_VO: {
    ...initial_unicro_payment_VO,
  },
  unicro_payment: { meta: { message: '' }, data: {} },
  unicro_complete: {
    meta: { message: '' },
    data: { ...initial_payco_comlete },
  },
  payco_complete: { meta: { message: '' }, data: { ...initial_payco_comlete } },
  payment_agree: [false, false, false],
  error: { message: '', code: 0, caller: '' },
  transfer_seller_info: {
    success: false,
    info: {
      sellerAccountHolderName: '',
      sellerAccountNo: '',
      sellerNickname: '',
      sellerStoreSeq: 0,
    },
    error: { message: '', code: 0 },
    moment: moment(),
  },
  order_transfer_chat: { success: false, moment: moment() },
  payco_result: {
    meta: { message: '' },
    data: { code: '', message: '', result: { ...initial_payco_result } },
  },
  kakao_result: { meta: { message: '' }, data: { ...initial_kakao_result } },
  kakao_complete: { meta: { message: '' }, data: { ...initial_payco_comlete } },

  // 토스
  toss_check: { fail: null, resultType: '', success: { scheme: '', link: '' } },
  toss_result: {
    code: 0,
    message: '',
    meta: { code: 0, message: '' },
    data: { ...initial_payco_comlete },
  },
  toss_result_error: { code: 0, message: '' },
  // 토스 결과 확인
  onSuccessTossResult: moment(),
  onFailureTossResult: moment(),
};
// 액션 생성자 비동기 처리

// 주문정보 가져오기
export const get_order = createAction(
  Payment.default.GET_ORDER,
  Payment.get_order,
);
// 유니크로 객체 변경
export const change_unicro_VO = createAction(
  Payment.default.CHANGE_UNICRO_VO,
  Payment.change_unicro_VO,
);
// 유니크로 결제 시작
export const unicro_start = createAction(
  Payment.default.UNICRO_START,
  Payment.unicro_start,
);
// 약관동의 상태 변경
export const change_agree_state = createAction(
  Payment.default.CHANGE_AGREE_STATE,
  Payment.change_agree_state,
);
// 유니크로 결제 객체 생성
export const unicro_payment_change = createAction(
  Payment.default.UNICRO_PAYMENT_CHANGE,
  Payment.unicro_payment_change,
);
// 유니크로 결제완료
export const unicro_order_completed = createAction(
  Payment.default.UNICRO_ORDER_COMPLETED,
  Payment.unicro_order_completed,
);
// 즉시송금 선택
export const transfer_select = createAction(
  Payment.default.TRANSFER_SELECT,
  Payment.transfer_select,
);
// 페이코 결제
export const payment_payco = createAction(
  Payment.default.PAYMENT_PAYCO,
  Payment.payment_payco,
);
// 페이코 결제완료
export const payco_order_completed = createAction(
  Payment.default.PAYCO_ORDER_COMPLETED,
  Payment.payco_order_completed,
);
// 카카오 결제
export const payment_kakao = createAction(
  Payment.default.PAYMENT_KAKAO,
  Payment.payment_kakao,
);
// 카카오 결제완료
export const kakao_order_completed = createAction(
  Payment.default.KAKAO_ORDER_COMPLETED,
  Payment.kakao_order_completed,
);
// 즉시 송금 채팅 확인
export const order_transfer_chat = createAction(
  Payment.default.ORDER_TRANSFER_CHAT,
  Payment.order_transfer_chat,
);
// 토스 결제
export const payment_toss = createAction(
  Payment.default.PAYMENT_TOSS,
  Payment.payment_toss,
);
export const payment_toss_result = createAction(
  Payment.default.PAYMENT_TOSS_RESULT,
  Payment.payment_toss_result,
);

export default handleActions(
  {
    // 주문정보 가져오기
    ...pender({
      type: Payment.default.GET_ORDER,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        data.moment = moment();
        return {
          ...state,
          order_info: data,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;

        data.meta.caller = Payment.default.GET_ORDER;

        return {
          ...state,
          error: data.meta,
        };
      },
    }),

    // 유니크로 시작
    ...pender({
      type: Payment.default.UNICRO_START,
      onSuccess: (state, action) => {
        return {
          ...state,
          unicro_payment: action.payload,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;

        data.meta.caller = Payment.default.UNICRO_START;
        return {
          ...state,
          error: data.meta,
        };
      },
    }),

    // 유니크로 결제완료
    ...pender({
      type: Payment.default.UNICRO_ORDER_COMPLETED,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          unicro_complete: data,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;

        data.meta.caller = Payment.default.UNICRO_ORDER_COMPLETED;

        return {
          ...state,
          error: data.meta,
        };
      },
    }),

    // 즉시송금 선택
    ...pender({
      type: Payment.default.TRANSFER_SELECT,
      onSuccess: (state, action) => {
        const { data, moment } = action.payload;
        return {
          ...state,
          transfer_seller_info: {
            success: true,
            info: data.data,
            error: { message: '', code: 0 },
            moment,
          },
        };
      },
    }),

    // 페이코 결제
    ...pender({
      type: Payment.default.PAYMENT_PAYCO,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          payco_result: data,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;

        data.meta.caller = Payment.default.PAYMENT_PAYCO;

        return {
          ...state,
          error: data.meta,
        };
      },
    }),

    // 페이코 결제완료
    ...pender({
      type: Payment.default.PAYCO_ORDER_COMPLETED,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          payco_complete: data,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;

        data.meta.caller = Payment.default.PAYCO_ORDER_COMPLETED;

        return {
          ...state,
          error: data.meta,
        };
      },
    }),

    // 카카오 결제
    ...pender({
      type: Payment.default.PAYMENT_KAKAO,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          kakao_result: data,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;

        data.meta.caller = Payment.default.PAYMENT_KAKAO;

        return {
          ...state,
          error: data.meta,
        };
      },
    }),

    // 카카오 결제완료
    ...pender({
      type: Payment.default.KAKAO_ORDER_COMPLETED,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          kakao_complete: data,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;

        data.meta.caller = Payment.default.KAKAO_ORDER_COMPLETED;

        return {
          ...state,
          error: data.meta,
        };
      },
    }),

    // 토스 결제
    ...pender({
      type: Payment.default.PAYMENT_TOSS,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          toss_check: data.data,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        data.meta.caller = Payment.default.PAYMENT_TOSS;
        return {
          ...state,
          error: data.meta,
        };
      },
    }),
    ...pender({
      type: Payment.default.PAYMENT_TOSS_RESULT,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          toss_result: data,
          onSuccessTossResult: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          toss_result_error: data.meta,
          onFailureTossResult: moment(),
        };
      },
    }),

    // 유니크로 객체 변경
    [Payment.default.CHANGE_UNICRO_VO]: (state, action) => {
      return {
        ...state,
        unicro_VO: action.payload,
      };
    },
    // 유니크로 약관동의 상태 변경
    [Payment.default.CHANGE_AGREE_STATE]: (state, action) => {
      return {
        ...state,
        payment_agree: action.payload,
      };
    },

    // 유니크로 결제 객체 생성
    [Payment.default.UNICRO_PAYMENT_CHANGE]: (state, action) => {
      return {
        ...state,
        unicro_payment_VO: action.payload,
      };
    },

    // 즉시 송금 채팅 확인
    ...pender({
      type: Payment.default.ORDER_TRANSFER_CHAT,
      onSuccess: state => {
        const order_transfer_chat = { success: true, moment: moment() };
        return { ...state, order_transfer_chat };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          transfer_seller_info: {
            success: false,
            info: {
              sellerAccountHolderName: '',
              sellerAccountNo: '',
              sellerNickname: '',
              sellerStoreSeq: 0,
              sellerBankName: '',
            },
            error: data.meta,
          },
        };
      },
    }),
  },
  initialState,
);
