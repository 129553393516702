/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import useHistory from '../../../common/Hook/useHistory';

import styles from './Toast.module.scss';

let timeOut = null;

const Toast = () => {
  const toastRef = useRef();
  const {
    location: { pathname },
  } = useHistory();

  const text = useSelector(({ toast }) => toast.text);
  const force = useSelector(({ toast }) => toast.force);
  const time = useSelector(({ toast }) => toast.time);
  const setFunc = useSelector(({ toast }) => toast.setFunc);

  useEffect(() => {
    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  useEffect(() => {
    clearTimeout(timeOut);
    if (text !== '') {
      toastRef.current.classList.add(styles.isShow);
      timeOut = setTimeout(() => {
        toastRef.current.classList.remove(styles.isShow);
      }, 2000);

      if (setFunc !== null) setFunc();
    }

    return () => {
      clearTimeout(timeOut);
    };
  }, [text, time]);

  useEffect(() => {
    if (!force) {
      clearTimeout(timeOut);
      toastRef.current.classList.remove(styles.isShow);
    }
  }, [pathname]);

  return (
    <div className={classNames(styles.toast)} ref={toastRef}>
      <p>
        {text.split('\n').map((t, i) => (
          <span className="f15" key={`toastText_${t}_${i}`}>
            {t}
          </span>
        ))}
      </p>
    </div>
  );
};

Toast.propTypes = {};

Toast.defaultProps = {
  history: {},
};

export default Toast;
