import axios from 'axios';
import * as Config from '../config';

const layout = {
  TOGGLE_SHOW_HEADER: 'TOGGLE_SHOW_HEADER',
};

export function toggleShowHeader(bool) {
  return bool;
}

export default layout;
