import axios from 'axios';
import * as Config from '../config';

const user = {
  // 이메일, 닉네임, 추천코드 검사
  POST_USER_OVERLAP: 'user/POST_USER_OVERLAP',

  // SNS, EMAIL - 로그인, 회원가입
  POST_USER_SNS_LOGIN: 'user/POST_USER_SNS_LOGIN',
  POST_USER_SNS_JOIN: 'user/POST_USER_SNS_JOIN',
  POST_USER_EMAIL_JOIN: 'user/POST_USER_EMAIL_JOIN',
  POST_USER_EMAIL_LOGIN: 'user/POST_USER_EMAIL_LOGIN',

  // 친구 초대 추천인 코드 조회
  GET_RECOMM_CODE: 'user/GET_RECOMM_CODE',

  // 비밀번호 변경
  POST_PASSWORD_CHANGE: 'user/POST_PASSWORD_CHANGE',
  // 로그아웃
  POST_USER_LOGOUT: 'user/POST_USER_LOGOUT',

  // 정보 저장
  SET_PAGE_TYPE: 'user/SET_PAGE_TYPE',
  SET_LOGIN_TYPE: 'user/SET_LOGIN_TYPE',
  SET_NAVER_DATA: 'user/SET_NAVER_DATA',
  SET_KAKAO_DATA: 'user/SET_KAKAO_DATA',
  SET_KAKAO_USER: 'user/SET_KAKAO_USER',
  SET_FACEBOOK_DATA: 'user/SET_FACEBOOK_DATA',
  SET_PAYCO_DATA: 'user/SET_PAYCO_DATA',
  SET_MARKETING_YN: 'user/SET_MARKETING_YN',
  SET_FIND_ID: 'user/SET_FIND_ID',

  // 외부 URL
  SET_REDIRECT_URL: 'user/SET_REDIRECT_URL',
  SET_AFTER_LOGIN_URL: 'user/SET_AFTER_LOGIN_URL',

  // 회원정보설정 - 닉네임 변경
  PUT_CHANGE_NICKNAME: 'user/PUT_CHANGE_NICKNAME',
  // 회원정보설정 - 비밀번호 변경
  PUT_CHANGE_PASSWORD: 'user/PUT_CHANGE_PASSWORD',
  // 회원정보설정 - 안심번호 설정
  PUT_STATE_SAFETY: 'user/PUT_STATE_SAFETY',
  // 회원정보설정 - 전화번호 비공개 설정
  PUT_STATE_PHONE_SHOW: 'user/PUT_STATE_PHONE_SHOW',
  // 회원정보설정 - 마케팅 수신동의 설정
  PUT_STATE_MARKETING: 'user/PUT_STATE_MARKETING',
  GET_STATE_MARKETING: 'user/GET_STATE_MARKETING',
  // 회원정보설정 - 회원탈퇴
  DELETE_USER_STATE: 'user/DELETE_USER_STATE',
  // 회원정보설정 - 이메일 연동
  PUT_LINK_EMAIL: 'user/PUT_LINK_EMAIL',
  // 회원정보설정 - 이메일 연동해제
  DELETE_UNLINK_EMAIL: 'user/DELETE_UNLINK_EMAIL',
  SNS_LINK: 'user/SNS_LINK',
  // 회원정보설정 - sns 연동 해제
  DELETE_UNLINK_SNS: 'user/DELETE_UNLINK_SNS',
  // 회원정보설정 - cafe 연동
  PUT_LINK_CAFE: 'user/PUT_LINK_CAFE',
  // 회원정보설정 - cafe 연동해제
  DELETE_UNLINK_CAFE: 'user/DELETE_UNLINK_CAFE',

  // 초기화
  CLEAR_USER: 'user/CLEAR_USER',
  CLEAR_USER_NOT_REDIRECT: 'user/CLEAR_USER_NOT_REDIRECT',
  CLEAR_SNS_ERROR: 'user/CLEAR_SNS_ERROR',
};

// 이메일, 닉네임, 추천코드, 중복 확인 및 이메일 연동 여부 확인
export function postUserOverlap(param) {
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/user/overlap`,
    param,
    Config.default.JSON_HEADER,
  );
  return promise;
}

// 회원 SNS 로그인
export function postUserSnsLogin(param) {
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/user/sns/login`,
    param,
    Config.default.JSON_HEADER,
  );
  return promise;
}

// 회원 SNS 회원가입
export function postUserSnsJoin(param) {
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/user/sns/join`,
    param,
    Config.default.JSON_HEADER,
  );
  return promise;
}

// 회원 이메일 회원가입
export function postUserEmailJoin(param) {
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/user/email/join`,
    param,
    Config.default.JSON_HEADER,
  );
  return promise;
}

// 회원 이메일 로그인
export function postUserEmailLogin(param) {
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/user/email/login`,
    param,
    Config.default.JSON_HEADER,
  );
  return promise;
}

// 친구초대 추천인 코드 조회
export function getRecommCode() {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/user/info/recomm_code`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 회원정보설정 - 닉네임 변경
export function putChangeNickname(nickName) {
  const vo = {
    nickName,
  };
  const promise = axios.put(
    `${Config.default.API_BASE_URL}/user/state/nickname`,
    vo,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 회원정보설정 - 비밀번호 변경
export function putChangePassword(userOldPassword, userPassword) {
  const vo = {
    userOldPassword,
    userPassword,
  };
  const promise = axios.put(
    `${Config.default.API_BASE_URL}/user/state/password`,
    vo,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 회원정보설정 - 비밀번호 변경
export function putLinkEmail(userId, userPassword) {
  const vo = {
    userId,
    userPassword,
  };
  const promise = axios.put(
    `${Config.default.API_BASE_URL}/user/state/link/email`,
    vo,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 회원정보설정 - 이메일 연동 해제
export function deleteUnlinkEmail() {
  const promise = axios.delete(
    `${Config.default.API_BASE_URL}/user/state/unlink/email`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// SNS 연동
export function sns_link(userSnsVO) {
  const promise = axios.put(
    `${Config.default.API_BASE_URL}/user/state/link/sns`,
    JSON.stringify(userSnsVO),
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 회원정보설정 - sns 연동 해제
export function deleteUnlinkSns(type) {
  const promise = axios.delete(
    `${Config.default.API_BASE_URL}/user/state/unlink/sns/${type}`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 회원정보설정 - cafe 연동
export function putLinkCafe(userSnsVO) {
  const promise = axios.put(
    `${Config.default.API_BASE_URL}/user/state/link/cafe`,
    JSON.stringify(userSnsVO),
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 회원정보설정 - cafe 연동 해제
export function deleteUnlinkCafe() {
  const promise = axios.delete(
    `${Config.default.API_BASE_URL}/user/state/unlink/cafe`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 비밀번호 변경
export function postPasswordChange(param) {
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/user/find/password/change`,
    param,
    Config.default.JSON_HEADER,
  );
  return promise;
}
// 로그아웃
export function postUserLogout() {
  const promise = axios.post(
    `${Config.default.API_BASE_URL}/user/logout`,
    '',
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 안심번호 설정
export function putStateSafety() {
  const promise = axios.put(
    `${Config.default.API_BASE_URL}/user/state/safety`,
    null,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 전화번호 비공개 설정
export function putStatePhoneShow() {
  const promise = axios.put(
    `${Config.default.API_BASE_URL}/user/state/phone-show`,
    null,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 마케팅 수신동의 설정 - sms, email, push
export function putStateMarketing(type, agree) {
  const promise = axios.put(
    `${Config.default.API_BASE_URL}/v2/user/state/marketing/${type}/${agree}`,
    null,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 마케팅 수신동의 조회
export function getStateMarketing() {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/user/state/marketing`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}
// 회원 탈퇴
export function deleteUserState() {
  const promise = axios.delete(
    `${Config.default.API_BASE_URL}/user/state/drop`,
    Config.getHeader(Config.default.JSON_TYPE),
  );
  return promise;
}

// 페이지 타입 저장
export function setPageType(data) {
  return data;
}
// 로그인 타입 저장
export function setLoginType(data) {
  return data;
}

// 네이버 데이터 저장
export function setNaverData(data) {
  return data;
}
// 카카오 데이터 저장
export function setKakaoData(data) {
  return data;
}
// 카카오톡 회원 정보
export function setKakaoUser(data) {
  return data;
}
// 페이스북 데이터 저장
export function setFacebookData(data) {
  return data;
}
// 페이코 데이터 저장
export function setPaycoData(data) {
  return data;
}
// GET /sns/payco 페이코 access token 발급
export function getPaycoAccessToken(code) {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/sns/payco?code=${code}`,
    Config.default.JSON_HEADER,
  );
  return promise;
}

// 마케팅 동의 여부
export function setMarketingYn(data) {
  return data;
}

// 찾은 id 저장
export function setFindId(data) {
  return data;
}

// 파트너 URL 저장
export function setRedirectUrl(path = '', ExternalPath = '') {
  return {
    path,
    ExternalPath,
  };
}

export default user;
