import axios from 'axios';
import * as Config from '../config';
import { getHeaders } from '../../api/apiUtil';

const faq = {
  GET_FAQ_CATEGORY: 'GET_FAQ_CATEGORY',
  GET_FAQ_LIST: 'GET_FAQ_LIST',
  GET_FAQ_SEARCH: 'GET_FAQ_SEARCH',
  PUT_FAQ_HIT: 'PUT_FAQ_HIT',
  SET_FAQ_SEARCH_INIT: 'SET_FAQ_SEARCH_INIT',
};

// 고객센터 - FAQ 카테고리 조회 API
export function getFaqCategory() {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/mypage/faq/category`,
    Config.default.JSON_HEADER,
  );
  return promise;
}

// 고객센터 - FAQ 조회 API
export function getFaqList(cateType, startIndex, searchQuantity) {
  const promise = axios.get(
    `${
      Config.default.API_BASE_URL
    }/mypage/faq?cateType=${cateType}&startIndex=${startIndex}&searchQuantity=${searchQuantity}`,
    Config.default.JSON_HEADER,
  );
  return promise;
}

// 고객센터 - FAQ 검색 API
export function getFaqSearch(word, startIndex, searchQuantity) {
  const promise = axios.get(
    `${Config.default.API_BASE_URL}/mypage/faq/search?word=${encodeURIComponent(
      word,
    )}&startIndex=${startIndex}&searchQuantity=${searchQuantity}`,
    Config.default.JSON_HEADER,
  );
  return promise;
}

// 고객센터 - FAQ 조회 API
export function putFaqHit(faqSeq) {
  // const promise = axios.put(
  //   `${Config.default.API_BASE_URL}/mypage/faq/hit/${faqSeq}`,
  //   null,
  //   Config.getHeader(Config.default.JSON_TYPE),
  // );
  return axios({
    method: 'put',
    url: `/mypage/faq/hit/${faqSeq}`,
    baseURL: Config.default.API_BASE_URL,
    headers: getHeaders(),
  });
}

// 고객센터 검색 리스트
export function setFaqSearchInit() {
  return null;
}

export default faq;
