import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import produce from 'immer';

import { config } from '../../common/api';

import {
  postFollow as postFollowApi,
  deleteFollow as deleteFollowApi,
 getFollow as getFollowApi } from '../../api/base/mypage';


export const Actions = {
  POST_FOLLOW: 'FOLLOW/POST_FOLLOW',
  DELETE_FOLLOW: 'FOLLOW/DELETE_FOLLOW',
  GET_FOLLOWER: 'FOLLOW/GET_FOLLOWER',
  GET_FOLLOWING: 'FOLLOW/GET_FOLLOWING',
  CLEAR_FOLLOW: 'FOLLOW/CLEAR_FOLLOW',
};

const initialState = {
  follower: {
    type: 'follower',
    text: '단골관리_단골손님',
    index: 0,
    isMore: true,
    items: [],
  },

  following: {
    type: 'following',
    text: '단골관리_단골가게',
    index: 0,
    isMore: true,
    items: [],
  },

  // 에러
  error: {
    code: 200,
    message: '',
  },
};

export const postFollow = createAction(Actions.POST_FOLLOW, postFollowApi);
export const deleteFollow = createAction(
  Actions.DELETE_FOLLOW,
  deleteFollowApi,
);

export const getFollower = createAction(
  Actions.GET_FOLLOWER,
  (storeSeq, index) => getFollowApi(0, storeSeq, index),
);
export const getFollowing = createAction(
  Actions.GET_FOLLOWING,
  (storeSeq, index) => getFollowApi(1, storeSeq, index),
);

export const clearFollow = createAction(Actions.CLEAR_FOLLOW);

export default handleActions(
  {
    ...pender({
      type: Actions.POST_FOLLOW,
      onSuccess: (state, action) => {
        const {
          config: { url },
        } = action.payload;
        const storeSeq = Number(
          url.replace(`${config.API_BASE_URL}/mypage/follow/`, ''),
        );

        const followerItem = state.follower.items.map(i => {
          return i.storeSeq === storeSeq
            ? {
                ...i,
                followingYn: 1,
              }
            : i;
        });

        const followingItem = state.following.items.map(i => {
          return i.storeSeq === storeSeq
            ? {
                ...i,
                followingYn: 1,
              }
            : i;
        });

        return produce(state, d => {
          const draft = d;
          draft.follower.items = followerItem;
          draft.following.items = followingItem;
          draft.error = initialState.error;
        });
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),

    ...pender({
      type: Actions.DELETE_FOLLOW,
      onSuccess: (state, action) => {
        const {
          config: { url },
        } = action.payload;
        const storeSeq = Number(
          url.replace(`${config.API_BASE_URL}/mypage/follow/`, ''),
        );

        const followerItem = state.follower.items.map(i => {
          return i.storeSeq === storeSeq
            ? {
                ...i,
                followingYn: 0,
              }
            : i;
        });

        const followingItem = state.following.items.map(i => {
          return i.storeSeq === storeSeq
            ? {
                ...i,
                followingYn: 0,
              }
            : i;
        });

        return produce(state, d => {
          const draft = d;
          draft.follower.items = followerItem;
          draft.following.items = followingItem;
          draft.error = initialState.error;
        });
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),

    ...pender({
      type: Actions.GET_FOLLOWER,
      onSuccess: (state, action) => {
        const {
          data: { data },
        } = action.payload;
        if (data.length > 0) {
          return produce(state, d => {
            const draft = d;
            draft.follower.index += 1;
            draft.following.isMore = data.length >= 20;
            draft.follower.items = [...state.follower.items, ...data];
            draft.error = initialState.error;
          });
        }
        return produce(state, d => {
          const draft = d;
          draft.follower.isMore = false;
        });
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),

    ...pender({
      type: Actions.GET_FOLLOWING,
      onSuccess: (state, action) => {
        const {
          data: { data },
        } = action.payload;
        if (data.length > 0) {
          // followingYn이 항상 0이 들어와서 클라쪽에서 해결해준다. -> 왜인지는 모름...
          // const setData = data.map(d => ({ ...d, followingYn: 1 }));
          return produce(state, d => {
            const draft = d;
            draft.following.index += 1;
            draft.following.isMore = data.length >= 20;
            draft.following.items = [...state.following.items, ...data];
            draft.error = initialState.error;
          });
        }
        return produce(state, d => {
          const draft = d;
          draft.following.isMore = false;
        });
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),

    [Actions.CLEAR_FOLLOW]: () => {
      return { ...initialState };
    },
  },
  initialState,
);
