import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import moment from 'moment';
import * as Common from '../../js/common';

import * as Location from '../../actions/service/LocationService';

export const getAddrList = createAction(
  Location.default.GET_ADDR_LIST,
  Location.getAddrList,
);
export const getCoordinateList = createAction(
  Location.default.GET_COORDINATE_LIST,
  Location.getCoordinateList,
);
export const postElasticType4 = createAction(
  Location.default.POST_ELASTIC_TYPE4,
  Location.postElasticType4,
);

// 지도에서 중심좌표에 대한 정보와 랜드마크
export const getLocationInfo = createAction(
  Location.default.GET_LOCATION_INFO,
  Location.getLocationInfo,
);
export const getLandmarkList = createAction(
  Location.default.GET_LANDMARKE_LIST,
  Location.getLandmarkList,
);
// user 현위치 정보와 랜드마크
export const getUserLocationInfo = createAction(
  Location.default.GET_USER_LOCATION_INFO,
  Location.getLocationInfo,
);
export const getUserLocationLandmarkList = createAction(
  Location.default.GET_USER_LOCATION_LANDMARKE_LIST,
  Location.getLandmarkList,
);

// 주소 검색 랜드마크
export const getAddrLandmarkList = createAction(
  Location.default.GET_ADDR_LANDMARKE_LIST,
  Location.getLandmarkList,
);

// 최근 검색어
export const getMyLocationHistory = createAction(
  Location.default.GET_MY_LOCATION_HISTORY,
  Location.getMyLocationHistory,
);
// 최근 검색어 저장
export const addMyLocationHistory = createAction(
  Location.default.ADD_MY_LOCATION_HISTORY,
  Location.addMyLocationHistory,
);
// 최근 검색어 삭제
export const deleteMylocationHistory = createAction(
  Location.default.DELETE_MY_LOCATION_HISTORY,
  Location.deleteMylocationHistory,
);

const initialState = {
  addrList: [],
  coordinateList: [],
  locationInfo: [],
  landmarkList: [],
  addrLandmarkList: [],
  userLocationInfo: [],
  userLocationLandmarkList: [],
  myLocationHistory: [],
  deleteHistory: moment(),
  locationProduct: [],
  onSuccessElasticType4: moment(),
};

export default handleActions(
  {
    ...pender({
      type: Location.default.GET_ADDR_LIST,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          addrList: data.data,
        };
      },
    }),
    ...pender({
      type: Location.default.GET_COORDINATE_LIST,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          coordinateList: data.data,
        };
      },
    }),
    ...pender({
      type: Location.default.GET_LOCATION_INFO,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          locationInfo: data.data,
        };
      },
    }),
    ...pender({
      type: Location.default.GET_LANDMARKE_LIST,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          landmarkList: data.data,
        };
      },
    }),
    ...pender({
      type: Location.default.GET_ADDR_LANDMARKE_LIST,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          addrLandmarkList: data.data,
        };
      },
    }),
    ...pender({
      type: Location.default.GET_USER_LOCATION_INFO,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          userLocationInfo: data.data,
        };
      },
    }),
    ...pender({
      type: Location.default.GET_USER_LOCATION_LANDMARKE_LIST,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          userLocationLandmarkList: data.data,
        };
      },
    }),
    ...pender({
      type: Location.default.GET_MY_LOCATION_HISTORY,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          myLocationHistory: data.data,
        };
      },
    }),
    ...pender({
      type: Location.default.DELETE_MY_LOCATION_HISTORY,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          deleteHistory: moment(),
        };
      },
    }),
    ...pender({
      type: Location.default.POST_ELASTIC_TYPE4,
      onSuccess: (state, action) => {
        const { data, config } = action.payload;
        // 첫페이지 로드시 리셋
        if (config.data != null && config.data.length > 0) {
          const paramData = JSON.parse(config.data);
          if (
            paramData.startIndex != null &&
            (paramData.startIndex === 0 || paramData.startIndex === '0')
          ) {
            state.locationProduct = [];
          }
        }

        data.data.map(item => {
          item.sortDateKor = Common.setDateFormat(item.sortDate);
        });

        // 스크롤링 시 데이터 병합
        let locationProduct = data.data;
        locationProduct = state.locationProduct.concat(locationProduct);
        return {
          ...state,
          locationProduct,
          onSuccessElasticType4: moment(),
        };
      },
    }),
  },
  initialState,
);
