import axios from 'axios';
import config from '../config';
import { getHeaders } from '../apiUtil';

const baseURL = `${config.AUTO_API_BASE_URL}`;

/**
 * 차량번호로 차량정보 조회
 * @param {Number} carNo
 * @param {boolean} getPath
 */
export function getSearchCarinfoByCarno(carNo, getPath = false) {
  if (getPath) return `${baseURL}/carinfo/searchCarinfoByCarno/${carNo}`;
  return axios({
    method: 'get',
    url: `/carinfo/searchCarinfoByCarno/${carNo}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 차량 옵션정보 조회
 */
export function getOptionList(carNo, getPath = false) {
  if (getPath) return `${baseURL}/carinfo/optionList`;
  return axios({
    method: 'get',
    url: `/carinfo/optionList`,
    baseURL,
    headers: getHeaders(),
  });
}

// GET /carinfo/makerList 제조사 리스트
export function getMakerList() {
  return axios({
    method: 'get',
    url: `/carinfo/makerList`,
    baseURL,
    headers: getHeaders(),
  });
}

// GET /carinfo/classModelList/{makerNo} 대표 모델 리스트
export function getClassModelList(makerNo) {
  return axios({
    method: 'get',
    url: `/carinfo/classModelList/${makerNo}`,
    baseURL,
    headers: getHeaders(),
  });
}

// GET /carinfo/modelList/{classModelNo} 모델 리스트
export function getModelList(classModelNo) {
  return axios({
    method: 'get',
    url: `/carinfo/modelList/${classModelNo}`,
    baseURL,
    headers: getHeaders(),
  });
}

// GET /carinfo/gradeList/{modelNo} 등급 리스트
export function getGradeList(modelNo) {
  return axios({
    method: 'get',
    url: `/carinfo/gradeList/${modelNo}`,
    baseURL,
    headers: getHeaders(),
  });
}

// GET /carinfo/subGradeList/{modelNo}/{bsNo} 세부 등급 리스트
export function getSubGradeList(modelNo, bsNo) {
  return axios({
    method: 'get',
    url: `/carinfo/subGradeList/${modelNo}/${bsNo}`,
    baseURL,
    headers: getHeaders(),
  });
}
