import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';

import {
  getMainInfo as getMainInfoApi,
  getMainUserInfo as getMainUserInfoApi,
} from '../../api/base/mainpage';

export const Actions = {
  GET_MAIN_INFO: 'MAIN/GET_MAIN_INFO',
  GET_MAIN_USER_INFO: 'MAIN/GET_MAIN_USER_INFO',
  CLEAR_MAIN_USER_INFO: 'MAIN/CLEAR_MAIN_USER_INFO',
  FOCUS_SEARCH_INPUT: 'MAIN/FOCUS_SEARCH_INPUT',
  BLUR_SEARCH_INPUT: 'MAIN/BLUR_SEARCH_INPUT',
};

const initialState = {
  isGet: false,

  // v2 데이터
  alarmCount: 0,

  // v1, v2 데이터
  bannerRollingPeriod: 4000,
  banners: [],

  // v1 데이터
  mustReads: [],
  notices: [],
  userInfo: null,

  // v3 데이터
  useSearchInput: false,

  error: {
    code: 200,
    message: '',
  },
};

export const getMainInfo = createAction(Actions.GET_MAIN_INFO, isV1 =>
  getMainInfoApi(isV1),
);

export const getMainUserInfo = createAction(
  Actions.GET_MAIN_USER_INFO,
  getMainUserInfoApi,
);

export const clearMainUserInfo = createAction(Actions.CLEAR_MAIN_USER_INFO);

export const focusSearchInput = createAction(Actions.FOCUS_SEARCH_INPUT);
export const blurSearchInput = createAction(Actions.BLUR_SEARCH_INPUT);

export default handleActions(
  {
    ...pender({
      type: Actions.GET_MAIN_INFO,
      onSuccess: (state, action) => {
        const {
          data: { data },
        } = action.payload;

        return {
          ...state,
          isGet: true,
          ...data,
          banners: data.banners.map(b => ({
            bannerSeq: b.bannerSeq,
            bannerTitle: b.bannerTitle || '',
            linkUrl: b.webUrlLink || '',
            imgUrl: b.bannerUrl || '',
            deepLinkUrl: b.deepLink || '',
          })),
          error: initialState.error,
        };
      },
      onFailure: (state, action) => {
        const { response } = action.payload;
        if (response) {
          return {
            ...state,
            error: {
              code: response.data.meta.code,
              message: response.data.meta.message,
            },
          };
        }
        return {
          ...state,
          error: {
            code: 999,
            message: 'Network Error',
          },
        };
      },
    }),
    ...pender({
      type: Actions.GET_MAIN_USER_INFO,
      onSuccess: (state, action) => {
        const {
          data: {
            data: { userInfo },
          },
        } = action.payload;
        return {
          ...state,
          userInfo,
          error: initialState.error,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),
    [Actions.FOCUS_SEARCH_INPUT]: state => {
      return {
        ...state,
        useSearchInput: true,
      };
    },
    [Actions.BLUR_SEARCH_INPUT]: state => {
      return {
        ...state,
        useSearchInput: false,
      };
    },
  },
  initialState,
);
