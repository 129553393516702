import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import useHistory from '../../common/Hook/useHistory';
import { goApp, getQueryString } from '../../common/util';
import { parseDeepLink } from '../../common/parser';

import { isDesktop } from '../../common/device';

import PopUp from '../common/popup/PopUp';
import icLogo from '../../asset/img/icLogo.png';

import styles from './AppDim.module.scss';

const AppDim = () => {
  const {
    location: { pathname },
  } = useHistory();

  const authAlertShow = useSelector(({ auth }) => auth.show);
  const maintenanceData = useSelector(({ maintenance }) => maintenance);

  const [dimApp, setDimApp] = useState(() => {
    const session = window.sessionStorage.getItem('jn_app_dim');
    const external = getQueryString('external');
    const type = getQueryString('type');
    const sendFrom = getQueryString('sendfrom');
    const pathnameCheck =
      pathname.indexOf('/fraud') > -1 ||
      pathname.indexOf('/point-event/') > -1 ||
      pathname.indexOf('/auto/autoshop') > -1 ||
      pathname.indexOf('/event-detail/293') > -1 ||
      pathname.indexOf('/join') > -1 ||
      pathname.indexOf('/login_sso_by_jongna_ps') > -1 ||
      pathname.indexOf('/information') > -1 ||
      pathname.indexOf('/change-user-info') > -1 ||
      pathname.indexOf('/maintenance') > -1 ||
      pathname.indexOf('/compare-terms') > -1 ||
      pathname.indexOf('/auto/sales') > -1 ||
      pathname.indexOf('/auto-thirdparties-terms') > -1;

    return (
      !isDesktop() &&
      !pathnameCheck &&
      !session &&
      !external &&
      type !== 'share' &&
      sendFrom.length === 0 &&
      maintenanceData.code === 200
    );
  });

  const handleClose = () => {
    window.sessionStorage.setItem('jn_app_dim', new Date());
    setDimApp(false);
  };

  const redirectApp = () => {
    const { link } = parseDeepLink(pathname, true);
    goApp(link);
  };

  return (
    <PopUp
      show={dimApp && !authAlertShow}
      warpClassName={styles.dimApp}
      type="slideToTop"
    >
      <div className={styles.dimAppBody}>
        <div className={styles.dimTextWrap}>
          <img className={styles.icon} src={icLogo} alt="중고나라" />
          <div className={styles.text}>
            <div>편리한 사용을 위해</div>
            <div>앱을 다운로드 하시겠습니까?</div>
          </div>
        </div>
        <div className={styles.buttons}>
          <button type="button" onClick={handleClose}>
            아니오
          </button>
          <button type="button" onClick={redirectApp}>
            네,그럴게요
          </button>
        </div>
      </div>
    </PopUp>
  );
};

export default AppDim;
