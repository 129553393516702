import axios from 'axios';
import config from '../config';
import { getHeaders } from '../apiUtil';

const baseURL = `${config.API_BASE_URL}`;

export function getMyTag() {
  return axios({
    method: 'get',
    url: `/mytag`,
    baseURL,
    headers: getHeaders(),
  });
}

export function postMyTag(string) {
  return axios({
    method: 'post',
    url: `/mytag?keywordName=${string}`,
    baseURL,
    headers: getHeaders(),
  });
}

export function deleteMyTag(seq) {
  return axios({
    method: 'delete',
    url: `/mytag/${seq}`,
    baseURL,
    headers: getHeaders(),
  });
}
