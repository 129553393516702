import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import produce from 'immer';

import { postOrdersPrePared } from '../../api/base/order';

export const Actions = {
  GET_ORDER_INFO: 'PAYMENT/GET_ORDER_INFO',
  UPDATE_ORDER_RESULT: 'PAYMENT/UPDATE_ORDER_RESULT',

  CHANGE_PAYMENT: 'PAYMENT/CHANGE_PAYMENT',
  CHANGE_PAYMENT_DETAIL: 'PAYMENT/CHANGE_PAYMENT_DETAIL',
  SELECT_CASH_RECEIPT: 'PAYMENT/SELECT_CASH_RECEIPT',
  SELECT_CASH_RECEIPT_DETAIL: 'PAYMENT/SELECT_CASH_RECEIPT_DETAIL',
  CHANGE_CASH_RECEIPT_VALUE: 'PAYMENT/CHANGE_CASH_RECEIPT_VALUE',
  CHANGE_DEPOSITLESS_BANK_IDX: 'PAYMENT/CHANGE_DEPOSITLESS_BANK_IDX',
  TOGGLE_AGREE_CASH_RECEIPT: 'PAYMENT/TOGGLE_AGREE_CASH_RECEIPT',
  CHANGE_TERMS_AGREE_STATUS: 'PAYMENT/CHANGE_TERMS_AGREE_STATUS',
  CHANGE_TRADE_METHOD: 'PAYMENT/CHANGE_TRADE_METHOD',
  CLEAR: 'PAYMENT/CLEAR',
};

export const getOrderInfo = createAction(
  Actions.GET_ORDER_INFO,
  postOrdersPrePared,
);

export const updateOrderResult = createAction(
  Actions.UPDATE_ORDER_RESULT,
  data => ({ data }),
);

export const changePayment = createAction(Actions.CHANGE_PAYMENT, payment => ({
  payment,
}));

export const changePaymentDetail = createAction(
  Actions.CHANGE_PAYMENT_DETAIL,
  paymentDetail => ({ paymentDetail }),
);

export const selectCashReceipt = createAction(
  Actions.SELECT_CASH_RECEIPT,
  name => ({ name }),
);

export const selectCashReceiptDetail = createAction(
  Actions.SELECT_CASH_RECEIPT_DETAIL,
  name => ({ name }),
);

export const changeCashReceiptValue = createAction(
  Actions.CHANGE_CASH_RECEIPT_VALUE,
  (name, value) => ({ name, value }),
);

export const changeDepositlessBankIdx = createAction(
  Actions.CHANGE_DEPOSITLESS_BANK_IDX,
  depositlessBankIdx => ({ depositlessBankIdx }),
);

export const toggleAgreeCashReceipt = createAction(
  Actions.TOGGLE_AGREE_CASH_RECEIPT,
  bool => ({ bool }),
);

export const changeTermsAgreeStatus = createAction(
  Actions.CHANGE_TERMS_AGREE_STATUS,
  (name, checked) => ({ name, checked }),
);

export const changeTradeMethod = createAction(
  Actions.CHANGE_TRADE_METHOD,
  tradeMethod => ({ tradeMethod }),
);

export const clear = createAction(Actions.CLEAR);

const initialState = {
  // 주문 정보
  orderInfo: {},

  // 결제완료 정보
  orderResult: null,

  // 배송지 정보
  // 다른 redux store에서 관리할 예정.

  // 현급영수증 정보
  cashReceiptsInfo: {
    receipt: 'PERSONAL',
    receiptDetail: 'PHONE_NUMBER',
  },

  // 선택값
  actives: {
    payment: '',
    paymentDetail: '',
    depositlessBankIdx: null,
    agreeCashReceipt: true,
    termsAgree: {
      settleTerms: {
        // 세틀뱅크 서비스 이용 약관
        pg: 'SETTLE',
        isAgree: false,
      },
      unicroTerms: {
        // 유니크로 서비스 이용약관
        pg: 'UNICRO',
        isAgree: false,
      },
      unicroPrivacyTerms: {
        // 유니크로 개인정보 수집 및 이용 동의
        pg: 'UNICRO',
        isAgree: false,
      },
      kbTerms: {
        // 개인정보 제 3자 제공 동의
        pg: 'KB',
        isAgree: false,
      },
      privacyTerms: {
        // 개인정보 수집 및 이용 약관
        pg: 'ALL',
        isAgree: false,
      },
      sellerTerms: {
        // 개인정보 판매자 제공 약관
        pg: 'ALL',
        isAgree: false,
      },
    },
    tradeMethod: 'POST',
  },

  // 에러
  error: {
    code: 200,
    message: '',
  },
};

// TODO: 왜 produce 사용했는지 다시 조사하기.

export default handleActions(
  {
    ...pender({
      type: Actions.GET_ORDER_INFO,
      onSuccess: (state, action) => {
        const {
          data: { data },
        } = action.payload;

        const returnState = {
          ...initialState,
          orderInfo: {
            ...data,
          },
        };
        if (data.products.tradeMethod !== 'ALL') {
          // 방어 로직 테스트용 코드: data.products.tradeMethod = 'ALL';
          returnState.actives.tradeMethod = data.products.tradeMethod;
        }

        return returnState;
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return produce(state, d => {
          const draft = d;
          draft.error.code = data.meta.code;
          draft.error.message = data.meta.message;
        });
      },
    }),

    [Actions.UPDATE_ORDER_RESULT]: (state, action) => {
      const { data } = action.payload;
      return produce(state, d => {
        const draft = d;
        draft.orderResult = { ...data };
      });
    },

    [Actions.CHANGE_PAYMENT]: (state, action) => {
      const { payment } = action.payload;
      return produce(state, d => {
        const draft = d;
        draft.actives.payment = payment;
      });
    },
    [Actions.CHANGE_PAYMENT_DETAIL]: (state, action) => {
      const { paymentDetail } = action.payload;
      return produce(state, d => {
        const draft = d;
        draft.actives.paymentDetail = paymentDetail;
      });
    },
    [Actions.CHANGE_TRADE_METHOD]: (state, action) => {
      const { tradeMethod } = action.payload;
      return produce(state, d => {
        const draft = d;
        draft.actives.tradeMethod = tradeMethod;
      });
    },
    [Actions.SELECT_CASH_RECEIPT]: (state, action) => {
      const { name } = action.payload;

      return produce(state, d => {
        const draft = d;
        draft.cashReceiptsInfo.receipt = name;
      });
    },
    [Actions.SELECT_CASH_RECEIPT_DETAIL]: (state, action) => {
      const { name } = action.payload;

      return produce(state, d => {
        const draft = d;
        draft.cashReceiptsInfo.receiptDetail = name;
      });
    },
    [Actions.CHANGE_CASH_RECEIPT_VALUE]: (state, action) => {
      const { name, value } = action.payload;

      return produce(state, d => {
        const draft = d;
        // draft.cashReceiptsInfo[name] = value;
        draft.cashReceiptsInfo = {
          ...draft.cashReceiptsInfo,
          [name]: value,
        };
      });
    },
    [Actions.CHANGE_DEPOSITLESS_BANK_IDX]: (state, action) => {
      const { depositlessBankIdx } = action.payload;

      return produce(state, d => {
        const draft = d;
        draft.actives.depositlessBankIdx = depositlessBankIdx;
      });
    },
    [Actions.TOGGLE_AGREE_CASH_RECEIPT]: (state, action) => {
      const { bool } = action.payload;

      return produce(state, d => {
        const draft = d;
        draft.actives.agreeCashReceipt = bool;
      });
    },
    [Actions.CHANGE_TERMS_AGREE_STATUS]: (state, action) => {
      const { name, checked } = action.payload;

      if (name === 'toggleAll') {
        return produce(state, d => {
          const draft = d;
          Object.keys(draft.actives.termsAgree).forEach(t => {
            draft.actives.termsAgree[t].isAgree = checked;
          });
        });
      }
      return produce(state, d => {
        const draft = d;
        draft.actives.termsAgree[name].isAgree = checked;
      });
    },
    [Actions.CLEAR]: () => {
      return {
        ...initialState,
      };
    },
  },
  initialState,
);
