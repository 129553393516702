import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';

import {
  getUserInfo,
  joinEmail as joinEmailApi,
  loginSmsEmail as loginEmailApi,
  joinSns as joinSnsApi,
  loginSns as loginSnsApi,
  logout as logoutApi,
} from '../../api/base/user';

import { config } from '../../common/api';
import { isApp, getOsType, getAppDatabyStorage } from '../../common/webView';
import {
  getToken,
  getUserInfo as getUserInfoByToken,
  saveToken,
  removeToken,
} from '../../common/auth';

export const Actions = {
  CLEAR_INFO: 'USER/CLEAR_INFO',
  SET_ERROR: 'USER/SET_ERROR',
  CLEAR_ERROR: 'USER/CLEAR_ERROR',
  GET_INFO: 'USER/GET_INFO',
  SET_INFO: 'USER/SET_INFO',
  SET_NICK_NAME: 'USER/SET_NICK_NAME',
  SET_PROFILE_IMG_URL: 'USER/SET_PROFILE_IMG_URL',

  JOIN_EMAIL: 'USER/JOIN_EMAIL',
  LOGIN_EMAIL: 'USER/LOGIN_EMAIL',

  JOIN_SNS: 'USER/JOIN_SNS',
  LOGIN_SNS: 'USER/LOGIN_SNS',

  JOIN_PARTNERCENTER: 'USER/JOIN_PARTNERCENTER',

  JOIN_LOGIN_JOONGNA: 'USER/JOIN_LOGIN_JOONGNA',

  RE_MOBILIANS: 'USER/RE_MOBILIANS',

  LOGOUT: 'USER/LOGOUT',
};

const initialState = () => ({
  // 접속정보 ( 중나 웹뷰 or 중나 웹뷰 아님 )
  isApp: isApp(),
  osType: getOsType(),
  appVersion: null,
  appOsVersion: null,
  isLocalhost: config.DEFAULT_URL === 'http://localhost:3000',

  // 토큰
  token: getToken(),
  tokenInfo: {},

  // 현재 어떤 경로로 로그인을 하였는지
  loginType: -1, // 0: 이메일, 1: 네이버, 2: 카카오, 3: 페이스북, 5: 페이코, 6: 자동 로그인

  // 유저 정보
  cafeUseYn: 0,
  marketing: {
    agree: false,
    emailYn: 0,
    pushYn: 0,
    smsYn: 0,
  },
  nickName: '',
  phoneNoShowYn: 0,
  profileImgUrl: '',
  safetyNoUseYn: 0,
  // 각 연동 이력
  status: {
    emailJoin: 0,
    emailStatus: 0,
    facebookJoin: 0,
    facebookStatus: 0,
    kakaoJoin: 0,
    kakaoStatus: 0,
    naverCafeStatus: 0,
    naverJoin: 0,
    naverStatus: 0,
    paycoJoin: 0,
    paycoStatus: 0,
    userId: '',
    userJoinType: 0,
    userStatus: 0,
  },

  gaUserInfo: {},
  userId: '',
  userPhoneNo: '',

  // error 통합. -> 아니면 분리?
  error: {
    code: 200,
    message: '',
  },
});

function switchApi(type, way) {
  if (type === 'join') {
    if (way === 0) return joinEmailApi;
    return joinSnsApi;
  }
  if (way === 0) return loginEmailApi;
  return loginSnsApi;
}

/**
 *
 * @param {*} type // join or login
 * @param {*} way  // 0: 이메일, 1: 네이버, 2: 카카오, 3: 페이스북, 5: 페이코
 * @param {*} data // login data
 */
async function joinAndLogin(type, way, param) {
  try {
    const {
      data: { data },
    } = await switchApi(type, way)(param);

    const {
      data: { data: userInfo },
    } = await getUserInfo(data);

    let tokenInfo = {};
    if (userInfo.status.userStatus === 0 || userInfo.status.userStatus === 3) {
      saveToken(data);
      tokenInfo = getUserInfoByToken();
    }

    return {
      token: data,
      tokenInfo,
      way,
      ...userInfo,
    };
  } catch (e) {
    return Promise.reject(e);
  }
}

export const clearInfo = createAction(
  Actions.CLEAR_INFO,
  (isRemoveToken = true) => ({ isRemoveToken }),
);
export const setError = createAction(Actions.SET_ERROR);
export const clearError = createAction(Actions.CLEAR_ERROR);

export const getInfo = createAction(Actions.GET_INFO, getUserInfo);
export const setInfo = createAction(Actions.SET_INFO);
export const setProfileImgUrl = createAction(Actions.SET_PROFILE_IMG_URL);
export const setNickName = createAction(Actions.SET_NICK_NAME);

export const joinAndLoginJoongna = createAction(
  Actions.JOIN_LOGIN_JOONGNA,
  (type, way, param) => joinAndLogin(type, way, param),
);

export const logout = createAction(Actions.LOGOUT, logoutApi);

export default handleActions(
  {
    [Actions.CLEAR_INFO]: (state, { payload }) => {
      const { isRemoveToken } = payload;
      if (isRemoveToken) removeToken();
      return { ...initialState() };
    },
    [Actions.SET_ERROR]: (state, { payload }) => {
      return { ...state, error: { ...payload } };
    },
    [Actions.CLEAR_ERROR]: state => {
      return { ...state, error: initialState().error };
    },

    // 정보 받아오기
    ...pender({
      type: Actions.GET_INFO,
      onSuccess: (state, action) => {
        const {
          data: { data },
        } = action.payload;

        const token = getToken();
        const tokenInfo = getUserInfoByToken();
        const userAgentData = getAppDatabyStorage() || {};

        return {
          ...state,
          ...data,
          loginType: 6,
          appVersion: userAgentData['App-Version'] || null,
          appOsVersion: userAgentData['Os-Version'] || null,
          token,
          tokenInfo,
          error: initialState().error,
        };
      },

      onFailure: (state, action) => {
        const { data } = action.payload.response;
        removeToken();
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),

    [Actions.SET_INFO]: (state, { payload }) => {
      return { ...state, ...payload };
    },
    [Actions.SET_NICK_NAME]: (state, { payload }) => {
      return { ...state, nickName: payload };
    },
    [Actions.SET_PROFILE_IMG_URL]: (state, { payload }) => {
      return { ...state, profileImgUrl: payload };
    },

    ...pender({
      type: Actions.JOIN_LOGIN_JOONGNA,
      onSuccess: (state, action) => {
        const {
          token,
          tokenInfo,
          way,
          status,
          marketing,
          cafeUseYn,
          safetyNoUseYn,
          phoneNoShowYn,
          userId,
          nickName,
          profileImgUrl,
          userPhoneNo,
          gaUserInfo,
        } = action.payload;

        return {
          ...state,
          token,
          tokenInfo,
          loginType: way,
          status,
          marketing,
          cafeUseYn,
          safetyNoUseYn,
          phoneNoShowYn,
          userId,
          nickName,
          profileImgUrl,
          userPhoneNo,
          gaUserInfo,
          error: initialState().error,
        };
      },

      onFailure: (state, action) => {
        const { data } = action.payload.response;
        const newToken =
          data.meta.code === 400115 || data.meta.code === 400116
            ? data.data
            : '';

        /**
         * - 각 Error 상황에 맞게 추가 -
         * Error Code 목록
         * 400111 - 탈퇴한 회원
         * 400112 - 영구 정지된 회원
         * 400114 - 관리자에 의해 삭제된 계정
         * 400115 - 전화번호 만료 회원
         * 400117 - 같은 경로로 가입된 이력 있음
         * 400118 - 다른 경로로 가입된 회원, 연동여부 확인
         * 400900 - 모빌리언스 값 위조
         */

        return {
          ...state,
          token: newToken,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),

    ...pender({
      type: Actions.LOGOUT,
      onSuccess: () => {
        removeToken();
        return {
          ...initialState(),
        };
      },

      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: {
            code: data.meta.code,
            message: data.meta.message,
          },
        };
      },
    }),
  },
  initialState(),
);
