import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import produce from 'immer';
import { isEmptyObject } from '../../common/util';

import { getProductAddComplete } from '../../api/edge/api';

export const Actions = {
  SET: 'product/SET',
  CLEAR: 'product/CLEAR',
  BACKUP: 'product/BACKUP',
  CALLBACKUP: 'product/CALLBACKUP',

  GET_COMPLETE_DATA: 'product/GET_COMPLETE_DATA',
  SET_COMPLETE_DATA: 'product/GET_COMPLETE_DATA',
  CLEAR_COMPLETE_DATA: 'product/CLEAR_COMPLETE_DATA',
};

const initialState = {
  backup: {}, // 작성 진행여부 체크
  editing: false, // 초기값 셋팅 외, 변경 처리 유무

  guideForm: {
    // 가이드 문구 노출 위치 정의
    productName: 0,
    productCategory: 1,
    sellPrice: 2,
    explanationBtn: 3,
  },

  // 상품명
  productName: '',

  // 카테고리
  categorySeq: 0,

  // 판매가격
  sellPrice: '',
  parcelFree: false, // 판매가격 - 배송비포함
  priceFree: false, // 판매가격 - 무료나눔

  // 사진
  attachPhoto: [],

  // 상품수량
  quantity: 1,

  // 상품설명
  productDescription: '',

  // 거래희망지역
  dealAreaList: [], // 거래희망지역 목록

  // 상품등록 transactionNote
  transactionNote: null,

  // 상품등록 tooltips
  productInputGuide: null,

  // 검색키워드
  keywordList: [], // 검색어 목록

  // 상품상태
  productCondition: 2, // 0 : 미개봉, 1 : 거의새것, 2 : 중고

  // 추가옵션
  flawed: false, // 0 : 하자없음, 1 : 하자있음
  fullPackage: false, // 0 : 모든구성품 아님, 1 : 모든구성품 있음
  limitedEdition: false, // 0 : 한정판 아님, 1 : 한정판 맞음

  complete: null,
};

export const set = createAction(Actions.SET, obj => ({ obj }));
export const clear = createAction(Actions.CLEAR);
export const backup = createAction(Actions.BACKUP);
export const callBackup = createAction(Actions.CALLBACKUP);

export const getCompleteData = createAction(
  Actions.GET_COMPLETE_DATA,
  getProductAddComplete,
);

export const setCompleteData = createAction(
  Actions.SET_COMPLETE_DATA,
  (name, value) => ({ name, value }),
);

export const clearCompleteData = createAction(Actions.CLEAR_COMPLETE_DATA);

export default handleActions(
  {
    [Actions.SET]: (state, action) => {
      const { obj } = action.payload;
      if (obj.editing === false) {
        return { ...state, ...obj };
      }
      return { ...state, ...obj, editing: true };
    },
    [Actions.CLEAR]: () => {
      return { ...initialState };
    },
    [Actions.BACKUP]: state => {
      const isSameCheck = key => {
        if (
          (key === 'userAccountSeq' && state.directAccount === false) ||
          (key === 'accountDetail' && state.directAccount === false)
        ) {
          return true;
        }
        if (
          typeof state[key] === 'object' &&
          state[key] !== null &&
          initialState[key] !== null &&
          isEmptyObject(state[key]) === true &&
          isEmptyObject(initialState[key]) === true
        ) {
          return true;
        }
        if (
          Array.isArray(state[key]) &&
          state[key].length === 0 &&
          initialState[key].length === 0
        ) {
          return true;
        }

        return state[key] === initialState[key];
      };

      const isSame = Object.keys(state).every(isSameCheck);
      const backupVO = isSame ? {} : { ...state };

      return {
        ...initialState,
        productInputGuide: state.productInputGuide,
        backup: backupVO,
      };
    },
    [Actions.CALLBACKUP]: state => {
      return isEmptyObject(state.backup)
        ? { ...initialState }
        : { ...state.backup, backup: { ...state.backup } };
    },

    ...pender({
      type: Actions.GET_COMPLETE_DATA,
      onSuccess: (state, action) => {
        const {
          data: { data },
        } = action.payload;

        return produce(state, d => {
          const draft = d;
          draft.complete = data || initialState.complete;
        });
      },

      onFailure: (state, action) => {
        // const { data } = action.payload.response;
        return {
          ...state,
          // error: {
          //   code: data.meta.code,
          //   message: data.meta.message,
          // },
        };
      },
    }),

    [Actions.SET_COMPLETE_DATA]: (state, action) => {
      const { name, value } = action.payload;
      return produce(state, d => {
        const draft = d;
        draft.complete[name] = value;
      });
    },

    [Actions.CLEAR_COMPLETE_DATA]: state => {
      return produce(state, d => {
        const draft = d;
        draft.complete = initialState.complete;
      });
    },
  },
  initialState,
);
