import { lazy } from 'react';

const preLoadLazy = component => {
  const Component = lazy(component);
  Component.preload = component;
  return Component;
};

// ------------------------------------------------------------------- //
// -- 공통 ------------------------------------------------------------ //
// ------------------------------------------------------------------- //
// 점검페이지
const Maintenance = preLoadLazy(() => import('./maintenance/Maintenance'));

// 웹뷰 캐시용 페이지
const CachePage = preLoadLazy(() => import('./cache/CachePage'));

// 앱 유도 페이지
const AppDirectionPage = preLoadLazy(() => import('./app/AppDirectionPage'));

// ------------------------------------------------------------------- //
// -- home ----------------------------------------------------------- //
// ------------------------------------------------------------------- //
// 메인, 홈
const Home = preLoadLazy(() => import('./home/HomeContainer'));
const OAuth = preLoadLazy(() => import('./login/OAuthContainer'));

const Category = preLoadLazy(() => import('./category/CategoryContainer'));

// 판매내역 전체보기
const AllproductList = preLoadLazy(() =>
  import('./allSales/AllSalesContainer'),
);

// ------------------------------------------------------------------- //
// -- 검색 ------------------------------------------------------------ //
// ------------------------------------------------------------------- //
// 검색 리스트
const SearchList = preLoadLazy(() => import('./search/SearchContainer'));
// 검색 카테고리 리스트
const SearchCategoryContainer = preLoadLazy(() =>
  import('./search/SearchCategoryContainer'),
);

// ------------------------------------------------------------------- //
// -- 상품 ------------------------------------------------------------ //
// ------------------------------------------------------------------- //
// 상품 상세
const ProductDetail = preLoadLazy(() =>
  import('./product/ProductDetailContainer'),
);

// ------------------------------------------------------------------- //
// -- 내 정보 ---------------------------------------------------------- //
// ------------------------------------------------------------------- //
// 계좌 관리
const SettlementList = preLoadLazy(() =>
  import('./mypage/SettlementListContainer'),
);
const SettlementDetail = preLoadLazy(() =>
  import('./mypage/SettlementDetailContainer'),
);
// 계좌 관리
const AccountManage = preLoadLazy(() =>
  import('./account/AccountManageContainer'),
);
// 계좌 등록
const AccountRegist = preLoadLazy(() =>
  import('./account/AccountRegistContainer'),
);

// 배송지 관리
const AddressManage = preLoadLazy(() =>
  import('./address/AddressManageContainer'),
);
// 배송지 등록
const AddressRegist = preLoadLazy(() =>
  import('./address/AddressRegistContainer'),
);
// 배송지 수정
const AddressEdit = preLoadLazy(() => import('./address/AddressEditContainer'));
// 배송지 주소검색
const AddressSearch = preLoadLazy(() =>
  import('./address/AddressSearchContainer'),
);
// 안전결제(유니크로) 배송등록
const UnicroDeliveryRegist = preLoadLazy(() =>
  import('./mypage/unicroDelivery/UnicroDeliveryRegistContainer'),
);
// 안전결제(유니크로) 배송수정
const UnicroDeliveryEdit = preLoadLazy(() =>
  import('./mypage/unicroDelivery/UnicroDeliveryEditContainer'),
);
// 택배 관리 리스트
const CvsDeliveryList = preLoadLazy(() =>
  import('./mypage/cvs/CvsDeliveryListContainer'),
);
// 택배 관리 상세
const CvsDeliveryDetail = preLoadLazy(() =>
  import('./mypage/cvs/CvsDeliveryDetailContainer'),
);
// 택배 등록
const CvsDeliveryRegist = preLoadLazy(() =>
  import('./mypage/cvs/CvsDeliveryRegistContainer'),
);
// 택배 등록 완료
const CvsDeliveryComplete = preLoadLazy(() =>
  import('./mypage/cvs/CvsDeliveryCompleteContainer'),
);
// 편의점 택배 접수처 찾기
const CvsFind = preLoadLazy(() => import('./mypage/cvs/CvsFindContainer'));
// 배송조회
const DeliveryHistory = preLoadLazy(() =>
  import('./mypage/DeliveryHistoryContainer'),
);
// 후기 작성
const OrderReview = preLoadLazy(() => import('./mypage/OrderReviewContainer'));

// ------------------------------------------------------------------- //
// -- 거래내역 --------------------------------------------------------- //
// ------------------------------------------------------------------- //
// 거래내역
const OrderHistory = preLoadLazy(() =>
  import('./order/history/OrderHistoryContainer'),
);
// 주문/결제 상세 리다이렉트
const OrderDetail = preLoadLazy(() => import('./payment/OrderDetailContainer'));
// 유니크로 판매 FAQ
const OrderSaleFaq = preLoadLazy(() =>
  import('./mypage/sale/OrderSaleFaqContainer'),
);
// 유니크로 구매 FAQ
const OrderBuyFaq = preLoadLazy(() =>
  import('./mypage/buy/OrderBuyFaqContainer'),
);
// 반품수락 ( 배송지 정보 )
const OrderSaleReturn = preLoadLazy(() =>
  import('./mypage/sale/OrderSaleReturnContainer'),
);

// ------------------------------------------------------------------- //
// -- 내 가게 ---------------------------------------------------------- //
// ------------------------------------------------------------------- //
// 내 가게
const Store = preLoadLazy(() => import('./store/StoreContainer'));

// 스토어 후기
const StoreReviewList = preLoadLazy(() =>
  import('./store/DealReviewListContainer'),
);

// 내 가게 공지사항 전체보기
const StoreNotice = preLoadLazy(() => import('./store/StoreNoticeContainer'));

// ------------------------------------------------------------------- //
// -- 결제 ------------------------------------------------------------ //
// ------------------------------------------------------------------- //
// 중고나라 결제방법
const PaymentGuid = preLoadLazy(() =>
  import('./payment/guide/PaymentGuideContainer'),
);

// 주문/결제
const Order = preLoadLazy(() => import('./payment/OrderContainer'));
// 주문/결제 완료
const OrderResult = preLoadLazy(() => import('./payment/OrderResultContainer'));

const SafepayGuide = preLoadLazy(() =>
  import('./payment/SafepayGuideContainer'),
);

// ------------------------------------------------------------------- //
// -- 오토 ------------------------------------------------------------ //
// ------------------------------------------------------------------- //
// 오토 내차팔기 (PC+웹뷰)
const AutoSales = preLoadLazy(() => import('./auto/AutoSalesContainer'));
// 오토 비용계산기 (웹뷰)
const AutoCostCalc = preLoadLazy(() => import('./auto/AutoCostCalcContainer'));
// 오토 내 차로 담보대출 (웹뷰)
const AutoLoan = preLoadLazy(() => import('./auto/AutoLoanContainer'));
// 오토 할부계산 (웹뷰)
const InstalmentLimit = preLoadLazy(() =>
  import('./auto/InstalmentLimitContainer'),
);
// 오토 차고지
// 오토 시세조회 (웹뷰)
const AutoGarageDetail = preLoadLazy(() =>
  import('./auto/AutoGarageDetailContainer'),
);

// 오토 정비소 상세
const RepairShop = preLoadLazy(() => import('./auto/RepairShop/RepairShop'));
// 오토 이용약관
const AutoThirdPartiesTerms = preLoadLazy(() =>
  import('./terms/AutoThirdPartiesContainer'),
);
// 오토 예약내역 확인
const AutoReservation = preLoadLazy(() =>
  import('./auto/AutoReservationContainer'),
);

// ------------------------------------------------------------------- //
// -- 이벤트 ----------------------------------------------------------- //
// ------------------------------------------------------------------- //
// 이벤트 리스트
const EventList = preLoadLazy(() => import('./event/EventListContainer'));
// 이벤트 상세
const EventDetail = preLoadLazy(() => import('./event/EventDetail'));
// 이벤트 출석체크 포인트
const EventStampPoint = preLoadLazy(() =>
  import('./event/EventStampContainer'),
);

// ------------------------------------------------------------------- //
// -- 고객센터 --------------------------------------------------------- //
// ------------------------------------------------------------------- //
// 고객센터
const Faq = preLoadLazy(() => import('./customerService/FaqConatiner'));
const CsQna = preLoadLazy(() =>
  import('../components/common/Loading/LogoLoading'),
);

// 공지사항
const Notice = preLoadLazy(() => import('./customerService/Notice'));
// 중고나라 이용안내
const Useinfo = preLoadLazy(() => import('./customerService/UseinfoContainer'));
// 중고나라 이용안내 상세
const UseinfoDetail = preLoadLazy(() =>
  import('./customerService/UseinfoDetailContainer'),
);

// ------------------------------------------------------------------- //
// -- 기타 ------------------------------------------------------------ //
// ------------------------------------------------------------------- //
// 시세조회
const AvgPrice = preLoadLazy(() => import('./search/price/SearchPriceRoute'));

// 사기조회
const Fraud = preLoadLazy(() => import('./fraud/FraudContainer'));

// 회원등급 안내
const Grade = preLoadLazy(() => import('./info/GradeInfoContainer'));

// 모바일 가맹점
const Mobile = preLoadLazy(() => import('./mobile/Mobile'));

// 지도로 동네 추가
const LocationMap = preLoadLazy(() =>
  import('./location/LocationMapContainer'),
);
// 주소로 동네 추가
const LocationAddr = preLoadLazy(() =>
  import('./location/LocationAddrContainer'),
);
// 거래 가능 지역 지도
const LocationProductAddr = preLoadLazy(() =>
  import('./location/LocationProductAddrContainer'),
);
// 지역별 상품 리스트 ( ! 사용여부 확인 필요 )
const LocationProductMap = preLoadLazy(() =>
  import('./location/LocationProductMapContainer'),
);
// 위치 전송
const LocationShare = preLoadLazy(() =>
  import('./location/LocationShareContainer'),
);

// ------------------------------------------------------------------- //
// -- 기타 외부 서비스 --------------------------------------------------- //
// ------------------------------------------------------------------- //
// 모빌리언스 인증
const MobileAuth = preLoadLazy(() => import('./login/MobileAuth'));

// 외부 연동 통합 관리 (예정)
const ExternalConnection = preLoadLazy(() =>
  import('./external/ExternalConnection'),
);
// 카페상품 보기 ( ! 사용여부 확인 필요 )
const NaverCafeProduct = preLoadLazy(() =>
  import('./naverCafe/NaverCafeProductContainer'),
);

// ------------------------------------------------------------------- //
// -- 이용약관 --------------------------------------------------------- //
// ------------------------------------------------------------------- //
// 회원 분쟁/불만 처리절차
const MemberDispute = preLoadLazy(() =>
  import('./memberDispute/memberDisputeContainer'),
);
const AdTerm = preLoadLazy(() => import('./terms/AdTerm'));
const Privacy = preLoadLazy(() => import('./terms/PrivacyTermsContainer'));
const CompareTerms = preLoadLazy(() => import('./terms/CompareTermsContainer'));
const Terms = preLoadLazy(() => import('./terms/TermsContainer'));
const OrderTerms = preLoadLazy(() => import('./terms/OrderTermsContainer'));

export default [
  {
    name: '점검',
    isPrivate: false,
    exact: true,
    path: '/maintenance',
    component: Maintenance,
    isAccess: true,
  },
  {
    name: '외부 연동 위함',
    isPrivate: false,
    disabled: true,
    exact: true,
    path: '/externalconnect',
    component: ExternalConnection,
    isAccess: false,
  },
  {
    name: '모빌리언스 인증',
    isPrivate: false,
    exact: true,
    path: '/mobile-auth',
    component: MobileAuth,
    isAccess: false,
  },
  {
    name: '회원 분쟁/불만 처리절차',
    isPrivate: false,
    exact: true,
    path: '/member/dispute',
    component: MemberDispute,
    isAccess: true,
  },
  {
    name: '시세조회',
    isPrivate: false,
    exact: true,
    path: '/avg-price/:keyword?',
    component: AvgPrice,
    isAccess: false,
  },
  {
    name: '시세조회 신규',
    isPrivate: false,
    exact: true,
    path: '/search/price',
    component: AvgPrice,
    isAccess: true,
  },
  {
    name: '사기조회',
    isPrivate: false,
    exact: true,
    path: '/fraud',
    component: Fraud,
    isAccess: true,
  },
  {
    name: '상품상세',
    isPrivate: false,
    exact: true,
    path: '/product-detail/:productSeq/:type?',
    component: ProductDetail,
    isAccess: true,
  },
  {
    name: '카페상품 보기',
    isPrivate: false,
    exact: true,
    path: '/naver/product',
    component: NaverCafeProduct,
    isAccess: true,
  },
  {
    name: '배송지 등록',
    isPrivate: true,
    exact: true,
    path: '/address-regist',
    component: AddressRegist,
    isAccess: false,
  },
  {
    name: '배송지 상세보기',
    isPrivate: true,
    exact: true,
    path: '/address-edit/:deliverySeq',
    component: AddressEdit,
    isAccess: false,
  },
  {
    name: '배송지 관리',
    isPrivate: true,
    exact: true,
    path: '/address-manage',
    component: AddressManage,
    isAccess: false,
  },
  {
    name: '주소 검색',
    isPrivate: false,
    exact: true,
    path: '/address-search/:prevPage/:seq?',
    component: AddressSearch,
    isAccess: false,
  },
  {
    name: '지도로 동네추가',
    isPrivate: true,
    exact: true,
    path: '/location-map',
    component: LocationMap,
    isAccess: false,
  },
  {
    name: '주소로 동네추가',
    isPrivate: true,
    exact: true,
    path: '/location-addr',
    component: LocationAddr,
    isAccess: false,
  },
  {
    name: '지역별 상품 지도보기',
    isPrivate: false,
    exact: true,
    path: '/location-product-map',
    component: LocationProductMap,
    isAccess: false,
  },
  {
    name: '거래희망지역 지도보기',
    isPrivate: false,
    exact: true,
    path: '/location-product-addr',
    component: LocationProductAddr,
    isAccess: true,
  },
  {
    name: '위치 전송',
    isPrivate: false,
    exact: true,
    path: '/location-share/:lat?/:lon?/:locationType?',
    component: LocationShare,
    isAccess: false,
  },
  {
    name: '검색 리스트',
    isPrivate: false,
    exact: false,
    path: '/search-list/:searchTab',
    component: SearchList,
    isAccess: true,
  },
  {
    name: '검색 카테고리 리스트',
    isPrivate: false,
    exact: true,
    path: '/search-category',
    component: SearchCategoryContainer,
    isAccess: true,
  },
  {
    name: '주문/결제',
    isPrivate: true,
    exact: true,
    path: '/order/:productSeq',
    component: Order,
    isAccess: false,
  },
  {
    name: '주문/결제 완료',
    isPrivate: true,
    exact: true,
    path: '/order-result',
    component: OrderResult,
    isAccess: false,
  },
  {
    name: '중고나라 결제방법',
    isPrivate: false,
    exact: true,
    path: '/payment-guide/:type?',
    component: PaymentGuid,
    isAccess: true,
  },
  {
    name: '편의점택배 관리',
    isPrivate: true,
    exact: true,
    path: '/cvs/:type?/:seq?',
    component: CvsDeliveryList,
    isAccess: false,
  },
  {
    name: '편의점택배 신청',
    isPrivate: true,
    exact: true,
    path: '/cvs-regist/:type/:seq/:orderStatus?',
    component: CvsDeliveryRegist,
    isAccess: false,
  },
  {
    name: '편의점택배 상세',
    isPrivate: true,
    exact: true,
    path: '/cvs-detail/:type/:deliverySeq/:pageType?',
    component: CvsDeliveryDetail,
    isAccess: false,
  },
  {
    name: '편의점택배 신청완료',
    isPrivate: true,
    exact: true,
    path: '/cvs-complete/:deliveryCorpName',
    component: CvsDeliveryComplete,
    isAccess: false,
  },
  {
    name: '편의점택배 접수처 찾기',
    isPrivate: true,
    exact: true,
    path: '/cvs-find/:deliveryCorpName?',
    component: CvsFind,
    isAccess: false,
  },
  {
    name: '이벤트 상세보기',
    isPrivate: false,
    exact: true,
    path: '/event-detail/:eventSeq',
    component: EventDetail,
    isAccess: true,
  },
  {
    name: '이벤트 목록',
    isPrivate: false,
    exact: false,
    path: '/event-list',
    component: EventList,
    isAccess: true,
  },
  {
    name: '출석체크 이벤트',
    isPrivate: false,
    exact: true,
    path: '/point-event/2/:storeSeq?',
    component: EventStampPoint,
    isAccess: true,
  },
  {
    name: '고객센터',
    isPrivate: false,
    exact: true,
    path: '/cs/main/:type?',
    component: Faq,
    isAccess: true,
  },
  {
    name: '1:1문의',
    isPrivate: true,
    exact: true,
    path: '/cs/qna',
    component: CsQna,
    isAccess: false,
  },
  {
    name: '중고나라 이용안내',
    isPrivate: false,
    exact: true,
    path: '/cs/info',
    component: Useinfo,
    isAccess: true,
  },
  {
    name: '중고나라 이용안내 상세보기',
    isPrivate: false,
    exact: true,
    path: '/cs/info-detail/:seq?',
    component: UseinfoDetail,
    isAccess: true,
  },
  {
    name: '공지사항 상세보기',
    isPrivate: false,
    exact: false,
    path: '/cs/notice',
    component: Notice,
    isAccess: true,
  },
  {
    name: '모바일가맹점',
    isPrivate: false,
    exact: false,
    path: '/mobile',
    component: Mobile,
    isAccess: true,
  },
  {
    name: '이용약관',
    isPrivate: false,
    exact: false,
    path: '/terms',
    component: Terms,
    isAccess: true,
  },
  {
    name: '이용약관 개정 대비표',
    isPrivate: false,
    exact: false,
    path: '/compare-terms',
    component: CompareTerms,
    isAccess: true,
  },
  {
    name: '오토 이용약관',
    isPrivate: false,
    exact: false,
    path: '/auto-thirdparties-terms',
    component: AutoThirdPartiesTerms,
    isAccess: true,
  },
  {
    name: '개인정보처리방침',
    isPrivate: false,
    exact: true,
    path: '/privacy-policy',
    component: Privacy,
    isAccess: true,
  },
  {
    name: '광고주센터 이용약관',
    isPrivate: false,
    exact: true,
    path: '/adterm',
    component: AdTerm,
    isAccess: true,
  },
  {
    name: '결제 관련 이용약관',
    isPrivate: false,
    exact: false,
    path: '/order-terms',
    component: OrderTerms,
    isAccess: true,
  },
  {
    name: '가게 공지사항',
    isPrivate: false,
    exact: true,
    path: '/store-notice/:storeSeq',
    component: StoreNotice,
    isAccess: true,
  },
  {
    name: '가게 후기',
    isPrivate: false,
    exact: true,
    path: '/store-reviews/:storeSeq?',
    component: StoreReviewList,
    isAccess: true,
  },
  {
    name: '가게',
    isPrivate: false,
    exact: true,
    path: '/store/:storeSeq',
    component: Store,
    isAccess: true,
  },
  {
    name: '중고나라 회원등급',
    isPrivate: false,
    exact: true,
    path: '/rating/:storeSeq?',
    component: Grade,
    isAccess: true,
  },
  {
    name: '거래내역',
    isPrivate: true,
    exact: false,
    path: '/order-history',
    component: OrderHistory,
    isAccess: false,
  },
  {
    name: '구매내역 상세',
    isPrivate: true,
    exact: true,
    path: '/order-buy-detail/:orderNo/:type?',
    component: OrderDetail,
    isAccess: false,
  },
  {
    name: '판매내역 상세',
    isPrivate: true,
    exact: true,
    path: '/order-sale-detail/:orderNo/:type?',
    component: OrderDetail,
    isAccess: false,
  },
  {
    name: '구매내역 FAQ',
    isPrivate: true,
    exact: true,
    path: '/order-buy-faq',
    component: OrderBuyFaq,
    isAccess: false,
  },
  {
    name: '판매내역 FAQ',
    isPrivate: true,
    exact: true,
    path: '/order-sale-faq',
    component: OrderSaleFaq,
    isAccess: false,
  },
  {
    name: '반품수락 ( 배송지 정보 )',
    isPrivate: true,
    exact: true,
    path: '/order-sale-return/:orderNo/:type?',
    component: OrderSaleReturn,
    isAccess: false,
  },
  {
    name: '안전결제 배송등록',
    isPrivate: true,
    exact: true,
    path: '/delivery-regist/:type/:orderNo',
    component: UnicroDeliveryRegist,
    isAccess: false,
  },
  {
    name: '안전결제 배송수정',
    isPrivate: true,
    exact: true,
    path: '/delivery-edit/:type/:orderNo',
    component: UnicroDeliveryEdit,
    isAccess: false,
  },
  {
    name: '안전결제 배송조회',
    isPrivate: true,
    exact: true,
    path: '/delivery-history/:type/:seq/:deliveryCorpCode?/:invoiceNo?',
    component: DeliveryHistory,
    isAccess: false,
  },
  {
    name: '후기 작성하기',
    isPrivate: true,
    exact: true,
    path: '/review/:orderNo',
    component: OrderReview,
    isAccess: false,
  },
  {
    name: '정산관리',
    isPrivate: true,
    exact: true,
    path: '/mysettlement',
    component: SettlementList,
    isAccess: false,
  },
  {
    name: '정산 내역 상세',
    isPrivate: true,
    exact: true,
    path: '/mysettlement-detail/:orderNo',
    component: SettlementDetail,
    isAccess: false,
  },
  {
    name: '계좌 관리',
    isPrivate: true,
    exact: true,
    path: '/account-manage',
    component: AccountManage,
    isAccess: true,
  },
  {
    name: '계좌 신규등록',
    isPrivate: true,
    exact: true,
    path: '/account-regist',
    component: AccountRegist,
    isAccess: true,
  },
  {
    name: '판매상품 전체보기',
    isPrivate: false,
    exact: true,
    path: '/allproduct-list/:storeSeq?',
    component: AllproductList,
    isAccess: true,
  },
  {
    name: '오토 비용계산기',
    isPrivate: false,
    exact: true,
    path: '/auto/cost-calc/:productSeq',
    component: AutoCostCalc,
    isAccess: false,
  },
  {
    name: '오토 진단차량 예약확인',
    isPrivate: false,
    exact: true,
    path: '/auto/reservation/:seq',
    component: AutoReservation,
    isAccess: false,
  },
  {
    name: '오토 내차시세조회',
    isPrivate: false,
    exact: true,
    path: '/auto/garage-detail/:productSeq',
    component: AutoGarageDetail,
    isAccess: false,
  },
  {
    name: '오토 내차팔기',
    isPrivate: false,
    exact: false,
    path: '/auto/sales',
    component: AutoSales,
    isAccess: true,
  },
  {
    name: '오토 담보 대출',
    isPrivate: true,
    exact: true,
    path: '/auto/autoloan/:productSeq', // 신규 url
    component: AutoLoan,
    isAccess: false,
  },
  {
    name: '오토 담보 대출',
    isPrivate: true,
    exact: true,
    path: '/web/autoLoan/inquiry/collateralLoan/:productSeq', // 구 url
    component: AutoLoan,
    isAccess: false,
  },
  {
    name: '오토 할부 한도조회',
    isPrivate: true,
    exact: false,
    path: '/auto/instalment-check/:productSeq', // 신규 url
    component: InstalmentLimit,
    isAccess: false,
  },
  {
    name: '오토 정비소 상세페이지',
    isPrivate: false,
    exact: true,
    path: '/auto/repairShop/:shopSeq',
    component: RepairShop,
    isAccess: false,
  },
  {
    name: '카테고리',
    isPrivate: false,
    exact: true,
    path: '/category',
    component: Category,
    isAccess: true,
  },
  {
    name: '웹뷰 캐싱용 페이지',
    isPrivate: false,
    exact: true,
    path: '/webview-cache',
    component: CachePage,
    isAccess: false,
  },
  {
    name: '앱 유도 페이지',
    isPrivate: false,
    exact: false,
    path: '/app',
    component: AppDirectionPage,
    isAccess: true,
  },
  {
    name: '광고주센터 로그인',
    isPrivate: false,
    exact: false,
    path: '/oauth',
    component: OAuth,
    isAccess: true,
  },
  {
    name: '중고나라페이 안전결제 안내',
    isPrivate: false,
    exact: false,
    path: '/safepay-guide',
    component: SafepayGuide,
    isAccess: true,
  },
  {
    name: '메인 화면',
    isPrivate: false,
    exact: false,
    path: '/home',
    component: Home,
    isAccess: true,
  },
];
