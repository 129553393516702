import { getOsType } from '../common/webView';

const config = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  APP_API_POINT_URL: process.env.REACT_APP_API_POINT_URL,
  TICKETS_API_BASE_URL: process.env.REACT_APP_TICKETS_API_BASE_URL,
  AUTO_API_BASE_URL: process.env.REACT_APP_AUTO_API_BASE_URL,
  SEARCH_API_BASE_URL: process.env.REACT_APP_SEARCH_API_BASE_URL,
  STANDARD_PRICE_API_BASE_URL: process.env.STANDARD_PRICE_API_BASE_URL,
  EDGE_API_URL: process.env.REACT_APP_EDGE_API_URL,
  DEFAULT_URL: process.env.REACT_APP_DEFAULT_URL,
  SHARE_URL: process.env.REACT_APP_SHARE_URL,
  CDN_URL: 'https://img2.joongna.com',
  KAKAO_API_KEY: process.env.REACT_APP_KAKAO_API,
  JSON_HEADER: {
    headers: {
      'Content-Type': 'application/json',
      'Os-Type': getOsType(),
    },
  },
  FORM_HEADER: {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Os-Type': getOsType(),
    },
  },
  JSON_TOKEN_HEADER: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jn_access_token')}`,
      'Content-Type': 'application/json',
      'Os-Type': getOsType(),
    },
  },
  FORM_TOKEN_HEADER: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jn_access_token')}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'Os-Type': getOsType(),
    },
  },
  MULTIPART_TOKEN_HEADER: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jn_access_token')}`,
      'Content-Type': 'multipart/form-data',
      'Os-Type': getOsType(),
    },
  },
  JSON_TYPE: 'application/json',
  FORM_TYPE: 'application/x-www-form-urlencoded',
  MULTIPART_TYPE: 'multipart/form-data',
};

// header
export function getHeader(ContentType, jn_access_token) {
  jn_access_token =
    jn_access_token || localStorage.getItem('jn_access_token') || '';
  const headers = {
    Authorization: `Bearer ${jn_access_token}`,
    'Content-Type': ContentType,
    'Os-Type': getOsType(),
  };

  return { headers };
}

export function getHeader2(ContentType, jn_access_token) {
  jn_access_token =
    jn_access_token || localStorage.getItem('jn_access_token') || '';

  const headers =
    jn_access_token === undefined
      ? {
          'Content-Type': ContentType,
          'Os-Type': getOsType(),
        }
      : {
          Authorization: `Bearer ${jn_access_token}`,
          'Content-Type': ContentType,
          'Os-Type': getOsType(),
        };
  return { headers };
}

export function getJSONHeader(jn_access_token) {
  const ContentType = config.JSON_TYPE;
  return getHeader(ContentType, jn_access_token);
}

export function getJSONHeader2(jn_access_token) {
  const ContentType = config.JSON_TYPE;
  return getHeader2(ContentType, jn_access_token);
}

export function getMultiPartHeader(jn_access_token) {
  const ContentType = config.MULTIPART_TYPE;
  return getHeader(ContentType, jn_access_token);
}

export default config;
