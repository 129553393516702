import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import classNames from 'classnames';
import produce from 'immer';

import useHistory from '../../../common/Hook/useHistory';
import { postAddDelivery } from '../../../api/base/user';
import {
  ToastReducer,
  DeliveryPopUpReducer,
} from '../../../reducers/actionCreators';

import PopUp from './PopUp';
import Page from '../Page';
import AddressSearch from '../../address/AddressSearchComponent';
import IcClose from '../Svg/IcClose';
import BorderLine from '../BorderLine';

import { onlyNumber } from '../../../common/number';
import { addDay } from '../../../common/time';

import { getCookie } from '../../../common/util';

import styles from './DeliveryPopUp.module.scss';

const DeliveryPopUp = () => {
  const {
    location: { pathname },
  } = useHistory();
  const noShow = getCookie('JNdeliveryPopUpNoShow');
  const show = useSelector(({ deliveryPopUp }) => deliveryPopUp.show);
  const option = useSelector(({ deliveryPopUp }) => deliveryPopUp.option);

  const [showSearchAdd, setShowSearchAdd] = useState(false);
  const [info, setInfo] = useState({
    deliveryAddress: '', // 배송지 주소
    deliveryAddressDetail: '', // 배송지 상세 주소
    deliveryPostNo: '', // 배송지 우편번호
    deliverySeq: 0, // 배송지 seq, 등록시에는 사용하지 않음
    deliveryTitle: '기본 배송지', // 배송지 제목
    deliveryUserName: '', // 배송지 받는 사람
    deliveryUserPhoneNo: '', // 배송지 받는 사람 연락처
    mainAddressYn: 1, // 대표 배송지 여부
  });

  function setCookie(cookieName, value, days) {
    const cookieValue =
      escape(value) +
      (days == null ? '' : `;    expires=${addDay(days).toString()}`);
    window.document.cookie = `${cookieName}=${cookieValue}`;
  }

  function onChangeValue(e) {
    const { name } = e.target;
    let { value } = e.target;
    if (name === 'deliveryUserPhoneNo') {
      if (value.length > 12) {
        ToastReducer.open('최대 12자까지 입력가능합니다.');
        value = info.deliveryUserPhoneNo;
      }
      value = onlyNumber(value);
    }
    if (name === 'deliveryUserName' && value.length > 10) {
      ToastReducer.open('최대 10자까지 입력가능합니다.');
      value = info.deliveryUserName;
    }

    setInfo(
      produce(info, d => {
        const draft = d;
        draft[name] = value;
      }),
    );
  }

  function onToggleSearchPage() {
    setShowSearchAdd(!showSearchAdd);
  }

  async function onSubmit() {
    if (info.deliveryUserName === '') {
      ToastReducer.open('이름을 입력해주세요.');
    } else if (info.deliveryUserPhoneNo === '') {
      ToastReducer.open('연락처를 입력해주세요.');
    } else if (info.deliveryAddress === '') {
      ToastReducer.open('주소를 입력해주세요.');
    } else {
      try {
        await postAddDelivery(info);
        DeliveryPopUpReducer.close(true);
        ToastReducer.open('배송지 등록이 완료 되었습니다');
      } catch (e) {
        console.log(e);
        ToastReducer.open('다시 시도해주세요.');
      }
    }
  }

  function onClickNoShow(e) {
    const expires = e.target.name === 'week' ? 7 : 1;
    setCookie('JNdeliveryPopUpNoShow', true, expires);
    DeliveryPopUpReducer.close();
  }

  useEffect(() => {
    if (pathname !== '/home' || pathname.includes('/product-detail/')) {
      if (show) {
        setShowSearchAdd(false);
        DeliveryPopUpReducer.close();
      }
    }
  }, [pathname]);

  return (
    <>
      <PopUp show={show && !noShow}>
        <button
          type="button"
          className="dis_block ml_auto"
          onClick={() => {
            DeliveryPopUpReducer.close();
          }}
        >
          <IcClose width={26} height={26} fillColor="#fff" />
        </button>
        <div className={classNames(styles.pop)}>
          <div className={classNames(styles.head, 'align_c', 'pd_h20 pd_v25')}>
            <p className="f18 c_orange">
              <strong>딱! 한 번만</strong>
            </p>
            <p className="f18">
              <strong>배송 받을 주소를 등록해 주세요.</strong>
            </p>
            <p className="f13 c_gray mt10">
              배송 받으실 주소를 최초 1회 등록해 두시면,
            </p>
            <p className="f13 c_gray">
              주문하실 때 마다 입력하실 필요가 없어요!
            </p>
          </div>
          <div className={classNames('border_t', styles.inputWrap)}>
            <p className={classNames('f14', styles.label)}>
              <strong>이름</strong>
            </p>
            <input
              name="deliveryUserName"
              placeholder="최대 10자 이내로 입력"
              value={info.deliveryUserName}
              onChange={onChangeValue}
              className={styles.input}
            />
          </div>
          <div className={classNames('border_t', styles.inputWrap)}>
            <p className={classNames('f14', styles.label)}>
              <strong>연락처</strong>
            </p>
            <input
              name="deliveryUserPhoneNo"
              type="tel"
              placeholder="'-'없이 숫자만 입력"
              value={info.deliveryUserPhoneNo}
              onChange={onChangeValue}
              className={styles.input}
            />
          </div>
          <BorderLine />
          <div className={classNames(styles.inputWrap)}>
            <p className={classNames('f14', styles.label)}>
              <strong>주소</strong>
            </p>
            <input
              name="deliveryAddress"
              placeholder="주소 입력"
              readOnly
              value={info.deliveryAddress}
              onClick={onToggleSearchPage}
              className={styles.input}
            />
          </div>
          <div className={classNames('border_t', styles.inputWrap)}>
            <input
              name="deliveryAddressDetail"
              placeholder="상세주소"
              value={info.deliveryAddressDetail}
              onChange={onChangeValue}
              className={styles.input}
            />
          </div>
          <BorderLine />
          <p className="pd_t8 pd_h16 pd_b20 f12 c_gray">
            내 정보 &gt; 배송지 관리에서 수정 가능합니다.
          </p>
          <div className={styles.footer}>
            <button
              className="bg_orange pd_v15 pd_h20"
              type="button"
              onClick={onSubmit}
            >
              <span className="f15 c_white">배송지 등록하기</span>
            </button>
          </div>
        </div>
        {option && (
          <div className={styles.option}>
            <button
              name="week"
              type="button"
              className="f13 c_white"
              onClick={onClickNoShow}
            >
              일주일 동안 안볼래요.
            </button>
            <div />
            <button
              name="day"
              type="button"
              className="f13 c_white"
              onClick={onClickNoShow}
            >
              오늘 하루 안볼래요.
            </button>
          </div>
        )}
      </PopUp>
      <PopUp show={showSearchAdd} contentClassName={styles.addSearch}>
        <Page className={classNames('page', 'bg_white', 'possibleScroll')}>
          <AddressSearch
            className={classNames(styles.addSearchComponent, 'possibleScroll')}
            onSearchDeliveryValue={value => {
              setInfo(
                produce(info, d => {
                  const draft = d;
                  draft.deliveryAddress = value.address;
                  draft.deliveryPostNo = value.zonecode.replace(/-/g, '');
                }),
              );
              onToggleSearchPage();
            }}
            goBack={onToggleSearchPage}
          />
        </Page>
      </PopUp>
    </>
  );
};

export default DeliveryPopUp;
