import { combineReducers } from 'redux';
import { penderReducer } from 'redux-pender';

import maintenance from './maintenance';
import errors from './errors';

import main from './main';
import init from './init';

import category from './category';
import login from './login';
import findinfo from './findinfo';
import search from './search';
import searchCategory from './searchCategory';
import product from './product';
import productV2 from './productV2';
import allSales from './allSales';
import location from './location';
import myLocation from './myLocation';
import myLocationV2 from './myLocationV2';
import address from './address';
import paymentOld from './paymentOld';
import payment from './payment';
import mytag from './mytag';
//
import myWishList from './myWishList';

import account from './account';
import event from './event';
import mypage from './mypage';
import unicro from './unicro';
import delivery from './delivery';
import faq from './faq';

import qna from './qna';
import useinfo from './useinfo';
import user from './user';
import toast from './toast';
import style from './style';
import overlay from './overlay';
import notify from './notify';
import backup from './backup';
import subAction from './subAction';
// 단골 관련
import follow from './follow';
// 가게
import store from './store';
// 우리동네
import town from './town';
// 티켓/쿠폰
import ticketCoupon from './ticketCoupon';
// 마일리지?
import mileage from './mileage';
// 오토관련
import auto from './auto';
import autoSales from './autoSales';
import autoRegist from './autoRegist';
import autoInstalment from './autoInstalment';

// 댓글관련
import comment from './comment';
// 인증관련
import auth from './auth';
// 경고 관련
import alert from './alert';
import inviteFriends from './inviteFriends';
import layout from './layout';
/**
 * store로 유저 정보 관리하기 위해 새롭게 만든 Store
 * 나중에는 흩어져있는 유저 정보 관리 이곳으로 통합
 */
import userLoginInfo from './userLoginInfo';
// 배송지 입력 팝업
import deliveryPopUp from './deliveryPopUp';
import orderHistory from './orderHistory';

// 모바일 가맹점
import mobileFranchise from './mobileFranchise';
import standardPrice from './standardPrice';

// GeoLocation
import geoLocation from './geoLocation';

export default combineReducers({
  maintenance,
  errors,
  main,
  init,
  category,
  login,
  findinfo,
  search,
  searchCategory,
  product,
  productV2,
  allSales,
  location,
  myLocation,
  myLocationV2,
  address,
  paymentOld,
  payment,
  mytag,
  myWishList,
  account,
  event,
  mypage,
  unicro,
  delivery,
  faq,

  qna,
  useinfo,
  user,
  toast,
  style,
  overlay,
  notify,
  backup,
  subAction,
  // 단골
  follow,
  // 가게
  store,
  // 우리동네
  town,
  // 티켓/쿠폰
  ticketCoupon,
  // 마일리지
  mileage,
  // 오토
  auto,
  autoSales,
  autoRegist,
  autoInstalment,
  // 댓글
  comment,
  // auth
  auth,
  // alert
  alert,
  // 친구 초대
  inviteFriends,
  // 레이아웃
  layout,
  userLoginInfo,
  // 배송지 입력 팝업
  deliveryPopUp,
  // 거래내역
  orderHistory,
  // 모바일 가맹점
  mobileFranchise,
  // GeoLocation
  geoLocation,
  // 시세조회
  standardPrice,

  pender: penderReducer,
});
