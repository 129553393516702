import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';

export const Actions = {
  SET_MAINTENANCE: 'maintenance/SET_MAINTENANCE',
  CLEAR_MAINTENANCE: 'maintenance/CLEAR_MAINTENANCE',
};

const initialState = {
  code: 200,
  data: {
    status: 1,
    startDateTime: '2020-08-17 03:00',
    endDateTime: '2020-08-17 05:00',
    ImgUrl: 'https://common.joongna.com/image/maintenance/maintenance.png',
    message: '보다 나은 서비스를 제공하기 위하여 시스템 점검 진행중 입니다.',
  },
};

export const setMaintenance = createAction(Actions.SET_MAINTENANCE);
export const clearMaintenance = createAction(Actions.CLEAR_MAINTENANCE);

export default handleActions(
  {
    [Actions.SET_MAINTENANCE]: (state, { payload }) => {
      const { response } = payload;

      return produce(state, d => {
        const draft = d;
        draft.code = response.meta.code;
        draft.data = response.data;
      });
    },
    [Actions.CLEAR_MAINTENANCE]: () => {
      return { ...initialState };
    },
  },
  initialState,
);
