import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import moment from 'moment';
import * as Common from '../../js/common';
import * as Mypage from '../../actions/service/MypageService';

const initialState = {
  notices: [],
  pageNo: 1,

  // 20181213 해당 검색 조건 저장 요청에 의해 추가 - 검색어, 상태
  search_status: '',
  search_title: '',
  // 판매내역
  order_sale_clickcount: 0,
  order_sale: { totalAmount: 0, list: [], isFinishPage: false },

  order_detail: {},
  order_product: {},
  order_product_error: { message: '', code: 0, moment: moment() },

  // 편의점 택배
  delivery_product: {},
  delivery_receive: [],
  delivery_send: [],
  delivery_detail: {},
  delivery_history: null,
  sendProductList: {
    item: null,
    page: 0,
    isMore: true,
  },
  error: { message: '', code: 0, caller: '', moment: moment() },
  onFailureDeliveryHistory: moment(),
  delivery_history_error: { meta: { message: '' }, data: {} },

  // 마이스토어 마이페이지 인포
  mypage: [],
  mystore_set: '',
  mystore_info: [],
  mystore_category: [],
  mystore_category_set: [],
  onSuccessMyCategory: moment(),
  myStoreVO: [],
  storeProductsList: [],
  seenProductList: [],
  myStoreRank: [],
  myStoreSell: [],
  myStoreBuy: [],
  myStoreReviewVO: [],
  myStoreLocationVOList: [],
  myStoreTagVOList: [],
  review_list: [],
  myStoreReviewList: [],
  storeFollowYn: 0,
  productList: [],
  pushSetting: [],
  onSuccessPushSetting: moment(),
  pushSettingFollow: [],
  onSuccessPushSettingFollow: moment(),
  onSuccessPutPushSettingFollow: moment(),
  onSuccessPutPushSettingFollowAll: moment(),
  mystoreShare: [],
  onSuccessChangeProfile: moment(),
  onSuccessCafeLevelApply: moment(),
  cafe_levelup_failcode: '',
  onFailureCafeLevelup: moment(),

  // 팔로우
  follower: [],
  following: [],
  onSuccessDeleteFollow: moment(),
  onSuccessPostFollow: moment(),

  // 알림설정 키워드 알림 설정
  keyword_userSeq: '',
  keyword_notify_set: [],
  onSuccessDelKeyword: moment(),
  onSuccessRegistKeyword: moment(),
  error_code: '',
  onFailureRegistKeyword: moment(),
  onSuccessModifyKeyword: moment(),

  // 이전상품 판매리스트
  beforeSellList: undefined,
};

export const getNoticeList = createAction(
  Mypage.default.GET_NOTICE,
  Mypage.getNotice,
);
// 판매내역
export const getOrderSale = createAction(
  Mypage.default.GET_ORDER_SALE,
  Mypage.getOrderSale,
);
export const getOrderSaleDetail = createAction(
  Mypage.default.GET_ORDER_SALE_DETAIL,
  Mypage.getOrderSaleDetail,
);
export const getOrderProduct = createAction(
  Mypage.default.GET_ORDER_PRODUCT,
  Mypage.getOrderProduct,
);
export const setOrderSaleDetailInit = createAction(
  Mypage.default.SET_ORDER_SALE_DETAIL_INIT,
  Mypage.setOrderSaleDetailInit,
);
// 판매내역 초기화
export const setOrderSaleReset = createAction(
  Mypage.default.SET_ORDER_SALE_RESET,
  Mypage.setOrderSaleReset,
);
// 편의점택배
export const getDeliveryProduct = createAction(
  Mypage.default.GET_DELIVERY_PRODUCT,
  Mypage.getDeliveryProduct,
);
export const getDeliveryReceive = createAction(
  Mypage.default.GET_DELIVERY_RECEIVE,
  Mypage.getDeliveryReceive,
);
export const getDeliverySend = createAction(
  Mypage.default.GET_DELIVERY_SEND,
  Mypage.getDeliverySend,
);
export const getDeliveryDetail = createAction(
  Mypage.default.GET_DELIVERY_DETAIL,
  Mypage.getDeliveryDetail,
);
export const getDeliveryHistory = createAction(
  Mypage.default.GET_DELIVERY_HISTORY,
  Mypage.getDeliveryHistory,
);
export const clearDeliveryHistory = createAction(
  Mypage.default.CLEAR_DELIVERY_HISTORY,
);
export const setDeliveryDetailInit = createAction(
  Mypage.default.SET_DELIVERY_DETAIL_INIT,
  Mypage.setDeliveryDetailInit,
);
// 택배 신청
export const getSendProductList = createAction(
  Mypage.default.GET_SEND_PRODUCT_LIST,
  Mypage.getSendProductList,
);
export const clearSendProductList = createAction(
  Mypage.default.CLEAR_SEND_PRODUCT_LIST,
);

// 상품 상태값 변경
export const setProductState = createAction(
  Mypage.default.SET_PRODUCT_STATE,
  Mypage.setProductState,
);
export const setProductDelete = createAction(
  Mypage.default.SET_PRODUCT_DELETE,
  Mypage.setProductDelete,
);

// 거래내역 status별 타입 설정
export const setOrderStatus = data => {
  if (data instanceof Array) {
    return data.map(item => setOrderStatusItem(item));
  }
  return setOrderStatusItem(data);
};

// 마이페이지 인포
export const getMypage = createAction(
  Mypage.default.GET_MYPAGE,
  Mypage.getMypage,
);

// 마이페이지 팔로우
export const getFollower = createAction(
  Mypage.default.GET_FOLLOWER,
  Mypage.getFollower,
);
export const getFollowing = createAction(
  Mypage.default.GET_FOLLOWING,
  Mypage.getFollowing,
);
export const deleteFollow = createAction(
  Mypage.default.DELETE_FOLLOW,
  Mypage.deleteFollow,
);
export const postFollow = createAction(
  Mypage.default.POST_FOLLOW,
  Mypage.postFollow,
);

// 마이스토어 셋팅
export const putMystoreSet = createAction(
  Mypage.default.PUT_MYSTORE_SET,
  Mypage.putMystoreSet,
);
// 마이스토어 셋팅
export const putMystoreCategorySet = createAction(
  Mypage.default.PUT_MYSTORE_CATEGORY_SET,
  Mypage.putMystoreCategorySet,
);

// 마이스토어 정보
export const getMystoreInfo = createAction(
  Mypage.default.GET_MYSTORE_INFO,
  Mypage.getMystoreInfo,
);
export const clearMystoreInfo = createAction(
  Mypage.default.CLEAR_MYSTORE_INFO,
  () => {},
);

// 마이스토어 후기 리스트
export const getReviewList = createAction(
  Mypage.default.GET_REVIEW_LIST,
  Mypage.getReviewList,
);
// 마이스토어 후기 좋아요/싫어요
export const putReviewLike = createAction(
  Mypage.default.PUT_REVIEW_LIKE,
  Mypage.putReviewLike,
);
export const deleteReviewLike = createAction(
  Mypage.default.DELETE_REVIEW_LIKE,
  Mypage.deleteReviewLike,
);
// 마이스토어 후기 삭제
export const deleteReview = createAction(
  Mypage.default.DELETE_REVIEW,
  Mypage.deleteReview,
);

// 마이스토어 판매리스트 삭제
export const mystoreSellClear = createAction(
  Mypage.default.MY_STORE_SELL_CLEAR,
);
// 마이스토어 판매리스트
export const postMystoreSell = createAction(
  Mypage.default.POST_MYSTORE_SELL,
  Mypage.postMystoreSell,
);
// 마이스토어 알림설정 조회
export const getPushSetting = createAction(
  Mypage.default.GET_PUSH_SETTING,
  Mypage.getPushSetting,
);
// 마이스토어 알림 설정
export const putPushSetting = createAction(
  Mypage.default.PUT_PUSH_SETTING,
  Mypage.putPushSetting,
);
// 마이스토어 스토어별 알림 설정
export const putPushSettingFollow = createAction(
  Mypage.default.PUT_PUSH_SETTING_FOLLOW,
  Mypage.putPushSettingFollow,
);
// 마이스토어 스토어 전체 알림 설정
export const putPushSettingFollowAll = createAction(
  Mypage.default.PUT_PUSH_SETTING_FOLLOW_ALL,
  Mypage.putPushSettingFollowAll,
);
// 마이스토어 알림설정 조회
export const getMystoreShare = createAction(
  Mypage.default.GET_MYSTORE_SHARE,
  Mypage.getMystoreShare,
);
// 마이페이지 친구초대 조회
export const getMystoreFriend = createAction(
  Mypage.default.GET_MYSTORE_FRIEND,
  Mypage.getMystoreFriend,
);
// 마이페이지 프로필 이미지 변경
export const putMystoreProfile = createAction(
  Mypage.default.PUT_MYSTORE_PROFILE,
  Mypage.putMystoreProfile,
);
// 마이페이지 카페 레벨업 신청
export const postCafeLevelApply = createAction(
  Mypage.default.POST_CAFELEVEL_APPLY,
  Mypage.postCafeLevelApply,
);

// 알림설정 - 키워드 알림 설정
export const getKeywordNotifySet = createAction(
  Mypage.default.GET_KEYWORD_NOTIFYSET,
  Mypage.getKeywordNotifySet,
);
// 키워드 등록
export const postKeywordRegist = createAction(
  Mypage.default.POST_KEYWORD_REGIST,
  Mypage.postKeywordRegist,
);
// 키워드 수정
export const putKeywordModify = createAction(
  Mypage.default.PUT_KEYWORD_MODIFY,
  Mypage.putKeywordModify,
);
// 키워드 삭제
export const deleteKeywordNotify = createAction(
  Mypage.default.DELETE_KEYWORD_NOTIFY,
  Mypage.deleteKeywordNotify,
);

// 이전상품 판매리스트
export const postBeforeSellList = createAction(
  Mypage.default.POST_BEFORE_SELL_LIST,
  Mypage.postBeforeSellList,
);

const setOrderStatusItem = item => {
  // styleType: 버튼 타입, guideType : 안내팝업타입
  // item.orderStatus = "30"
  // item.partnerCenterPaymentUrl = "https://www.naver.com"
  if (item.orderStatus) {
    if (item.orderStatus === '00') {
      item.styleType = 'type1';
      item.guideStyleType = '';
      item.orderStatusKor = '주문완료';
    } else if (item.orderStatus === '30') {
      item.styleType = 'type19';
      item.orderStatusKor = '인증거래 상품';
    } else if (item.orderStatus === '01') {
      item.styleType = 'type2';
      item.guideStyleType = 'Type2';
      item.orderStatusKor = '결제완료';
    } else if (item.orderStatus === '02') {
      if (item.jnDeliveryYn == 1) {
        item.styleType =
          item.invoiceNo != null && item.invoiceNo.length > 0
            ? 'type6'
            : 'type15';
      } else {
        item.styleType = 'type5';
      }
      item.guideStyleType = 'Type5';
      item.orderStatusKor = '배송중';
    } else if (item.orderStatus === '03') {
      // 구매확정 -> 거래완료 상태
      if (item.jnDeliveryYn == 1) {
        item.styleType =
          item.invoiceNo != null && item.invoiceNo.length > 0
            ? 'type12' // 배송 조회
            : 'type15'; // 택배 예약중
      } else {
        item.styleType = 'type12'; // 배송 조회
      }
      item.guideStyleType = item.completeYn > 0 ? 'Type8' : 'Type7'; // 판매대금 지급,미지급 구분
      item.orderStatusKor = '거래완료';
    } else if (item.orderStatus === '05') {
      item.styleType = 'type7';
      item.guideStyleType = 'Type9';
      item.orderStatusKor = '반품신청';
    } else if (item.orderStatus === '06') {
      if (item.jnDeliveryYn == 1) {
        item.styleType =
          item.invoiceNo != null && item.invoiceNo.length > 0
            ? 'type8'
            : 'type15';
      } else {
        item.styleType = 'type8';
      }
      item.guideStyleType = 'Type10';
      item.orderStatusKor =
        item.jnDeliveryYn == 1 ? '반품 택배 예약중' : '반품수락';
    } else if (item.orderStatus === '07') {
      item.styleType = 'type9';
      item.guideStyleType = 'Type11';
      item.orderStatusKor = '반품배송중';
    } else if (item.orderStatus === '08') {
      item.styleType = 'type99';
      item.guideStyleType = '';
      item.orderStatusKor = '무통장입금 취소';
    } else if (item.orderStatus === '09') {
      item.styleType = 'type1';
      item.guideStyleType = 'Type1';
      item.orderStatusKor = '무통장 입금예정';
    } else if (item.orderStatus === '10') {
      item.styleType = 'type10';
      item.guideStyleType = 'Type12';
      item.orderStatusKor = '반품완료';
    } else if (item.orderStatus === '11') {
      // 판매수락
      item.styleType = item.jnDeliveryYn == 1 ? 'type1' : 'type3';
      item.guideStyleType = item.jnDeliveryYn == 1 ? 'Type4' : 'Type3';
      item.orderStatusKor = item.jnDeliveryYn == 1 ? '택배 예약중' : '판매수락';
    } else if (item.orderStatus === '21') {
      // 편의점택배 배송 예약중
      item.styleType = 'type1';
      item.guideStyleType = 'Type4';
      item.orderStatusKor = '판매수락';
    } else if (item.orderStatus === '23') {
      item.styleType = 'type6';
      item.guideStyleType = 'Type6';
      item.orderStatusKor = '배송완료';
    } else if (item.orderStatus === '84') {
      // 거래취소요청 ->판매수락 단계이며, 수락한후에 구매자 취소상태로 변경됨
      item.styleType = item.jnDeliveryYn == 1 ? 'type11' : 'type3'; // type11:[거래취소], type3:[거래취소,배송등록]
      item.guideStyleType = item.jnDeliveryYn == 1 ? 'Type4' : 'Type3';
      item.orderStatusKor = '판매수락';
    } else if (item.orderStatus === '81') {
      item.styleType = 'type1';
      item.guideStyleType = 'Type13';
      item.orderStatusKor = '거래취소';
    } else if (item.orderStatus === '82') {
      item.styleType = 'type1';
      item.guideStyleType = 'Type13';
      item.orderStatusKor = '거래취소';
    } else if (item.orderStatus === '83') {
      item.styleType = 'type1';
      item.guideStyleType = 'Type13';
      item.orderStatusKor = '거래취소';
    } else if (item.orderStatus === '59') {
      // 편의점 택배 예약 취소
      item.styleType = 'type18';
      item.guideStyleType = 'Type59';
      item.orderStatusKor = '거래취소';
    } else if (item.orderStatus === '60') {
      // 즉시송금
      if (item.orderDeliveryYn) {
        if (
          item.jnDeliveryYn == 1 &&
          !(item.deliveryStatus && item.deliveryStatus == '09')
        ) {
          // 마이그레이션 이슈료 type15이 있는곳은 deliveryStatus ="09" 까지 체크
          item.styleType =
            item.invoiceNo != null && item.invoiceNo.length > 0
              ? 'type12' // 배송 조회;
              : 'type15'; // 택배 예약중
        } else {
          item.styleType = 'type14'; // 편의점 택배 신청
        }
      } else {
        item.styleType = 'type13'; // 배송 상품 아님
      }
      item.guideStyleType = 'Type15';
      item.orderStatusKor = '거래완료';
    } else if (item.orderStatus === '50') {
      // 직접거래-구매자있는 직거래
      if (item.jnDeliveryYn == 1) {
        if ((item.deliveryStatus && item.deliveryStatus == '09') == false) {
          item.styleType =
            item.invoiceNo != null && item.invoiceNo.length > 0
              ? 'type12' // 배송조회
              : 'type15'; // 택배 예약중
        } else {
          item.styleType = 'type14'; // 택배미신청인데 배송지정보무-> 편의점 택배 신청
        }
        item.guideStyleType = 'Type-jnDelivery';
      } else {
        item.styleType = 'type14'; // 택배미신청인데 배송지정보무-> 편의점 택배 신청
        item.guideStyleType = 'Type16';
      }

      item.orderStatusKor = '거래완료';
    } else if (item.orderStatus === '51') {
      // 직접거래-구매자 없는 직거래
      if (item.jnDeliveryYn == 1) {
        if ((item.deliveryStatus && item.deliveryStatus == '09') == false) {
          item.styleType =
            item.invoiceNo != null && item.invoiceNo.length > 0
              ? 'type12' // 배송조회
              : 'type15'; // 택배 예약중
        } else {
          item.styleType = 'type14'; // 택배미신청인데 배송지정보무-> 편의점 택배 신청
        }
        item.guideStyleType = 'Type-jnDelivery';
      } else {
        item.styleType = 'type14'; // 택배미신청인데 배송지정보무-> 편의점 택배 신청
        item.guideStyleType = 'Type16';
      }

      item.orderStatusKor = '거래완료';
    }
  }

  item.orderStatusKor = item.orderStatusKor ? item.orderStatusKor : '';
  item.styleType = item.styleType ? item.styleType : 'type1';
  item.guideStyleType = item.guideStyleType ? item.guideStyleType : '';

  if (item.orderType) {
    if (item.orderType == '11') {
      item.orderTypeKor = '안전결제 / 신용카드';
    } else if (item.orderType == '12') {
      item.orderTypeKor = '안전결제 / 실시간계좌이체';
    } else if (item.orderType == '13') {
      item.orderTypeKor = '안전결제 / 무통장입금';
    } else if (item.orderType == '2') {
      item.orderTypeKor = '즉시송금 / PAYCO';
    } else if (item.orderType == '3') {
      item.orderTypeKor = '즉시송금 / KAKOPAY';
    } else if (item.orderType == '4') {
      item.orderTypeKor = '즉시송금 / TOSS';
    } else if (
      item.orderType == '7' ||
      item.orderType == '8' ||
      item.orderType == '9'
    ) {
      item.orderTypeKor = '직접거래';
    } else {
      item.orderTypeKor = '';
    }
  }

  return item;
};

// 편의점택배 status별 타입 설정
export const setDeliveryStatus = data => {
  if (data instanceof Array) {
    return data.map(item => setDeliveryStatusItem(item));
  }
  return setDeliveryStatusItem(data);
};

const setDeliveryStatusItem = item => {
  if (item.deliveryStatus) {
    if (item.deliveryStatus === '99') {
      item.deliveryStyle = 'type1';
      item.deliveryStatusKor = '예약중';
    } else if (item.deliveryStatus === '01') {
      item.deliveryStyle = 'type2';
      item.deliveryStatusKor = '예약중';
    } else if (item.deliveryStatus === '02') {
      item.deliveryStyle = 'type3';
      item.deliveryStatusKor = '접수완료';
    } else if (item.deliveryStatus === '03') {
      item.deliveryStyle = 'type4';
      item.deliveryStatusKor = '배송중';
    } else if (item.deliveryStatus === '04') {
      item.deliveryStyle = 'type5';
      item.deliveryStatusKor = '배송완료';
    } else if (item.deliveryStatus === '09') {
      item.deliveryStyle = 'type1';
      item.deliveryStatusKor = '예약취소';
    }
  }
  item.deliveryStatusKor = item.deliveryStatusKor
    ? item.deliveryStatusKor
    : '예약중';

  if (item.productCondition == '0') {
    item.productConditionKor = '미개봉';
  } else if (item.productCondition == '1') {
    item.productConditionKor = '거의새것';
  } else if (item.productCondition == '2') {
    item.productConditionKor = '중고';
  } else {
    item.productConditionKor = '중고';
  }

  return item;
};

const setProductStateClass = (state, productHiddenStatus) => {
  let stateClass = '';

  if (
    productHiddenStatus == 5 ||
    productHiddenStatus == 6 ||
    productHiddenStatus == 7 ||
    productHiddenStatus == 8
  ) {
    stateClass = 'cutoff';
    return stateClass;
  }

  if (state == 1) {
    stateClass = 'reserve';
  } else if (state == 2) {
    stateClass = 'hold';
  } else if (state == 3) {
    stateClass = 'complete';
  } else if (state == 4) {
    stateClass = '';
  }
  // else if(state ==5){
  //   stateClass = "cutoff"
  // }else if(state ==6){
  //   stateClass = "cutoff"
  // }
  return stateClass;
};

export default handleActions(
  {
    ...pender({
      type: Mypage.default.GET_NOTICE,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          notices: data.data,
        };
      },
    }),
    ...pender({
      type: Mypage.default.GET_ORDER_SALE,
      onSuccess: (state, action) => {
        const { data, config } = action.payload;

        // 검색조건 확인
        const params = {};
        config.url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
          str,
          key,
          value,
        ) {
          params[key] = value;
        });

        const clickCount = parseInt(params.clickCount);
        const { orderStatus } = params;
        const productTitle =
          params.productTitle != null && params.productTitle.length > 0
            ? decodeURIComponent(params.productTitle)
            : '';

        if (data.data && data.data.list) {
          // data.data.list = (clickCount === 0 ) ? setOrderStatus(data.data.list) : state.order_sale.list.concat(setOrderStatus(data.data.list))
          data.data.list = setOrderStatus(data.data.list);
          data.data.list = [...state.order_sale.list, ...data.data.list];
        }

        return {
          ...state,
          order_sale_clickcount: clickCount,

          // 검색조건 - 다른화면 이동시 저장 필요
          search_status: orderStatus,
          search_title: productTitle,

          order_sale: data.data,
          pageNo: state.pageNo + 1,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: { ...data.meta, moment: moment() },
        };
      },
    }),
    ...pender({
      type: Mypage.default.GET_ORDER_SALE_DETAIL,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        // data.data.orderStatus= "60"
        // data.data.jnDeliveryYn=1
        return {
          ...state,
          order_detail: setOrderStatus(data.data),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: { ...data.meta, moment: moment() },
        };
      },
    }),
    ...pender({
      type: Mypage.default.GET_DELIVERY_RECEIVE,
      onSuccess: (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          delivery_receive: setDeliveryStatus(data.data),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: { ...data.meta, moment: moment() },
        };
      },
    }),
    ...pender({
      type: Mypage.default.GET_DELIVERY_SEND,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          delivery_send: setDeliveryStatus(data.data),
        };
      },
    }),
    ...pender({
      type: Mypage.default.GET_DELIVERY_DETAIL,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          delivery_detail: setDeliveryStatus(data.data),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: { ...data.meta, moment: moment() },
        };
      },
    }),
    ...pender({
      type: Mypage.default.GET_DELIVERY_HISTORY,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          delivery_history: data.data,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          onFailureDeliveryHistory: moment(),
          delivery_history_error: data.meta,
        };
      },
    }),
    [Mypage.default.CLEAR_DELIVERY_HISTORY]: state => {
      return {
        ...state,
        delivery_history: initialState.delivery_history,
      };
    },
    // 택배 신청
    ...pender({
      type: Mypage.default.GET_SEND_PRODUCT_LIST,
      onSuccess: (state, action) => {
        const {
          data: {
            data: { item, page, pageSize },
          },
        } = action.payload;
        return {
          ...state,
          sendProductList: {
            item:
              state.sendProductList.item === null
                ? [...item]
                : [...state.sendProductList.item, ...item],
            page: page + 1,
            isMore: item.length === pageSize,
          },
        };
      },
    }),
    [Mypage.default.CLEAR_SEND_PRODUCT_LIST]: state => {
      return {
        ...state,
        sendProductList: initialState.sendProductList,
      };
    },
    ...pender({
      type: Mypage.default.GET_ORDER_PRODUCT,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          order_product: data.data,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          order_product_error:
            data && data.meta
              ? data.meta
              : { code: 500, message: 'SERVER ERROR' },
        };
      },
    }),
    ...pender({
      type: Mypage.default.GET_DELIVERY_PRODUCT,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          delivery_product: data.data,
        };
      },
    }),
    // 마이페이지 인포
    ...pender({
      type: Mypage.default.GET_MYPAGE,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          mypage: data.data,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: { ...data.meta, moment: moment() },
        };
      },
    }),
    // 마이페이지 팔로우
    ...pender({
      type: Mypage.default.GET_FOLLOWER,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        // 단위콤마 넣기
        data.data.map(item => {
          item.sellCount = item.sellCount.toLocaleString();
          item.followerCount = item.followerCount.toLocaleString();
        });
        return {
          ...state,
          follower: data.data,
        };
      },

      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: { ...data.meta, moment: moment() },
        };
      },
    }),
    ...pender({
      type: Mypage.default.GET_FOLLOWING,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        // 단위콤마 넣기
        data.data.map(item => {
          item.sellCount = item.sellCount.toLocaleString();
          item.followerCount = item.followerCount.toLocaleString();
          item.followingYn = 1;
        });
        return {
          ...state,
          following: data.data,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: { ...data.meta, moment: moment() },
        };
      },
    }),
    // 마이페이지 팔로우 해제
    ...pender({
      type: Mypage.default.DELETE_FOLLOW,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSuccessDeleteFollow: moment(),
        };
      },
    }),
    // 마이페이지 팔로우 하기
    ...pender({
      type: Mypage.default.POST_FOLLOW,
      onSuccess: (state, action) => {
        return {
          ...state,
          onSuccessPostFollow: moment(),
        };
      },
    }),

    // 마이스토어 셋팅
    ...pender({
      type: Mypage.default.PUT_MYSTORE_SET,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          mystore_set: data.data,
        };
      },
    }),

    // 마이스토어 카테고리 셋팅
    ...pender({
      type: Mypage.default.PUT_MYSTORE_CATEGORY_SET,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          mystore_category_set: data.data,
        };
      },
    }),

    // 마이스토어 정보
    ...pender({
      type: Mypage.default.GET_MYSTORE_INFO,
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: { ...data.meta, moment: moment() },
        };
      },
      onSuccess: (state, action) => {
        const { data } = action.payload;
        data.data.myStoreVO.lastAccessDateKor = Common.setDateFormat(
          data.data.myStoreVO.lastAccessDate,
        );

        // 판매
        // 택배거래 퍼센트
        data.data.myStoreSell.orderDeliveryPercent = Math.round(
          (data.data.myStoreSell.orderDeliveryCount /
            (data.data.myStoreSell.orderDeliveryCount +
              data.data.myStoreSell.orderDirectCount)) *
            100,
        );
        // 직거래 퍼센트
        data.data.myStoreSell.orderDirectPercent = Math.round(
          (data.data.myStoreSell.orderDirectCount /
            (data.data.myStoreSell.orderDeliveryCount +
              data.data.myStoreSell.orderDirectCount)) *
            100,
        );
        // 안전결제 퍼센트
        data.data.myStoreSell.paySafePercent = Math.round(
          (data.data.myStoreSell.paySafeCount /
            (data.data.myStoreSell.paySafeCount +
              data.data.myStoreSell.payDirectCount +
              data.data.myStoreSell.payNormalCount)) *
            100,
        );
        // 즉시송금 퍼센트
        data.data.myStoreSell.payDirectPercent = Math.round(
          (data.data.myStoreSell.payDirectCount /
            (data.data.myStoreSell.paySafeCount +
              data.data.myStoreSell.payDirectCount +
              data.data.myStoreSell.payNormalCount)) *
            100,
        );
        // 일반결제 퍼센트
        data.data.myStoreSell.payNormalPercent = Math.round(
          (data.data.myStoreSell.payNormalCount /
            (data.data.myStoreSell.paySafeCount +
              data.data.myStoreSell.payDirectCount +
              data.data.myStoreSell.payNormalCount)) *
            100,
        );

        // 구매
        // 택배거래 퍼센트
        data.data.myStoreBuy.orderDeliveryPercent = Math.round(
          (data.data.myStoreBuy.orderDeliveryCount /
            (data.data.myStoreBuy.orderDeliveryCount +
              data.data.myStoreBuy.orderDirectCount)) *
            100,
        );
        // 직거래 퍼센트
        data.data.myStoreBuy.orderDirectPercent = Math.round(
          (data.data.myStoreBuy.orderDirectCount /
            (data.data.myStoreBuy.orderDeliveryCount +
              data.data.myStoreBuy.orderDirectCount)) *
            100,
        );
        // 안전결제 퍼센트
        data.data.myStoreBuy.paySafePercent = Math.round(
          (data.data.myStoreBuy.paySafeCount /
            (data.data.myStoreBuy.paySafeCount +
              data.data.myStoreBuy.payDirectCount +
              data.data.myStoreBuy.payNormalCount)) *
            100,
        );
        // 즉시송금 퍼센트
        data.data.myStoreBuy.payDirectPercent = Math.round(
          (data.data.myStoreBuy.payDirectCount /
            (data.data.myStoreBuy.paySafeCount +
              data.data.myStoreBuy.payDirectCount +
              data.data.myStoreBuy.payNormalCount)) *
            100,
        );
        // 일반결제 퍼센트
        data.data.myStoreBuy.payNormalPercent = Math.round(
          (data.data.myStoreBuy.payNormalCount /
            (data.data.myStoreBuy.paySafeCount +
              data.data.myStoreBuy.payDirectCount +
              data.data.myStoreBuy.payNormalCount)) *
            100,
        );

        if (data.data.myStoreRank) {
          data.data.myStoreRank.map(item => {
            if (item.categorySeq == 0) {
              return (data.data.myStoreRank.totalRank = item.rank);
            }
          });
        }

        // 가격 , 표시
        if (data.data.storeProductsList) {
          data.data.storeProductsList.map(item => {
            item.productPrice = item.productPrice.toLocaleString();
          });
        }
        if (data.data.seenProductList) {
          data.data.seenProductList.map(item => {
            item.productPrice = item.productPrice.toLocaleString();
          });
        }

        data.data.storePredictProfit = data.data.storePredictProfit.toLocaleString();
        data.data.storeTotalProfit = data.data.storeTotalProfit.toLocaleString();

        // 우리동네 갯수
        data.data.myStoreLocationVOList.locationLength =
          data.data.myStoreLocationVOList.length;

        // 동네 일치 갯수
        data.data.myStoreLocationVOList.matchLocation = 0;
        if (data.data.myStoreLocationVOList) {
          data.data.myStoreLocationVOList.find(item => {
            if (item.matchYn == 1) {
              data.data.myStoreLocationVOList.matchLocation =
                data.data.myStoreLocationVOList.matchLocation + 1;
            }
          });
        }

        // 구매확정율
        if (data.data.myStoreBuy.buyCompleteCount == 0) {
          data.data.myStoreBuy.buyCompleteCountKor = '1시간 이내';
        } else if (
          data.data.myStoreBuy.buyCompleteCount < 24 &&
          data.data.myStoreBuy.buyCompleteCount != 0
        ) {
          data.data.myStoreBuy.buyCompleteCountKor = `${
            data.data.myStoreBuy.buyCompleteCount
          }시간 이내`;
        } else if (
          data.data.myStoreBuy.buyCompleteCount < 48 &&
          data.data.myStoreBuy.buyCompleteCount > 24
        ) {
          data.data.myStoreBuy.buyCompleteCountKor = '2일 이내';
        } else if (
          data.data.myStoreBuy.buyCompleteCount < 72 &&
          data.data.myStoreBuy.buyCompleteCount > 48
        ) {
          data.data.myStoreBuy.buyCompleteCountKor = '3일 이내';
        } else if (data.data.myStoreBuy.buyCompleteCount > 72) {
          data.data.myStoreBuy.buyCompleteCountKor = '3일 이상';
        }

        if (data.data.myStoreReviewVO) {
          // 거래만족도
          if (
            data.data.myStoreReviewVO.dealSatisfaction >= 0 &&
            data.data.myStoreReviewVO.dealSatisfaction <= 10
          ) {
            data.data.myStoreReviewVO.dealSatisfactionKor =
              data.data.myStoreReviewVO.dealSatisfaction;
          } else if (
            data.data.myStoreReviewVO.dealSatisfaction >= 11 &&
            data.data.myStoreReviewVO.dealSatisfaction <= 99
          ) {
            if (
              data.data.myStoreReviewVO.dealSatisfaction >= 11 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 19
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '10+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 20 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 29
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '20+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 30 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 39
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '30+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 40 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 49
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '40+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 50 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 59
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '50+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 60 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 69
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '60+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 70 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 79
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '70+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 80 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 89
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '80+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 90 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 99
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '90+';
            }
          } else if (
            data.data.myStoreReviewVO.dealSatisfaction >= 100 &&
            data.data.myStoreReviewVO.dealSatisfaction <= 998
          ) {
            if (
              data.data.myStoreReviewVO.dealSatisfaction >= 100 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 199
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '100+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 200 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 299
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '200+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 300 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 399
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '300+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 400 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 499
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '400+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 500 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 599
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '500+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 600 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 699
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '600+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 700 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 799
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '700+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 800 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 899
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '800+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 900 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 998
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '900+';
            }
          } else if (data.data.myStoreReviewVO.dealSatisfaction >= 999) {
            data.data.myStoreReviewVO.dealSatisfactionKor = '999+';
          }
        }

        // data.data.myStoreVO.selling = null

        return {
          ...state,
          // 마이스토어 정보
          mystore_info: data.data,
          // 마이스토어 카테고리
          mystore_category: data.data.myStoreCategory,
          onSuccessMyCategory: moment(),

          // 마이스토어 정보
          myStoreVO: data.data.myStoreVO,
          // 판매중인 상품리스트
          storeProductsList: data.data.storeProductsList,
          // 최근 본 상품
          seenProductList: data.data.seenProductList,
          // 스토어 랭크
          myStoreRank: data.data.myStoreRank,
          // 구매이력
          myStoreBuy: data.data.myStoreBuy,
          // 판매이력
          myStoreSell: data.data.myStoreSell,
          // 스토어후기
          myStoreReviewVO: data.data.myStoreReviewVO,
          // 우리동네
          myStoreLocationVOList: data.data.myStoreLocationVOList,
          // 태그리스트
          myStoreTagVOList: data.data.myStoreTagVOList,
          // 팔로우 유무
          storeFollowYn: data.data.storeFollowYn,
        };
      },
    }),
    [Mypage.default.CLEAR_MYSTORE_INFO]: state => {
      return {
        ...state,
        mystore_info: [],
        mystore_category: [],
        onSuccessMyCategory: moment(),
        myStoreVO: [],
        storeProductsList: [],
        seenProductList: [],
        myStoreRank: [],
        myStoreBuy: [],
        myStoreSell: [],
        myStoreReviewVO: [],
        myStoreLocationVOList: [],
        myStoreTagVOList: [],
        storeFollowYn: 0,
      };
    },
    // 마이스토어 후기 리스트
    ...pender({
      type: Mypage.default.GET_REVIEW_LIST,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        // 후기 더미데이터
        // data.data.myStoreReviewVO = {
        //   grade : 1,
        //   dealSatisfaction : 9,
        //   reviewTotal : 1,
        //   sellerStr : "친절하던데",
        //   sellerCount : 2,
        //   speedStr : "적당히빠름",
        //   speedCount : 1,
        //   descriptionStr : "비슷함",
        //   descriptionCount : 0
        // }
        // data.data.myStoreReviewList[0] = {
        //   nickname : "함냐함냐",
        //   regdate : 2019,
        //   reviewContent : "아항 그래서 후기가 잇구나 가데이터 아니 더미 요호호호홀ㄹ로로로로 호로요이",
        //   sellerStr : "그냥그랫어사실",
        //   speedStr : "알앗냐"
        //   ,descriptionStr : "알겟냐고 ㅇㅇ?",
        //   reviewLikeClick : 1,
        //   orderNo : 3215123,
        //   reviewMasterYn : 0
        // }
        // data.data.myStoreReviewList[1] = {
        //   nickname : "함냐함냐",
        //   regdate : 2019,
        //   reviewContent : "아항 그래서 후기가 잇구나 가데이터 아니 더미 요호호호홀ㄹ로로로로 호로요4124124이",
        //   sellerStr : "그냥그랫어사실",
        //   speedStr : "알앗냐"
        //   ,descriptionStr : "알겟냐고 ㅇㅇ?",
        //   reviewLikeClick : 1,
        //   orderNo : 3215123,
        //   reviewMasterYn : 1
        // }

        if (data.data.myStoreReviewVO) {
          // 후기평점
          if (
            data.data.myStoreReviewVO.grade >= 0 &&
            data.data.myStoreReviewVO.grade <= 1.9
          ) {
            data.data.myStoreReviewVO.gradeKor = 'C+';
          } else if (
            data.data.myStoreReviewVO.grade >= 2 &&
            data.data.myStoreReviewVO.grade <= 2.4
          ) {
            data.data.myStoreReviewVO.gradeKor = 'B';
          } else if (
            data.data.myStoreReviewVO.grade >= 2.5 &&
            data.data.myStoreReviewVO.grade <= 2.9
          ) {
            data.data.myStoreReviewVO.gradeKor = 'B+';
          } else if (
            data.data.myStoreReviewVO.grade >= 3.0 &&
            data.data.myStoreReviewVO.grade <= 3.9
          ) {
            data.data.myStoreReviewVO.gradeKor = 'A';
          } else if (data.data.myStoreReviewVO.grade >= 4.0) {
            data.data.myStoreReviewVO.gradeKor = 'A+';
          }
          // 거래만족도
          if (
            data.data.myStoreReviewVO.dealSatisfaction >= 0 &&
            data.data.myStoreReviewVO.dealSatisfaction <= 10
          ) {
            data.data.myStoreReviewVO.dealSatisfactionKor =
              data.data.myStoreReviewVO.dealSatisfaction;
          } else if (
            data.data.myStoreReviewVO.dealSatisfaction >= 11 &&
            data.data.myStoreReviewVO.dealSatisfaction <= 99
          ) {
            if (
              data.data.myStoreReviewVO.dealSatisfaction >= 11 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 19
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '10+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 20 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 29
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '20+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 30 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 39
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '30+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 40 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 49
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '40+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 50 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 59
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '50+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 60 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 69
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '60+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 70 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 79
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '70+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 80 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 89
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '80+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 90 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 99
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '90+';
            }
          } else if (
            data.data.myStoreReviewVO.dealSatisfaction >= 100 &&
            data.data.myStoreReviewVO.dealSatisfaction <= 998
          ) {
            if (
              data.data.myStoreReviewVO.dealSatisfaction >= 100 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 199
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '100+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 200 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 299
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '200+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 300 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 399
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '300+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 400 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 499
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '400+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 500 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 599
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '500+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 600 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 699
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '600+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 700 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 799
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '700+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 800 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 899
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '800+';
            } else if (
              data.data.myStoreReviewVO.dealSatisfaction >= 900 &&
              data.data.myStoreReviewVO.dealSatisfaction <= 998
            ) {
              data.data.myStoreReviewVO.dealSatisfactionKor = '900+';
            }
          } else if (data.data.myStoreReviewVO.dealSatisfaction >= 999) {
            data.data.myStoreReviewVO.dealSatisfactionKor = '999+';
          }
        }
        return {
          ...state,
          review_list: data.data,
          myStoreReviewVO: data.data.myStoreReviewVO,
          myStoreReviewList: data.data.myStoreReviewList,
        };
      },
    }),
    // 마이스토어 후기 좋아요
    ...pender({
      type: Mypage.default.PUT_REVIEW_LIKE,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSuccessLike: moment(),
        };
      },
    }),
    // 마이스토어 후기 좋아요 삭제
    ...pender({
      type: Mypage.default.DELETE_REVIEW_LIKE,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSuccessDelLike: moment(),
        };
      },
    }),
    // 마이스토어 후기 삭제
    ...pender({
      type: Mypage.default.DELETE_REVIEW,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSuccessDelReview: moment(),
        };
      },
    }),

    // 마이스토어 판매상품 리스트 초기화
    [Mypage.default.MY_STORE_SELL_CLEAR]: state => {
      return {
        ...state,
        productList: [],
      };
    },

    // 마이스토어 판매상품 리스트
    ...pender({
      type: Mypage.default.POST_MYSTORE_SELL,
      onSuccess: (state, action) => {
        const { data, config } = action.payload;
        if (data.data) {
          data.data.map(item => {
            // item.state = 2
            // item.productHiddenStatus = 5
            /*
              state (0:판매중, 1:예약중, 2:판매보류, 3:판매완료, 4:상품삭제, 7:판매대기)
              productHiddenStatus (0:노출중, 4:삭제, 5:관리자숨김, 6:신고숨김, 7:회원제재숨김, 8:url 로만 접근 가능한 상태)
            */

            // 상품업데이트 시간
            item.sortDateKor = Common.setDateFormat(item.sortDate);

            // 가격 원 표시
            item.price = item.price.toLocaleString();

            if (item.productHiddenStatus == 5) {
              item.productHiddenStatusKor =
                '관리자에 의해<br />숨김 처리 되었습니다.';
            } else if (item.productHiddenStatus == 6) {
              item.productHiddenStatusKor =
                '다수의 사용자 신고로<br />숨김 처리 되었습니다.';
            } else if (item.productHiddenStatus == 7) {
              item.productHiddenStatusKor =
                '회원 제재에 의해<br />숨김 처리 되었습니다.';
            } else if (item.productHiddenStatus == 8) {
              item.productHiddenStatusKor =
                'URL로 접근 가능한<br />상품입니다.';
            }

            // class 셋팅
            if (data.state) {
              item.stateClass = setProductStateClass(
                data.state,
                item.productHiddenStatus,
              );
            } else {
              item.stateClass = setProductStateClass(
                item.state,
                item.productHiddenStatus,
              );
            }
          });
        }

        // data.data.totalCount = data.data[0].totalCnt

        const paramJson = JSON.parse(config.data);
        let productList = data.data;
        productList =
          state.productList &&
          state.productList.length > 0 &&
          paramJson.startIndex != 0
            ? state.productList.concat(productList)
            : productList;

        return {
          ...state,
          productList,
        };
      },
    }),

    // 마이스토어 알림 설정 조회
    ...pender({
      type: Mypage.default.GET_PUSH_SETTING,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          pushSetting: data.data,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: { ...data.meta, moment: moment() },
        };
      },
    }),
    // 마이스토어 알림 설정
    ...pender({
      type: Mypage.default.PUT_PUSH_SETTING,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSuccessPushSetting: moment(),
        };
      },
    }),
    // 마이스토어 스토어별 알림 설정
    ...pender({
      type: Mypage.default.PUT_PUSH_SETTING_FOLLOW,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSuccessPutPushSettingFollow: moment(),
        };
      },
    }),
    // 마이스토어 스토어 전체 알림 설정
    ...pender({
      type: Mypage.default.PUT_PUSH_SETTING_FOLLOW_ALL,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSuccessPutPushSettingFollowAll: moment(),
        };
      },
    }),
    // 마이스토어 공유하기
    ...pender({
      type: Mypage.default.GET_MYSTORE_SHARE,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          mystoreShare: data.data,
        };
      },
    }),
    // 마이스토어 공유하기
    ...pender({
      type: Mypage.default.GET_MYSTORE_FRIEND,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          mystoreFriend: data.data,
        };
      },
    }),
    // 마이페이지 프로필 사진 변경
    ...pender({
      type: Mypage.default.PUT_MYSTORE_PROFILE,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSuccessChangeProfile: moment(),
        };
      },
    }),
    // 마이페이지 카페 레벨업 신청
    ...pender({
      type: Mypage.default.POST_CAFELEVEL_APPLY,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSuccessCafeLevelApply: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          cafe_levelup_failcode: data.meta.code,
          onFailureCafeLevelup: moment(),
        };
      },
    }),

    // 알림 설정 - 키워드 알림 설정
    ...pender({
      type: Mypage.default.GET_KEYWORD_NOTIFYSET,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          keyword_userSeq: data.data.userSeq,
          keyword_notify_set: data.data.keywordVOS,
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error: { ...data.meta, moment: moment() },
        };
      },
    }),

    // 키워드 등록
    ...pender({
      type: Mypage.default.POST_KEYWORD_REGIST,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSuccessRegistKeyword: moment(),
        };
      },
      onFailure: (state, action) => {
        const { data } = action.payload.response;
        return {
          ...state,
          error_code: data.meta.code,
          onFailureRegistKeyword: moment(),
        };
      },
    }),
    // 키워드 수정
    ...pender({
      type: Mypage.default.PUT_KEYWORD_MODIFY,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSuccessModifyKeyword: moment(),
        };
      },
    }),
    // 키워드 삭제
    ...pender({
      type: Mypage.default.DELETE_KEYWORD_NOTIFY,
      onSuccess: (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          onSuccessDelKeyword: moment(),
        };
      },
    }),

    // 이전상품 판매상품 리스트
    ...pender({
      type: Mypage.default.POST_BEFORE_SELL_LIST,
      onSuccess: (state, action) => {
        const { data, config } = action.payload;
        const { productVO } = data.data;
        if (productVO) {
          productVO.map(item => {
            // item.productHiddenStatus = 5

            // 상품업데이트 시간
            item.sortDateKor = Common.setDateFormat(item.sortDate);

            // 가격 원 표시
            item.price = item.price.toLocaleString();

            // class 셋팅
            if (data.state) {
              item.stateClass = setProductStateClass(data.state);
            } else {
              item.stateClass = setProductStateClass(item.state);
            }

            // item.stateClass = 'cutoff'

            if (item.productHiddenStatus == 5) {
              item.productHiddenStatusKor = '관리자에 의해';
            } else if (item.productHiddenStatus == 6) {
              item.productHiddenStatusKor = '다수의 사용자 신고로';
            }
          });
        }

        // data.data.totalCount = data.data[0].totalCnt

        const paramJson = JSON.parse(config.data);
        let beforeSellList = productVO;
        beforeSellList =
          state.beforeSellList &&
          state.beforeSellList.length > 0 &&
          paramJson.startIndex != 0
            ? state.beforeSellList.concat(beforeSellList)
            : beforeSellList;

        return {
          ...state,
          beforeSellList,
        };
      },
    }),

    [Mypage.default.SET_ORDER_SALE_DETAIL_INIT]: (state, action) => {
      return {
        ...state,
        order_detail: {},
      };
    },
    [Mypage.default.SET_DELIVERY_DETAIL_INIT]: (state, action) => {
      return {
        ...state,
        delivery_detail: {},
      };
    },
    [Mypage.default.SET_PRODUCT_STATE]: (state, action) => {
      const data = action.payload;
      let returnProducList = state.productList;
      returnProducList = returnProducList.map(item => {
        if (item.seq == data.productSeq) {
          item.state = data.state;
          item.stateClass = setProductStateClass(data.state);
        }
        return item;
      });
      return {
        ...state,
        productList: returnProducList,
      };
    },
    [Mypage.default.SET_PRODUCT_DELETE]: (state, action) => {
      const data = action.payload;
      let returnProducList = state.productList;
      returnProducList = returnProducList.filter(item => {
        return item.seq != data.productSeq;
      });
      return {
        ...state,
        productList: returnProducList,
      };
    },
    [Mypage.default.SET_ORDER_SALE_RESET]: (state, action) => {
      return {
        ...state,
        order_sale: initialState.order_sale,
        pageNo: initialState.pageNo,
      };
    },
  },
  initialState,
);
