/* eslint-disable no-param-reassign */
import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import produce from 'immer';

import * as CommentApi from '../../api/base/comment';

export const COMMENT = {
  CLEAR: 'comment/CLEAR',
  GET_COMMENT: 'comment/GET_COMMENT',
  GET_PAGE: 'comment/GET_PAGE',
  POST_COMMENT: 'comment/POST_COMMENT',
  POST_REPLY_COMMENT: 'comment/POST_REPLY_COMMENT',
  EDIT_COMMENT: 'comment/EDIT_COMMENT',
  DELETE_COMMENT: 'comment/DELETE_COMMENT',
};

const initialState = {
  // 댓글 리스트, 리스트 갯수, 댓글 index
  startIndex: 0,
  commentCount: 0,
  commentList: [],
  filterCommentCount: 0,
  error: false,
  errorMessage: '',
  isEnd: false,
};

export const clear = createAction(COMMENT.CLEAR);
export const getComment = createAction(
  COMMENT.GET_COMMENT,
  CommentApi.getCommentList,
);
export const getPage = createAction(
  COMMENT.GET_COMMENT,
  CommentApi.getCommentPage,
);
export const postComment = createAction(
  COMMENT.POST_COMMENT,
  CommentApi.postComment,
);
export const postReplyComment = createAction(
  COMMENT.POST_REPLY_COMMENT,
  CommentApi.postComment,
);
export const editComment = createAction(
  COMMENT.EDIT_COMMENT,
  CommentApi.editComment,
);
export const deleteComment = createAction(
  COMMENT.DELETE_COMMENT,
  CommentApi.deleteComment,
);

export default handleActions(
  {
    [COMMENT.CLEAR]: () => {
      return { ...initialState };
    },
    ...pender({
      type: COMMENT.GET_COMMENT,
      onSuccess: (state, action) => {
        const {
          data: {
            data: { commentList, commentCount },
          },
        } = action.payload;

        /**
         * 1. commentCount 가 20보다 작거나 같을경우
         *    a. 더보기가 없으므로 무조건 새롭게 넣는다. - true
         * 2. commentCount 가 20보다 클 경우
         *    a. list 가 20보다 크면 전부다 받아온것. - true
         *    b. list 가 20보다 작으면 일부만 받아온것. - false
         * 3. commentList 가 0 이면 더이상 없는거
         */

        const filterCommentCount = commentList.filter(
          c => c.commentStatus === 1,
        ).length;

        if (filterCommentCount === 0) {
          return produce(state, draft => {
            draft.isEnd = true;
          });
        }

        if (
          commentList.length <= 20 &&
          commentCount >= state.filterCommentCount + filterCommentCount
        ) {
          return produce(state, draft => {
            draft.commentList.push(...commentList);
            draft.commentCount = commentCount;
            draft.startIndex = state.startIndex + 1;
            draft.filterCommentCount =
              state.filterCommentCount + filterCommentCount;
            draft.isEnd = false;
            draft.error = false;
            draft.errorCode = 0;
            draft.errorMessage = '';
          });
        }

        return produce(state, draft => {
          draft.commentList = commentList;
          draft.commentCount = commentCount;
          draft.startIndex = Math.floor(commentList.length / 20);
          draft.filterCommentCount = filterCommentCount;
          draft.isEnd = false;
          draft.error = false;
          draft.errorCode = 0;
          draft.errorMessage = '';
        });
      },
    }),

    ...pender({
      type: COMMENT.POST_COMMENT,
      onSuccess: state => {
        return {
          ...state,
          error: false,
          errorCode: 0,
          errorMessage: '',
        };
      },
      onFailure: (state, action) => {
        return {
          ...state,
          error: true,
          errorCode: action.payload.response.data.meta.code,
          errorMessage: action.payload.response.data.meta.message,
        };
      },
    }),
    ...pender({
      type: COMMENT.POST_REPLY_COMMENT,
      onSuccess: state => {
        return {
          ...state,
          error: false,
          errorCode: 0,
          errorMessage: '',
        };
      },
      onFailure: (state, action) => {
        return {
          ...state,
          error: true,
          errorCode: 0,
          errorMessage: action.payload.response.data.meta.message,
        };
      },
    }),
    ...pender({
      type: COMMENT.EDIT_COMMENT,
      onSuccess: state => {
        return {
          ...state,
          error: false,
          errorCode: 0,
          errorMessage: '',
        };
      },
      onFailure: (state, action) => {
        return {
          ...state,
          error: true,
          errorCode: action.payload.response.data.meta.code,
          errorMessage: action.payload.response.data.meta.message,
        };
      },
    }),
    ...pender({
      type: COMMENT.DELETE_COMMENT,
      onSuccess: state => {
        return {
          ...state,
          error: false,
          errorCode: 0,
          errorMessage: '',
        };
      },
      onFailure: (state, action) => {
        return {
          ...state,
          error: true,
          errorCode: action.payload.response.data.meta.code,
          errorMessage: action.payload.response.data.meta.message,
        };
      },
    }),
  },
  initialState,
);
