import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { addZero } from './number';

dayjs.locale('ko');
dayjs.extend(weekOfYear);

export const day = dateType => {
  if (Array.isArray(dateType)) {
    const dateArr = [...dateType];
    dateArr[1] -= 1;
    return dayjs(new Date(...dateArr));
  }
  return dateType === undefined ? dayjs() : dayjs(dateType);
};

export const dayString = (format = 'YYYY-MM-DD HH:mm:ss', dateType) => {
  return day(dateType).format(format);
};

export const dayIsValid = time => {
  return day(time).isValid;
};

export const isBeforeTime = (start, compare = day()) => {
  return start.isBefore(compare);
};

export const isAfterTime = (start, compare = day()) => {
  return start.isAfter(compare);
};
export function isSameTime(start, compare = day()) {
  return start.isSame(compare);
}

export function isSameOrAfterTime(start, compare = day()) {
  return start.isSame(compare) || day(start).isAfter(compare);
}

export const addDay = d => {
  return day()
    .add(d, 'day')
    .startOf('day');
};

export const addTime = (r, range = 'day') => {
  return day()
    .add(r, range)
    .startOf(range);
};

export const subTime = (r, range = 'day') => {
  return day()
    .subtract(r, range)
    .startOf(range);
};

export const remainTime = (start, end) => {
  const diffDay = end.diff(start, 'day');
  const diffHour = addZero(end.diff(start, 'hour') % 24);
  const diffMinute = addZero(end.diff(start, 'minute') % 60);
  const diffSecond = addZero(end.diff(start, 'second') % 60);

  return diffDay > 0
    ? `${diffDay}일 ${diffHour}:${diffMinute}:${diffSecond}`
    : `${diffHour}:${diffMinute}:${diffSecond}`;
};

export const remainTimeMunite = (start, end) => {
  const diffMinute = end.diff(start, 'minute') % 60;
  const diffSecond = addZero(end.diff(start, 'second') % 60);

  return `${diffMinute} : ${diffSecond}`;
};

export const getRegDate = time => {
  const diffSec = dayjs().diff(dayjs(time, 'YYYY-MM-DD HH:mm:ss'), 'second');
  if (diffSec < 10) {
    // 10초전
    return '방금전';
  }
  if (diffSec < 60) {
    // 10초~60초미만
    return `${diffSec}초 전`;
  }
  if (diffSec < 60 * 60) {
    // 1분~60분 미만
    return `${Number(diffSec / 60).toFixed(0)}분 전`;
  }
  if (diffSec < 60 * 60 * 24) {
    // 1시간~24시간 미만
    return `${Number(diffSec / 60 / 60).toFixed(0)}시간 전`;
  }
  if (diffSec < 60 * 60 * 24 * 30) {
    // 1일 30일 미만
    return `${Number(diffSec / 60 / 60 / 24).toFixed(0)}일 전`;
  }
  return dayjs(time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
};

export const getMonth = date => {
  return dayjs(date).month();
};

export function getDay(dayObj = dayjs()) {
  return dayObj.day();
}

export const dayOfWeek = idx => {
  const dayOfTheWeek = [
    {
      shot: '일',
      long: '일요일',
    },
    {
      shot: '월',
      long: '월요일',
    },
    {
      shot: '화',
      long: '화요일',
    },
    {
      shot: '수',
      long: '수요일',
    },
    {
      shot: '목',
      long: '목요일',
    },
    {
      shot: '금',
      long: '금요일',
    },
    {
      shot: '토',
      long: '토요일',
    },
  ];

  return dayOfTheWeek[idx];
};
