import axios from 'axios';
import { baseApi, pointApi } from '../../common/api';

const event = {
  CLEAR: 'EVENT/CLEAR',
  GET_EVENT_DETAIL: 'EVENT/GET_EVENT_DETAIL',
  POST_EVENT_JOIN: 'EVENT/POST_EVENT_JOIN',
  GET_EVENT_WINNER: 'EVENT/GET_EVENT_WINNER',

  GET_EV2_ATTEND: 'EVENT/GET_EV2_ATTEND',
  POST_EV2_ATTEND: 'EVENT/POST_EV2_ATTEND',
  POST_EV2_ATTEND_TICKET: 'EVENT/POST_EV2_ATTEND_TICKET',
  SET_EV2_ATTEND_TICKET: 'EVENT/SET_EV2_ATTEND_TICKET',
  GET_EV2_ATTEND_YN: 'EVENT/GET_EV2_ATTEND_YN',

  UPDATE_EVENT_DETAIL_AUCTION: 'EVENT/UPDATE_EVENT_DETAIL_AUCTION',
};

// 이벤트 상세 API
export function getEventDetail(eventSeq) {
  const promise = axios.get(`/mypage/event/${eventSeq}`, { ...baseApi() });
  return promise;
}

// 이벤트 참여 API
export function postEventJoin(eventSeq) {
  const promise = axios.post(`/mypage/event/${eventSeq}/join`, '', {
    ...baseApi(),
  });
  return promise;
}

// 이벤트 리스트 API
export function getEventList(eventType, startIndex, searchQuantity) {
  const promise = axios.get(
    `/mypage/event?osType=3&deviceType=2&eventType=${eventType}&startIndex=${startIndex}&searchQuantity=${searchQuantity}`,
    { ...baseApi() },
  );
  return promise;
}

// 이벤트 당첨자 API
export function getEventWinner(eventType, startIndex, searchQuantity) {
  const promise = axios.get(
    `/mypage/event?osType=3&deviceType=2&eventType=${eventType}&startIndex=${startIndex}&searchQuantity=${searchQuantity}`,
    { ...baseApi() },
  );
  return promise;
}

// 포인트 이벤트 :: 출석체크 :: 목록 API
export function getEv2Attend(month) {
  const promise = axios.get(`/point/use/attend/${month}`, { ...pointApi() });
  return promise;
}

// 포인트 이벤트 :: 출석체크 :: 참여 API
export function postEv2Attend() {
  const promise = axios.post(`/point/use/attend`, '', { ...pointApi() });
  return promise;
}

// 포인트 이벤트 :: 출석체크 :: 경품 응모 API
export function postEv2AttendTicket(month, key) {
  const promise = axios.post(`/point/use/attend/${month}/${key}`, '', {
    ...pointApi(),
  });
  return promise;
}

// 통신 안함 : UI 상으로 티겟 개수 증감
export function setEv2AttendTicket(ticket) {
  return ticket;
}

// 포인트 이벤트 :: 출석체크 :: 참여유무 체크
export function getEv2AttendYn() {
  const promise = axios.get(`/point/use/attendYn/`, { ...pointApi() });
  return promise;
}

export default event;
