import React, { useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { string, bool } from 'prop-types';
import classNames from 'classnames';
import { getImgSrc } from '../../../common/parser';

import FullImageSwiper from './FullImageSwiper';

import defaultImg from '../../../asset/img/190410_symbol_basic.png';
import styles from './Image.module.scss';

const Image = ({
  className,
  src,
  alt,
  circle,
  isSquaricle,
  noExpand,
  halfHeight,
  dim,
  cafeProfile,
  showTitle,
}) => {
  const { domain } = useSelector(({ init }) => init);

  const replaceSrc = useMemo(() => getImgSrc(src, domain.Image), [src]);
  const [show, setShow] = useState(false);

  const svgImage = useRef(null);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    if (!noExpand) {
      setShow(true);
    }
  };

  const onLoad = e => {
    e.persist();
    const img = e.target;
    if (img.clientWidth < img.clientHeight) {
      img.classList.add(styles.heightLong);
    }
    if (svgImage.current && isSquaricle) {
      if (img.width < img.height) {
        svgImage.current.setAttribute('width', '100%');
        const align = Math.floor((100 - 100 / (img.height / img.width)) / 2);
        const otherVal = Math.floor((img.height / img.width) * 100);
        svgImage.current.setAttribute('height', `${otherVal}%`);
        svgImage.current.setAttribute('y', `-${align}%`);
      } else {
        svgImage.current.setAttribute('height', '100%');
        const align = Math.floor((100 - 100 / (img.width / img.height)) / 2);
        const otherVal = Math.floor((img.width / img.height) * 100);
        svgImage.current.setAttribute('width', `${otherVal}%`);
        svgImage.current.setAttribute('x', `-${align}%`);
      }
    }
  };

  // hamin_이미지 뷰가 정사각형이 아닌 2:1 뷰일 때 동직합니다.
  const halfOnLoad = e => {
    e.persist();
    const img = e.target;
    if (img.clientWidth / 2 <= img.clientHeight) {
      img.classList.add(styles.heightLong);
    }
  };

  const onError = e => {
    e.persist();
    e.target.src = defaultImg;
    if (svgImage.current && isSquaricle) {
      svgImage.current.src = defaultImg;
    }
  };

  return (
    <>
      {/* 실제 보여지는 이미지 */}
      <div
        className={classNames(
          className,
          styles.imgWrap,
          circle && styles.circle,
        )}
        onClick={handleShow}
        onKeyPress={() => {}}
        role="button"
        tabIndex="0"
      >
        {isSquaricle && (
          <svg
            fill="#e1e1e1"
            viewBox="0 0 80 80"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
          >
            <desc>{alt}</desc>
            <defs>
              <pattern
                id={`squaricle-${replaceSrc}`}
                width="100%"
                height="100%"
              >
                <image xlinkHref={replaceSrc} x="0" y="0" ref={svgImage} />
              </pattern>
            </defs>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              {cafeProfile ? (
                <g fill="#ffffff">
                  <path
                    fill={`url(#squaricle-${replaceSrc})`}
                    d="M34,1.9h12c18.78,0,34,14.5,34,32.38v11.43C80,63.6,64.78,78.1,46,78.1H34c-18.78,0-34-14.5-34-32.38V34.29
                    C0,16.4,15.22,1.9,34,1.9z"
                  />
                  <path
                    fill="#5BDC7C"
                    d="M46,3.9c17.64,0,32,13.63,32,30.38v11.43C78,62.47,63.64,76.1,46,76.1H34c-17.64,0-32-13.63-32-30.38V34.29
                    C2,17.53,16.36,3.9,34,3.9H46 M46,1.9H34C15.22,1.9,0,16.4,0,34.29v11.43C0,63.6,15.22,78.1,34,78.1h12c18.78,0,34-14.5,34-32.38
                    V34.29C80,16.4,64.78,1.9,46,1.9L46,1.9z"
                  />
                </g>
              ) : (
                <g fill="#e1e1e1">
                  <path
                    d="M40,80c39.69,0,40-17.91,40-40S79.69,0,40,0S0,17.91,0,40S0.31,80,40,80"
                    fill={`url(#squaricle-${replaceSrc})`}
                  />
                </g>
              )}
            </g>
          </svg>
        )}
        <img
          src={replaceSrc}
          alt={alt}
          onError={onError}
          onLoad={halfHeight ? halfOnLoad : onLoad}
          style={{
            display: isSquaricle ? 'none' : 'block',
          }}
        />
        {dim && <div className="dim" />}
        {showTitle && <div className={styles.bannerTitle}>{alt}</div>}
      </div>
      {!noExpand && (
        <FullImageSwiper
          show={show}
          images={[
            {
              mediaUrl: replaceSrc,
              alt,
              type: 0,
            },
          ]}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

Image.propTypes = {
  className: string,
  src: string.isRequired,
  alt: string.isRequired,
  noExpand: bool,
  isSquaricle: bool,
  circle: bool,
  halfHeight: bool,
  dim: bool,
  cafeProfile: bool,
  showTitle: bool,
};

Image.defaultProps = {
  className: '',
  noExpand: false,
  isSquaricle: false,
  circle: false,
  halfHeight: false,
  dim: false,
  cafeProfile: false,
  showTitle: false,
};

export default Image;
