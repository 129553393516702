import axios from 'axios';
import config from '../config';
import { getHeaders } from '../apiUtil';

const baseURL = `${config.API_BASE_URL}`;

/**
 *
 * @param {number} seq
 */
export const getStoreInfo = seq => {
  if (seq) {
    return axios({
      method: 'get',
      url: `/mystore?storeSeq=${seq}`,
      baseURL,
      headers: getHeaders(),
    });
  }

  return axios({
    method: 'get',
    url: `/mystore`,
    baseURL,
    headers: getHeaders(),
  });
};

export const getSettlement = (pageNo = 0, getPath = false) => {
  if (getPath) return `${baseURL}/mystore/settlement?pageNo=${pageNo}`;

  return axios({
    method: 'get',
    url: `/mystore/settlement?pageNo=${pageNo}`,
    baseURL,
    headers: getHeaders(),
  });
};

export const getSettlementDetail = (orderNo, getPath = false) => {
  if (getPath) return `${baseURL}/mystore/settlement/${orderNo}`;

  return axios({
    method: 'get',
    url: `/mystore/settlement/${orderNo}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 상품 리뷰 삭제
 * @param {string} orderNo
 */
export function delOrderReview(orderNo) {
  return axios({
    method: 'delete',
    url: `/mystore/review/${orderNo}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 *
 * @param {string} orderNo
 * @param {number} likeType
 */
export function putReviewLike(orderNo, likeType) {
  const encodeOrderNo = encodeURIComponent(orderNo);
  return axios({
    method: 'put',
    url: `mystore/review/${encodeOrderNo}/like/${likeType}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 *
 * @param {string} orderNo
 * @param {number} likeType
 */
export function delReviewLike(orderNo, likeType) {
  const encodeOrderNo = encodeURIComponent(orderNo);
  return axios({
    method: 'delete',
    url: `/mystore/review/${encodeOrderNo}/like/${likeType}`,
    baseURL,
    headers: getHeaders(),
  });
}

// 기존 MyStore Api

export function putChangeImage({ profile = '', background = '' }) {
  return axios({
    method: 'put',
    url: `/mystore/profile?profile=${profile}&background=${background}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 *
 * @param {number} type  // 1: 스토어 소개, 2: 연락 가능 시간
 * @param {string} content
 */
export function putChangeStoreSetting(type, content) {
  const encodeContent = encodeURIComponent(content);
  return axios({
    method: 'put',
    url: `/mystore/mypage/setting/${type}?content=${encodeContent}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 가게 정보 조회
 * @param {*} storeSeq - path
 */
export function getStoreInfoV2(storeSeq, visitYn = 0) {
  return axios({
    method: 'get',
    url: `/v2/my-store/${storeSeq}?visitYn=${visitYn}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 가게 소개 조회
 * @param {*} storeSeq - path
 */
export function getStoreAbout(storeSeq) {
  return axios({
    method: 'get',
    url: `/v2/my-store/${storeSeq}/about`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 가게 수익 조회
 * @param {*} storeSeq - path
 * @param {*} date - query
 */
export function getStoreBenefit(storeSeq, date) {
  const encodeDate = encodeURIComponent(date);
  return axios({
    method: 'get',
    url: `/v2/my-store/${storeSeq}/benefit?date=${encodeDate}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 가게 응대 가능 시간 조회
 * @param {*} storeSeq - path
 */
export function getStoreContactTime(storeSeq) {
  return axios({
    method: 'get',
    url: `/v2/my-store/${storeSeq}/contact-time`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 가게 공지사항 전체보기
 * @param {*} storeSeq - path
 * @param {*} pageNo - query
 */
export function getStoreNotice(storeSeq, pageNo = 0) {
  return axios({
    method: 'get',
    url: `/v2/my-store/${storeSeq}/notice?pageNo=${pageNo}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 가게 공지사항 삭제
 * @param {*} storeSeq - path
 * @param {*} noticeSeq - query
 */
export function delStoreNotice(storeSeq, noticeSeq) {
  return axios({
    method: 'get',
    url: `/v2/my-store/${storeSeq}/notice?noticeSeq=${noticeSeq}`,
    baseURL,
    headers: getHeaders(),
  });
}

export function getNoticeInfo(storeSeq, productSeq) {
  return axios({
    method: 'get',
    url: `/v2/my-store/${storeSeq}/notice/${productSeq}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 가게 공지사항 등록
 * @param {*} storeSeq - path
 * @param {*} productSeq - path
 * @param {*} notice - body = string
 */
export function putStoreNotice(storeSeq, productSeq, notice) {
  return axios({
    method: 'put',
    url: `/v2/my-store/${storeSeq}/notice/${productSeq}`,
    data: notice,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 가게 전시 상품 등록
 * @param {*} storeSeq - path
 * @param {*} product - body = string
 */
export function putStoreShowCase(storeSeq, product) {
  return axios({
    method: 'put',
    url: `/v2/my-store/${storeSeq}/product`,
    data: product,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 가게 전시 상품 삭제
 * @param {*} storeSeq - path
 * @param {*} productSeq - path
 */
export function delStoreShowCase(storeSeq, productSeq) {
  return axios({
    method: 'delete',
    url: `/v2/my-store/${storeSeq}/product/${productSeq}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 가게 Review
 * @param {*} storeSeq - path
 * @param {*} orderBy - query = NEW: 최신순, HIGH: 평점 높은순, LOW: 평점 낮은순
 */
export function getStoreReview(storeSeq, orderBy, pageNo) {
  const encodeOrder = encodeURIComponent(orderBy);
  const encodePageNo = encodeURIComponent(pageNo);
  return axios({
    method: 'get',
    url: `/v2/my-store/${storeSeq}/review?orderBy=${encodeOrder}&pageNo=${encodePageNo}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 가게 전시 상품 전체보기
 * @param {*} storeSeq - path
 */
export function getStoreShowCase(storeSeq) {
  return axios({
    method: 'get',
    url: `/v2/my-store/${storeSeq}/showcase`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 가게 전시 기본 정보
 * @param {*} storeSeq - path
 */
export function getStoreShowCaseInfo(storeSeq) {
  return axios({
    method: 'get',
    url: `/v2/my-store/${storeSeq}/showcase/info`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 가게 전시 대상 상품 전체보기
 * @param {*} storeSeq - path
 * @param {*} pageNo - query
 */
export function getStoreShowCaseSellList(storeSeq, pageNo = 0) {
  return axios({
    method: 'get',
    url: `/v2/my-store/${storeSeq}/showcase/selling-list?pageNo=${pageNo}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 가게 전시관리 On/Off
 * @param {*} storeSeq - path
 * @param {*} useYn - query
 */
export function putStoreUseShowCase(storeSeq, useYn = 1) {
  return axios({
    method: 'put',
    url: `/v2/my-store/${storeSeq}/showcase/use?useYn=${useYn}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 가게 Tag 관리
 * @param {*} storeSeq - path
 */
export function getStoreTag(storeSeq) {
  return axios({
    method: 'get',
    url: `/v2/my-store/${storeSeq}/tag`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 가게 Tag 등록, 수정
 * @param {*} storeSeq - path
 * @param {*} tags - body = { tagName: string, tagSeq: number }
 */
export function putStoreTag(storeSeq, tags) {
  return axios({
    method: 'put',
    url: `/v2/my-store/${storeSeq}/tag`,
    data: tags,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 가게 Tag 상품 조회
 * @param {*} storeSeq - path
 * @param {*} parameter - body = { orderBy: string, productKind: string, productStatus: string, tagName: string }
 */
export function postStoreTagProduct(storeSeq, parameter, pageNo) {
  return axios({
    method: 'post',
    url: `/v2/my-store/${storeSeq}/tag/product?pageNo=${pageNo}`,
    data: parameter,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 가게 공지사항 텍스트 수정
 * @param {*} noticeSeq - path
 * @param {*} notice - body = string
 */
export function putStoreEditNotice(noticeSeq, notice) {
  return axios({
    method: 'put',
    url: `/v2/my-store/notice/${noticeSeq}`,
    data: notice,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 가게 공지사항 On/Off
 * @param {*} noticeSeq - path
 * @param {*} useYn - query
 */
export function putStoreUseNotice(noticeSeq, useYn = 1) {
  return axios({
    method: 'put',
    url: `/v2/my-store/notice/${noticeSeq}/onoff?useYn=${useYn}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 가게 셀러전문카테고리 리스트
 * @param {*} storeSeq - path
 */
export function getSellerCategory(storeSeq) {
  return axios({
    method: 'get',
    url: `/v2/my-store/sellecategory/${storeSeq}`,
    baseURL,
    headers: getHeaders(),
  });
}

/**
 * 내 가게 셀러전문카테고리 등록/수정
 * @param {*} parameter - body = { sellerCategory:[{ sellerCategorySeq: number, sellerCategorySort: number, sellerCategoryName: string, storeSellerCategorySeq: number, storeSellerCategorySelected: number, sellerCategoryImgUrl: string }] }
 */
export function postSellerCategory(parameter) {
  return axios({
    method: 'post',
    url: `/v2/my-store/sellecategory`,
    data: parameter,
    baseURL,
    headers: getHeaders(),
  });
}
