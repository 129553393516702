import axios from 'axios';
import config from '../config';
import { getHeaders } from '../apiUtil';

const baseURL = `${config.EDGE_API_URL}`;

/**
 * 상품 재고 조회
 * @param {number} productSeq
 */

export const getProductQuantity = (
  productSeq,
  checkOrderForm = false,
  getPath = false,
) => {
  if (getPath) {
    return `${baseURL}/api/product/basic/quantity/${productSeq}?checkOrderForm=${checkOrderForm}`;
  }

  return axios({
    method: 'get',
    url: `/api/product/basic/quantity/${productSeq}?checkOrderForm=${checkOrderForm}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 상품 상태변경 처리
 * @param {*} params
 * {
      "buyerStoreSeq": 0,
      "orderType": 0,
      "productSeq": 0,
      "status": 0
    }
 */

export const putChangeStatus = params => {
  return axios({
    method: 'put',
    url: `/api/product/status`,
    baseURL,
    data: params,
    headers: getHeaders(),
  });
};

/**
 * 상품 정보 조회
 * @param {number} productSeq
 */

export const getProductDetail = (
  productSeq,
  queryStr = '',
  getPath = false,
) => {
  if (getPath) return `${baseURL}/api/product/basic/${productSeq}${queryStr}`;

  return axios({
    method: 'get',
    url: `/api/product/basic/${productSeq}${queryStr}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 상품 재등록 (끌어올리기)
 * @param {number} productSeq
 */
export const putModifyUp = productSeq => {
  return axios({
    method: 'put',
    url: `/api/product/additional/display-top/${productSeq}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 상품 복사 가능 여부 체크
 * @param {number} productSeq
 */
export const getCanCopy = (productSeq, getPath = false) => {
  if (getPath) return `${baseURL}/api/product/additional/copy/${productSeq}`;

  return axios({
    method: 'get',
    url: `/api/product/additional/copy/${productSeq}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 상품 재판매 ( 상품 복사등록 )
 * @param {number} productSeq
 */
export const postReSaleProduct = productSeq => {
  return axios({
    method: 'post',
    url: `/api/product/additional/copy/${productSeq}`,
    baseURL,
    headers: getHeaders(),
  });
};

/**
 * 상품 등록 완료 팝업 정보 조회
 * 네이버카페연동, 광고주센터배너, 대표계좌설정
 */
export const getProductAddComplete = (getPath = false) => {
  if (getPath) return `${baseURL}/api/product/popup/view/add-complete`;

  return axios({
    method: 'get',
    url: `/api/product/popup/view/add-complete`,
    baseURL,
    headers: getHeaders(),
  });
};
