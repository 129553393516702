import { isApp, interfaceShareKakao } from './webView';

// 카카오 공유
export const sendKakaoLink = data => {
  const content = {
    title: data.title,
    description: data.description,
    imageUrl: data.imageUrl,
    link: {
      mobileWebUrl: data.link,
      webUrl: data.link,
    },
  };
  if (data.imageWidth) {
    content.imageWidth = data.imageWidth;
  }
  if (data.imageHeight) {
    content.imageHeight = data.imageHeight;
  }

  if (isApp()) {
    interfaceShareKakao({ ...content, shareUrl: data.link });
  } else if (window.Kakao) {
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content,
      buttons: [
        {
          title: data.btn_title,
          link: {
            mobileWebUrl: data.link,
            webUrl: data.link,
          },
        },
      ],
      installTalk: true,
      fail: () => {
        if (isApp()) {
          interfaceShareKakao(content);
        } else {
          console.log('fail');
        }
      },
    });
  }
};

// 페이스북 공유
export const sendFacebooklink = link => {
  const shareLink = `https://www.facebook.com/sharer/sharer.php?u=${link}`;

  if (isApp()) {
    window.location.href = `jnapps3://?openweb=${shareLink}`;
  } else {
    window.open(shareLink);
  }
};
