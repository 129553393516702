/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import Swiper from 'react-id-swiper';
import { arrayOf, shape, string, number, bool, func } from 'prop-types';
import classNames from 'classnames';

import PopUp from '../popup/PopUp';
import IcClose from '../Svg/IcClose';
import Page from '../Page';

import styles from './FullImageSwiper.module.scss';

const FullImageSwiper = ({ images, show, handleClose, initIndex }) => {
  const fullParams = {
    initialSlide: initIndex,
    containerClass: classNames('swiper-container', styles.swiper),
    loop: true,
    pagination: {
      el: `.swiper-pagination.${styles.pagination}`,
      type: 'fraction',
    },
    noSwiping: images.length < 2,
  };
  return (
    <PopUp show={show} contentClassName={styles.fullImagesPopup}>
      <Page className={classNames(styles.body, 'possibleScroll')}>
        <header
          className={classNames(
            styles.header,
            styles.headerWrap,
            styles.hiddenLine,
            styles.hiddenBg,
          )}
        >
          <div className={classNames(styles.wrap)}>
            <div className={styles.right}>
              <button
                className={styles.closeBtn}
                type="button"
                onClick={handleClose}
              >
                <IcClose width={26} height={26} fillColor="#fff" />
              </button>
            </div>
          </div>
        </header>
        <div className={styles.fullImagesBody}>
          <Swiper {...fullParams}>
            {images.map(img => {
              if (img.type === 1) {
                return (
                  <div className="relative">
                    <video
                      preload="metadata"
                      autoPlay
                      loop
                      poster={img.imgSrc}
                      className={styles.video}
                      playsinline={0}
                    >
                      <source src={img.mediaUrl} type="video/mp4" />
                    </video>
                  </div>
                );
              }

              return <img key={img.alt} src={img.mediaUrl} alt={img.alt} />;
            })}
          </Swiper>
        </div>
      </Page>
    </PopUp>
  );
};

FullImageSwiper.propTypes = {
  images: arrayOf(
    shape({
      seq: number,
      src: string,
      alt: string,
    }),
  ),
  show: bool.isRequired,
  handleClose: func.isRequired,
  initIndex: number,
};

FullImageSwiper.defaultProps = {
  images: [],
  initIndex: 0,
};

export default FullImageSwiper;
