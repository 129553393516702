import { isIOS, isAndroid } from './device';

// userAgent의 앱 정보 체크
export function getOsTypeByUserAgent() {
  const { userAgent } = navigator;
  const index = userAgent.indexOf('JNAPP');

  if (index > -1) {
    const jnAppData = userAgent.slice(index).split(')')[0];
    const osTypeStr = jnAppData.split(';')[1].trim();
    return { os: osTypeStr };
  }
  return null;
}

// sessionStorage나 userAgent에서 저장된 앱 정보 가져옴
export function getAppDatabyStorage(isFindSession = false) {
  const { userAgent } = navigator;
  const uaArray = userAgent.split('|');

  if (uaArray[1] && !isFindSession) {
    try {
      const data = JSON.parse(uaArray[1]);
      return data;
    } catch (e) {
      return null;
    }
  }

  if (sessionStorage.getItem('jnAppInfo')) {
    const data = JSON.parse(sessionStorage.getItem('jnAppInfo'));
    return data;
  }

  return null;
}

// 중고나라 AOS앱 여부 체크
export function isAndroidApp() {
  return Boolean(window.qdillion);
}

// 중고나라 IOS앱 여부 체크
export function isIOSApp() {
  const osType = getOsTypeByUserAgent();

  if (osType !== null && osType.os === 'IOS') {
    return true;
  }

  const data = getAppDatabyStorage();
  return data !== null && data.scheme === 'jnapp' && data['Os-Type'] === '1';
}

// 중고나라 APP 체크
export function isApp() {
  return isAndroidApp() || isIOSApp();
}

// OS TYPE 체크
export const getOsType = (isNormal = true) => {
  if (isNormal) {
    if (isAndroidApp()) return 0;
    if (isIOSApp()) return 1;
    return 2;
  }
  if (isAndroidApp()) return 1;
  if (isIOSApp()) return 2;
  return 3;
};

/**
 * 페이스북 인앱 웹뷰 확인
 */
export function isFaceBookWebView() {
  const ua = window.navigator.userAgent || navigator.vendor || window.opera;
  return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
}
/**
 * 페이스북 웹뷰 닫음
 */
export const closeFacsBookWebView = () => {
  window.close();
};
/**
 * 카카오톡 인앱 웹뷰 확인
 */
export function isKaKaotalkWebView() {
  const ua = window.navigator.userAgent || navigator.vendor || window.opera;
  return ua.indexOf('kakaotalk') > -1 || ua.indexOf('KAKAOTALK') > -1;
}
/**
 * 카카오 웹뷰 닫음 (AOS)
 */
export const closeKaKaoWebView = () => {
  if (isAndroid()) window.location.href = 'kakaotalk://inappbrowser/close';
  else window.location.href = 'kakaoweb://closeBrowser';
};
/**
 * 네이버 밴드 인앱 웹뷰 확인 -> Ios 만
 */
export function isBandWebView() {
  const ua = window.navigator.userAgent || navigator.vendor || window.opera;
  return ua.indexOf('BAND') > -1;
}
/**
 * 기타 위 IOS 웹뷰 체크
 */
export function isIosOtherWebView() {
  return (
    isIOS() && (isFaceBookWebView() || isKaKaotalkWebView() || isBandWebView())
  );
}

// 앱 버전 체크
export function compareAppVersion(versionNum) {
  const data = getAppDatabyStorage();

  // UserAgent가 없을 경우
  if (data === null) {
    return false;
  }

  const baseVersion = versionNum.split('.').map(Number);
  const nowVersion = data['App-Version'].split('.').map(Number);

  for (let i = 0; i < nowVersion.length; i += 1) {
    baseVersion[i] = baseVersion[i] || 0;
    if (nowVersion[i] > baseVersion[i]) {
      return true;
    }
    if (nowVersion[i] !== baseVersion[i]) {
      return false;
    }
  }
  return baseVersion.length <= nowVersion.length;
}

/**
 * Interface
 */
// 웹 뷰 닫기
export const interfaceCloseWebView = () => {
  if (isAndroidApp()) {
    window.qdillion.close();
  } else if (isIOSApp()) {
    window.webkit.messageHandlers.closeEnd.postMessage('');
  }
};

// 앱 후기 유도 팝업
// FE-555 : 앱 버전 분기를 태워야하는 이슈가 있어 버전별 분기 태웠습니다. 강업시 분기 삭제 필요
export const interfaceAppReviewPopup = (
  path = window.location.href,
  label,
  locationCode,
  isClose,
) => {
  if (isAndroidApp() && window.qdillion.ratingPopup) {
    if (compareAppVersion('7.0.8')) {
      window.qdillion.ratingPopup(path, label, locationCode, isClose);
    } else if (compareAppVersion('7.0.6')) {
      window.qdillion.ratingPopup(path, label);
    } else {
      window.qdillion.ratingPopup(path);
    }
  } else if (isIOSApp() && window.webkit.messageHandlers.ratingPopup) {
    if (compareAppVersion('4.4.9')) {
      const data = {
        path,
        label,
        locationCode,
      };
      window.webkit.messageHandlers.ratingPopup.postMessage(
        JSON.stringify(data),
      );
    } else if (compareAppVersion('4.4.7')) {
      const data = {
        path,
        label,
      };
      window.webkit.messageHandlers.ratingPopup.postMessage(
        JSON.stringify(data),
      );
    } else {
      window.webkit.messageHandlers.ratingPopup.postMessage(path);
    }
  }
};

// 로그인 팝업 오픈 요청
export function interfaceAppLoginPopup(url = window.location.href) {
  if (isAndroidApp() && window.qdillion.openLoginPopup) {
    window.qdillion.openLoginPopup(url);
  } else if (isIOSApp() && window.webkit.messageHandlers.openLoginPopup) {
    window.webkit.messageHandlers.openLoginPopup.postMessage(url);
  }
}

// 모빌리언스 휴대폰 본인 인증
export function interfaceMobilians(url) {
  if (isAndroidApp() && window.qdillion.androidMobilians) {
    window.qdillion.androidMobilians();
  } else if (isIOSApp()) {
    if (
      window.webkit.messageHandlers.iosMobilians &&
      compareAppVersion('4.4.8')
    ) {
      window.webkit.messageHandlers.iosMobilians.postMessage('');
    } else {
      window.location.href = `jnapps3://?openmobiliance=${url}`;
    }
  }
}

// 카카오톡 공유하기
export function interfaceShareKakao(content) {
  if (isAndroidApp() && window.qdillion.shareKakao) {
    window.qdillion.shareKakao(JSON.stringify(content));
  } else if (isIOSApp() && window.webkit.messageHandlers.shareKakao) {
    window.webkit.messageHandlers.shareKakao.postMessage(
      JSON.stringify(content),
    );
  }
}

// 상품 상세 업데이트
export const interfaceProductRefresh = () => {
  if (isAndroidApp() && window.qdillion.productRefresh) {
    window.qdillion.productRefresh();
  } else if (isIOSApp() && window.webkit.messageHandlers.productRefresh) {
    window.webkit.messageHandlers.productRefresh.postMessage('');
  }
};

// 안드로이드 뒤로가기 작동 여부
export const interfaceAosBackHold = (isBack = false) => {
  if (
    isAndroidApp() &&
    window.qdillion.setBackHold &&
    compareAppVersion('7.0.7')
  ) {
    window.qdillion.setBackHold(isBack);
  }
};

// 주소검색
export function interfaceSetDelivery(address) {
  if (isAndroidApp() && window.qdillion.setDelivery) {
    window.qdillion.setDelivery(JSON.stringify(address));
  } else if (isIOSApp() && window.webkit.messageHandlers.setDelivery) {
    window.webkit.messageHandlers.setDelivery.postMessage(
      JSON.stringify(address),
    );
  }
}

// 위치정보 서비스 허용 팝업
export function interfaceLocationService() {
  if (isAndroidApp() && window.qdillion.locationSetting) {
    window.qdillion.locationSetting();
    return true;
  }
  return false;
}

// 우리동네 등록
export function interfaceSetMyLocation(locationParam) {
  if (isAndroidApp() && window.qdillion.setLocation) {
    window.qdillion.setLocation(JSON.stringify(locationParam));
  } else if (isIOSApp() && window.webkit.messageHandlers.setLocation) {
    window.webkit.messageHandlers.setLocation.postMessage(
      JSON.stringify(locationParam),
    );
  }
}

// 채팅 위치전송
export function interfaceShareLocation(locationParam) {
  if (isAndroidApp() && window.qdillion.shareLocation) {
    window.qdillion.shareLocation(JSON.stringify(locationParam));
  } else if (isIOSApp() && window.webkit.messageHandlers.shareLocation) {
    window.webkit.messageHandlers.shareLocation.postMessage(
      JSON.stringify(locationParam),
    );
  }
}

// 오토상품 Step 이어등록
export function interfaceAutoRegistStep(productSeq, garageStep) {
  const param = {
    productSeq,
    garageStep,
  };
  if (
    isAndroidApp() &&
    window.qdillion.autoStepSeq &&
    compareAppVersion('7.0.7')
  ) {
    window.qdillion.autoStepSeq(JSON.stringify(param));
  } else if (
    isIOSApp() &&
    window.webkit.messageHandlers.autoStepSeq &&
    compareAppVersion('4.4.8')
  ) {
    window.webkit.messageHandlers.autoStepSeq.postMessage(
      JSON.stringify(param),
    );
  } else {
    window.location.href = `jnapps3://?autostepseq=${productSeq}&garageStep=${garageStep}`;
  }
}

// 진단예약완료 이후 플로우
export function interfaceInspectReservation(reserveData) {
  if (isAndroidApp() && window.qdillion.actionInspectReservation) {
    window.qdillion.actionInspectReservation(JSON.stringify(reserveData));
  } else if (
    isIOSApp() &&
    window.webkit.messageHandlers.actionInspectReservation
  ) {
    window.webkit.messageHandlers.actionInspectReservation.postMessage(
      JSON.stringify(reserveData),
    );
  }
}

// 앱 정보 요청 (토큰정보 포함)
export function interfaceGetAppInfo(isOnlyCheck = false) {
  if (isAndroidApp() && window.qdillion.getAppInfo) {
    if (!isOnlyCheck) {
      window.qdillion.getAppInfo();
    }
    return true;
  }

  if (isIOSApp() && window.webkit.messageHandlers.getAppInfo) {
    if (!isOnlyCheck) {
      window.webkit.messageHandlers.getAppInfo.postMessage('');
    }
    return true;
  }
  return false;
}

// 앱에 키워드 저장 요청
export function interfaceAddKeyword(keyword) {
  if (isAndroidApp() && window.qdillion.addKeyword) {
    window.qdillion.addKeyword(JSON.stringify(keyword));
  }
  if (isIOSApp() && window.webkit.messageHandlers.addKeyword) {
    window.webkit.messageHandlers.addKeyword.postMessage(
      JSON.stringify(keyword),
    );
  }
}

// 앱에 키워드 리스트 저장 요청
export function interfaceAddKeywordList(keywordsList) {
  if (isAndroidApp() && window.qdillion.addAllKeyword) {
    window.qdillion.addAllKeyword(JSON.stringify(keywordsList));
  }
  if (isIOSApp() && window.webkit.messageHandlers.addAllKeyword) {
    window.webkit.messageHandlers.addAllKeyword.postMessage(
      JSON.stringify(keywordsList),
    );
  }
}

// 앱에 키워드 삭제 요청
export function interfaceDeleteKeyword(keyword) {
  if (isAndroidApp() && window.qdillion.deleteKeyword) {
    window.qdillion.deleteKeyword(JSON.stringify(keyword));
  }
  if (isIOSApp() && window.webkit.messageHandlers.deleteKeyword) {
    window.webkit.messageHandlers.deleteKeyword.postMessage(
      JSON.stringify(keyword),
    );
  }
}

// 앱 내 저장된 키워드 저장 여부 변경
export function interfaceDeleteAllKeywordList() {
  if (isAndroidApp() && window.qdillion.deleteAllKeyword) {
    window.qdillion.deleteAllKeyword();
  }
  if (isIOSApp() && window.webkit.messageHandlers.deleteAllKeyword) {
    window.webkit.messageHandlers.deleteAllKeyword.postMessage('');
  }
}

// 앱에 키워드 autoSave값 변경
export function interfaceToggleAutoSave(data) {
  if (isAndroidApp() && window.qdillion.setKeywordAutoSave) {
    window.qdillion.setKeywordAutoSave(JSON.stringify(data));
  }
  if (isIOSApp() && window.webkit.messageHandlers.setKeywordAutoSave) {
    window.webkit.messageHandlers.setKeywordAutoSave.postMessage(
      JSON.stringify(data),
    );
  }
}

// 앱 내 저장된 키워드 리스트 가져오기
export function interfaceGetKeywordData() {
  if (isAndroidApp() && window.qdillion.getAllKeywordData) {
    window.qdillion.getAllKeywordData();
    return true;
  }

  if (isIOSApp() && window.webkit.messageHandlers.getAllKeywordData) {
    window.webkit.messageHandlers.getAllKeywordData.postMessage('');
    return true;
  }
  return false;
}

// 앱 상품상세 인터페이스
export function interfaceOpenProductDetail(isOnlyCheck = false, data) {
  if (isAndroidApp() && window.qdillion.openProductDetail) {
    if (!isOnlyCheck) {
      window.qdillion.openProductDetail(JSON.stringify(data));
    }
    return true;
  }

  if (isIOSApp() && window.webkit.messageHandlers.openProductDetail) {
    if (!isOnlyCheck) {
      window.webkit.messageHandlers.openProductDetail.postMessage(
        JSON.stringify(data),
      );
    }
    return true;
  }
  return false;
}

// 안드로이드 물리적 백버튼 -> 주문/결제 취소 팝업
export function interfacePaymentHardwareBackKey(bool) {
  const isEnabled = bool.toString();
  if (isAndroidApp() && window.qdillion.cancelPaymentWithHardwareBackKey) {
    window.qdillion.cancelPaymentWithHardwareBackKey(isEnabled);
  }
}

// 네이티브 결제하기 네비게이션바 노출
export function interfacePaymentNavigation(data) {
  if (isAndroidApp() && window.qdillion.showPaymentNavigation) {
    window.qdillion.showPaymentNavigation(JSON.stringify(data));
    return true;
  }

  if (isIOSApp() && window.webkit.messageHandlers.showPaymentNavigation) {
    window.webkit.messageHandlers.showPaymentNavigation.postMessage(
      JSON.stringify(data),
    );
    return true;
  }
  return false;
}

// 회원가입 페이지로 연결
export function interfaceShowJoinPopup() {
  if (isAndroidApp() && window.qdillion.showJoinPopup) {
    window.qdillion.showJoinPopup();
    return true;
  }

  if (isIOSApp() && window.webkit.messageHandlers.showJoinPopup) {
    window.webkit.messageHandlers.showJoinPopup.postMessage('');
    return true;
  }
  return false;
}

/** 앱스플라이어 데이터 전송. CR-2009 */
export function interfaceSendAppsflyer(name, params) {
  if (isAndroidApp() && window.qdillion.sendAppsflyer) {
    const data = {
      name,
      params: JSON.stringify(params),
    };

    window.qdillion.sendAppsflyer(data.name, data.params);
    return true;
  }

  if (isIOSApp() && window.webkit.messageHandlers.sendAppsflyer) {
    const data = {
      name,
      params,
    };
    window.webkit.messageHandlers.sendAppsflyer.postMessage(
      JSON.stringify(data),
    );
    return true;
  }
  return false;
}
