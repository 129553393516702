import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';

import { getRecommendCode } from '../../api/base/user';

export const Actions = {
  OPEN: 'INVITE_FRIENDS/OPEN',
  CLOSE: 'INVITE_FRIENDS/CLOSE',
  GET_CODE: 'INVITE_FRIENDS/GET_CODE',
  SET_CODE: 'INVITE_FRIENDS/SET_CODE',
};

const initialState = {
  show: false,
  code: '',
};

export const open = createAction(Actions.OPEN);
export const close = createAction(Actions.CLOSE);
export const getCode = createAction(Actions.GET_CODE, getRecommendCode);
export const setCode = createAction(Actions.SET_CODE);

export default handleActions(
  {
    [Actions.OPEN]: state => {
      return { ...state, show: true };
    },
    [Actions.CLOSE]: state => {
      return { ...state, show: false };
    },

    // 친구 초대 코드 조회
    ...pender({
      type: Actions.GET_CODE,
      onSuccess: (state, action) => {
        const {
          data: { data },
        } = action.payload;
        return {
          ...state,
          code: data,
        };
      },
      onFailure: state => {
        // 에러처리 -> 필요한가?
        return {
          ...state,
        };
      },
    }),

    [Actions.SET_CODE]: (state, action) => {
      return { ...state, code: action.payload };
    },
  },
  initialState,
);
